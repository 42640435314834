import React from 'react'
import style from '../../assets/editor.module.scss'
import { RS, EM, IF } from '../imports'
import NaviBarTopSetting from '../Navigation/NaviBarTopSetting'
import NaviBarBotSetting from '../Navigation/NaviBarBotSetting'
import MenuBarSetting from '../Navigation/MenuBarSetting'
import SyncSetting from '../Navigation/SyncSetting'
import IconSetting from '../Navigation/IconSetting'
import { handleShowMenuIcon } from '../Navigation/methods'


const NavigationEditor = (props: {
    loading: boolean,
    control: IF.CTRL.ControlIF,
    naviBar: IF.NAVG.NaviBarIF,
    menuBar: IF.NAVG.MenuBarIF,
    modules: IF.STAT.ModuleIF[],
    selection: IF.COMM.ValueNameIF,
    saveStyle: React.CSSProperties,
    undoStyle: React.CSSProperties,
    uploading: boolean,
    uploadBar: any,
    NAVGSnapshot: IF.NAVG.NaviBarIF,
    MENUSnapshot: IF.NAVG.MenuBarIF,
    setControl: React.Dispatch<React.SetStateAction<IF.CTRL.ControlIF>>,
    setNaviBar: React.Dispatch<React.SetStateAction<IF.NAVG.NaviBarIF>>,
    setMenuBar: React.Dispatch<React.SetStateAction<IF.NAVG.MenuBarIF>>,
    setShowMenu: React.Dispatch<React.SetStateAction<boolean>>,
    setOpenIcon: React.Dispatch<React.SetStateAction<boolean>>,
    setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>,
    setSelection: React.Dispatch<React.SetStateAction<IF.COMM.ValueNameIF>>,
    setUploading: React.Dispatch<React.SetStateAction<boolean>>,
    setUploadbar: React.Dispatch<React.SetStateAction<any>>,
    setFileObject: React.Dispatch<React.SetStateAction<string | File>>,
}) => {
    /** Properties */
    const {
        loading, control, naviBar, menuBar, modules, selection, saveStyle,
        undoStyle, uploading, uploadBar, NAVGSnapshot, MENUSnapshot,
        setControl, setNaviBar, setMenuBar, setShowMenu, setOpenIcon,
        setOpenMenu, setSelection, setUploading, setUploadbar, setFileObject } = props

    // const [selection, setSelection] = React.useState<string>(EM.NAVG.SETTING.DEFAULT)
    const [menuList, setMenuList] = React.useState<IF.COMM.ValueIF[]>(RS.NAVG.menuList)

    React.useEffect(() => {
        const allowTwo = naviBar.sync !== 1 && naviBar.sync !== 4
        const allowThree = naviBar.sync !== 2 && naviBar.sync !== 3 && naviBar.sync !== 4
        setMenuList([
            {
                ...menuList[0],
                value: naviBar.optionOne === 1 ?
                    `url(${naviBar.imageOne}) center center / cover` : naviBar.colorOne,
                color: naviBar.textOne,
            },
            {
                ...menuList[1],
                value: naviBar.optionTwo === 1 ?
                    `url(${naviBar.imageTwo}) center center / cover` : naviBar.colorTwo,
                color: naviBar.textTwo,
                allow: allowTwo,
            },
            {
                ...menuList[2],
                value: menuBar.option === 1 ?
                    `url(${menuBar.image}) center center / cover` : menuBar.color,
                color: menuBar.titleColor,
                allow: allowThree,
            },
            {
                ...menuList[3],
                value: menuBar.iconOption === 1 ? menuBar.iconImage : menuBar.icon,
                color: menuBar.iconOption === 1 ? menuBar.titleColor : menuBar.iconColor,
                number: menuBar.iconOption
            },
            { ...menuList[4] },
        ])
    }, [naviBar, menuBar])

    const onSelectSetting = (item: IF.COMM.ValueIF) => {
        if (!item.allow) return
        setSelection(item as IF.COMM.ValueNameIF)
        const pages = Array.from(document.getElementsByClassName(style.editorPageOne) as HTMLCollectionOf<HTMLElement>)
        pages.forEach((pages: any) => (pages.style.transform = 'translateX(-100%)'))
        setControl({ ...control, page: 2 })
        const body = document.getElementsByTagName('body')[0]
        setTimeout(() => { body.style.overflowY = EM.COMM.BODY.HIDDEN }, 200)
        if (item.name === EM.NAVG.SETTING.NAVITOP) {
            if (window.scrollY !== 0) {
                body.style.overflowY = EM.COMM.BODY.INITIAL
                setTimeout(() => {
                    const html = document.documentElement
                    html.scrollTop = 0
                }, 100)
            }
        }
        if (item.name === EM.NAVG.SETTING.NAVIBOT) {
            if (window.scrollY < 20) {
                body.style.overflowY = EM.COMM.BODY.INITIAL
                setTimeout(() => {
                    const html = document.documentElement
                    html.scrollTop = 100
                }, 100)
            }
        }
        if (item.name === EM.NAVG.SETTING.MENU) {
            setShowMenu(true)
            setOpenMenu(true)
        }
        if (item.name === EM.NAVG.SETTING.ICON) {
            setOpenIcon(true)
        }
    }

    const textStyle: React.CSSProperties = { position: 'absolute' }
    const iconStyle: React.CSSProperties = { position: 'absolute', bottom: '8px' }
    const iconHeight: React.CSSProperties = { height: '100%' }

    return (
        <div className={style.editorPageDiv}>
            <div className={style.editorPage}>
                <div className={style.editorPageOne}>
                    <div className={style.editorPageOneDiv}>
                        {menuList.map((item: IF.COMM.ValueIF, index: number) => {
                            const borderColor = item.number === 1 ? control.color : item.color
                            const thisStyle = index !== 3 ? { background: item.value, color: item.color } :
                                { border: `1px solid ${borderColor}`, color: borderColor }
                            return <div key={index} className={style.editorPageOneOuterNV}>
                                <div className={item.allow ? style.editorPageOneInner
                                    : style.editorPageOneDivDisable} style={thisStyle}
                                    onClick={() => onSelectSetting(item)}>
                                    {index !== 3 && <span style={textStyle}>{item.name}</span>}
                                    {index === 3 && <span style={item.number === 1 ? textStyle : iconStyle}>
                                        {item.name}</span>}
                                    {index === 3 && item.number === 1 &&
                                        <img src={item.value} style={iconHeight} alt='menu-icon' />}
                                    {index === 3 && item.number === 2 &&
                                        handleShowMenuIcon(item.value, 70, item.color!)}
                                </div>
                            </div>
                        })}
                    </div>
                </div>
                <div className={style.editorPageOne}>
                    {selection.name === EM.NAVG.SETTING.NAVITOP &&
                        <NaviBarTopSetting
                            control={control}
                            naviBar={naviBar}
                            modules={modules}
                            snapShot={NAVGSnapshot}
                            saveStyle={saveStyle}
                            undoStyle={undoStyle}
                            uploading={uploading}
                            uploadBar={uploadBar}
                            loading={loading}
                            setNaviBar={setNaviBar}
                            setUploading={setUploading}
                            setUploadbar={setUploadbar}
                            setFileObject={setFileObject} />}
                    {selection.name === EM.NAVG.SETTING.NAVIBOT &&
                        <NaviBarBotSetting
                            control={control}
                            naviBar={naviBar}
                            modules={modules}
                            snapShot={NAVGSnapshot}
                            saveStyle={saveStyle}
                            undoStyle={undoStyle}
                            uploading={uploading}
                            uploadBar={uploadBar}
                            loading={loading}
                            setNaviBar={setNaviBar}
                            setUploading={setUploading}
                            setUploadbar={setUploadbar}
                            setFileObject={setFileObject} />}
                    {selection.name === EM.NAVG.SETTING.MENU &&
                        <MenuBarSetting
                            control={control}
                            menuBar={menuBar}
                            snapShot={MENUSnapshot}
                            modules={modules}
                            saveStyle={saveStyle}
                            undoStyle={undoStyle}
                            uploading={uploading}
                            uploadBar={uploadBar}
                            loading={loading}
                            setMenuBar={setMenuBar}
                            setUploading={setUploading}
                            setUploadbar={setUploadbar}
                            setFileObject={setFileObject} />}
                    {selection.name === EM.NAVG.SETTING.ICON &&
                        <IconSetting
                            control={control}
                            menuBar={menuBar}
                            snapShot={MENUSnapshot}
                            saveStyle={saveStyle}
                            undoStyle={undoStyle}
                            uploading={uploading}
                            uploadBar={uploadBar}
                            loading={loading}
                            setMenuBar={setMenuBar}
                            setUploading={setUploading}
                            setUploadbar={setUploadbar}
                            setFileObject={setFileObject} />}
                    {selection.name === EM.NAVG.SETTING.SYNC &&
                        <SyncSetting
                            naviBar={naviBar}
                            setNaviBar={setNaviBar} />}
                </div>
            </div>
        </div>
    )
}

export default NavigationEditor