import React from 'react'
import style from '../../assets/editor.module.scss'
import { Col } from 'antd'
import { EM, IF } from '../imports'

/**
 * Editor ant icon picker component to pick ant icons.
 * @param color icon color.
 * @param selection dropdown selection options.
 * @param iconList icon array.
 * @param handlePreviewIcon callback function to update ant icon preview.
 * @returns editor ant icon picker component.
 */
const EditorAntIconPicker: React.FC<{
  color: string
  selection: IF.COMM.ValueNameIF
  iconList: (color: string) => IF.COMM.IconIF[]
  handlePreviewIcon: (icon: string) => void
}> = ({ color, selection, iconList, handlePreviewIcon }) => {
  /**
   * The ant icon component to display the ant icons to be selected.
   * @returns JSX element to render the array of ant icons.
   */
  const getIcons = () => {
    return iconList(color).map((item: IF.COMM.IconIF, index: number) => (
      <Col xs={12} sm={4} md={6} key={index} className={style.pickerIconColumn}>
        <div className={style.pickerIconDivOne}>
          {<div onClick={() => handlePreviewIcon(item.icon)}>{item.jsx}</div>}
        </div>
      </Col>
    ))
  }

  return <>{selection.value === EM.COMM.OPTION.ICON && getIcons()}</>
}

export default EditorAntIconPicker
