import React from 'react'
import style from '../../assets/editor.module.scss'
import { Button, Col, Input, InputRef, Progress, Typography } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { EM, IF, RS } from '../imports'

const { Text } = Typography

/**
 * Editor image picker component to pick images.
 * @param type image type for the current component.
 * @param images image array to select.
 * @param selection dropdown selection options.
 * @param imageRef React image ref.
 * @param saveStyle CSS style for the save button.
 * @param fileName fileName state.
 * @param uploading indicating whether the file component is uploading.
 * @param uploadBar upload status while uploading a file.
 * @param loading indicating whether the component is loading.
 * @param setUploading React dispatcher callback function to update uploading state.
 * @param setUploadbar React dispatcher callback function to update uploadbar state.
 * @param handlePreviewImage callback function to update image preview.
 * @param handlePreviewURL callback function to update image URL preview.
 * @param handlePreviewUpload callback function to update image upload file preview.
 * @returns editor image picker component.
 */
const EditorImagePicker: React.FC<{
  type: EM.COMM.IMAGETYPE
  images: IF.COMM.ImageIF[]
  selection: IF.COMM.ValueNameIF
  imageRef: React.RefObject<InputRef>
  saveStyle: React.CSSProperties
  fileName: string
  uploading: boolean
  uploadBar: any
  loading: boolean
  setUploading: React.Dispatch<React.SetStateAction<boolean>>
  setUploadbar: React.Dispatch<React.SetStateAction<any>>
  handlePreviewImage: (image: string) => void
  handlePreviewURL: (url: string) => void
  handlePreviewUpload: (event: React.ChangeEvent<HTMLInputElement>) => void
}> = ({
  type,
  images,
  selection,
  imageRef,
  saveStyle,
  fileName,
  uploading,
  uploadBar,
  loading,
  setUploading,
  setUploadbar,
  handlePreviewImage,
  handlePreviewURL,
  handlePreviewUpload,
}) => {
  /**
   * React hooks on the editor image picker level.
   */
  const [sizeMessage, setSizeMessage] = React.useState<EM.COMM.IMAGEERROR>(EM.COMM.IMAGEERROR.NONE)

  /**
   * Local variables.
   * - fiveMBErrorType: if the image type can trigger the error message for which the file size is bigger than 5MB.
   */
  const fiveMBErrorType =
    type === EM.COMM.IMAGETYPE.BKGD ||
    type === EM.COMM.IMAGETYPE.CTRL ||
    type === EM.COMM.IMAGETYPE.AVATAR ||
    type === EM.COMM.IMAGETYPE.NAVBAR ||
    type === EM.COMM.IMAGETYPE.MENUBAR ||
    type === EM.COMM.IMAGETYPE.MENUICON

  /**
   * Handles error, warning or empty messages for upload file size.
   * @param fileSize the file size.
   */
  const handleMessage = (fileSize: number) => {
    let errorMessage = EM.COMM.IMAGEERROR.NONE
    if (fiveMBErrorType && fileSize > RS.COMM.size5MB) {
      errorMessage = EM.COMM.IMAGEERROR.ERROR5
    } else if (type === EM.COMM.IMAGETYPE.FRAME && fileSize > RS.COMM.size3MB) {
      errorMessage = EM.COMM.IMAGEERROR.ERROR3
    } else if (type === EM.COMM.IMAGETYPE.BORDER && fileSize > RS.COMM.size2MB) {
      errorMessage = EM.COMM.IMAGEERROR.ERROR2
    }
    setSizeMessage(errorMessage)
  }

  /**
   * Displays the error or warning messages for the file size.
   * @returns JSX element to display the text in either warning or danger color style.
   */
  const displayMessage = () => {
    if (sizeMessage === EM.COMM.IMAGEERROR.NONE && fileName === EM.COMM.FILENAME.NOTAVAILABLE) {
      if (fiveMBErrorType) {
        return <Text type="warning">{EM.COMM.IMAGEERROR.WARN5}</Text>
      } else if (type === EM.COMM.IMAGETYPE.FRAME) {
        return <Text type="warning">{EM.COMM.IMAGEERROR.WARN3}</Text>
      } else if (type === EM.COMM.IMAGETYPE.BORDER) {
        return <Text type="warning">{EM.COMM.IMAGEERROR.WARN2}</Text>
      }
    }
    return <Text type="danger">{sizeMessage}</Text>
  }

  /**
   * Pass the event data to the method handlePreviewImage to display previews for image selection.
   * If the file upload snippet doesn't contain any file, return void
   * @param event React change event that contains the file to be displayed and uploaded.
   * @returns void
   */
  const onChangeUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files![0] === undefined) return
    handleMessage(event.target.files![0].size)
    handlePreviewUpload(event)
  }

  /**
   * The mouse event to open the file upload snippet upon clicking the upload button.
   */
  const uploadImage = () => {
    imageRef?.current?.input!.click()
    setUploading(false)
    setUploadbar({ percentage: 0 })
  }

  /**
   * The image component to display the images to be selected.
   * @returns JSX element to render the array of images.
   */
  const getImages = () => {
    if ([EM.COMM.IMAGETYPE.CTRL, EM.COMM.IMAGETYPE.MENUICON].includes(type)) {
      return images.map((item: IF.COMM.ImageIF, index: number) => (
        <Col xs={12} sm={4} md={6} key={index} className={style.pickerIconColumn}>
          <div className={style.pickerIconDivTwo}>
            <img
              className={style.pickerIcon}
              src={item.url}
              alt={`editor-image-${index}`}
              loading="lazy"
              onClick={() => handlePreviewImage(item.url)}
            />
          </div>
        </Col>
      ))
    } else {
      return images.map((item: IF.COMM.ImageIF, index: number) => (
        <Col xs={12} sm={8} key={index}>
          <div className={style.pickerImageDiv}>
            <img
              className={style.pickerImage}
              src={item.url}
              alt={`editor-image-${index}`}
              loading="lazy"
              onClick={() => handlePreviewImage(item.url)}
            />
          </div>
        </Col>
      ))
    }
  }

  /**
   * The URL component for users to enter the image URL.
   * @returns JSX element to render the input element for entering the image URL.
   */
  const getURL = () => (
    <div className={style.pickerURL}>
      <Input placeholder={EM.COMM.IMAGE.HOLDER} onChange={(event) => handlePreviewURL(event.target.value)} />
    </div>
  )

  /**
   * The upload component to upload file and display corresponding messages about the file size.
   * @returns JSX element to display the file upload status and the upload button.
   */
  const getUpload = () => (
    <>
      <Col xs={6} sm={6}>
        <div className={style.pickerUploadDiv}>
          <Input
            type="file"
            ref={imageRef}
            style={RS.COMM.hideBlock}
            accept="image/*"
            onChange={(event) => onChangeUpload(event)}
          />
          <Button
            type="primary"
            style={saveStyle}
            disabled={loading}
            size={EM.COMM.BUTTON.SMALL}
            onClick={() => uploadImage()}
          >
            {EM.COMM.BUTTON.UPLOAD} <UploadOutlined />
          </Button>
        </div>
      </Col>
      <Col xs={18} sm={18}>
        {uploading && (
          <div className={style.pickerProgress}>
            <Progress size="small" percent={uploadBar.percentage} status="active" />
          </div>
        )}
        <div className={style.pickerText}>
          {!uploading && (
            <div>
              {EM.COMM.IMAGE.NAME}
              {fileName}
            </div>
          )}
        </div>
      </Col>
      <div className={style.pickerWarning}>{displayMessage()}</div>
    </>
  )

  return (
    <>
      {selection.value === EM.COMM.OPTION.IMAGE && getImages()}
      {selection.value === EM.COMM.OPTION.URL && getURL()}
      {selection.value === EM.COMM.OPTION.UPLOAD && getUpload()}
    </>
  )
}

export default EditorImagePicker
