import React, { CSSProperties } from 'react'
import style from '../../assets/editor.module.scss'
import { isEqual } from 'lodash'
import { Button, InputRef, Row } from 'antd'
import { DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons'
import {
  EditorDropDown,
  EditorImage,
  EditorInput,
  EditorLabel,
  EditorHistoryLabel,
  EditorParallel,
  EditorSlider,
} from '../Common/EditorParts'
import { EditorHistorySVGPicker } from '../Common/History'
import { handleColorPickerWidth, handleGradientPointer } from '../Editor/methods'
import { EM, IF, RS, UT } from '../imports'
import EditorImagePicker from '../Common/EditorImagePicker'
import EditorColorPicker from '../Common/EditorColorPicker'
import EditorSVG from '../Common/EditorSVG'
import EditorGradientPicker from '../Common/EditorGradientPicker'

/**
 * Background Setting component to edit background properties.
 * @param auth authentication state.
 * @param control control state.
 * @param saveStyle CSS style for the save button.
 * @param undoStyle CSS style for the undo button.
 * @param uploading indicating whether the file component is uploading.
 * @param uploadBar upload status while uploading a file.
 * @param background background state.
 * @param backgrounds background array state.
 * @param snapShot background snapshot state on the reducer level.
 * @param setControl React dispatcher callback function to update control state.
 * @param setUploading React dispatcher callback function to update uploading state.
 * @param setUploadbar React dispatcher callback function to update uploadbar state.
 * @param setFileObject React dispatcher callback function to update fileObject state.
 * @param setBackgrounds React dispatcher callback function to update backgrounds state.
 * @returns background setting JSX component.
 */
const BackgroundSetting: React.FC<{
  auth: IF.AUTH.AuthIF
  control: IF.CTRL.ControlIF
  saveStyle: CSSProperties
  undoStyle: CSSProperties
  uploading: boolean
  uploadBar: IF.COMM.UploadStatusIF
  background: IF.BKGD.BackgroundIF
  backgrounds: IF.BKGD.BackgroundIF[]
  snapShot: IF.BKGD.BackgroundIF
  setControl: React.Dispatch<React.SetStateAction<IF.CTRL.ControlIF>>
  setUploading: React.Dispatch<React.SetStateAction<boolean>>
  setUploadbar: React.Dispatch<React.SetStateAction<any>>
  setFileObject: React.Dispatch<React.SetStateAction<string | File>>
  setBackgrounds: React.Dispatch<React.SetStateAction<IF.BKGD.BackgroundIF[]>>
}> = ({
  auth,
  control,
  saveStyle,
  undoStyle,
  uploading,
  uploadBar,
  background,
  backgrounds,
  snapShot,
  setControl,
  setUploading,
  setUploadbar,
  setFileObject,
  setBackgrounds,
}) => {
  /**
   * React hooks on the background setting level.
   */
  const [selection, setSelection] = React.useState<IF.BKGD.SelectOptionIF>(RS.BKGD.selectDefault)
  const [palette, setPalette] = React.useState<IF.BKGD.SVGComponentIF[]>(background.svg)
  const [fileName, setFileName] = React.useState<string>(EM.COMM.FILENAME.NOTAVAILABLE)
  const imageRef = React.useRef<InputRef>(null)
  const gradientRef = React.useRef<HTMLDivElement>(null)

  /**
   * Obtains the color picker width upon loading the background setting component.
   */
  React.useEffect(() => {
    handleColorPickerWidth()
  }, [])

  /**
   * Updates the SVG palette preview for the selected background and the CSS style for the cross hair
   * pointers of the SVG palette component when either palette or gradientRef is updated with the useEffect hook.
   */
  React.useEffect(() => {
    handlePalettePreview(palette)
    handleGradientPointer(gradientRef)
  }, [palette, gradientRef])

  /**
   * Local dynamic variables.
   * - showPickerComponent: toggle to display the picker component based on selection options.
   * - showColorPicker: toggle to display the color component if selecting the color picker option.
   * - showGradientPicker: toggle to display the picker component if selecting the gradient picker option.
   */
  const showPickerComponent = UT.toggleDisplay(selection !== RS.BKGD.selectDefault)
  const showColorPicker = isEqual(selection, RS.BKGD.selectColor)
  const showGradientPicker = isEqual(selection, RS.BKGD.selectGradient)

  /**
   * The main image component to be rendered for the background setting component.
   * @returns the JSX element to be rendered.
   */
  const mainImage = (): JSX.Element => {
    const imageStyle: CSSProperties =
      background.option === 1 ? { background: background.image } : { background: background.color }
    return (
      <div className={style.editorPreviewImage} style={imageStyle}>
        {background.option === 3 && <EditorSVG svg={background.svg} height={150} />}
      </div>
    )
  }

  /**
   * Update the selected background title.
   * @param title background title.
   * @returns void.
   */
  const handlePreviewTitle = (title: string) => {
    setBackgrounds([
      ...backgrounds.slice(0, control.index),
      { ...background, title: title },
      ...backgrounds.slice(control.index + 1),
    ])
  }

  /**
   * Update the selected background height.
   * @param height background height.
   * @returns void.
   */
  const handlePreviewHeight = (height: string) => {
    setBackgrounds([
      ...backgrounds.slice(0, control.index),
      { ...background, height: height },
      ...backgrounds.slice(control.index + 1),
    ])
  }

  /**
   * Update the selected background order.
   * @param moveUp if moveUp button or moveDown button is clicked.
   * @returns void.
   */
  const handlePreviewOrder = (moveUp: boolean) => {
    let index = control.index
    if (moveUp && background.order === 1) return
    if (!moveUp && background.order === backgrounds.length) return
    const aboveItems = index === 0 ? [] : backgrounds.slice(0, index - 1)
    let oneAbove = backgrounds[index - 1]
    let thisItem = { ...background }
    let oneBelow = backgrounds[index + 1]
    let middleItems = [oneAbove, thisItem, oneBelow]
    const order = thisItem.order
    const belowItems = backgrounds.slice(index + 2)
    let thisTop = document.getElementById(`${EM.CTRL.TYPE.BACKGROUND}-${thisItem.title}`)!.offsetTop
    if (moveUp) {
      oneAbove = { ...oneAbove, order: order + 1 }
      thisItem = { ...thisItem, order: order - 1 }
      middleItems = [thisItem, oneAbove, oneBelow]
      index -= 1
      thisTop = document.getElementById(`${EM.CTRL.TYPE.BACKGROUND}-${oneAbove.title}`)!.offsetTop
    } else {
      oneBelow = { ...oneBelow, order: order - 1 }
      thisItem = { ...thisItem, order: order + 1 }
      middleItems = [oneAbove, oneBelow, thisItem]
      index += 1
      thisTop = document.getElementById(`${EM.CTRL.TYPE.BACKGROUND}-${oneBelow.title}`)!.offsetTop
    }
    const newBackgrounds = aboveItems
      .concat(middleItems)
      .concat(belowItems)
      .filter((item: any) => item !== undefined)
    setBackgrounds(newBackgrounds)
    setControl({ ...control, index: index })
    const body = document.getElementsByTagName('body')[0]
    body.style.overflowY = EM.COMM.BODY.INITIAL
    setTimeout(() => {
      const html = document.documentElement
      html.scrollTop = thisTop
    }, 100)
    setTimeout(() => {
      body.style.overflowY = EM.COMM.BODY.HIDDEN
    }, 200)
  }

  /**
   * Display the order buttons for the order option.
   * @returns order buttons JSX elements.
   */
  const orderButtons = (): JSX.Element[] => [
    <Button
      style={undoStyle}
      size={EM.COMM.BUTTON.SMALL}
      key="1"
      onClick={() => handlePreviewOrder(true)}
      disabled={background.order === 1}
    >
      {EM.COMM.BUTTON.MOVEUP} <UpCircleOutlined style={{ fontSize: '16px' }} />
    </Button>,
    <Button
      style={undoStyle}
      size={EM.COMM.BUTTON.SMALL}
      key="2"
      onClick={() => handlePreviewOrder(false)}
      disabled={background.order === backgrounds.length}
    >
      {EM.COMM.BUTTON.MOVEDN} <DownCircleOutlined style={{ fontSize: '16px' }} />
    </Button>,
  ]

  /**
   * Handles the select options and display the background component correspondingly.
   * @param item the generic item parameter with ValueName interface from the common file.
   * @returns void.
   */
  const onChangeSelectOption = (item: IF.COMM.ValueNameIF) => {
    setSelection(item as IF.BKGD.SelectOptionIF)
    let number = 1
    if (isEqual(item, RS.BKGD.selectColor)) number = 2
    if (isEqual(item, RS.BKGD.selectGradient)) number = 3
    setBackgrounds([
      ...backgrounds.slice(0, control.index),
      { ...background, option: number },
      ...backgrounds.slice(control.index + 1),
    ])
  }

  /**
   * Update the selected background image.
   * @param image string.
   * @returns void.
   */
  const handlePreviewImage = (image: string) => {
    const newBackground = { ...background }
    newBackground.image = `url("${image}") center center / cover`
    newBackground.option = 1
    setBackgrounds([...backgrounds.slice(0, control.index), newBackground, ...backgrounds.slice(control.index + 1)])
    setFileObject('')
  }

  /**
   * Update the selected background image URL.
   * @param url string.
   * @returns void.
   */
  const handlePreviewURL = (url: string) => {
    const newBackground = { ...background }
    newBackground.image = `url("${url}") center center / cover`
    newBackground.option = 1
    setBackgrounds([...backgrounds.slice(0, control.index), newBackground, ...backgrounds.slice(control.index + 1)])
    setFileObject('')
  }

  /**
   * Update the selected background image upload file.
   * @param event React change event for the HTML input element.
   * @returns void.
   */
  const handlePreviewUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files![0].size > RS.COMM.size5MB) {
      setBackgrounds([...backgrounds.slice(0, control.index), snapShot, ...backgrounds.slice(control.index + 1)])
      setFileName(EM.COMM.FILENAME.NOTAVAILABLE)
      setFileObject('')
      return
    }
    const imageReader = new FileReader()
    imageReader.onload = (event) => {
      setBackgrounds([
        ...backgrounds.slice(0, control.index),
        { ...background, image: `url("${event.target!.result}") center center / cover` },
        ...backgrounds.slice(control.index + 1),
      ])
    }
    imageReader.readAsDataURL(event.target.files![0])
    setFileName(event.target.files![0].name)
    setFileObject(event.target.files![0])
  }

  /**
   * Update the selected background color.
   * @param color color data.
   * @returns void.
   */
  const handlePreviewColor = (color: string) => {
    setBackgrounds([
      ...backgrounds.slice(0, control.index),
      { ...background, color: color, option: 2 },
      ...backgrounds.slice(control.index + 1),
    ])
    setFileName(EM.COMM.FILENAME.NOTAVAILABLE)
    setFileObject('')
  }

  /**
   * Update the selected background SVG palette.
   * @param palette an array of selected palette from the SVG component.
   * @returns void.
   */
  const handlePalettePreview = (palette: IF.COMM.SVGIF[]) => {
    if (isEqual(selection, RS.BKGD.selectGradient)) {
      setBackgrounds([
        ...backgrounds.slice(0, control.index),
        { ...background, svg: palette, option: 3 },
        ...backgrounds.slice(control.index + 1),
      ])
    }
    setFileName(EM.COMM.FILENAME.NOTAVAILABLE)
    setFileObject('')
  }

  /**
   * Update the selected background from picking a history item.
   * @param value image URL.
   * @param option selected history item option.
   * @param palette selected SVG palette value.
   * @returns void.
   */
  const handleHistoryPreview = (value: string, option: number, palette: IF.COMM.SVGIF[]) => {
    const newBackground = { ...background }
    newBackground.option = option
    if (option === 1) {
      newBackground.image = `url("${value}") center center / cover`
    } else if (option === 2) {
      newBackground.color = value
    } else if (option === 3) {
      newBackground.svg = palette
    }
    setBackgrounds([...backgrounds.slice(0, control.index), newBackground, ...backgrounds.slice(control.index + 1)])
    setFileObject('')
  }

  /**
   * Update background attribute by passing value to corresponding methods.
   * @param value value for title, height or order.
   * @param attribute background attribute: title, height or order.
   * @returns void.
   */
  const handleAttribute = (value: string | number | boolean, attribute: string) => {
    if (attribute === EM.COMM.EDITOR.TITLE) {
      handlePreviewTitle(value as string)
    } else if (attribute === EM.COMM.EDITOR.HEIGHT) {
      handlePreviewHeight(value.toString())
    } else if (attribute === EM.COMM.EDITOR.ORDER) {
      handlePreviewOrder(value as boolean)
    }
  }

  return (
    <div className={style.editorPageTwo}>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.COMM.EDITOR.IMAGE} />
        <EditorImage mainImage={mainImage()} />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.COMM.EDITOR.TITLE} />
        <EditorInput
          max={RS.COMM.max30}
          span={18}
          value={background.title}
          inputType={EM.COMM.TYPE.TEXT}
          attribute={EM.COMM.EDITOR.TITLE}
          handleAttribute={handleAttribute}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.COMM.EDITOR.ORDER} />
        <EditorParallel span={18} elements={orderButtons()} />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.COMM.EDITOR.HEIGHT} />
        <EditorSlider
          min={0}
          max={200}
          span={18}
          value={+background.height}
          width={RS.COMM.width90}
          attribute={EM.COMM.EDITOR.HEIGHT}
          handleAttribute={handleAttribute}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.COMM.EDITOR.OPTION} />
        <EditorDropDown
          span={18}
          loading={auth.loading}
          sourceList={RS.BKGD.selectOptions}
          selection={selection}
          saveStyle={saveStyle}
          onChangeItem={onChangeSelectOption}
        />
      </Row>
      <Row className={style.editorRow} style={showPickerComponent}>
        <EditorImagePicker
          type={EM.COMM.IMAGETYPE.BKGD}
          images={RS.BKGD.pickers}
          selection={selection}
          imageRef={imageRef}
          saveStyle={saveStyle}
          fileName={fileName}
          uploading={uploading}
          uploadBar={uploadBar}
          loading={auth.loading}
          setUploading={setUploading}
          setUploadbar={setUploadbar}
          handlePreviewImage={handlePreviewImage}
          handlePreviewURL={handlePreviewURL}
          handlePreviewUpload={handlePreviewUpload}
        />
        <EditorColorPicker
          color={background.color}
          showColorPicker={showColorPicker}
          handlePreviewColor={handlePreviewColor}
        />
        <EditorGradientPicker
          palette={palette}
          gradientRef={gradientRef}
          showGradientPicker={showGradientPicker}
          setPalette={setPalette}
        />
      </Row>
      <Row className={style.editorRowHistory}>
        <EditorHistoryLabel history={background.history} />
        <EditorHistorySVGPicker history={background.history} handleHistoryPreview={handleHistoryPreview} />
      </Row>
    </div>
  )
}

export default BackgroundSetting
