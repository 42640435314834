import React from 'react'
import style from '../../assets/editor.intro.module.scss'
import { Col, Collapse, Row } from 'antd'
import {
  CalendarTwoTone,
  FacebookFilled,
  GithubFilled,
  HomeTwoTone,
  InstagramFilled,
  LinkedinFilled,
  MailTwoTone,
  PhoneTwoTone,
  TwitterSquareFilled,
  YoutubeFilled,
} from '@ant-design/icons'
import { BsGlobe2 } from 'react-icons/bs'
import { EditorLabel, EditorSlider, EditorSwitch } from '../Common/EditorParts'
import { IF, RS, EM } from '../imports'
import * as m from './methods'

const { Panel } = Collapse

const SizeSetting = (props: {
  intro: IF.INTR.IntroductionIF
  control: IF.CTRL.ControlIF
  module: IF.STAT.ModuleIF
  modules: IF.STAT.ModuleIF[]
  setModules: React.Dispatch<React.SetStateAction<IF.STAT.ModuleIF[]>>
}) => {
  const { intro, control, module, modules, setModules } = props

  React.useEffect(() => {
    const collapse = document.getElementsByClassName('fieldCollapse')[0]
    if (collapse) {
      const panels = Array.from(
        collapse?.getElementsByClassName('ant-collapse-header') as HTMLCollectionOf<HTMLElement>
      )
      panels.forEach(
        (item: any) => (
          (item.style.color = control.color), (item.style.fontWeight = 'bold'), (item.style.borderRadius = '8px')
        )
      )
    }
  }, [])

  /** Local Methods */
  const syncStyle = { backgroundColor: control.color, width: '120px' }

  const onChangeSize = (value: number, attribute: string) => {
    m.onChangeIntroSize(intro, value, attribute, control, module, modules, setModules)
  }

  const onChangeSyncSize = (value: boolean, attribute: string) => {
    const newValue = value ? EM.COMM.LOWER.Y : EM.COMM.LOWER.N
    let newIntro = { ...intro }
    newIntro = {
      ...newIntro,
      icons: {
        ...newIntro.icons,
        meta: { ...newIntro.icons.meta, [attribute]: newValue },
      },
    }
    const newModule = { ...module, value: { introduction: newIntro } }
    setModules([...modules.slice(0, control.index), newModule, ...modules.slice(control.index + 1)])
  }

  return (
    <div className={style.editorPageTwo}>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.INTR.LABEL.NAME} />
        <EditorSlider
          min={1}
          max={100}
          span={18}
          value={intro.name.size}
          width={RS.COMM.width90}
          attribute={EM.INTR.ATTRIBUTE.NAME}
          handleAttribute={onChangeSize}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.INTR.LABEL.GREETING} />
        <EditorSlider
          min={1}
          max={100}
          span={18}
          value={intro.greeting.size}
          width={RS.COMM.width90}
          attribute={EM.INTR.ATTRIBUTE.GREETING}
          handleAttribute={onChangeSize}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.INTR.LABEL.TITLE} />
        <EditorSlider
          min={1}
          max={100}
          span={18}
          value={intro.title.size}
          width={RS.COMM.width90}
          attribute={EM.INTR.ATTRIBUTE.TITLE}
          handleAttribute={onChangeSize}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.INTR.LABEL.DETAIL} />
        <EditorSlider
          min={1}
          max={100}
          span={18}
          value={intro.detail.size}
          width={RS.COMM.width90}
          attribute={EM.INTR.ATTRIBUTE.DETAIL}
          handleAttribute={onChangeSize}
        />
      </Row>
      <Collapse defaultActiveKey={['-1']} accordion ghost className="fieldCollapse">
        <Panel header={EM.INTR.LABEL.BIRTHDAY} key={0}>
          <Row className={style.editorRow}>
            <EditorLabel span={6} label={EM.INTR.LABEL.BIRTHDAY} />
            <EditorSlider
              min={1}
              max={100}
              span={18}
              value={intro.birthday.size}
              width={RS.COMM.width90}
              attribute={EM.INTR.ATTRIBUTE.BIRTHDAY}
              handleAttribute={onChangeSize}
            />
          </Row>
          <Row className={style.editorRow}>
            <Col span={6} className={style.partLabel}>
              <CalendarTwoTone className={style.introEditorIcons} twoToneColor={intro.birthday.meta.icon.color} />
            </Col>
            <EditorSlider
              min={1}
              max={100}
              span={18}
              value={intro.birthday.meta.icon.size}
              width={RS.COMM.width90}
              attribute={EM.INTR.ATTRIBUTE.BIRTHDAYICON}
              handleAttribute={onChangeSize}
            />
          </Row>
        </Panel>
        <Panel header={EM.INTR.LABEL.EMAIL} key={1}>
          <Row className={style.editorRow}>
            <EditorLabel span={6} label={EM.INTR.LABEL.EMAIL} />
            <EditorSlider
              min={1}
              max={100}
              span={18}
              value={intro.email.size}
              width={RS.COMM.width90}
              attribute={EM.INTR.ATTRIBUTE.EMAIL}
              handleAttribute={onChangeSize}
            />
          </Row>
          <Row className={style.editorRow}>
            <Col span={6} className={style.partLabel}>
              <MailTwoTone className={style.introEditorIcons} twoToneColor={intro.email.meta.icon.color} />
            </Col>
            <EditorSlider
              min={1}
              max={100}
              span={18}
              value={intro.email.meta.icon.size}
              width={RS.COMM.width90}
              attribute={EM.INTR.ATTRIBUTE.EMAILICON}
              handleAttribute={onChangeSize}
            />
          </Row>
        </Panel>
        <Panel header={EM.INTR.LABEL.PHONE} key={2}>
          <Row className={style.editorRow}>
            <EditorLabel span={6} label={EM.INTR.LABEL.PHONE} />
            <EditorSlider
              min={1}
              max={100}
              span={18}
              value={intro.phone.size}
              width={RS.COMM.width90}
              attribute={EM.INTR.ATTRIBUTE.PHONE}
              handleAttribute={onChangeSize}
            />
          </Row>
          <Row className={style.editorRow}>
            <Col span={6} className={style.partLabel}>
              <PhoneTwoTone className={style.introEditorIcons} twoToneColor={intro.phone.meta.icon.color} />
            </Col>
            <EditorSlider
              min={1}
              max={100}
              span={18}
              value={intro.phone.meta.icon.size}
              width={RS.COMM.width90}
              attribute={EM.INTR.ATTRIBUTE.PHONEICON}
              handleAttribute={onChangeSize}
            />
          </Row>
        </Panel>
        <Panel header={EM.INTR.LABEL.ADDRESS} key={3}>
          <Row className={style.editorRow}>
            <EditorLabel span={6} label={EM.INTR.LABEL.ADDRESS} />
            <EditorSlider
              min={1}
              max={100}
              span={18}
              value={intro.address.size}
              width={RS.COMM.width90}
              attribute={EM.INTR.ATTRIBUTE.ADDRESS}
              handleAttribute={onChangeSize}
            />
          </Row>
          <Row className={style.editorRow}>
            <Col span={6} className={style.partLabel}>
              <HomeTwoTone className={style.introEditorIcons} twoToneColor={intro.address.meta.icon.color} />
            </Col>
            <EditorSlider
              min={1}
              max={100}
              span={18}
              value={intro.address.meta.icon.size}
              width={RS.COMM.width90}
              attribute={EM.INTR.ATTRIBUTE.ADDRESSICON}
              handleAttribute={onChangeSize}
            />
          </Row>
        </Panel>
        <Panel header={EM.INTR.LABEL.WEBSITE} key={4}>
          <Row className={style.editorRow}>
            <EditorLabel span={6} label={EM.INTR.LABEL.WEBSITE} />
            <EditorSlider
              min={1}
              max={100}
              span={18}
              value={intro.website.size}
              width={RS.COMM.width90}
              attribute={EM.INTR.ATTRIBUTE.WEBSITE}
              handleAttribute={onChangeSize}
            />
          </Row>
          <Row className={style.editorRow}>
            <Col span={6} className={style.partLabel}>
              <BsGlobe2 className={style.introEditorIcons} style={{ color: intro.website.meta.icon.color }} />
            </Col>
            <EditorSlider
              min={1}
              max={100}
              span={18}
              value={intro.website.meta.icon.size}
              width={RS.COMM.width90}
              attribute={EM.INTR.ATTRIBUTE.WEBSITEICON}
              handleAttribute={onChangeSize}
            />
          </Row>
        </Panel>
        <Panel header={EM.INTR.LABEL.ICONS} key={5}>
          <Row className={style.editorRow}>
            <EditorLabel span={6} label={EM.INTR.FIELDLABEL.SYNCSIZE} />
            <EditorSwitch
              span={18}
              show={intro.icons.meta.syncSize === EM.COMM.LOWER.Y}
              attribute={EM.INTR.ATTRIBUTE.SYNCSIZE}
              switchStyle={syncStyle}
              checkedChildren={EM.INTR.VAR.SYNCHRONIZED}
              unCheckedChildren={EM.INTR.VAR.DESYNCHRONIZED}
              handleAttribute={onChangeSyncSize}
            />
          </Row>
          {intro.icons.meta.syncSize === EM.COMM.LOWER.Y && (
            <Row className={style.editorRow}>
              <EditorLabel span={6} label={EM.INTR.LABEL.ICONS} />
              <EditorSlider
                min={1}
                max={100}
                span={18}
                value={intro.icons.size}
                width={RS.COMM.width90}
                attribute={EM.INTR.ATTRIBUTE.ICONS}
                handleAttribute={onChangeSize}
              />
            </Row>
          )}
          {intro.icons.meta.syncSize !== EM.COMM.LOWER.Y && intro.facebook.show === EM.COMM.DISPLAY.FLEX && (
            <Row className={style.editorRow}>
              <Col span={6} className={style.partLabel}>
                <FacebookFilled className={style.introEditorIcons} style={{ color: intro.facebook.color }} />
              </Col>
              <EditorSlider
                min={1}
                max={100}
                span={18}
                value={intro.facebook.size}
                width={RS.COMM.width90}
                attribute={EM.INTR.ATTRIBUTE.FACEBOOK}
                handleAttribute={onChangeSize}
              />
            </Row>
          )}
          {intro.icons.meta.syncSize !== EM.COMM.LOWER.Y && intro.twitter.show === EM.COMM.DISPLAY.FLEX && (
            <Row className={style.editorRow}>
              <Col span={6} className={style.partLabel}>
                <TwitterSquareFilled className={style.introEditorIcons} style={{ color: intro.twitter.color }} />
              </Col>
              <EditorSlider
                min={1}
                max={100}
                span={18}
                value={intro.twitter.size}
                width={RS.COMM.width90}
                attribute={EM.INTR.ATTRIBUTE.TWITTER}
                handleAttribute={onChangeSize}
              />
            </Row>
          )}
          {intro.icons.meta.syncSize !== EM.COMM.LOWER.Y && intro.linkedin.show === EM.COMM.DISPLAY.FLEX && (
            <Row className={style.editorRow}>
              <Col span={6} className={style.partLabel}>
                <LinkedinFilled className={style.introEditorIcons} style={{ color: intro.linkedin.color }} />
              </Col>
              <EditorSlider
                min={1}
                max={100}
                span={18}
                value={intro.linkedin.size}
                width={RS.COMM.width90}
                attribute={EM.INTR.ATTRIBUTE.LINKEDIN}
                handleAttribute={onChangeSize}
              />
            </Row>
          )}
          {intro.icons.meta.syncSize !== EM.COMM.LOWER.Y && intro.instagram.show === EM.COMM.DISPLAY.FLEX && (
            <Row className={style.editorRow}>
              <Col span={6} className={style.partLabel}>
                <InstagramFilled className={style.introEditorIcons} style={{ color: intro.instagram.color }} />
              </Col>
              <EditorSlider
                min={1}
                max={100}
                span={18}
                value={intro.instagram.size}
                width={RS.COMM.width90}
                attribute={EM.INTR.ATTRIBUTE.INSTAGRAM}
                handleAttribute={onChangeSize}
              />
            </Row>
          )}
          {intro.icons.meta.syncSize !== EM.COMM.LOWER.Y && intro.youtube.show === EM.COMM.DISPLAY.FLEX && (
            <Row className={style.editorRow}>
              <Col span={6} className={style.partLabel}>
                <YoutubeFilled className={style.introEditorIcons} style={{ color: intro.youtube.color }} />
              </Col>
              <EditorSlider
                min={1}
                max={100}
                span={18}
                value={intro.youtube.size}
                width={RS.COMM.width90}
                attribute={EM.INTR.ATTRIBUTE.YOUTUBE}
                handleAttribute={onChangeSize}
              />
            </Row>
          )}
          {intro.icons.meta.syncSize !== EM.COMM.LOWER.Y && intro.github.show === EM.COMM.DISPLAY.FLEX && (
            <Row className={style.editorRow}>
              <Col span={6} className={style.partLabel}>
                <GithubFilled className={style.introEditorIcons} style={{ color: intro.github.color }} />
              </Col>
              <EditorSlider
                min={1}
                max={100}
                span={18}
                value={intro.github.size}
                width={RS.COMM.width90}
                attribute={EM.INTR.ATTRIBUTE.GITHUB}
                handleAttribute={onChangeSize}
              />
            </Row>
          )}
        </Panel>
      </Collapse>
    </div>
  )
}

export default SizeSetting
