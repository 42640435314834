import React from 'react'
import base from '../../assets/base.module.scss'
import style from '../../assets/background.module.scss'

/**
 * Error message JSX component.
 * @param code error code number.
 * @returns error message JSX component.
 */
const ErrorMessage: React.FC<{ code: number }> = ({ code }) => {
  const ErrorForbidden = (
    <div>
      <h1>403 Access Forbidden</h1>
      <h4>This website is not yet published.</h4>
      <h4>Please come back soon.</h4>
    </div>
  )

  const ErrorNotFound = (
    <div>
      <h1>404 Not Found</h1>
      <h4>This website doesn&apos;t exist.</h4>
      <h4>Please check your URL.</h4>
    </div>
  )

  return (
    <div className={base.baseColContainer}>
      <div className={style.backgroundNotExistDiv}>
        <div className={style.backgroundNotExistFrame}>
          <div className={style.backgroundResetDiv}>
            {code === 403 && ErrorForbidden}
            {code === 404 && ErrorNotFound}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ErrorMessage
