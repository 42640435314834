import { VS } from '../components/imports'


const ResetPWD = () => {
    return (
        <>
            <VS.RegisterBackground />
            <VS.Reset />
        </>
    )
}
export default ResetPWD
