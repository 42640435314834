import style from '../../assets/register.module.scss'
import { Link } from 'react-router-dom'
import { CheckCircleTwoTone } from '@ant-design/icons'
import { EM, RX } from '../imports'
import * as m from './methods'


const Signok = (props: any) => {
    /** Properties */
    const { auth, dispatch } = props

    /** React Methods */
    const resetMessage = () => { RX.AUTH.authSetMessage(auth, dispatch, null) }

    return <div className={style.registerMessageDiv}>
        <div className={style.registerTitle}>
            {EM.RSTR.TEXT.SUCCESS} <CheckCircleTwoTone twoToneColor={EM.RSTR.PASSWORD.SIGNUP} />
        </div>
        <div className={style.registerMessage}>
            {m.regiterEmail}
        </div>
        <div className={style.registerCenter} onClick={resetMessage}>
            <a className={style.registerCreate}>{EM.RSTR.TEXT.RETURN}</a>
        </div>
        <div className={style.registerCenter}>
            {EM.RSTR.TEXT.ACCOUNTEXITS}&nbsp;
            <Link
                className={style.registerCreate}
                onClick={resetMessage}
                to={EM.RSTR.URL.SIGNIN}>
                {EM.RSTR.TEXT.SIGNIN}
            </Link>
        </div>
    </div>
}

export default Signok