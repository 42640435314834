/**
 * Enum for select option name key.
 */
export enum OPTIONNAME {
  DEFAULT = 'Select Option',
  IMAGE = 'Select Image',
  URL = 'Image Address',
  UPLOAD = 'Upload Image',
  COLOR = 'Color Picker',
  GRADIENT = 'Gradient Picker',
}

/**
 * Enum for select option value key.
 */
export enum OPTIONVALUE {
  DEFAULT = 'Select Option',
  IMAGE = 'Select Image',
  URL = 'Image Address',
  UPLOAD = 'Upload Image',
  COLOR = 'Color Picker',
  GRADIENT = 'Gradient Picker',
}

export enum URL {
  UPDATE = '/api/talent/background/',
  ORDER = '/api/talent/background/order/',
}

export enum HINT {
  EDIT = 'Drag the item to reposition the order. Click the item to edit it.',
}
