import React from 'react'
import style from '../../assets/base.module.scss'
import { EM, IF, RS } from '../imports'
import { scrollToModule } from '../Navigation/methods'

const EditorPopup = (props: {
    modules: IF.STAT.ModuleIF[],
    control: IF.CTRL.ControlIF,
    showEdit: IF.COMM.DisplayIF,
    setShowEdit: React.Dispatch<React.SetStateAction<IF.COMM.DisplayIF>>,
    setControl: React.Dispatch<React.SetStateAction<IF.CTRL.ControlIF>>
}) => {
    const { modules, control, showEdit, setControl, setShowEdit } = props
    return <div className={style.baseEditDiv} style={showEdit}>
        <div className={style.baseEdit} style={{ backgroundColor: control.color }}
            onClick={() => {
                setShowEdit(RS.COMM.hideBlock)
                setControl({ ...control, showDrawer: true, showPanel: false, page: 2 })
                scrollToModule(modules[control.index])
            }}>
            {EM.FRME.BUTTON.EDIT}
        </div>
    </div>
}

export default EditorPopup