import React from 'react'
import style from '../../assets/editor.intro.module.scss'
import { Row } from 'antd'
import AddressSetting from './PresetSetting/AddressSetting'
import BirthdaySetting from './PresetSetting/BirthdaySetting'
import DetailSetting from './PresetSetting/DetailSetting'
import EmailSetting from './PresetSetting/EmailSetting'
import GreetingSetting from './PresetSetting/GreetingSetting'
import IconsSetting from './PresetSetting/IconsSetting'
import NameSetting from './PresetSetting/NameSetting'
import PhoneSetting from './PresetSetting/PhoneSetting'
import TitleSetting from './PresetSetting/TitleSetting'
import WebsiteSetting from './PresetSetting/WebsiteSetting'
import { isEqual } from 'lodash'
import { EditorDropDown } from '../Common/EditorParts'
import { EM, IF, RS } from '../imports'
import * as m from './methods'

const PresetSetting = (props: {
  loading: boolean
  intro: IF.INTR.IntroductionIF
  control: IF.CTRL.ControlIF
  module: IF.STAT.ModuleIF
  modules: IF.STAT.ModuleIF[]
  saveStyle: React.CSSProperties
  undoStyle: React.CSSProperties
  setModules: React.Dispatch<React.SetStateAction<IF.STAT.ModuleIF[]>>
}) => {
  const { loading, intro, control, module, modules, saveStyle, undoStyle, setModules } = props

  /** React Methods */
  const [selection, setSelection] = React.useState<IF.COMM.ValueNameIF>(RS.INTR.valueNameSelect)
  const [editStyle, setEditStyle] = React.useState<IF.COMM.DisplayIF>(RS.COMM.hideBlock)

  React.useEffect(() => {
    if (!isEqual(selection, RS.INTR.valueNameSelect)) {
      const collapse = document.getElementsByClassName('fieldCollapse')[0]
      if (collapse) {
        const panels = Array.from(
          collapse?.getElementsByClassName('ant-collapse-header') as HTMLCollectionOf<HTMLElement>
        )
        panels.forEach(
          (item: any) => (
            (item.style.color = control.color), (item.style.fontWeight = 'bold'), (item.style.borderRadius = '8px')
          )
        )
      }
    }
  }, [selection])

  /** Local Variables */
  const editorStyle = { padding: '0' }
  const switchStyle = { backgroundColor: control.color, width: '80px' }
  const socialIcons = [intro.facebook, intro.twitter, intro.linkedin, intro.instagram, intro.youtube, intro.github]

  const onChangeDropDown = (item: IF.COMM.ValueNameIF) => {
    setSelection(item)
    setEditStyle(RS.COMM.showBlock)
  }

  const onChangeIntroduction = (value: any, attribute: string) => {
    const newModule = { ...module }
    let newIntro = { ...module.value.introduction! }

    if (selection.name === EM.INTR.SETTING.NAME) {
      let newValue = newIntro.name
      const newMeta = newValue.meta
      if (attribute === EM.INTR.ATTRIBUTE.FIRSTNAME) {
        newValue = {
          ...newValue,
          text: `${value} ${newMeta.middleName} ${newMeta.lastName}`,
          meta: { ...newMeta, firstName: value },
        }
      } else if (attribute === EM.INTR.ATTRIBUTE.MIDDLENAME) {
        newValue = {
          ...newValue,
          text: `${newMeta.firstName} ${value} ${newMeta.lastName}`,
          meta: { ...newMeta, middleName: value },
        }
      } else if (attribute === EM.INTR.ATTRIBUTE.LASTNAME) {
        newValue = {
          ...newValue,
          text: `${newMeta.firstName} ${newMeta.middleName} ${value}`,
          meta: { ...newMeta, lastName: value },
        }
      } else {
        newValue = { ...newValue, [attribute]: value }
      }
      newIntro = { ...newIntro, name: newValue }
    } else if (selection.name === EM.INTR.SETTING.GREETING) {
      let newValue = newIntro.greeting
      newValue = { ...newValue, [attribute]: value }
      newIntro = { ...newIntro, greeting: newValue }
    } else if (selection.name === EM.INTR.SETTING.TITLE) {
      let newValue = newIntro.title
      newValue = { ...newValue, [attribute]: value }
      newIntro = { ...newIntro, title: newValue }
    } else if (selection.name === EM.INTR.SETTING.DETAIL) {
      let newValue = newIntro.detail
      newValue = { ...newValue, [attribute]: value }
      newIntro = { ...newIntro, detail: newValue }
    } else if (selection.name === EM.INTR.SETTING.ADDRESS) {
      let newValue = newIntro.address
      const newMeta = newValue.meta
      if (attribute === EM.INTR.ATTRIBUTE.STREETONE) {
        newValue = {
          ...newValue,
          text: `${value} ${newMeta.city} ${newMeta.stateCode} ${newMeta.countryName}`,
          meta: { ...newMeta, streetOne: value },
        }
      } else if (attribute === EM.INTR.ATTRIBUTE.CITY) {
        newValue = {
          ...newValue,
          text: `${newMeta.streetOne} ${value} ${newMeta.stateCode} ${newMeta.countryName}`,
          meta: { ...newMeta, city: value },
        }
      } else if (attribute === EM.INTR.ATTRIBUTE.STATECODE) {
        const stateCode = value.split(':')[0]
        const stateName = value.split(':')[1]
        newValue = {
          ...newValue,
          text: `${newMeta.streetOne} ${newMeta.city} ${stateCode} ${newMeta.countryName}`,
          meta: { ...newMeta, stateCode: stateCode, stateName: stateName },
        }
      } else if (attribute === EM.INTR.ATTRIBUTE.STATENAME) {
        newValue = {
          ...newValue,
          text: `${newMeta.streetOne} ${newMeta.city} ${value} ${newMeta.countryName}`,
          meta: { ...newMeta, stateName: value },
        }
      } else if (attribute === EM.INTR.ATTRIBUTE.COUNTRYCODE) {
        const countryCode = value.split(':')[0]
        const countryName = value.split(':')[1]
        const stateCode = countryCode === newMeta.countryCode ? newMeta.stateCode : ''
        const stateName = countryName === newMeta.countryName ? newMeta.stateName : ''
        const state = stateCode || stateName
        newValue = {
          ...newValue,
          text: `${newMeta.streetOne} ${newMeta.city} ${state} ${countryName}`,
          meta: {
            ...newMeta,
            stateCode: stateCode,
            stateName: stateName,
            countryCode: countryCode,
            countryName: countryName,
          },
        }
      } else if (attribute === EM.INTR.FIELDKEY.ICONSIZE) {
        newValue = {
          ...newValue,
          meta: { ...newMeta, icon: { ...newMeta.icon, size: value } },
        }
      } else if (attribute === EM.INTR.FIELDKEY.ICONCOLOR) {
        newValue = {
          ...newValue,
          meta: { ...newMeta, icon: { ...newMeta.icon, color: value } },
        }
      } else {
        newValue = { ...newValue, [attribute]: value }
      }
      newIntro = { ...newIntro, address: newValue }
    } else if (selection.name === EM.INTR.SETTING.BIRTHDAY) {
      let newValue = newIntro.birthday
      const newMeta = newValue.meta
      if (attribute === EM.INTR.FIELDKEY.ICONSIZE) {
        newValue = {
          ...newValue,
          meta: { ...newMeta, icon: { ...newMeta.icon, size: value } },
        }
      } else if (attribute === EM.INTR.FIELDKEY.ICONCOLOR) {
        newValue = {
          ...newValue,
          meta: { ...newMeta, icon: { ...newMeta.icon, color: value } },
        }
      } else {
        newValue = { ...newValue, [attribute]: value }
      }
      newIntro = { ...newIntro, birthday: newValue }
    } else if (selection.name === EM.INTR.SETTING.EMAIL) {
      let newValue = newIntro.email
      const newMeta = newValue.meta
      if (attribute === EM.INTR.FIELDKEY.ICONSIZE) {
        newValue = {
          ...newValue,
          meta: { ...newMeta, icon: { ...newMeta.icon, size: value } },
        }
      } else if (attribute === EM.INTR.FIELDKEY.ICONCOLOR) {
        newValue = {
          ...newValue,
          meta: { ...newMeta, icon: { ...newMeta.icon, color: value } },
        }
      } else {
        newValue = { ...newValue, [attribute]: value }
      }
      newIntro = { ...newIntro, email: newValue }
    } else if (selection.name === EM.INTR.SETTING.PHONE) {
      let newValue = newIntro.phone
      const newMeta = newValue.meta
      if (attribute === EM.INTR.FIELDKEY.ICONSIZE) {
        newValue = {
          ...newValue,
          meta: { ...newMeta, icon: { ...newMeta.icon, size: value } },
        }
      } else if (attribute === EM.INTR.FIELDKEY.ICONCOLOR) {
        newValue = {
          ...newValue,
          meta: { ...newMeta, icon: { ...newMeta.icon, color: value } },
        }
      } else if (attribute === EM.INTR.ATTRIBUTE.PHONECODE) {
        newValue = {
          ...newValue,
          text: `${value.split(':')[1]} ${newMeta.number}`,
          meta: {
            ...newMeta,
            country: value.split(':')[0],
            code: value.split(':')[1],
          },
        }
      } else if (attribute === EM.INTR.ATTRIBUTE.PHONENUMBER) {
        newValue = {
          ...newValue,
          text: `${newMeta.code} ${value}`,
          meta: { ...newMeta, number: value },
        }
      } else {
        newValue = { ...newValue, [attribute]: value }
      }
      newIntro = { ...newIntro, phone: newValue }
    } else if (selection.name === EM.INTR.SETTING.WEBSITE) {
      let newValue = newIntro.website
      const newMeta = newValue.meta
      if (attribute === EM.INTR.FIELDKEY.ICONSIZE) {
        newValue = {
          ...newValue,
          meta: { ...newMeta, icon: { ...newMeta.icon, size: value } },
        }
      } else if (attribute === EM.INTR.FIELDKEY.ICONCOLOR) {
        newValue = {
          ...newValue,
          meta: { ...newMeta, icon: { ...newMeta.icon, color: value } },
        }
      } else {
        newValue = { ...newValue, [attribute]: value }
      }
      newIntro = { ...newIntro, website: newValue }
    } else if (selection.name === EM.INTR.SETTING.ICONS) {
      let newValue = newIntro.icons
      const newMeta = newValue.meta
      if (attribute === EM.INTR.ATTRIBUTE.SYNCSIZE) {
        newValue = { ...newValue, meta: { ...newMeta, syncSize: value } }
      } else if (attribute === EM.INTR.ATTRIBUTE.SYNCCOLOR) {
        newValue = { ...newValue, meta: { ...newMeta, syncColor: value } }
      } else if (attribute === EM.INTR.ATTRIBUTE.SYNCSHOW) {
        newValue = { ...newValue, meta: { ...newMeta, syncShow: value } }
      } else {
        newValue = { ...newValue, [attribute]: value }
      }
      newIntro = { ...newIntro, icons: newValue }
    }
    newModule.value = { introduction: newIntro }
    setModules([...modules.slice(0, control.index), newModule, ...modules.slice(control.index + 1)])
  }

  const onChangeAttribute = (value: any, attribute: string) => {
    if (attribute === EM.INTR.FIELDKEY.SHOW) {
      value = value ? EM.COMM.DISPLAY.FLEX : EM.COMM.DISPLAY.NONE
    }
    onChangeIntroduction(value, attribute)
  }

  const onChangeOrder = (value: boolean, attribute: string) => {
    m.onChangeOrder(value, attribute, intro, control, module, modules, setModules)
  }

  return (
    <div className={style.editorPageTwo}>
      <div className={style.introFieldHeader}>
        <Row>
          <EditorDropDown
            span={24}
            loading={loading}
            sourceList={RS.INTR.fieldSelection}
            selection={selection}
            saveStyle={saveStyle}
            onChangeItem={onChangeDropDown}
          />
        </Row>
      </div>
      <div style={editStyle}>
        {selection.name === EM.INTR.SETTING.NAME && (
          <NameSetting
            value={intro.name}
            order={intro.order}
            control={control}
            label={EM.INTR.LABEL.NAME}
            attribute={EM.INTR.ATTRIBUTE.NAME}
            saveStyle={saveStyle}
            undoStyle={undoStyle}
            switchStyle={switchStyle}
            onChangeAttribute={onChangeAttribute}
            onChangeOrder={onChangeOrder}
          />
        )}
        {selection.name === EM.INTR.SETTING.GREETING && (
          <GreetingSetting
            value={intro.greeting}
            order={intro.order}
            control={control}
            label={EM.INTR.LABEL.GREETING}
            attribute={EM.INTR.ATTRIBUTE.GREETING}
            saveStyle={saveStyle}
            undoStyle={undoStyle}
            switchStyle={switchStyle}
            onChangeAttribute={onChangeAttribute}
            onChangeOrder={onChangeOrder}
          />
        )}
        {selection.name === EM.INTR.SETTING.TITLE && (
          <TitleSetting
            value={intro.title}
            order={intro.order}
            control={control}
            label={EM.INTR.LABEL.TITLE}
            attribute={EM.INTR.ATTRIBUTE.TITLE}
            saveStyle={saveStyle}
            undoStyle={undoStyle}
            switchStyle={switchStyle}
            onChangeAttribute={onChangeAttribute}
            onChangeOrder={onChangeOrder}
          />
        )}
        {selection.name === EM.INTR.SETTING.DETAIL && (
          <DetailSetting
            value={intro.detail}
            order={intro.order}
            control={control}
            label={EM.INTR.LABEL.DETAIL}
            attribute={EM.INTR.ATTRIBUTE.DETAIL}
            saveStyle={saveStyle}
            undoStyle={undoStyle}
            switchStyle={switchStyle}
            onChangeAttribute={onChangeAttribute}
            onChangeOrder={onChangeOrder}
          />
        )}
        {selection.name === EM.INTR.SETTING.ADDRESS && (
          <AddressSetting
            value={intro.address}
            order={intro.order}
            control={control}
            label={EM.INTR.LABEL.ADDRESS}
            attribute={EM.INTR.ATTRIBUTE.ADDRESS}
            saveStyle={saveStyle}
            undoStyle={undoStyle}
            switchStyle={switchStyle}
            editorStyle={editorStyle}
            onChangeAttribute={onChangeAttribute}
            onChangeOrder={onChangeOrder}
          />
        )}
        {selection.name === EM.INTR.SETTING.BIRTHDAY && (
          <BirthdaySetting
            value={intro.birthday}
            order={intro.order}
            control={control}
            label={EM.INTR.LABEL.BIRTHDAY}
            attribute={EM.INTR.ATTRIBUTE.BIRTHDAY}
            saveStyle={saveStyle}
            undoStyle={undoStyle}
            switchStyle={switchStyle}
            editorStyle={editorStyle}
            onChangeAttribute={onChangeAttribute}
            onChangeOrder={onChangeOrder}
          />
        )}
        {selection.name === EM.INTR.SETTING.EMAIL && (
          <EmailSetting
            value={intro.email}
            order={intro.order}
            control={control}
            label={EM.INTR.LABEL.EMAIL}
            attribute={EM.INTR.ATTRIBUTE.EMAIL}
            saveStyle={saveStyle}
            undoStyle={undoStyle}
            switchStyle={switchStyle}
            editorStyle={editorStyle}
            onChangeAttribute={onChangeAttribute}
            onChangeOrder={onChangeOrder}
          />
        )}
        {selection.name === EM.INTR.SETTING.PHONE && (
          <PhoneSetting
            value={intro.phone}
            order={intro.order}
            control={control}
            label={EM.INTR.LABEL.PHONE}
            attribute={EM.INTR.ATTRIBUTE.PHONE}
            saveStyle={saveStyle}
            undoStyle={undoStyle}
            switchStyle={switchStyle}
            editorStyle={editorStyle}
            onChangeAttribute={onChangeAttribute}
            onChangeOrder={onChangeOrder}
          />
        )}
        {selection.name === EM.INTR.SETTING.WEBSITE && (
          <WebsiteSetting
            value={intro.website}
            order={intro.order}
            control={control}
            label={EM.INTR.LABEL.WEBSITE}
            attribute={EM.INTR.ATTRIBUTE.WEBSITE}
            saveStyle={saveStyle}
            undoStyle={undoStyle}
            switchStyle={switchStyle}
            editorStyle={editorStyle}
            onChangeAttribute={onChangeAttribute}
            onChangeOrder={onChangeOrder}
          />
        )}
        {selection.name === EM.INTR.SETTING.ICONS && (
          <IconsSetting
            intro={intro}
            control={control}
            socialIcons={socialIcons}
            label={EM.INTR.LABEL.ICONS}
            attribute={EM.INTR.ATTRIBUTE.ICONS}
            module={module}
            modules={modules}
            undoStyle={undoStyle}
            editorStyle={editorStyle}
            switchStyle={switchStyle}
            setModules={setModules}
            onChangeAttribute={onChangeAttribute}
            onChangeOrder={onChangeOrder}
          />
        )}
      </div>
    </div>
  )
}

export default PresetSetting
