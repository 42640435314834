import React from 'react'
import { request } from '../../utils/request'
import { updateStatus } from '../reducer'
import { authUpdateSnapshots } from './auth'
import { IF, RS } from '../../components/imports'

const statusStart = (status: IF.STAT.StatusIF, dispatch: any) => {
  dispatch(
    updateStatus({
      ...status,
      loading: true,
      complete: false,
    })
  )
}

const statusFail = (status: IF.STAT.StatusIF, dispatch: any, code: number) => {
  dispatch(
    updateStatus({
      ...status,
      loading: false,
      complete: false,
      code: code,
    })
  )
}

const statusSuccess = (status: IF.STAT.StatusIF, dispatch: any) => {
  dispatch(
    updateStatus({
      ...status,
      loading: false,
      complete: true,
    })
  )
}

// const statusCheckTimeout = () => {
//   setTimeout(() => {
//     localStorage.removeItem('sessionCookie')
//     window.location.reload()
//   }, 3600 * 24 * 1000)
// }

// export const modulesUpdate = (
//     modules:IF.STAT.ModuleIF[],
//     dispatch:any
//     ) => {
//     dispatch(updateModules(modules))
// }

// export const authUpdateSnapshots = (
//     snapshot: IF.STAT.ModuleIF[],
//     dispatch:any
//     )=> {
//     dispatch(updateSnapshot(snapshot))
// }

export const fetchProfile = async (
  status: IF.STAT.StatusIF,
  dispatch: any,
  account: string,
  setMenuBar: React.Dispatch<React.SetStateAction<IF.NAVG.MenuBarIF>>,
  setNaviBar: React.Dispatch<React.SetStateAction<IF.NAVG.NaviBarIF>>,
  setControl: React.Dispatch<React.SetStateAction<IF.CTRL.ControlIF>>,
  setModules: React.Dispatch<React.SetStateAction<IF.STAT.ModuleIF[]>>,
  setBackgrounds: React.Dispatch<React.SetStateAction<IF.BKGD.BackgroundIF[]>>
) => {
  statusStart(status, dispatch)
  try {
    const response = await request(`/api/talent/profile/${account}/`, null, null, 'get')
    const data = await response.data
    if (data.complete) {
      const control = { ...RS.CTRL.control, ...data.control }
      authUpdateSnapshots(dispatch, data.profile, data.navigation, data.menu, control, data.backgrounds, data.modules)
      setNaviBar(data.navigation)
      setMenuBar(data.menu)
      setControl(control)
      setModules(data.modules)
      setBackgrounds(data.backgrounds)
      statusSuccess(status, dispatch)
    } else {
      statusFail(status, dispatch, response.status)
    }
    // if (data.cookie !== EM.STAT.status.COOKIE) {
    //   sessionStorage.setItem('sessionCookie', data.cookie)
    //   statusCheckTimeout()
    // }
  } catch (error: any) {
    statusFail(status, dispatch, error.response.status)
  }
}

// export const statusDownloadImage = async (image: string | ArrayBuffer | null) => {
//     try {
//         const response = await requestBinary('/api/gadget/imagedownload/', null, {'image': image}, 'post')
//         const data = await response.data
//         const blob = new Blob([data], {type: response.headers['content-type']})
//         const blobUrl = window.URL.createObjectURL(blob)
//         return blobUrl
//     } catch (error:any) {
//     }
// }
