/**
 * The position enum which CSS property sets how an element is positioned in a document.
 */
export enum POSITION {
  STATIC = 'static',
  RELATIVE = 'relative',
  ABSOLUTE = 'absolute',
  STICKY = 'sticky',
  FIXED = 'fixed',
}

/**
 * The API methods used to call the backend endpoints.
 */
export enum APIMETHOD {
  GET = 'get',
  POST = 'post',
  PATCH = 'patch',
  PUT = 'put',
  DELETE = 'delete',
}

export enum BUTTON {
  SMALL = 'small',
  LARGE = 'large',
  PREVIEW = 'Preview',
  SAVE = 'Save',
  UNDO = 'Undo',
  BACK = 'Back',
  EDIT = 'Edit',
  CANCEL = 'Cancel',
  DELETE = 'Delete',
  ADDFIELD = 'Add Field',
  CLEARFIELD = 'Clear Field',
  MOVEUP = 'Move Up',
  MOVEDN = 'Move Down',
  UPLOAD = 'Upload',
  DRAG = 'Drag and move',
  MORE = 'More',
  NAVIBAR = 'Navigation',
  CONTROL = 'Control Center',
  BACKGROUND = 'Backgound',
  MODULE = 'Module',
  ADDBKGD = 'Add Background',
  ORDERBKGD = 'Order Backgrounds',
  ADDMODL = 'Add Module',
  ORDERMODL = 'Order Modules',
}

export enum BODY {
  INITIAL = 'initial',
  HIDDEN = 'hidden',
}

export enum COLOR {
  WHITE = 'rgb(255, 255, 255)',
  BLACK = 'rgb(0, 0, 0)',
  NONE = 'rgba(0, 0, 0, 0)',
  SIZE = '150px',
}

export enum COUNTRY {
  US = 'US',
  CA = 'CA',
}

export enum DISPLAY {
  BLOCK = 'block',
  FLEX = 'flex',
  NONE = 'none',
}

export enum EDITOR {
  IMAGE = 'Image',
  TITLE = 'Title',
  HEIGHT = 'Height',
  ORDER = 'Order',
  OPTION = 'Option',
  HISTORY = 'History',
}

export enum LOWER {
  Y = 'y',
  N = 'n',
}

export enum OPTION {
  SELECT = 'Select',
  DEFAULT = 'Select Option',
  IMAGE = 'Select Image',
  URL = 'Image Address',
  UPLOAD = 'Upload Image',
  COLOR = 'Color Picker',
  ICON = 'Select Icon',
  GRADIENT = 'Gradient Picker',
  DISABLE = 'Disable Image',
}

/**
 * Image type enum
 */
export enum IMAGETYPE {
  BKGD = 'background',
  CTRL = 'control',
  MODL = 'module',
  FRAME = 'module frame',
  AVATAR = 'module avatar',
  BORDER = 'border image',
  NAVBAR = 'navi bar',
  MENUBAR = 'menu bar',
  MENUICON = 'menu icon',
}

/**
 * Image error enum
 */
export enum IMAGEERROR {
  NONE = '',
  WARN2 = 'The upload file size is set to 2MB to optimize application.',
  WARN3 = 'The upload file size is set to 3MB to optimize application.',
  WARN5 = 'The upload file size is set to 5MB to optimize application.',
  ERROR2 = 'This file size exceedes 2MB. Please use another file.',
  ERROR3 = 'This file size exceedes 3MB. Please use another file.',
  ERROR5 = 'This file size exceedes 5MB. Please use another file.',
}

export enum IMAGE {
  EMPTY = 'Your image history is empty',
  NAME = 'File Name: ',
  HOLDER = 'Enter Your Image Website Address',
  WARN2 = 'The upload file size is set to 2MB to optimize your experience',
  WARN3 = 'The upload file size is set to 3MB to optimize your experience',
  WARN5 = 'The upload file size is set to 5MB to optimize your experience',
  ERROR2 = 'This file size exceedes 2MB. Please use another file.',
  ERROR3 = 'This file size exceedes 3MB. Please use another file.',
  ERROR5 = 'This file size exceedes 5MB. Please use another file.',
}

export enum TYPE {
  BKGD = 'background',
  CTRL = 'control',
  MODL = 'module',
  FRAME = 'module frame',
  AVATAR = 'module avatar',
  BORDER = 'border image',
  NAVBAR = 'navi bar',
  MENUBAR = 'menu bar',
  MENUICON = 'menu icon',
  TEXT = 'text',
  NUMBER = 'number',
}

export enum MODL {
  INTRAVTR = 'introAvatar',
}

export enum VAR {
  NA = 'Not Available',
  ERROR = 'error',
  SHOW = 'Show',
  HIDE = 'Hide',
}

/**
 * File name enum.
 */
export enum FILENAME {
  NOTAVAILABLE = 'Not Available',
}
