import style from '../../assets/editor.module.scss'
import { Col, Row } from 'antd'
import { handleHistoryIcon } from './methods'
import { EM, IF } from '../imports'
import EditorSVG from './EditorSVG'

export const EditorHistorySVGPicker = (props: {
  history: IF.COMM.HistoryIF[]
  handleHistoryPreview: (value: string, option: number, svg: IF.COMM.SVGIF[]) => void
}) => {
  const { history, handleHistoryPreview } = props

  const showHistory = (item: IF.COMM.HistoryIF) => {
    switch (item.option) {
      case 1:
        return (
          <img
            className={style.historyBG}
            src={item.value}
            onClick={() => handleHistoryPreview(item.value, item.option!, item.svg!)}
          />
        )
      case 2:
        return (
          <div
            className={style.historyBG}
            style={{ background: item.value }}
            onClick={() => handleHistoryPreview(item.value, item.option!, item.svg!)}
          ></div>
        )
      case 3:
        return (
          <div className={style.historyBG} onClick={() => handleHistoryPreview(item.value, item.option!, item.svg!)}>
            <EditorSVG svg={item.svg!} height={45} />
          </div>
        )
      default:
        return
    }
  }

  return (
    <Row>
      {history.map((item: IF.COMM.HistoryIF, index: number) => (
        <Col sm={4} md={4} key={index}>
          <div className={style.historyDiv}>{showHistory(item)}</div>
        </Col>
      ))}
    </Row>
  )
}

export const EditorHistoryColorPicker = (props: {
  history: IF.COMM.HistoryIF[]
  handleHistoryPreview: (value: string, option: number) => void
}) => {
  const { history, handleHistoryPreview } = props

  const showHistory = (item: IF.COMM.HistoryIF) => {
    switch (item.option) {
      case 1:
        return (
          <img
            className={style.historyBG}
            src={item.value}
            onClick={() => handleHistoryPreview(item.value, item.option!)}
          />
        )
      case 2:
        return (
          <div
            className={style.historyBG}
            style={{ background: item.value }}
            onClick={() => handleHistoryPreview(item.value, item.option!)}
          ></div>
        )
      default:
        return
    }
  }

  return (
    <Row>
      {history.map((item: IF.COMM.HistoryIF, index: number) => (
        <Col sm={4} md={4} key={index}>
          <div className={style.historyDiv}>{showHistory(item)}</div>
        </Col>
      ))}
    </Row>
  )
}

export const EditorHistoryIconPicker = (props: {
  color: string
  history: IF.COMM.HistoryIF[]
  handlePreviewHistoryIcon: (value: string, option: number) => void
}) => {
  const { color, history, handlePreviewHistoryIcon } = props

  const showHistory = (item: IF.COMM.HistoryIF) => {
    switch (item.option) {
      case 1:
        return (
          <img
            className={style.historyImage}
            src={item.value}
            onClick={() => handlePreviewHistoryIcon(item.value, item.option!)}
          />
        )
      case 2:
        return (
          <div className={style.historyIcon} onClick={() => handlePreviewHistoryIcon(item.value, item.option!)}>
            {handleHistoryIcon(color, item.value)}
          </div>
        )
      default:
        return
    }
  }

  return (
    <Row>
      {history.map((item: IF.COMM.HistoryIF, index: number) => (
        <Col sm={4} md={4} key={index}>
          <div className={style.historyDiv}>{showHistory(item)}</div>
        </Col>
      ))}
    </Row>
  )
}

export const EditorHistoryValuePicker = (props: {
  history: IF.COMM.HistoryIF[]
  handleHistoryPreview: (value: string) => void
}) => {
  const { history, handleHistoryPreview } = props

  const showHistory = (item: IF.COMM.HistoryIF) => {
    return <img className={style.historyIcon} src={item.value} onClick={() => handleHistoryPreview(item.value)} />
  }

  return (
    <Row>
      {history.map((item: IF.COMM.HistoryIF, index: number) => (
        <Col sm={4} md={4} key={index}>
          <div className={style.historyDiv}>{showHistory(item)}</div>
        </Col>
      ))}
    </Row>
  )
}

export const EditorHistoryPicker = (props: {
  type: string
  color: string
  history: IF.COMM.HistoryIF[]
  handleHistoryPreview: (value: string, option?: number, svg?: IF.COMM.SVGIF[]) => void
}) => {
  const { type, color, history, handleHistoryPreview } = props

  const showVar4 = (item: IF.COMM.HistoryIF) => {
    switch (item.option) {
      case 1:
        return (
          <img
            className={style.historyBG}
            src={item.value}
            onClick={() => handleHistoryPreview(item.value, item.option!, item.svg!)}
          />
        )
      case 2:
        return (
          <div
            className={style.historyBG}
            style={{ background: item.value }}
            onClick={() => handleHistoryPreview(item.value, item.option!, item.svg!)}
          ></div>
        )
      case 3:
        return (
          <div className={style.historyBG} onClick={() => handleHistoryPreview(item.value, item.option!, item.svg!)}>
            <EditorSVG svg={item.svg!} height={45} />
          </div>
        )
      default:
        return
    }
  }

  const showVar3 = (item: IF.COMM.HistoryIF) => {
    switch (item.option) {
      case 1:
        return (
          <img
            className={style.historyBG}
            src={item.value}
            onClick={() => handleHistoryPreview(item.value, item.option!)}
          />
        )
      case 2:
        return (
          <div
            className={style.historyBG}
            style={{ background: item.value }}
            onClick={() => handleHistoryPreview(item.value, item.option!)}
          ></div>
        )
      default:
        return
    }
  }

  const showVar2 = (item: IF.COMM.HistoryIF) => {
    switch (item.option) {
      case 1:
        return (
          <img
            className={style.historyImage}
            src={item.value}
            onClick={() => handleHistoryPreview(item.value, item.option!)}
          />
        )
      case 2:
        return (
          <div className={style.historyIcon} onClick={() => handleHistoryPreview(item.value, item.option!)}>
            {handleHistoryIcon(color, item.value)}
          </div>
        )
      default:
        return
    }
  }

  const showVar1 = (item: IF.COMM.HistoryIF) => {
    return <img className={style.historyIcon} src={item.value} onClick={() => handleHistoryPreview(item.value)} />
  }

  const getHistory = history.map((item: IF.COMM.HistoryIF, index: number) => (
    <Col sm={4} md={4} key={index}>
      <div className={style.historyDiv}>
        {type === EM.COMM.TYPE.BKGD && showVar4(item)}
        {type === EM.COMM.TYPE.CTRL && showVar2(item)}
        {type === EM.COMM.TYPE.MODL && showVar1(item)}
        {type === EM.COMM.TYPE.MENUICON && showVar2(item)}
        {type === EM.COMM.TYPE.MENUBAR && showVar3(item)}
      </div>
    </Col>
  ))

  return <Row>{getHistory}</Row>
}
