import React from 'react'
import style from '../../assets/editor.intro.module.scss'
import { Col, Row } from 'antd'
import { EditorHuePicker, EditorHuePicker2, EditorLabel, EditorSwitch, EditorText } from '../Common/EditorParts'
import { IF, EM, RS } from '../imports'
import IntroIcons from './View/Icons'
import * as m from './methods'

const ColorSetting = (props: {
  intro: IF.INTR.IntroductionIF
  content: IF.INTR.IntroContentIF[]
  control: IF.CTRL.ControlIF
  undoStyle: React.CSSProperties
  module: IF.STAT.ModuleIF
  modules: IF.STAT.ModuleIF[]
  setModules: React.Dispatch<React.SetStateAction<IF.STAT.ModuleIF[]>>
}) => {
  const { intro, content, control, module, modules, undoStyle, setModules } = props

  React.useEffect(() => {
    const collapse = document.getElementsByClassName('fieldCollapse')[0]
    if (collapse) {
      const panels = Array.from(
        collapse?.getElementsByClassName('ant-collapse-header') as HTMLCollectionOf<HTMLElement>
      )
      panels.forEach(
        (item: any) => (
          (item.style.color = control.color), (item.style.fontWeight = 'bold'), (item.style.borderRadius = '8px')
        )
      )
    }
  }, [])

  const syncStyle = { backgroundColor: control.color, width: '120px' }
  const textStyle: React.CSSProperties = {
    padding: '6px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: '',
    fontFamily: '',
  }

  const onChangeColor = (color: string, attribute: string) => {
    m.onChangeIntroColor(intro, color, attribute, control, module, modules, setModules)
  }

  const onChangeSyncColor = (value: boolean, attribute: string) => {
    const newValue = value ? EM.COMM.LOWER.Y : EM.COMM.LOWER.N
    onChangeColor(newValue, attribute)
  }

  return (
    <div className={style.editorPageTwo}>
      {RS.INTR.settingList1.map((setting: IF.COMM.ValueLabelIF, index: number) => (
        <Row className={style.editorRow} key={index}>
          <EditorLabel span={6} label={setting.label} />
          <Col span={18}>
            <EditorText
              span={24}
              text={content!.find((item) => item.label === setting.value)!.text.slice(0, 20) + '...'}
              iStyle={{
                ...textStyle,
                color: content!.find((item) => item.label === setting.value)!.color,
                font: content!.find((item) => item.label === setting.value)!.font,
              }}
            />
            <EditorHuePicker
              span={24}
              width={'80%'}
              color={content!.find((item) => item.label === setting.value)!.color}
              attribute={setting.value}
              undoStyle={undoStyle}
              handleAttribute={onChangeColor}
            />
          </Col>
        </Row>
      ))}
      {RS.INTR.settingList2.map((setting: IF.COMM.LabelListIF, index: number) => (
        <Row className={style.editorRow} key={index}>
          <EditorLabel span={6} label={setting.label} />
          <Col span={18}>
            <div className={style.introEditorIconColumn}>
              <div style={{ marginLeft: '5px' }}>
                {m.introIconObject(content!.find((item) => item.label === setting.list[0])!, true)}
              </div>
              <EditorHuePicker2
                width={'80%'}
                color={content!.find((item) => item.label === setting.list[0])!.color}
                attribute={setting.list[0]}
                undoStyle={undoStyle}
                handleAttribute={onChangeColor}
              />
              <EditorHuePicker2
                width={'80%'}
                color={content!.find((item) => item.label === setting.list[0])!.meta.icon!.color}
                attribute={setting.list[1]}
                undoStyle={undoStyle}
                handleAttribute={onChangeColor}
              />
            </div>
          </Col>
        </Row>
      ))}
      {RS.INTR.settingListColor.map((setting: any, index: number) => {
        const thisObject = content.find((item) => item.label === setting.value)!
        return (
          <>
            <Row className={style.editorRow} key={index}>
              <EditorLabel span={6} label={setting.sync} />
              <EditorSwitch
                span={18}
                show={thisObject.meta.syncColor === EM.COMM.LOWER.Y}
                attribute={setting.attr}
                switchStyle={syncStyle}
                checkedChildren={EM.INTR.VAR.SYNCHRONIZED}
                unCheckedChildren={EM.INTR.VAR.DESYNCHRONIZED}
                handleAttribute={onChangeSyncColor}
              />
            </Row>
            {thisObject.meta.syncColor === EM.COMM.LOWER.Y && (
              <Row className={style.editorRow}>
                <EditorLabel span={6} label={setting.label} />
                <Col span={18}>
                  <IntroIcons color={thisObject.color} />
                  <EditorHuePicker
                    span={24}
                    width={'80%'}
                    color={thisObject.color}
                    attribute={setting.value}
                    undoStyle={undoStyle}
                    handleAttribute={onChangeColor}
                  />
                </Col>
              </Row>
            )}
            {setting.list.map((icon: any) => {
              if (thisObject.meta.syncColor !== EM.COMM.LOWER.Y) {
                const thisIcon = content.find((iconItem) => iconItem.label === icon.label)!
                if (thisIcon.show === EM.COMM.DISPLAY.FLEX) {
                  return (
                    <Row className={style.editorRow}>
                      <Col span={6} className={style.partLabel}>
                        {m.introIconItem(icon.label, thisIcon.color)}
                      </Col>
                      <EditorHuePicker
                        span={18}
                        width={'80%'}
                        color={thisIcon.color}
                        attribute={icon.label}
                        undoStyle={undoStyle}
                        handleAttribute={onChangeColor}
                      />
                    </Row>
                  )
                }
              }
            })}
          </>
        )
      })}
    </div>
  )
}

export default ColorSetting
