import React from 'react'
import style from '../../assets/editor.module.scss'
import { Button, Col, Popover } from 'antd'
import { ColorResult, HuePicker, SwatchesPicker } from 'react-color'
import { EM } from '../imports'

/**
 * Editor hue color picker component to pick colors.
 * @param span width of the component.
 * @param width width of the hue picker.
 * @param color color of the component.
 * @param undoStyle CSS style for the undo button.
 * @param handlePreviewColor callback function to update color.
 * @returns hue color picker.
 */
const EditorHuePicker: React.FC<{
  span: number
  width: string
  color: string
  undoStyle: React.CSSProperties
  handlePreviewColor: (color: string) => void
}> = ({ span, width, color, undoStyle, handlePreviewColor }) => {
  /**
   * Turn React color result to rbg color value.
   * @param color React color result
   * @returns rgb color value.
   */
  const handleColor = (color: ColorResult) => {
    const rgb = color?.rgb
    return `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`
  }

  /**
   * SwatchesPicker color JSX element.
   * @returns SwatchesPicker JSX element.
   */
  const content = () => {
    return <SwatchesPicker color={color} onChange={(color) => handlePreviewColor(handleColor(color))} />
  }

  return (
    <Col span={span}>
      <div className={style.partDivP}>
        <HuePicker width={width} color={color} onChange={(color) => handlePreviewColor(handleColor(color))} />
        <Popover placement="bottomRight" content={content()} trigger="click">
          <Button style={undoStyle} size={EM.COMM.BUTTON.SMALL}>
            {EM.COMM.BUTTON.MORE}
          </Button>
        </Popover>
      </div>
    </Col>
  )
}

export default EditorHuePicker
