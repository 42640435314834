import React from 'react'
import style from '../../assets/editor.module.scss'
import { Col, Slider } from 'antd'

const EditorSlider: React.FC<{
  min: number
  max: number
  span: number
  value: number
  width: React.CSSProperties
  handleAttribute: (value: number) => void
}> = ({ min, max, span, value, width, handleAttribute }) => {
  return (
    <Col span={span}>
      <div className={style.partCenter}>
        <Slider
          defaultValue={value}
          min={min}
          max={max}
          style={width}
          onChange={(value: number) => handleAttribute(value)}
        />
      </div>
    </Col>
  )
}

export default EditorSlider
