import React from 'react'
import style from '../../assets/register.module.scss'
import parse from 'html-react-parser'
import { Link } from 'react-router-dom'
import { Form, Input, Button } from 'antd'
import { isEmpty } from 'lodash'
import { EM, IF, RS, RX } from '../imports'
import Password from './Password'
import * as m from './methods'


const Signup = (props: any) => {
    /** Properties */
    const { auth, dispatch } = props

    /** React Methods */
    const [strength, setStrength] = React.useState<IF.RSTR.PasswordStrengthIF>(RS.RSTR.passwordStrength)
    const passwordCon: any = React.useRef(null)
    const passwordDiv: any = React.useRef(null)

    // eslint-disable-next-line
    const numspecChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~0-9]/

    /** Local Methods */
    const onFinish = async (values: any) => {
        await RX.AUTH.authSignup(auth, dispatch, values.email, values.password, values.firstname, values.lastname)
    }

    return (
        <div className={style.registerDiv}>
            <div className={style.registerTitle}>
                {EM.RSTR.TITLE.SIGNUP}
            </div>
            <Form
                name={EM.RSTR.FORMNAME.SIGNUPFORM}
                onFinish={onFinish}
            >
                <Form.Item
                    name={EM.RSTR.TYPE.EMAIL}
                    rules={[
                        {
                            type: EM.RSTR.TYPE.EMAIL,
                            message: EM.RSTR.FORM.EMAILINVALID,
                        },
                        {
                            required: true,
                            message: EM.RSTR.FORM.EMAILMESSAGE,
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value) {
                                    return Promise.resolve()
                                } else if (auth.users.includes(getFieldValue(EM.RSTR.TYPE.EMAIL))) {
                                    return Promise.reject(new Error(EM.RSTR.FORM.EMAILEXISTED))
                                } else if (getFieldValue(EM.RSTR.TYPE.EMAIL).length >= 150) {
                                    return Promise.reject(new Error(EM.RSTR.FORM.EMAILMAXERR))
                                } else {
                                    return Promise.resolve()
                                }
                            },
                        }),
                    ]}
                    hasFeedback
                >
                    <Input
                        size="large"
                        type={EM.RSTR.TYPE.EMAIL}
                        prefix={'Email: '}
                        disabled={auth.loading}
                        allowClear={true}
                    />
                </Form.Item>
                <Form.Item
                    name={EM.RSTR.TYPE.FIRSTNAME}
                    rules={[
                        {
                            required: true,
                            message: EM.RSTR.FORM.FIRSTNAMEMESSAGE,
                        },
                        () => ({
                            validator(_, value) {
                                if (!value) {
                                    return Promise.resolve()
                                } else if (numspecChars.test(value)) {
                                    return Promise.reject(new Error(EM.RSTR.FORM.NAMEINVALID))
                                } else if (value.length >= 64) {
                                    return Promise.reject(new Error(EM.RSTR.FORM.FIRSTNAMEMAXERR))
                                }
                                return Promise.resolve()
                            }
                        }),
                    ]}
                    hasFeedback
                >
                    <Input
                        size="large"
                        type="text"
                        prefix={EM.RSTR.PREFIX.FIRSTNAME}
                        allowClear={true}
                        disabled={auth.loading}
                    />
                </Form.Item>
                <Form.Item
                    name={EM.RSTR.TYPE.LASTNAME}
                    rules={[
                        {
                            required: true,
                            message: EM.RSTR.FORM.LASTNAMEMESSAGE,
                        },
                        () => ({
                            validator(_, value) {
                                if (!value) {
                                    return Promise.resolve()
                                } else if (numspecChars.test(value)) {
                                    return Promise.reject(new Error(EM.RSTR.FORM.NAMEINVALID))
                                } else if (value.length >= 64) {
                                    return Promise.reject(new Error(EM.RSTR.FORM.LASTNAMEMAXERR))
                                }
                                return Promise.resolve()
                            }
                        }),
                    ]}
                    hasFeedback
                >
                    <Input
                        size="large"
                        type="text"
                        prefix={EM.RSTR.PREFIX.LASTNAME}
                        allowClear={true}
                        disabled={auth.loading}
                    />
                </Form.Item>
                <Form.Item
                    name={EM.RSTR.TYPE.PASSWORD}
                    rules={[
                        {
                            required: true,
                            message: EM.RSTR.FORM.PASSWORDMESSAGE,
                        },
                        () => ({
                            validator(_, value) {
                                if (!value) {
                                    setStrength({ ...RS.RSTR.passwordStrength, length: value.length })
                                    return Promise.resolve()
                                } else if (value.length < 6) {
                                    setStrength({ ...RS.RSTR.passwordStrength, length: value.length })
                                    return Promise.reject(new Error(EM.RSTR.FORM.PASSWORDINVALID))
                                }
                                m.passwordValidation(value, strength, setStrength)
                                return Promise.resolve()
                            },
                        }),
                    ]}
                    hasFeedback
                >
                    <Input.Password
                        size="large"
                        prefix={EM.RSTR.PREFIX.PASSWORD}
                        allowClear={true}
                        disabled={auth.loading}
                        onFocus={() => {
                            passwordCon.current.style.height = passwordDiv.current.clientHeight + 'px'
                        }}
                    />
                </Form.Item>
                <Password strength={strength} passwordCon={passwordCon} passwordDiv={passwordDiv} />
                <Form.Item
                    name={EM.RSTR.TYPE.CONFIRM}
                    dependencies={[EM.RSTR.TYPE.PASSWORD]}
                    rules={[
                        {
                            required: true,
                            message: EM.RSTR.FORM.PASSWORDMESSAGE,
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue(EM.RSTR.TYPE.PASSWORD) === value) {
                                    return Promise.resolve()
                                }
                                return Promise.reject(new Error(EM.RSTR.FORM.PASSWORDMISMATCH))
                            },
                        }),
                    ]}
                    hasFeedback
                >
                    <Input.Password
                        size="large"
                        prefix={EM.RSTR.PREFIX.CONFIRM}
                        allowClear={true}
                        disabled={auth.loading}
                    />
                </Form.Item>
                {!isEmpty(auth.error) && <div className={style.signupError}>{parse(auth.error)}</div>}
                <Form.Item className={style.registerCenter}>
                    <Button size="large" type="primary" htmlType="submit"
                        disabled={auth.loading} className={style.registerButton}>
                        Sign Up
                    </Button>
                </Form.Item>
                <div className={style.registerCenter}>
                    Already have an account?&nbsp;
                    <Link className={style.registerCreate} to={EM.RSTR.URL.SIGNIN}>Sign In</Link>
                </div>
            </Form>
        </div>
    )
}

export default Signup
