import React from 'react'
import style from '../assets/base.module.scss'
import { useAppSelector, useAppDispatch } from '../../hooks'
import { backgroundsMapper, modulesMapper } from './methods'
import { IF, RS, RX, VS } from '../components/imports'
import { RouteComponentProps, match } from 'react-router-dom'

interface TemplateRouteProps extends RouteComponentProps {
  match: match<{ account: string }>
}

const Template: React.FC<TemplateRouteProps> = ({ ...props }) => {
  /**
   * URL property parameter account
   */
  const account = props.match.params.account
  const auth = useAppSelector((state) => state.talentsReducer.auth)
  const status = useAppSelector((state) => state.talentsReducer.status)
  const MENUSnapshot = useAppSelector((state) => state.talentsReducer.MENUSnapshot)
  const NAVGSnapshot = useAppSelector((state) => state.talentsReducer.NAVGSnapshot)
  const CTRLSnapshot = useAppSelector((state) => state.talentsReducer.CTRLSnapshot)
  const BKGDSnapshot = useAppSelector((state) => state.talentsReducer.BKGDSnapshot)
  const MODLSnapshot = useAppSelector((state) => state.talentsReducer.MODLSnapshot)
  const controlSnap = useAppSelector((state) => state.controlReducer.controlSnap)
  const dispatch = useAppDispatch()

  /**
   * React Methods
   */
  const [menuBar, setMenuBar] = React.useState<IF.NAVG.MenuBarIF>(RS.NAVG.menu)
  const [naviBar, setNaviBar] = React.useState<IF.NAVG.NaviBarIF>(RS.NAVG.navibar)
  const [control, setControl] = React.useState<IF.CTRL.ControlIF>(RS.CTRL.control)
  const [modules, setModules] = React.useState<IF.STAT.ModuleIF[]>(RS.STAT.modules)
  const [backgrounds, setBackgrounds] = React.useState<IF.BKGD.BackgroundIF[]>(RS.BKGD.backgrounds)
  const [showEdit, setShowEdit] = React.useState<IF.COMM.DisplayIF>(RS.COMM.hideBlock)
  const [openIcon, setOpenIcon] = React.useState<boolean>(false) // open the menu icon
  const [openMenu, setOpenMenu] = React.useState<boolean>(false) // open the menu drawer
  const [showMenu, setShowMenu] = React.useState<boolean>(false) // trigger the menu display
  const [currentBGTop, setCurrentBGTop] = React.useState<number>(0) // current background offsetTop

  const controlBtnRef: any = React.useRef(null)
  const controlIconRef: any = React.useRef(null)
  const controlPanelRef: any = React.useRef(null)

  React.useEffect(() => {
    RX.AUTH.authAutoSignin(auth, dispatch)
    RX.STAT.fetchProfile(status, dispatch, account, setMenuBar, setNaviBar, setControl, setModules, setBackgrounds)
  }, [])

  /** Local Variables */
  const showLoading = status.loading
  const showError = !status.loading && !status.complete
  const showBackgrounds = !status.loading && status.complete && backgrounds?.length > 0
  const showModules = !status.loading && status.complete && modules?.length > 0
  const showControl = auth.token && auth.account == account
  const showSaveBtn = control.showSave
  const showDrawer = control.showDrawer
  const showPanel = control.showPanel
  const isAuthorized = showControl

  /** Local Methods */
  const resetProperty = (e: any) => {
    const outsideBtn = controlBtnRef.current && !controlBtnRef.current.contains(e.target)
    const outsideIcon = controlIconRef.current && !controlIconRef.current.contains(e.target)
    const outsidePanel = controlPanelRef.current && !controlPanelRef.current.contains(e.target)
    if (outsideBtn && outsideIcon && showSaveBtn) setControl({ ...control, showSave: false })
    if (outsidePanel && outsideIcon && showPanel) setControl({ ...control, showPanel: false })
  }

  const naviBarView = <VS.NaviBar naviBar={naviBar} modules={modules} />

  const menuBarView = (
    <VS.MenuBar
      naviBar={naviBar}
      menuBar={menuBar}
      modules={modules}
      openIcon={openIcon}
      openMenu={openMenu}
      showMenu={showMenu}
      setOpenMenu={setOpenMenu}
      setShowMenu={setShowMenu}
    />
  )

  const controlIcon = (
    <VS.ControlIcon
      control={control}
      controlSnap={controlSnap}
      setControl={setControl}
      setShowEdit={setShowEdit}
      controlIconRef={controlIconRef}
    />
  )

  const controlSave = (
    <VS.ControlSave
      auth={auth}
      dispatch={dispatch}
      control={control}
      controlBtnRef={controlBtnRef}
      controlSnap={controlSnap}
      controlIconRef={controlIconRef}
      setControl={setControl}
    />
  )

  const controlPanel = (
    <VS.ControlPanel
      control={control}
      controlPanelRef={controlPanelRef}
      setControl={setControl}
      setOpenMenu={setOpenMenu}
      setShowMenu={setShowMenu}
    />
  )

  const allBackgrounds = backgroundsMapper(backgrounds)

  const allModules = modulesMapper(
    auth,
    isAuthorized,
    control,
    modules,
    MODLSnapshot,
    dispatch,
    setControl,
    setModules,
    setShowEdit
  )

  const editor = (
    <VS.Editor
      auth={auth}
      naviBar={naviBar}
      menuBar={menuBar}
      control={control}
      modules={modules}
      backgrounds={backgrounds}
      NAVGSnapshot={NAVGSnapshot}
      MENUSnapshot={MENUSnapshot}
      controlSnap={controlSnap}
      BKGDSnapshot={BKGDSnapshot}
      MODLSnapshot={MODLSnapshot}
      currentBGTop={currentBGTop}
      controlIconRef={controlIconRef}
      dispatch={dispatch}
      setNaviBar={setNaviBar}
      setMenuBar={setMenuBar}
      setControl={setControl}
      setModules={setModules}
      setOpenIcon={setOpenIcon}
      setOpenMenu={setOpenMenu}
      setShowMenu={setShowMenu}
      setBackgrounds={setBackgrounds}
      setCurrentBGTop={setCurrentBGTop}
    />
  )

  const editButton = (
    <VS.EditorPopup
      modules={modules}
      control={control}
      showEdit={showEdit}
      setControl={setControl}
      setShowEdit={setShowEdit}
    />
  )

  return (
    <div id={style.templateDiv} onClick={(e) => resetProperty(e)}>
      {showLoading && <VS.Loading />}
      {showError && <VS.ErrorMessage code={status.code!} />}
      {showModules && naviBarView}
      {showModules && menuBarView}
      {showControl && controlIcon}
      {showSaveBtn && controlSave}
      {showPanel && controlPanel}
      {showBackgrounds && allBackgrounds}
      {showModules && allModules}
      {showDrawer && editor}
      {editButton}
    </div>
  )
}

export default Template
