import React from 'react'
import style from '../../assets/editor.module.scss'
import { isEqual } from 'lodash'
import { Row } from 'antd'
import {
  EditorDropDown,
  EditorFontPicker,
  EditorHistoryLabel,
  EditorHuePicker,
  EditorLabel,
  EditorNavPreview,
  EditorSlider,
  EditorWeight,
} from '../Common/EditorParts'
import { EditorHistoryColorPicker } from '../Common/History'
import { handleColorPickerWidth } from '../Editor/methods'
import { weightObject } from '../Common/methods'
import { EM, IF, RS } from '../imports'
import * as m from './methods'
import EditorImagePicker from '../Common/EditorImagePicker'
import EditorColorPicker from '../Common/EditorColorPicker'

const NaviBarBotSetting = (props: any) => {
  /** Properties */
  const {
    control,
    naviBar,
    snapShot,
    modules,
    saveStyle,
    undoStyle,
    uploading,
    uploadBar,
    loading,
    setNaviBar,
    setUploading,
    setUploadbar,
    setFileObject,
  } = props

  /** React Methods */
  const [selection, setSelection] = React.useState<IF.COMM.ValueNameIF>(RS.COMM.selectOption)
  const [naviPreview, setNaviPreview] = React.useState<IF.NAVG.NaviStyleIF>(RS.NAVG.navPreview)
  const [fileName, setFileName] = React.useState<string>(EM.COMM.FILENAME.NOTAVAILABLE)
  const imageRef: any = React.useRef(null)

  React.useEffect(() => {
    const height = Math.floor(400 * (naviBar.heightTwo / window.innerHeight))
    const size = Math.floor(naviBar.sizeTwo * (naviBar.heightTwo / window.innerHeight))
    setNaviPreview({
      color: naviBar.textTwo,
      width: `${naviBar.width}%`,
      height: `${height}px`,
      fontSize: `${size === 0 ? 1 : size}px`,
      fontFamily: naviBar.fontTwo,
      fontWeight: weightObject(naviBar.weightTwo),
      background:
        naviBar.optionTwo === 1 ? `url(${naviBar.imageTwo}) center ${naviBar.positionTwo}% / cover` : naviBar.colorTwo,
    })
    handleColorPickerWidth()
  }, [naviBar])

  /* Local Variables */
  const showPickerRow = selection === RS.COMM.selectOption ? RS.COMM.hideBlock : RS.COMM.showFlex
  const showColorPicker = isEqual(selection, RS.BKGD.selectColor)

  /** Local Methods */
  const onChangeOption = (item: IF.COMM.ValueNameIF) => {
    setSelection(item)
  }

  /**
   * Update the navigation bar bottom image.
   * @param image string.
   * @returns void.
   */
  const handlePreviewImage = (image: string) => {
    setNaviBar({ ...naviBar, imageTwo: image, optionTwo: 1 })
    setFileObject('')
  }

  /**
   * Update the navigation bar bottom image URL.
   * @param url string.
   * @returns void.
   */
  const handlePreviewURL = (url: string) => {
    setNaviBar({ ...naviBar, imageTwo: url, optionTwo: 1 })
    setFileObject('')
  }

  /**
   * Update the navigation bar bottom image upload file.
   * @param event React change event for the HTML input element.
   * @returns void.
   */
  const handlePreviewUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files![0] === undefined) return
    if (event.target.files![0].size > RS.COMM.size5MB) {
      setNaviBar(snapShot)
      setFileName(EM.COMM.FILENAME.NOTAVAILABLE)
      setFileObject('')
    }
    const imageReader = new FileReader()
    imageReader.onload = (event) => {
      setNaviBar({ ...naviBar, imageTwo: event!.target!.result as string, optionTwo: 1 })
    }
    imageReader.readAsDataURL(event.target.files![0])
    setFileName(event.target.files![0].name)
    setFileObject(event.target.files![0])
  }

  const handlePreviewColor = (color: string) => {
    setNaviBar({ ...naviBar, colorTwo: color, optionTwo: 2 })
    setFileObject('')
  }

  const handleAttribute = (event: any, attribute: string) => {
    m.handleNavBarAttribute(event, attribute, naviBar, setNaviBar)
  }

  const handleHistoryPreview = (value: string, option: number) => {
    if (option === 1) {
      setSelection(RS.COMM.selectOption)
      setNaviBar({ ...naviBar, imageTwo: value, optionTwo: 1 })
    } else if (option === 2) {
      setSelection(RS.COMM.selectColor)
      setNaviBar({ ...naviBar, colorTwo: value, optionTwo: 2 })
    }
  }

  return (
    <div className={style.editorPageTwo}>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.NAVG.LABEL.BOT} />
        <EditorNavPreview naviBar={naviBar} modules={modules} naviPreview={naviPreview} />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.NAVG.LABEL.OPTION} />
        <EditorDropDown
          span={18}
          loading={loading}
          sourceList={RS.COMM.selectionNoGrad}
          selection={selection}
          saveStyle={saveStyle}
          onChangeItem={onChangeOption}
        />
      </Row>
      <Row className={style.editorRow} style={showPickerRow}>
        <EditorImagePicker
          type={EM.COMM.IMAGETYPE.NAVBAR}
          images={RS.BKGD.pickers}
          selection={selection}
          imageRef={imageRef}
          saveStyle={saveStyle}
          fileName={fileName}
          uploading={uploading}
          uploadBar={uploadBar}
          loading={loading}
          setUploading={setUploading}
          setUploadbar={setUploadbar}
          handlePreviewImage={handlePreviewImage}
          handlePreviewURL={handlePreviewURL}
          handlePreviewUpload={handlePreviewUpload}
        />
        <EditorColorPicker
          color={naviBar.colorTwo}
          showColorPicker={showColorPicker}
          handlePreviewColor={handlePreviewColor}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.NAVG.LABEL.WIDTH} />
        <EditorSlider
          min={0}
          max={100}
          span={18}
          value={naviBar.widthTwo}
          width={RS.COMM.width90}
          attribute={EM.NAVG.FORM.WIDTHTWO}
          handleAttribute={handleAttribute}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.NAVG.LABEL.HEIGHT} />
        <EditorSlider
          min={0}
          max={200}
          span={18}
          value={naviBar.heightTwo}
          width={RS.COMM.width90}
          attribute={EM.NAVG.FORM.HEIGHTTWO}
          handleAttribute={handleAttribute}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.NAVG.LABEL.SIZE} />
        <EditorSlider
          min={0}
          max={100}
          span={18}
          value={naviBar.sizeTwo}
          width={RS.COMM.width90}
          attribute={EM.NAVG.FORM.SIZETWO}
          handleAttribute={handleAttribute}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.NAVG.LABEL.POSITION} />
        <EditorSlider
          min={0}
          max={100}
          span={18}
          value={naviBar.positionTwo}
          width={RS.COMM.width90}
          attribute={EM.NAVG.FORM.POSITIONTWO}
          handleAttribute={handleAttribute}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.NAVG.LABEL.FONT} />
        <EditorFontPicker
          span={18}
          text={modules[0].title}
          buttonWidth={'100px'}
          textWidth={'140px'}
          buttonColor={control.color}
          textColor={naviBar.textTwo}
          snapShot={snapShot.fontTwo}
          fontSize={naviBar.sizeTwo}
          fontFamily={naviBar.fontTwo}
          fontWeight={naviBar.weightTwo}
          attribute={EM.NAVG.FORM.FONTTWO}
          handleAttribute={handleAttribute}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.NAVG.LABEL.COLOR} />
        <EditorHuePicker
          span={18}
          width={'70%'}
          color={naviBar.textTwo}
          attribute={EM.NAVG.FORM.TEXTTWO}
          undoStyle={undoStyle}
          handleAttribute={handleAttribute}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.NAVG.LABEL.WEIGHT} />
        <EditorWeight
          span={18}
          size={2}
          value={naviBar.weightTwo}
          saveStyle={saveStyle}
          undoStyle={undoStyle}
          attribute={EM.NAVG.FORM.WEIGHTTWO}
          handleAttribute={handleAttribute}
        />
      </Row>
      <Row className={style.editorRowHistory}>
        <EditorHistoryLabel history={naviBar.historyTwo} />
        <EditorHistoryColorPicker history={naviBar.historyTwo} handleHistoryPreview={handleHistoryPreview} />
      </Row>
    </div>
  )
}

export default NaviBarBotSetting
