import React from 'react'
import style from '../../assets/editor.intro.module.scss'
import type { DatePickerProps } from 'antd'
import { Col, Input, Row, Select } from 'antd'
import {
  EditorDatePicker,
  EditorEmail,
  EditorInput,
  EditorInputAddon,
  EditorLabel,
  EditorTextArea,
} from '../Common/EditorParts'
import { IF, RS, EM } from '../imports'
import * as m from './methods'

const TextSetting = (props: {
  intro: IF.INTR.IntroductionIF
  control: IF.CTRL.ControlIF
  module: IF.STAT.ModuleIF
  modules: IF.STAT.ModuleIF[]
  setModules: React.Dispatch<React.SetStateAction<IF.STAT.ModuleIF[]>>
}) => {
  const { intro, control, module, modules, setModules } = props

  /** React Methods */
  const [emailError, setEmailError] = React.useState<string>('')
  const [phoneProps, setPhoneProps] = React.useState<IF.INTR.IntroPhonePropsIF>({
    countryCode: intro.phone.meta.country,
    phoneCode: intro.phone.meta.code,
  })

  /** Local Variables */
  const address = intro.content!.find((item) => item.label === EM.INTR.ATTRIBUTE.ADDRESS)!

  const onChangePhoneCode = (result: { value: string; label: React.ReactNode }) => {
    setPhoneProps({
      countryCode: result.value.split(':')[0],
      phoneCode: result.value.split(':')[1],
    })
    onChangeText(result.value, EM.INTR.ATTRIBUTE.PHONECODE)
  }

  const selectBefore = (
    <Select
      value={{
        value: `${phoneProps.countryCode}:${phoneProps.phoneCode}`,
        label: `${phoneProps.countryCode}: ${phoneProps.phoneCode}`,
      }}
      labelInValue
      showSearch
      optionFilterProp="children"
      onChange={onChangePhoneCode}
      filterOption={m.filterOption}
      options={m.optionPhone}
      style={{ width: '100px' }}
    ></Select>
  )

  /** Local Methods */
  const onChangeBirthday: DatePickerProps['onChange'] = (date, dateString) => {
    onChangeText(dateString, EM.INTR.ATTRIBUTE.BIRTHDAY)
  }

  const onChangeEmail = (value: string, attribute: string) => {
    // eslint-disable-next-line
    const validEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/
    if (!validEmail.test(value)) {
      setEmailError(EM.RSTR.FORM.EMAILINVALID)
    } else {
      setEmailError('')
    }
    onChangeText(value, attribute)
  }

  const onChangePhone = (value: string, attribute: string) => {
    value = value.replace(/\D/g, '')
    if (value.length > 0) value = '(' + value
    if (value.length > 3) value = value.slice(0, 4) + ') ' + value.slice(4)
    if (value.length > 9) value = value.slice(0, 9) + '-' + value.slice(9)
    onChangeText(value, attribute)
  }

  const onChangeCountry = (result: { value: string; label: React.ReactNode }) => {
    onChangeText(result.value, EM.INTR.ATTRIBUTE.COUNTRYCODE)
  }

  const onChangeState = (result: { value: string; label: React.ReactNode }) => {
    onChangeText(result.value, EM.INTR.ATTRIBUTE.STATECODE)
  }

  const onChangeText = (value: string, attribute: string) => {
    m.onChangeIntroText(value, attribute, control, module, modules, setModules)
  }

  return (
    <div className={style.editorPageTwo}>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.INTR.LABEL.FIRSTNAME} />
        <EditorInput
          max={60}
          span={18}
          value={intro.content!.find((item) => item.label === EM.INTR.ATTRIBUTE.NAME)!.meta.firstName}
          inputType={EM.COMM.TYPE.TEXT}
          attribute={EM.INTR.ATTRIBUTE.FIRSTNAME}
          handleAttribute={onChangeText}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.INTR.LABEL.MIDDLENAME} />
        <EditorInput
          max={60}
          span={18}
          value={intro.content!.find((item) => item.label === EM.INTR.ATTRIBUTE.NAME)!.meta.middleName}
          inputType={EM.COMM.TYPE.TEXT}
          attribute={EM.INTR.ATTRIBUTE.MIDDLENAME}
          handleAttribute={onChangeText}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.INTR.LABEL.LASTNAME} />
        <EditorInput
          max={60}
          span={18}
          value={intro.content!.find((item) => item.label === EM.INTR.ATTRIBUTE.NAME)!.meta.lastName}
          inputType={EM.COMM.TYPE.TEXT}
          attribute={EM.INTR.ATTRIBUTE.LASTNAME}
          handleAttribute={onChangeText}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.INTR.LABEL.GREETING} />
        <EditorInput
          max={60}
          span={18}
          value={intro.content!.find((item) => item.label === EM.INTR.ATTRIBUTE.GREETING)!.text}
          inputType={EM.COMM.TYPE.TEXT}
          attribute={EM.INTR.ATTRIBUTE.GREETING}
          handleAttribute={onChangeText}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.INTR.LABEL.TITLE} />
        <EditorInput
          max={60}
          span={18}
          value={intro.content!.find((item) => item.label === EM.INTR.ATTRIBUTE.TITLE)!.text}
          inputType={EM.COMM.TYPE.TEXT}
          attribute={EM.INTR.ATTRIBUTE.TITLE}
          handleAttribute={onChangeText}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.INTR.LABEL.DETAIL} />
        <EditorTextArea
          max={300}
          span={18}
          minRows={3}
          maxRows={4}
          value={intro.content!.find((item) => item.label === EM.INTR.ATTRIBUTE.DETAIL)!.text}
          attribute={EM.INTR.ATTRIBUTE.DETAIL}
          handleAttribute={onChangeText}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.INTR.LABEL.BIRTHDAY} />
        <EditorDatePicker
          span={18}
          value={intro.content!.find((item) => item.label === EM.INTR.ATTRIBUTE.BIRTHDAY)!.text}
          handleAttribute={onChangeBirthday}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.INTR.LABEL.EMAIL} />
        <EditorEmail
          max={60}
          span={18}
          stat={emailError === '' ? '' : EM.COMM.VAR.ERROR}
          value={intro.content!.find((item) => item.label === EM.INTR.ATTRIBUTE.EMAIL)!.text}
          error={emailError}
          inputType={EM.COMM.TYPE.TEXT}
          attribute={EM.INTR.ATTRIBUTE.EMAIL}
          handleAttribute={onChangeEmail}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.INTR.LABEL.PHONE} />
        <EditorInputAddon
          max={16}
          span={18}
          value={intro.content!.find((item) => item.label === EM.INTR.ATTRIBUTE.PHONE)!.meta.number}
          addon={selectBefore}
          attribute={EM.INTR.ATTRIBUTE.PHONENUMBER}
          handleAttribute={onChangePhone}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.INTR.LABEL.STREETONE} />
        <EditorInput
          max={60}
          span={18}
          value={address.meta.streetOne}
          inputType={EM.COMM.TYPE.TEXT}
          attribute={EM.INTR.ATTRIBUTE.STREETONE}
          handleAttribute={onChangeText}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.INTR.LABEL.CITY} />
        <EditorInput
          max={60}
          span={18}
          value={address.meta.city!}
          inputType={EM.COMM.TYPE.TEXT}
          attribute={EM.INTR.ATTRIBUTE.CITY}
          handleAttribute={onChangeText}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.INTR.LABEL.STATE} />
        <Col span={18}>
          <div className={style.introColCenter}>
            {address.meta.countryCode !== EM.COMM.COUNTRY.US && address.meta.countryCode !== EM.COMM.COUNTRY.CA ? (
              <Input
                value={address.meta.stateName}
                showCount
                maxLength={60}
                style={RS.COMM.width90}
                onChange={(event: any) => onChangeText(event.target.value, EM.INTR.ATTRIBUTE.STATENAME)}
              />
            ) : (
              <Select
                value={{
                  value: `${address.meta.stateCode}:${address.meta.stateName}`,
                  label: address.meta.stateName,
                }}
                labelInValue
                showSearch
                optionFilterProp="children"
                onChange={onChangeState}
                filterOption={m.filterOption}
                options={address.meta.countryCode === EM.COMM.COUNTRY.CA ? m.optionCanada : m.optionUS}
                style={RS.COMM.width90}
              ></Select>
            )}
          </div>
        </Col>
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.INTR.LABEL.COUNTRY} />
        <Col span={18}>
          <div className={style.introColCenter}>
            <Select
              value={{
                value: `${address.meta.countryCode}:${address.meta.countryName}`,
                label: address.meta.countryName,
              }}
              labelInValue
              showSearch
              optionFilterProp="children"
              onChange={onChangeCountry}
              filterOption={m.filterOption}
              options={m.optionCountry}
              style={RS.COMM.width90}
            ></Select>
          </div>
        </Col>
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.INTR.LABEL.WEBSITE} />
        <EditorInput
          max={60}
          span={18}
          value={intro.content!.find((item) => item.label === EM.INTR.ATTRIBUTE.WEBSITE)!.text}
          inputType={EM.COMM.TYPE.TEXT}
          attribute={EM.INTR.ATTRIBUTE.WEBSITE}
          handleAttribute={onChangeText}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.INTR.LABEL.FACEBOOK} />
        <EditorInput
          max={60}
          span={18}
          value={intro.content!.find((item) => item.label === EM.INTR.ATTRIBUTE.FACEBOOK)!.text}
          inputType={EM.COMM.TYPE.TEXT}
          attribute={EM.INTR.ATTRIBUTE.FACEBOOK}
          handleAttribute={onChangeText}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.INTR.LABEL.TWITTER} />
        <EditorInput
          max={60}
          span={18}
          value={intro.content!.find((item) => item.label === EM.INTR.ATTRIBUTE.TWITTER)!.text}
          inputType={EM.COMM.TYPE.TEXT}
          attribute={EM.INTR.ATTRIBUTE.TWITTER}
          handleAttribute={onChangeText}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.INTR.LABEL.LINKEDIN} />
        <EditorInput
          max={60}
          span={18}
          value={intro.content!.find((item) => item.label === EM.INTR.ATTRIBUTE.LINKEDIN)!.text}
          inputType={EM.COMM.TYPE.TEXT}
          attribute={EM.INTR.ATTRIBUTE.LINKEDIN}
          handleAttribute={onChangeText}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.INTR.LABEL.INSTAGRAM} />
        <EditorInput
          max={60}
          span={18}
          value={intro.content!.find((item) => item.label === EM.INTR.ATTRIBUTE.INSTAGRAM)!.text}
          inputType={EM.COMM.TYPE.TEXT}
          attribute={EM.INTR.ATTRIBUTE.INSTAGRAM}
          handleAttribute={onChangeText}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.INTR.LABEL.YOUTUBE} />
        <EditorInput
          max={60}
          span={18}
          value={intro.content!.find((item) => item.label === EM.INTR.ATTRIBUTE.YOUTUBE)!.text}
          inputType={EM.COMM.TYPE.TEXT}
          attribute={EM.INTR.ATTRIBUTE.YOUTUBE}
          handleAttribute={onChangeText}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.INTR.LABEL.GITHUB} />
        <EditorInput
          max={60}
          span={18}
          value={intro.content!.find((item) => item.label === EM.INTR.ATTRIBUTE.GITHUB)!.text}
          inputType={EM.COMM.TYPE.TEXT}
          attribute={EM.INTR.ATTRIBUTE.GITHUB}
          handleAttribute={onChangeText}
        />
      </Row>
    </div>
  )
}

export default TextSetting
