import { Button } from 'antd'
import React from 'react'
import base from '../../assets/base.module.scss'
import style from '../../assets/frame.module.scss'
import Avatar from '../Introduction/View/Avatar'
import Biography from '../Introduction/View/Biography'
import { EM, IF, RS, RX } from '../imports'
import FrameSVG from './FrameSVG'
import * as m from './methods'

const IntroTypeOne = (props: any) => {
  /** Properties */
  const {
    auth,
    dispatch,
    index,
    modules,
    control,
    intro,
    frame,
    FRAMESnapshot,
    isAuthorized,
    setControl,
    setModules,
    setShowEdit,
  } = props

  /** React Methods */
  const [avatarHeight, setAvatarHeight] = React.useState<number>(frame.height)
  const [frameStyle, setFrameStyle] = React.useState<IF.FRME.BorderIF>(frame)
  const [showHint, setShowHint] = React.useState<boolean>(false)

  const bioRef: any = React.useRef(null)
  const frameRef: any = React.useRef(null)

  React.useEffect(() => {
    const bioHeight = bioRef?.current?.offsetHeight
    if (bioHeight > frame.height) setAvatarHeight(bioHeight)
  }, [bioRef?.current?.offsetHeight])

  React.useEffect(() => {
    if (modules[index].show) m.updateFrameStyle(frame, isAuthorized, setFrameStyle)
  }, [frame, modules[index].show, window.innerWidth])

  /** Local Variables */
  const hasMovedFrame = FRAMESnapshot && (FRAMESnapshot.top !== frame.top || FRAMESnapshot.left !== frame.left)
  const saveStyle = { borderColor: control.color, backgroundColor: control.color }
  const undoStyle = { borderColor: control.color, color: control.color }
  const showHintStyle = isAuthorized && showHint && !hasMovedFrame ? RS.COMM.showFlex : RS.COMM.hideBlock
  const showSaveStyle = isAuthorized && hasMovedFrame ? RS.COMM.showFlex : RS.COMM.hideBlock
  const showModule = modules[index].show && modules[index].status === EM.MODL.TYPE.ACTIVE

  /** Local Methods */
  const onSavePose = () => RX.MODL.updatePosition(auth, dispatch, index, modules)
  const onDragModule = (evt: any) => {
    if (evt.button === 0) m.dragModule(evt, index, frame, frameRef, modules, isAuthorized, setModules, setShowHint)
  }
  const onRevertPose = () => m.revertPosition(index, modules, FRAMESnapshot, setModules, setShowHint)
  const onHoverIntro = (hover: boolean) => {
    m.handleHoverModule(index, hover, modules, setModules)
  }
  const handleEditButton = (event: any) => {
    event.preventDefault()
    if (isAuthorized && (showHint || hasMovedFrame)) {
      setControl({ ...control, showPanel: false })
      setShowEdit({
        ...RS.COMM.showFlex,
        width: 'max-content',
        top: `${event.clientY + 10}px`,
        left: `${event.clientX}px`,
      })
    }
  }

  const showHintMsg = (
    <div className={style.frameMoveHint}>
      <span>{EM.FRME.BUTTON.DRAG}</span>
      <span>{EM.FRME.BUTTON.RIGHT}</span>
    </div>
  )

  const showSaveDiv = (
    <div className={style.frameSaveHint}>
      <Button style={saveStyle} type="primary" onClick={onSavePose}>
        {EM.COMM.BUTTON.SAVE}
      </Button>
      <Button style={undoStyle} onClick={onRevertPose}>
        {EM.COMM.BUTTON.UNDO}
      </Button>
    </div>
  )

  return (
    <div className={base.baseColContainer}>
      <div
        id={`module-${modules[index].title}`}
        className={`${style.frame} ${isAuthorized ? style.frameDiv : ''}`}
        style={showModule ? frameStyle : RS.COMM.hideBlock}
        ref={frameRef}
        onMouseDown={onDragModule}
        onClick={() => {
          setShowEdit(RS.COMM.hideBlock)
        }}
        onMouseOver={() => {
          setShowHint(true)
          onHoverIntro(true)
          setControl({ ...control, index: index, type: EM.CTRL.TYPE.MODULE })
        }}
        onMouseOut={() => {
          setShowHint(false)
          onHoverIntro(false)
        }}
        onContextMenu={(event: any) => handleEditButton(event)}
      >
        <div className={style.frameHintDiv} style={showHintStyle}>
          {!hasMovedFrame && showHintMsg}
        </div>
        <div className={style.frameSaveDiv} style={showSaveStyle}>
          {showSaveDiv}
        </div>
        {frame.option === 3 && <FrameSVG svg={frame.svg} height={'100%'} radius={frame.radius} />}
        <>
          <Avatar avatar={intro.avatar} height={avatarHeight} />
          <Biography bioRef={bioRef} intro={intro} />
        </>
      </div>
    </div>
  )
}

export default IntroTypeOne
