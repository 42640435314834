import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { controlSnap as initialState } from '../../resources/control'
import { RootState } from '../../../store'
import { IF } from '../../components/imports'

export const control = createSlice({
  name: 'control',
  initialState,
  reducers: {
    updateControlSnap(state: IF.STAT.ControlStateIF, action: PayloadAction<IF.CTRL.ControlIF>) {
      state.controlSnap = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateControlSnap } = control.actions

export const controlSnap = (state: RootState) => state.controlReducer.controlSnap

export default control.reducer
