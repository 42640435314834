import { IF } from '../components/imports'

export const frameIcon: IF.COMM.IconIF = {
  icon: 'https://assets.havkonsoft.com/webapp/icon_frame.png',
  jsx: (
    <a href="https://www.flaticon.com/free-icons/picture" title="picture icons">
      Picture icons created by Freepik - Flaticon
    </a>
  ),
}

export const avatarBoyIcon: IF.COMM.IconIF = {
  icon: 'https://assets.havkonsoft.com/webapp/icon_boy.png',
  jsx: (
    <a href="https://www.flaticon.com/free-icons/happy" title="happy icons">
      Happy icons created by Smashicons - Flaticon
    </a>
  ),
}

export const avatarGirlIcon: IF.COMM.IconIF = {
  icon: 'https://assets.havkonsoft.com/webapp/icon_girl.png',
  jsx: (
    <a href="https://www.flaticon.com/free-icons/girl" title="girl icons">
      Girl icons created by Smashicons - Flaticon
    </a>
  ),
}

export const presetIcon: IF.COMM.IconIF = {
  icon: 'https://assets.havkonsoft.com/webapp/icon_preset.png',
  jsx: (
    <a href="https://www.flaticon.com/free-icons/survey" title="survey icons">
      Survey icons created by smashingstocks - Flaticon
    </a>
  ),
}

export const customIcon: IF.COMM.IconIF = {
  icon: 'https://assets.havkonsoft.com/webapp/icon_custom.png',
  jsx: (
    <a href="https://www.flaticon.com/free-icons/personalized" title="personalized icons">
      Personalized icons created by Flat Icons - Flaticon
    </a>
  ),
}

export const orderIcon: IF.COMM.IconIF = {
  icon: 'https://assets.havkonsoft.com/webapp/icon_order.png',
  jsx: (
    <a href="https://www.flaticon.com/free-icons/picture" title="picture icons">
      Picture icons created by Freepik - Flaticon
    </a>
  ),
}

export const moduleIcon: IF.COMM.IconIF = {
  icon: 'https://assets.havkonsoft.com/webapp/icon_module.png',
  jsx: (
    <a href="https://www.flaticon.com/free-icons/module" title="module icons">
      Module icons created by Freepik - Flaticon
    </a>
  ),
}

export const textIcon: IF.COMM.IconIF = {
  icon: 'https://assets.havkonsoft.com/webapp/icon_text.png',
  jsx: (
    <a href="https://www.flaticon.com/free-icons/text" title="text icons">
      Text icons created by Freepik - Flaticon
    </a>
  ),
}

export const colorIcon: IF.COMM.IconIF = {
  icon: 'https://assets.havkonsoft.com/webapp/icon_color.png',
  jsx: (
    <a href="https://www.flaticon.com/free-icons/color-palette" title="color palette icons">
      Color palette icons created by Freepik - Flaticon
    </a>
  ),
}

export const fontIcon: IF.COMM.IconIF = {
  icon: 'https://assets.havkonsoft.com/webapp/icon_font.png',
  jsx: (
    <a href="https://www.flaticon.com/free-icons/font" title="font icons">
      Font icons created by Lizel Arina - Flaticon
    </a>
  ),
}

export const sizeIcon: IF.COMM.IconIF = {
  icon: 'https://assets.havkonsoft.com/webapp/icon_size.png',
  jsx: (
    <a href="https://www.flaticon.com/free-icons/scalability" title="scalability icons">
      Scalability icons created by Freepik - Flaticon
    </a>
  ),
}
