import React from 'react'
import base from '../../assets/base.module.scss'
import style from '../../assets/register.module.scss'
import { useAppSelector, useAppDispatch } from '../../../hooks'
import { Form, Input, Button } from 'antd'
import { Link, useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { EM, IF, RS, RX } from '../imports'
import Password from './Password'
import * as m from './methods'


const Reset = () => {
    /** Redux Properties */
    const history = useHistory()
    const auth = useAppSelector(state => state.talentsReducer.auth)
    const dispatch = useAppDispatch()

    /** React Methods */
    const [strength, setStrength] = React.useState<IF.RSTR.PasswordStrengthIF>(RS.RSTR.passwordStrength)
    const passwordCon: any = React.useRef(null)
    const passwordDiv: any = React.useRef(null)

    React.useEffect(() => {
        RX.AUTH.authResetPWD(auth, dispatch, 'get', name, uuid, null)
    }, [])

    /** Local Variables */
    const pathName = window.location.pathname
    const name = pathName.split('/')[3]
    const uuid = pathName.split('/')[4]

    /** Local Methods */
    const resetError = () => { RX.AUTH.authSetError(auth, dispatch, null) }

    const goToSignIn = () => {
        RX.AUTH.authSetMessage(auth, dispatch, null)
        history.push(EM.RSTR.URL.SIGNIN)
    }

    const onFinish = async (values: any) => {
        await RX.AUTH.authResetPWD(auth, dispatch, 'post', name, uuid, values.password)
    }

    return (
        <div className={base.baseColContainer}>
            <div className={style.registerFrame}>
                <div className={style.registerResetDiv}>
                    <div className={style.registerTitle}>{EM.RSTR.TITLE.RESET}</div>
                    {auth.loading && <p>{EM.RSTR.TEXT.LOADING} </p>}
                    {!isEmpty(auth.error) && auth.error === EM.RSTR.AUTH.NONE &&
                        <div>
                            {m.invalidLink}
                            <div className={style.registerCenter}>
                                {EM.RSTR.TEXT.ACCOUNTEXITS}&nbsp;
                                <Link
                                    className={style.registerCreate}
                                    onClick={resetError}
                                    to={EM.RSTR.URL.SIGNIN}>
                                    {EM.RSTR.TEXT.SIGNIN}
                                </Link>
                            </div>
                        </div>
                    }
                    {!isEmpty(auth.error) && auth.error === EM.RSTR.AUTH.ACTIVE &&
                        <Form
                            name={EM.RSTR.FORMNAME.RESETFORM}
                            onFinish={onFinish}
                        >
                            <Form.Item
                                name={EM.RSTR.TYPE.PASSWORD}
                                rules={[
                                    {
                                        required: true,
                                        message: EM.RSTR.FORM.PASSWORDMESSAGE,
                                    },
                                    () => ({
                                        validator(_, value) {
                                            if (!value) {
                                                setStrength({ ...RS.RSTR.passwordStrength, length: value.length })
                                                return Promise.resolve()
                                            } else if (value.length < 6) {
                                                setStrength({ ...RS.RSTR.passwordStrength, length: value.length })
                                                return Promise.reject(new Error(EM.RSTR.FORM.PASSWORDINVALID))
                                            }
                                            m.passwordValidation(value, strength, setStrength)
                                            return Promise.resolve()
                                        },
                                    }),
                                ]}
                                hasFeedback
                            >
                                <Input.Password
                                    size="large"
                                    prefix={EM.RSTR.PREFIX.PASSWORD}
                                    allowClear={true}
                                    disabled={auth.loading}
                                    onFocus={() => {
                                        passwordCon.current.style.height = passwordDiv.current.clientHeight + 'px'
                                    }}
                                />
                            </Form.Item>
                            <Password strength={strength} passwordCon={passwordCon} passwordDiv={passwordDiv} />
                            <Form.Item
                                name={EM.RSTR.TYPE.CONFIRM}
                                dependencies={[EM.RSTR.TYPE.PASSWORD]}
                                rules={[
                                    {
                                        required: true,
                                        message: EM.RSTR.FORM.PASSWORDCONFIRM,
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue(EM.RSTR.TYPE.PASSWORD) === value) {
                                                return Promise.resolve()
                                            }
                                            return Promise.reject(new Error(EM.RSTR.FORM.PASSWORDMISMATCH))
                                        },
                                    }),
                                ]}
                                hasFeedback
                            >
                                <Input.Password
                                    size="large"
                                    prefix={EM.RSTR.PREFIX.CONFIRM}
                                    allowClear={true}
                                    disabled={auth.loading}
                                />
                            </Form.Item>
                            <Form.Item className={style.registerCenter}>
                                <Button size="large" type="primary" htmlType="submit"
                                    disabled={auth.loading} className={style.registerButton}>
                                    {EM.RSTR.TEXT.RESET}
                                </Button>
                            </Form.Item>
                            <div className={style.registerCenter}>
                                {EM.RSTR.TEXT.ACCOUNTISNULL}&nbsp;
                                <Link className={style.signinCreate} to={EM.RSTR.URL.SIGNUP}>
                                    {EM.RSTR.TEXT.CREATE}
                                </Link>
                            </div>
                        </Form>
                    }
                    {isEmpty(auth.error) && auth.message === EM.RSTR.AUTH.DONE &&
                        <>
                            <div className={style.registerMessage}>
                                {m.passwordReset}
                            </div>
                            <div className={style.registerMessage} onClick={goToSignIn}>
                                <Button size="large" type="primary" htmlType="submit"
                                    disabled={auth.loading} className={style.registerButton}>
                                    {EM.RSTR.TEXT.SIGNIN}
                                </Button>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default Reset
