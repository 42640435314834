import React, { CSSProperties } from 'react'
import base from '../../assets/base.module.scss'
import style from '../../assets/background.module.scss'
import BackgroundSVG from './BackgroundSVG'
import { IF } from '../imports'

/**
 * Background component that renders the image, color or svg content.
 * @param background background data to populate the component.
 * @returns background JSX component.
 */
const Background: React.FC<{ background: IF.BKGD.BackgroundIF }> = ({ background }): JSX.Element => {
  /**
   * Local dynamic variables
   * - height: height of the background component.
   * - imageStyle: CSS style for displaying background as an image.
   * - colorStyle: CSS style for displaying background as a color-only component.
   */
  const height = `${background.height}vh`
  const imageStyle: CSSProperties = { height: height, background: background.image }
  const colorStyle: CSSProperties = { height: height, background: background.color }
  return (
    <div className={base.baseColContainer} id={`background-${background.title}`}>
      {background.option === 1 && <div className={style.background} style={imageStyle} />}
      {background.option === 2 && <div className={style.background} style={colorStyle} />}
      {background.option === 3 && <BackgroundSVG svg={background.svg} height={height} />}
    </div>
  )
}

export default Background
