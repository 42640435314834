import React from 'react'
import style from '../../assets/editor.module.scss'
import { EM, IF } from '../imports'
import { isEqual } from 'lodash'

export const myAntDrawer = () => {
  const antDrawerMask = Array.from(document.getElementsByClassName('ant-drawer-mask') as HTMLCollectionOf<HTMLElement>)
  if (antDrawerMask.length > 0) {
    antDrawerMask[0].style.background = 'none'
  }
  const antDrawers = Array.from(
    document.getElementsByClassName('ant-drawer-content-wrapper') as HTMLCollectionOf<HTMLElement>
  )
  if (antDrawers.length === 1) {
    antDrawers[0].style.height = '90vh'
    antDrawers[0].style.top = '5vh'
    antDrawers[0].style.right = '50px'
  } else {
    antDrawers[1].style.height = '90vh'
    antDrawers[1].style.top = '5vh'
    antDrawers[1].style.right = '50px'
  }
}

export const isNewNavigation = (
  naviBar: IF.NAVG.NaviBarIF,
  menuBar: IF.NAVG.MenuBarIF,
  NAVGSnapshot: IF.NAVG.NaviBarIF,
  MENUSnapshot: IF.NAVG.MenuBarIF,
  selection: IF.COMM.ValueNameIF
) => {
  if (selection.name === EM.NAVG.SETTING.NAVITOP) {
    return (
      naviBar.widthOne !== NAVGSnapshot.widthOne ||
      naviBar.heightOne !== NAVGSnapshot.heightOne ||
      naviBar.sizeOne !== NAVGSnapshot.sizeOne ||
      naviBar.textOne !== NAVGSnapshot.textOne ||
      naviBar.colorOne !== NAVGSnapshot.colorOne ||
      naviBar.imageOne !== NAVGSnapshot.imageOne ||
      naviBar.fontOne !== NAVGSnapshot.fontOne ||
      naviBar.weightOne !== NAVGSnapshot.weightOne ||
      naviBar.optionOne !== NAVGSnapshot.optionOne ||
      naviBar.positionOne !== NAVGSnapshot.positionOne
    )
  } else if (selection.name === EM.NAVG.SETTING.NAVIBOT) {
    return (
      naviBar.widthTwo !== NAVGSnapshot.widthTwo ||
      naviBar.heightTwo !== NAVGSnapshot.heightTwo ||
      naviBar.sizeTwo !== NAVGSnapshot.sizeTwo ||
      naviBar.textTwo !== NAVGSnapshot.textTwo ||
      naviBar.colorTwo !== NAVGSnapshot.colorTwo ||
      naviBar.imageTwo !== NAVGSnapshot.imageTwo ||
      naviBar.fontTwo !== NAVGSnapshot.fontTwo ||
      naviBar.weightTwo !== NAVGSnapshot.weightTwo ||
      naviBar.optionTwo !== NAVGSnapshot.optionTwo ||
      naviBar.positionTwo !== NAVGSnapshot.positionTwo
    )
  } else if (selection.name === EM.NAVG.SETTING.MENU) {
    return (
      menuBar.width !== MENUSnapshot.width ||
      menuBar.image !== MENUSnapshot.image ||
      menuBar.color !== MENUSnapshot.color ||
      menuBar.title !== MENUSnapshot.title ||
      menuBar.titleSize !== MENUSnapshot.titleSize ||
      menuBar.titleColor !== MENUSnapshot.titleColor ||
      menuBar.titleFont !== MENUSnapshot.titleFont ||
      menuBar.itemSize !== MENUSnapshot.itemSize ||
      menuBar.itemColor !== MENUSnapshot.itemColor ||
      menuBar.itemFont !== MENUSnapshot.itemFont ||
      menuBar.option !== MENUSnapshot.option ||
      menuBar.position !== MENUSnapshot.position
    )
  } else if (selection.name === EM.NAVG.SETTING.ICON) {
    return (
      menuBar.icon !== MENUSnapshot.icon ||
      menuBar.iconImage !== MENUSnapshot.iconImage ||
      menuBar.iconSize !== MENUSnapshot.iconSize ||
      menuBar.iconColor !== MENUSnapshot.iconColor ||
      menuBar.iconOption !== MENUSnapshot.iconOption
    )
  } else if (selection.name === EM.NAVG.SETTING.SYNC) {
    return naviBar.sync !== NAVGSnapshot.sync
  }
  return true
}

export const isNewModule = (module: IF.STAT.ModuleIF, snapShot: IF.STAT.ModuleIF, selection: IF.COMM.ValueNameIF) => {
  if (module.type === EM.MODL.TYPE.INTRODUCTION) {
    const intro = module.value.introduction!
    const introSnapShot = snapShot.value.introduction!
    if (selection.name === EM.INTR.SETTING.FRAME) {
      return !isEqual(module.frame, snapShot.frame)
    } else if (selection.name === EM.INTR.SETTING.AVATAR) {
      return !isEqual(intro.avatar, introSnapShot.avatar)
    } else if (
      selection.name === EM.INTR.SETTING.TEXT ||
      selection.name === EM.INTR.SETTING.COLOR ||
      selection.name === EM.INTR.SETTING.FONT ||
      selection.name === EM.INTR.SETTING.SIZE ||
      selection.name === EM.INTR.SETTING.PRESET ||
      selection.name === EM.INTR.SETTING.CUSTOM
    ) {
      return !isEqual(intro, introSnapShot)
    } else if (selection.name === EM.INTR.SETTING.ORDER) {
      return !isEqual(intro.order, introSnapShot.order)
    }
    // else if (selection.name === EM.INTR.SETTING.CUSTOM) {
    //   if (intro.content.length !== introSnapShot.content.length) {
    //     const thisExtra = {
    //       ...intro.extra[intro.extra.length - 1],
    //       key: '',
    //       order: 0,
    //     }
    //     return !isEqual(thisExtra, RS.INTR.extraDefault)
    //   }
    //   return !isEqual(intro.content, introSnapShot.content)
    // }
    else if (selection.name === EM.INTR.SETTING.MODULE) {
      const thisModule = {
        order: module.order,
        title: module.title,
        show: module.show,
        status: module.status,
      }
      const thisSnapShot = {
        order: snapShot.order,
        title: snapShot.title,
        show: snapShot.show,
        status: snapShot.status,
      }
      return !isEqual(thisModule, thisSnapShot)
    }
    return true
  }
  return true
}

/**
 * Handles the CSS style for the cross hair pointers of the SVG palette component.
 * @param gradientRef React reference object for the gradient HTML Div element.
 */
export const handleGradientPointer = (gradientRef: React.RefObject<HTMLDivElement>): void => {
  if (gradientRef.current) {
    const pointers = Array.from(document.getElementsByClassName('cs') as HTMLCollectionOf<HTMLElement>)
    pointers.forEach((item: HTMLElement) => {
      item.classList.add(style.pickerGradientBG)
      item.style.backgroundPosition = item.className.includes('active') ? 'left center' : ''
    })
  }
}

/* Background Methods */
/**
 * Obtains the color picker width with respect to window inner width.
 */
export const handleColorPickerWidth = () => {
  const colorPickers = Array.from(document.getElementsByClassName('chrome-picker') as HTMLCollectionOf<HTMLElement>)
  colorPickers.forEach((item: HTMLElement) => {
    if (window.innerWidth < 576) {
      item.style.width = window.innerWidth - 72 + 'px'
    } else {
      item.style.width = '250px'
    }
  })
}

export const handleCancelDrawer = (
  control: IF.CTRL.ControlIF,
  module: IF.STAT.ModuleIF,
  background: IF.BKGD.BackgroundIF,
  NAVGSnapshot: IF.NAVG.NaviBarIF,
  MENUSnapshot: IF.NAVG.MenuBarIF,
  controlSnap: IF.CTRL.ControlIF,
  BKGDSnapshot: IF.BKGD.BackgroundIF[],
  MODLSnapshot: IF.STAT.ModuleIF[],
  currentBGTop: number,
  setOpenIcon: React.Dispatch<React.SetStateAction<boolean>>,
  setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>,
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>,
  setNaviBar: React.Dispatch<React.SetStateAction<IF.NAVG.NaviBarIF>>,
  setMenuBar: React.Dispatch<React.SetStateAction<IF.NAVG.MenuBarIF>>,
  setControl: React.Dispatch<React.SetStateAction<IF.CTRL.ControlIF>>,
  setModules: React.Dispatch<React.SetStateAction<IF.STAT.ModuleIF[]>>,
  setBackgrounds: React.Dispatch<React.SetStateAction<IF.BKGD.BackgroundIF[]>>
) => {
  const body = document.getElementsByTagName('body')
  body[0].style.overflowY = ''
  switch (control.type) {
    case EM.CTRL.TYPE.BACKGROUND:
      setBackgrounds(BKGDSnapshot)
      setControl({ ...control, showDrawer: false, page: 1 })
      const bgSnapShot = BKGDSnapshot.find((snapshot: IF.BKGD.BackgroundIF) => snapshot.id === background.id)!
      if (background.order !== bgSnapShot.order) {
        setTimeout(() => {
          const html = document.documentElement
          html.scrollTop = currentBGTop
        }, 100)
      }
      break
    case EM.CTRL.TYPE.MODULE:
      setModules(MODLSnapshot)
      setControl({ ...control, showDrawer: false, page: 1 })
      const snapShot = MODLSnapshot.find((snapshot: IF.STAT.ModuleIF) => snapshot.id === module.id)!
      if (module.frame.top !== snapShot.frame.top) {
        const height = (window.innerHeight * snapShot.frame.height) / 400
        setTimeout(() => {
          const html = document.documentElement
          html.scrollTop = snapShot.frame.top - height
        }, 100)
      }
      break
    case EM.CTRL.TYPE.CONTROL:
      setControl({
        ...control,
        option: controlSnap.option,
        image: controlSnap.image,
        color: controlSnap.color,
        icon: controlSnap.icon,
        size: controlSnap.size,
        history: controlSnap.history,
        showDrawer: false,
        page: 1,
      })
      break
    case EM.CTRL.TYPE.NAVIGATION:
      setNaviBar(NAVGSnapshot)
      setMenuBar(MENUSnapshot)
      setControl({ ...control, showDrawer: false, page: 1 })
      setOpenMenu(false)
      setOpenIcon(false)
      setTimeout(() => setShowMenu(false), 500)
      break
    default:
      setControl({ ...control, showDrawer: false, page: 1 })
  }
}

export const dropBackground = (
  ev: any,
  style: any,
  dropIndex: number,
  dragIndex: number,
  backgrounds: IF.BKGD.BackgroundIF[],
  setBackgrounds: React.Dispatch<React.SetStateAction<IF.BKGD.BackgroundIF[]>>
) => {
  ev.preventDefault()
  if (dragIndex !== dropIndex) {
    const dropOrder = backgrounds[dropIndex].order
    const dragOrder = backgrounds[dragIndex].order
    const dropBackground = { ...backgrounds[dropIndex], order: dragOrder }
    const dragBackground = { ...backgrounds[dragIndex], order: dropOrder }

    const nowBackgrounds = [...backgrounds.slice(0, dropIndex), dragBackground, ...backgrounds.slice(dropIndex + 1)]
    const newBackgrounds = [
      ...nowBackgrounds.slice(0, dragIndex),
      dropBackground,
      ...nowBackgrounds.slice(dragIndex + 1),
    ]
    setBackgrounds(newBackgrounds)
    // const titleCls = document.getElementsByClassName(style.controlListTitle)
    // if (titleCls.length > 0) {
    //     const titles = Array.from(titleCls as HTMLCollectionOf<HTMLElement>)
    //     titles.forEach(item => {
    //         item.style.backgroundColor = EM.COMM.COLOR.WHITE
    //         item.style.color = EM.COMM.COLOR.BLACK
    //     })
    // }
  }
}

// export const handleTitle = (control: IF.CTRL.ControlIF, hover: boolean, e: any) => {
//     if (hover) {
//         const color = control.color.replace(')', ',0)')
//         e.target.style.backgroundColor = color
//         e.target.style.color = EM.COMM.COLOR.WHITE
//         e.target.style.borderColor = control.color
//     } else {
//         e.target.style.backgroundColor = EM.COMM.COLOR.NONE
//         e.target.style.color = EM.COMM.COLOR.BLACK
//         e.target.style.borderColor = EM.COMM.COLOR.NONE
//     }
// }

// export const handleBGHover = (
//     index: number,
//     hover: boolean,
//     backgrounds: IF.BKGD.BackgroundIF[],
//     setBackgrounds: React.Dispatch<React.SetStateAction<IF.BKGD.BackgroundIF[]>>
// ) => {
//     setBackgrounds([
//         ...backgrounds.slice(0, index),
//         { ...backgrounds[index], hover: hover },
//         ...backgrounds.slice(index + 1),
//     ])
// }

// export const getTitle = (order: number, title: string, type: EM.CTRL.TYPE) => {
//     const getTitle = title.slice(0, 10)
//     if (title) return title.length > 13 ? `${getTitle}...` : title
//     return `${type} - ${order}`
// }

/* Module Methods */
export const dropModule = (
  ev: any,
  style: any,
  dropIndex: number,
  dragIndex: number,
  modules: IF.STAT.ModuleIF[],
  setModules: React.Dispatch<React.SetStateAction<IF.STAT.ModuleIF[]>>
) => {
  ev.preventDefault()
  if (dragIndex !== dropIndex) {
    const dropTop = modules[dropIndex].frame!.top
    const dropLeft = modules[dropIndex].frame!.left
    const dragTop = modules[dragIndex].frame!.top
    const dragLeft = modules[dragIndex].frame!.left

    const dropModule = {
      ...modules[dropIndex],
      order: modules[dragIndex].order,
      frame: { ...modules[dropIndex].frame, top: dragTop, left: dragLeft },
    }
    const dragModule = {
      ...modules[dragIndex],
      order: modules[dropIndex].order,
      frame: { ...modules[dragIndex].frame, top: dropTop, left: dropLeft },
    }
    const nowModules = [...modules.slice(0, dropIndex), dragModule, ...modules.slice(dropIndex + 1)]
    const newModules = [...nowModules.slice(0, dragIndex), dropModule, ...nowModules.slice(dragIndex + 1)]

    setModules(newModules)
    // const titleCls = document.getElementsByClassName(style.controlListTitle)
    // if (titleCls.length > 0) {
    //     const titles = Array.from(titleCls as HTMLCollectionOf<HTMLElement>)
    //     titles.forEach(item => {
    //         item.style.backgroundColor = EM.COMM.COLOR.WHITE
    //         item.style.color = EM.COMM.COLOR.BLACK
    //     })
    // }
  }
}

export const handleHistoryMODL = (
  type: string,
  value: string,
  control: IF.CTRL.ControlIF,
  module: IF.STAT.ModuleIF,
  modules: IF.STAT.ModuleIF[],
  setModules: React.Dispatch<React.SetStateAction<IF.STAT.ModuleIF[]>>,
  setFileObject: React.Dispatch<React.SetStateAction<string | File>>
) => {
  const newModule = { ...module }
  if (type === EM.COMM.MODL.INTRAVTR) {
    let newIntro = { ...module.value.introduction! }
    const newAvatar = { ...newIntro.avatar, image: value }
    newIntro = { ...newIntro, avatar: newAvatar }
    newModule.value = { introduction: newIntro }
  }
  setModules([...modules.slice(0, control.index), newModule, ...modules.slice(control.index + 1)])
  setFileObject('')
}

// use this when we need backend fetching images
// export const pickerFetchImage = () => {
//     if (pickerImages.length < 10) {
//         RS.pickerImages.forEach((element) => {
//             RX.userDownloadImage(element.url).then(
//                 image_url => {
//                     setPickerImages(pickerImages => [
//                         ...pickerImages,
//                         {url: image_url || '', name: element.name, order: element.order}
//                     ])
//                 }
//             )
//         })
//     }
// }
