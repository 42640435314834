import style from '../../../assets/frame.module.scss'
import { IF } from '../../imports'
import * as m from '../methods'

const Biography = (props: { bioRef: any; intro: IF.INTR.IntroductionIF }) => {
  const { bioRef, intro } = props

  return (
    <div className={style.introBiography} ref={bioRef}>
      {intro.order.map((item: IF.INTR.IntroOrderIF, index: number) => {
        if (item.key) {
          const key = item.key as string
          const thisObject = intro.content?.find(
            (item: IF.INTR.IntroContentIF) => item.label === key
          ) as IF.INTR.IntroContentIF
          if (thisObject) return m.mainComponent(index, thisObject, intro)
        }
      })}
    </div>
  )
}

export default Biography
