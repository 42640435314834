import axios from 'axios'
import { EM } from '../components/imports'

const backendServer = () => {
  const host = process.env.REACT_APP_HOSTNAME
  if (window.location.host.includes('https://')) {
    return host?.replace('https://', 'http://')
  }
  return host
}

export const websocketHost = () => {
  const server = backendServer()
  if (server!.includes('http://')) {
    return server!.replace('http://', 'ws://')
  } else {
    return server!.replace('https://', 'wss://')
  }
}

export const request = async (url: string, token: any, data: any, method: any) => {
  const cookie = localStorage.getItem('sessionCookie') || EM.STAT.status.COOKIE
  const headers = {
    Authorization: '',
    'Content-Type': 'application/json;charset=UTF-8',
    'X-CSRFToken': cookie,
  }
  if (token !== null) headers['Authorization'] = `Bearer ${token}`

  if (data && data instanceof FormData) headers['Content-Type'] = 'multipart/form-data'

  const api_url = `${backendServer()}${url}`

  return axios(api_url, { method, headers, data })
}

export const requestBinary = async (url: string, token: any, body: any, method: any) => {
  const cookie = localStorage.getItem('sessionCookie') || EM.STAT.status.COOKIE
  const headers = {
    Authorization: '',
    'Content-Type': 'application/json;charset=UTF-8',
    'X-CSRFToken': cookie,
  }
  const api_url = `${backendServer()}${url}`

  if (token !== null) headers['Authorization'] = `Bearer ${token}`
  if (body && body instanceof FormData) headers['Content-Type'] = 'multipart/form-data'
  if (!(body instanceof FormData)) body = JSON.stringify(body)

  return axios(api_url, { responseType: 'arraybuffer', method: method, headers: headers, data: body })
}
