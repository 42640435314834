import style from '../../assets/control.module.scss'
import { Button } from 'antd'
import { EM, RX } from '../imports'

const ControlSave = (props: any) => {
  /** Properties */
  const { auth, dispatch, control, controlSnap, controlBtnRef, controlIconRef, setControl } = props

  /** Local Variables */
  let top = control.top + control.size + 5
  if (top + control.size / 2 > window.innerHeight) top = control.top - 25
  const buttonPosition = {
    top: `${top}px`,
    left: `${control.left + 3}px`,
    borderColor: control.color,
    backgroundColor: control.color,
    width: `${control.size - 6}px`,
  }

  const handleSave = () => {
    RX.CTRL.updateCTRL(auth, dispatch, control, controlSnap, controlIconRef, '', setControl)
  }

  return (
    <Button
      ref={controlBtnRef}
      id={style.controlBtnSave}
      style={buttonPosition}
      size="small"
      type="primary"
      onClick={() => handleSave()}
    >
      {EM.CTRL.BUTTON.SAVE}
    </Button>
  )
}

export default ControlSave
