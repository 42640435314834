/**
 * Generate a random text string consists of letters and numbers with a given length.
 * @param length Length of the text
 * @returns a string of text of the given length
 */
const randomText = (length: number) => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}

export default randomText
