import React from 'react'
import style from '../../assets/control.module.scss'
import { Button } from 'antd'
import { IF, EM, RS } from '../imports'
import * as m from './methods'


const ControlPanel = (props: {
    control: IF.CTRL.ControlIF,
    controlPanelRef: any,
    setControl: React.Dispatch<React.SetStateAction<IF.CTRL.ControlIF>>,
    setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>,
    setShowMenu: React.Dispatch<React.SetStateAction<boolean>>,
}) => {
    /** Properties */
    const { control, controlPanelRef, setControl, setOpenMenu, setShowMenu } = props

    /** React Methods */
    const [panelPosition, setPanelPosition] = React.useState<IF.CTRL.PositionIF>(RS.CTRL.panelPosition)

    React.useEffect(() => {
        if (controlPanelRef.current !== null) {
            m.positionPanel(control, controlPanelRef, setPanelPosition)
        }
    }, [controlPanelRef.current])

    /** Local Variables */
    const controlColor = {
        width: '100%',
        backgroundColor: control.color,
        borderColor: EM.COMM.COLOR.WHITE,
        color: EM.COMM.COLOR.WHITE,
    }

    /** Local Methods */
    const showDrawer = (index: number, type: string) => {
        if (type === EM.CTRL.TYPE.NAVIGATION) {
            setOpenMenu(true)
            setShowMenu(false)
        }
        setControl({ ...control, index: index, type: type, showDrawer: true, showPanel: false })
    }

    return (
        <div id={style.controlPanel} style={panelPosition} ref={controlPanelRef}>
            <div id={style.controlPanelDiv}>
                <div className={style.controlCenter}>
                    <Button style={controlColor} onClick={() => showDrawer(0, EM.CTRL.TYPE.NAVIGATION)}>
                        {EM.CTRL.BUTTON.NAVIBAR}
                    </Button>
                </div>
                <div className={style.controlCenter}>
                    <Button style={controlColor} onClick={() => showDrawer(0, EM.CTRL.TYPE.CONTROL)}>
                        {EM.CTRL.BUTTON.CONTROL}
                    </Button>
                </div>
                <div className={style.controlCenter}>
                    <Button style={controlColor} onClick={() => showDrawer(0, EM.CTRL.TYPE.BACKGROUND)}>
                        {EM.CTRL.BUTTON.BACKGROUND}
                    </Button>
                </div>
                <div className={style.controlCenter}>
                    <Button style={controlColor} onClick={() => showDrawer(0, EM.CTRL.TYPE.MODULE)}>
                        {EM.CTRL.BUTTON.MODULE}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default ControlPanel
