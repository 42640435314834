import React from 'react'
import { IF, VS, EM } from '../components/imports'


export const backgroundsMapper = (
    backgrounds: IF.BKGD.BackgroundIF[]
) => {
    return backgrounds.map((background) => (
        <VS.Background key={background.order} background={background} />
    ))
}


export const modulesMapper = (
    auth: IF.AUTH.AuthIF,
    isAuthorized: boolean | '' | null,
    control: IF.CTRL.ControlIF,
    modules: IF.STAT.ModuleIF[],
    MODLSnapshot: IF.STAT.ModuleIF[],
    dispatch: any,
    setControl: React.Dispatch<React.SetStateAction<IF.CTRL.ControlIF>>,
    setModules: React.Dispatch<React.SetStateAction<IF.STAT.ModuleIF[]>>,
    setShowEdit: React.Dispatch<React.SetStateAction<IF.COMM.DisplayIF>>,
) => {
    return modules.map((module, index) => {
        if (module.type === EM.MODL.TYPE.INTRODUCTION) {
            switch (module.value.introduction!.type) {
                case 1:
                    return <VS.IntroTypeOne
                        key={module.order}
                        auth={auth}
                        dispatch={dispatch}
                        index={index}
                        modules={modules}
                        control={control}
                        intro={module.value.introduction}
                        frame={module.frame}
                        FRAMESnapshot={MODLSnapshot[index].frame}
                        isAuthorized={isAuthorized}
                        setControl={setControl}
                        setModules={setModules}
                        setShowEdit={setShowEdit} />
                default:
                    return <div key={module.order}></div>
            }
        }
    })
}

export const findKey = (item: any) => {
    const keys = ['intro', 'frame']
    let key = ''
    for (let index = 0; index < keys.length; index++) {
        if (keys[index] in item) key = keys[index]
    }
    return key
}
