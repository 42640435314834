import { IF } from '../components/imports'

export const auth: IF.AUTH.AuthIF = {
    token: null,
    refresh: null,
    session: null,
    message: null,
    loading: true,
    error: null,
    account: null,
    users: []
}
