import React from 'react'
import style from '../../assets/editor.module.scss'
import { Button, Row } from 'antd'
import { EditorDropDown, EditorHuePicker, EditorLabel, EditorParallel, EditorSlider } from '../Common/EditorParts'
import { handleFrameBorder } from './methods'
import { EM, IF, RS } from '../imports'
import EditorBorderPicker from '../Common/EditorBorderPicker'

export const FrameImage = (props: any) => {
  const { frame, control, module, modules, saveStyle, undoStyle, loading, setModules, handleAttribute } = props

  const [selection, setSelection] = React.useState<IF.COMM.ValueNameIF>(RS.COMM.selectOption)

  const onChangeBorderImage = (item: IF.COMM.ValueNameIF) => {
    setSelection(item)
  }

  const handlePreview = (event: any, selection: string) => {
    handleFrameBorder(event, selection, control, module, modules, setModules)
  }

  const handlePreviewImage = (image: string) => {
    const newFrame = { ...module.frame }
    newFrame.imageSource = image
    setModules([
      ...modules.slice(0, control.index),
      { ...module, frame: newFrame },
      ...modules.slice(control.index + 1),
    ])
  }

  const handlePreviewURL = (url: string) => {
    const newFrame = { ...module.frame }
    newFrame.image = `url("${url}")`
    setModules([
      ...modules.slice(0, control.index),
      { ...module, frame: newFrame },
      ...modules.slice(control.index + 1),
    ])
  }

  const flexCol = { ...RS.COMM.showFlex, flexDirection: 'column' }
  const display = frame.style === 2 ? flexCol : RS.COMM.hideBlock
  const roundBtn = frame.imageStyle === EM.FRME.FORM.ROUND ? saveStyle : undoStyle
  const repeatBtn = frame.imageStyle === EM.FRME.FORM.REPEAT ? saveStyle : undoStyle
  const stretchBtn = frame.imageStyle === EM.FRME.FORM.STRETCH ? saveStyle : undoStyle

  /** Local JSX.Element */
  const elements = [
    <Button style={roundBtn} key={'1'} onClick={() => handleAttribute(EM.FRME.STYLE.ROUND, EM.FRME.FORM.IMAGESTYLE)}>
      {EM.FRME.STYLE.ROUND}
    </Button>,
    <Button style={repeatBtn} key={'2'} onClick={() => handleAttribute(EM.FRME.STYLE.REPEAT, EM.FRME.FORM.IMAGESTYLE)}>
      {EM.FRME.STYLE.REPEAT}
    </Button>,
    <Button
      style={stretchBtn}
      key={'3'}
      onClick={() => handleAttribute(EM.FRME.STYLE.STRETCH, EM.FRME.FORM.IMAGESTYLE)}
    >
      {EM.FRME.STYLE.STRETCH}
    </Button>,
  ]

  return (
    <div style={display}>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.FRME.EDITOR.IMAGEOPTION} />
        <EditorDropDown
          span={18}
          loading={loading}
          sourceList={RS.FRME.selectBorders}
          selection={selection}
          saveStyle={saveStyle}
          onChangeItem={onChangeBorderImage}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorBorderPicker
          images={RS.FRME.borderList}
          selection={selection}
          handlePreviewImage={handlePreviewImage}
          handlePreviewURL={handlePreviewURL}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.FRME.EDITOR.IMAGESTYLE} />
        <EditorParallel span={18} elements={elements} />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.FRME.EDITOR.IMAGEWIDTH} />
        <EditorSlider
          min={0}
          max={100}
          span={18}
          value={frame.lineWidth}
          width={RS.COMM.width90}
          attribute={EM.FRME.FORM.IMAGEWIDTH}
          handleAttribute={handleAttribute}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.FRME.EDITOR.IMAGESLICE} />
        <EditorSlider
          min={0}
          max={100}
          span={18}
          value={frame.imageSlice}
          width={RS.COMM.width90}
          attribute={EM.FRME.FORM.IMAGESLICE}
          handleAttribute={handleAttribute}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.FRME.EDITOR.IMAGESPACE} />
        <EditorSlider
          min={0}
          max={100}
          span={18}
          value={frame.imageSpace}
          width={RS.COMM.width90}
          attribute={EM.FRME.FORM.IMAGESPACE}
          handleAttribute={handleAttribute}
        />
      </Row>
    </div>
  )
}

export const FrameLine = (props: {
  frame: IF.FRME.FrameIF
  saveStyle: React.CSSProperties
  undoStyle: React.CSSProperties
  loading: boolean
  handleAttribute: (value: string | number, action: string) => void
}) => {
  const { frame, saveStyle, undoStyle, loading, handleAttribute } = props

  const [selection, setSelection] = React.useState<IF.COMM.ValueNameIF>(
    RS.FRME.lineOptions.find((item: IF.COMM.ValueNameIF) => item.value === frame.lineStyle)!
  )

  const onChangeStyle = (item: IF.COMM.ValueNameIF) => {
    setSelection(item)
    handleAttribute(item.value, EM.FRME.FORM.LINESTYLE)
  }

  const flexColumn = { ...RS.COMM.showFlex, flexDirection: 'column' }
  const display: React.CSSProperties = frame.style === 1 ? flexColumn : RS.COMM.hideBlock

  return (
    <div style={display}>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.FRME.EDITOR.LINE} />
        <EditorDropDown
          span={18}
          loading={loading}
          sourceList={RS.FRME.lineOptions}
          selection={selection}
          saveStyle={saveStyle}
          onChangeItem={onChangeStyle}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.FRME.EDITOR.WIDTH} />
        <EditorSlider
          min={0}
          max={100}
          span={18}
          value={frame.lineWidth}
          width={RS.COMM.width90}
          attribute={EM.FRME.FORM.LINEWIDTH}
          handleAttribute={handleAttribute}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.FRME.EDITOR.COLOR} />
        <EditorHuePicker
          span={18}
          width={'70%'}
          color={frame.lineColor}
          attribute={EM.FRME.FORM.LINECOLOR}
          undoStyle={undoStyle}
          handleAttribute={handleAttribute}
        />
      </Row>
    </div>
  )
}
