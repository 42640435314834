import style from '../../assets/register.module.scss'
import { CloseCircleTwoTone, CheckCircleTwoTone } from '@ant-design/icons'
import { EM } from '../imports'

const Password = (props: any) => {
    /** Properties */
    const { strength, passwordCon, passwordDiv } = props

    /** Local Variables */
    const check = <CheckCircleTwoTone twoToneColor={EM.RSTR.PASSWORD.STRONG} />
    const close = <CloseCircleTwoTone twoToneColor={EM.RSTR.PASSWORD.WEAK} />

    const blankStyle = { backgroundColor: EM.RSTR.PASSWORD.BLANK }
    const weakStyle = strength.strength > 0 ? { backgroundColor: EM.RSTR.PASSWORD.WEAK } : blankStyle
    const mediumStyle = strength.strength > 1 ? { backgroundColor: EM.RSTR.PASSWORD.MEDIUM } : blankStyle
    const strongStyle = strength.strength > 2 ? { backgroundColor: EM.RSTR.PASSWORD.STRONG } : blankStyle

    return <div ref={passwordCon} className={style.passwordContainer}>
        <div ref={passwordDiv}>
            <div className={style.passwordDiv}>
                <span>{EM.RSTR.TITLE.PASSWORD} </span>
                <div className={style.passwordStrengthDiv}>
                    <div className={style.passwordStrength} style={weakStyle}>
                        {EM.RSTR.TEXT.WEAK}
                    </div>
                    <div className={style.passwordStrength} style={mediumStyle}>
                        {EM.RSTR.TEXT.MEDIUM}
                    </div>
                    <div className={style.passwordStrength} style={strongStyle}>
                        {EM.RSTR.TEXT.STRONG}
                    </div>
                </div>
            </div>
            <div className={style.passwordLi}>
                <li>{strength.length > 5 ? check : close} {EM.RSTR.PASSWORD.LENGTH}</li>
                <li>{strength.upper ? check : close} {EM.RSTR.PASSWORD.UPPERC}</li>
                <li>{strength.letter ? check : close} {EM.RSTR.PASSWORD.LETTER}</li>
                <li>{strength.number ? check : close} {EM.RSTR.PASSWORD.NUMBER}</li>
                <li>{strength.symbol ? check : close} {EM.RSTR.PASSWORD.SYMBOL}</li>
            </div>
            <br />
        </div>
    </div>
}

export default Password