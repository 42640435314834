export enum URL {
    NAVITOP = '/api/talent/navigation/navitop/',
    NAVIBOT = '/api/talent/navigation/navibot/',
    MENU = '/api/talent/navigation/menu/',
    ICON = '/api/talent/navigation/icon/',
    SYNC = '/api/talent/navigation/sync/',
}

export enum SETTING {
    DEFAULT = 'Default Setting',
    NAVITOP = 'Top Navigation Setting',
    NAVIBOT = 'Navigation Setting',
    MENU = 'Menu Setting',
    ICON = 'Icon Setting',
    SYNC = 'Sync Setting',
}

export enum LABEL {
    TOP = 'Top Navbar',
    BOT = 'Navbar',
    WIDTH = 'Width',
    HEIGHT = 'Height',
    OPTION = 'Option',
    POSITION = 'Position',
    SIZE = 'Text Size',
    FONT = 'Text Font',
    COLOR = 'Text Color',
    WEIGHT = 'Text Weight',
    MENU = 'Menu Preview',
    TITLETEXT = 'Title Text',
    TITLESIZE = 'Title Size',
    TITLECOLOR = 'Title Color',
    TITLEFONT = 'Title Font',
    ITEMSIZE = 'Item Size',
    ITEMCOLOR = 'Item Color',
    ITEMFONT = 'Item Font',
    ICON = 'Icon Preview',
    ICONCOLOR = 'Icon Color',
    ICONSIZE = 'Icon Size',
    SYNC = 'Synchronization',
    SYNCZERO = 'Desynchronize all / customize navigation and menu freely',
    SYNCONE = 'Use the first navigation bar for the second navigation bar',
    SYNCTWO = 'Use the first navigation bar for the menu bar',
    SYNCTHREE = 'Use the second navigation bar for the menu bar',
    SYNCFOUR = 'Synchronize all / use the first navigation for other components',
}

export enum FORM {
    WIDTH = 'width',
    WIDTHTONE = 'widthOne',
    WIDTHTWO = 'widthTwo',
    HEIGHTONE = 'heightOne',
    HEIGHTTWO = 'heightTwo',
    SIZEONE = 'sizeOne',
    SIZETWO = 'sizeTwo',
    TEXTONE = 'textOne',
    TEXTTWO = 'textTwo',
    IMAGEONE = 'imageOne',
    IMAGETWO = 'imageTwo',
    FONTONE = 'fontOne',
    FONTTWO = 'fontTwo',
    WEIGHTONE = 'weightOne',
    WEIGHTTWO = 'weightTwo',
    OPTIONONE = 'optionOne',
    OPTIONTWO = 'optionTwo',
    POSITION = 'position',
    POSITIONONE = 'positionOne',
    POSITIONTWO = 'positionTwo',
    HISTORYONE = 'historyOne',
    HISTORYTWO = 'historyTwo',
    TITLE = 'title',
    TITLESIZE = 'titleSize',
    TITLECOLOR = 'titleColor',
    TITLEFONT = 'titleFont',
    ITEMSIZE = 'itemSize',
    ITEMCOLOR = 'itemColor',
    ITEMFONT = 'itemFont',
    ICONHISTORY = 'iconHistory',
    MENUHISTORY = 'menuHistory'
}