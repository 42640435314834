import React from 'react'
import style from '../../../assets/navigation.module.scss'
import { weightObject } from '../../Common/methods'
import { IF, RS } from '../../imports'
import * as m from '.././methods'


const NaviBar = (props: any) => {
    const { naviBar, modules } = props

    const [naviStyle, setNaviStyle] = React.useState<IF.NAVG.NaviStyleIF | IF.NAVG.NaviHideIF>({
        width: `${naviBar.width}%`,
        height: `${naviBar.heightOne}px`,
        color: naviBar.textOne,
        fontSize: `${naviBar.sizeOne}px`,
        fontFamily: naviBar.fontOne,
        fontWeight: weightObject(naviBar.weightOne),
        background: naviBar.optionOne === 1 ?
            `url(${naviBar.imageOne}) center ${naviBar.positionOne}% / cover` : naviBar.colorOne,
    })

    React.useEffect(() => {
        m.showNaviBar(naviBar, setNaviStyle)
    }, [naviBar])

    return (
        <div className={style.naviBar} style={naviStyle}>
            {modules.map((module: IF.STAT.ModuleIF) => (
                <span className={style.navItems}
                    key={module.order} onClick={() => m.scrollToModule(module)}
                    style={(module.show && module.status === 'active') ? RS.COMM.showFlex : RS.COMM.hideBlock}>
                    {module.title}
                </span>
            ))}
        </div>
    )
}

export default NaviBar
