import React from 'react'
import style from '../../assets/editor.module.scss'
import { isEqual } from 'lodash'
import { CloseOutlined } from '@ant-design/icons'
import { Row } from 'antd'
import {
  EditorDropDown,
  EditorFontPicker,
  EditorHistoryLabel,
  EditorHuePicker,
  EditorImage,
  EditorInput,
  EditorLabel,
  EditorSlider,
} from '../Common/EditorParts'
import { EditorHistoryColorPicker } from '../Common/History'
import { handleColorPickerWidth } from '../Editor/methods'
import { EM, IF, RS } from '../imports'
import * as m from './methods'
import EditorImagePicker from '../Common/EditorImagePicker'
import EditorColorPicker from '../Common/EditorColorPicker'

const MenuBarSetting = (props: {
  control: IF.CTRL.ControlIF
  menuBar: IF.NAVG.MenuBarIF
  snapShot: IF.NAVG.MenuBarIF
  modules: IF.STAT.ModuleIF[]
  saveStyle: React.CSSProperties
  undoStyle: React.CSSProperties
  uploading: boolean
  uploadBar: any
  loading: boolean
  setMenuBar: React.Dispatch<React.SetStateAction<IF.NAVG.MenuBarIF>>
  setUploading: React.Dispatch<React.SetStateAction<boolean>>
  setUploadbar: React.Dispatch<React.SetStateAction<any>>
  setFileObject: React.Dispatch<React.SetStateAction<string | File>>
}) => {
  /** Properties */
  const {
    control,
    menuBar,
    snapShot,
    modules,
    saveStyle,
    undoStyle,
    uploading,
    uploadBar,
    loading,
    setMenuBar,
    setUploading,
    setUploadbar,
    setFileObject,
  } = props

  /** React Methods */
  const [selection, setSelection] = React.useState<IF.COMM.ValueNameIF>(RS.COMM.selectOption)
  // const [naviPreview, setMenuPreview] = React.useState<IF.NAVG.NaviStyleIF>(RS.NAVG.navPreview)
  const [fileName, setFileName] = React.useState<string>(EM.COMM.FILENAME.NOTAVAILABLE)
  const imageRef: any = React.useRef(null)
  const showColorPicker = isEqual(selection, RS.BKGD.selectColor)
  const showGradientPicker = isEqual(selection, RS.BKGD.selectGradient)

  const menuStyle: React.CSSProperties = {
    height: '450px',
    width: `${menuBar.width}px`,
    background: menuBar.option === 1 ? `url(${menuBar.image}) ${menuBar.position}% center / cover` : menuBar.color,
  }
  const closeStyle: React.CSSProperties = { color: menuBar.titleColor, fontSize: '16px', marginInlineEnd: '12px' }
  const titleStyle: React.CSSProperties = {
    fontSize: `${menuBar.titleSize}px`,
    color: menuBar.titleColor,
    fontFamily: menuBar.titleFont,
    fontWeight: 600,
  }
  const itemStyle: React.CSSProperties = {
    fontSize: `${menuBar.itemSize}px`,
    color: menuBar.itemColor,
    fontFamily: menuBar.itemFont,
  }
  const mainImage = (
    <div style={menuStyle}>
      <div className={style.editorMenuTitle}>
        <CloseOutlined style={closeStyle} />
        <div style={titleStyle}>{menuBar.title}</div>
      </div>
      <div className={style.editorMenuItems}>
        {modules.slice(0, 2).map((module: IF.STAT.ModuleIF) => (
          <p style={module.show && module.status === 'active' ? itemStyle : RS.COMM.hideBlock} key={module.order}>
            {module.title}
          </p>
        ))}
      </div>
    </div>
  )

  React.useEffect(() => {
    handleColorPickerWidth()
  }, [menuBar])

  /* Local Variables */
  const showPickerRow = selection === RS.COMM.selectOption ? RS.COMM.hideBlock : RS.COMM.showFlex

  const handlePreviewColor = (color: string) => {
    setMenuBar({ ...menuBar, color: color, option: 2 })
    setFileObject('')
  }

  const handleAttribute = (event: any, attribute: string) => {
    m.handleMenuBarAttribute(event, attribute, menuBar, setMenuBar)
  }

  const onChangeOption = (item: IF.COMM.ValueNameIF) => {
    setSelection(item)
  }

  /**
   * Update the menu bar image.
   * @param image string.
   * @returns void.
   */
  const handlePreviewImage = (image: string) => {
    setMenuBar({ ...menuBar, image: image, option: 1 })
    setFileObject('')
  }

  /**
   * Update the menu bar image URL.
   * @param url string.
   * @returns void.
   */
  const handlePreviewURL = (url: string) => {
    setMenuBar({ ...menuBar, image: url, option: 1 })
    setFileObject('')
  }

  /**
   * Update the menu bar image upload file.
   * @param event React change event for the HTML input element.
   * @returns void.
   */
  const handlePreviewUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files![0] === undefined) return
    if (event.target.files![0].size > RS.COMM.size5MB) {
      setMenuBar(snapShot)
      setFileName(EM.COMM.FILENAME.NOTAVAILABLE)
      setFileObject('')
    }
    const imageReader = new FileReader()
    imageReader.onload = (event) => {
      setMenuBar({ ...menuBar, image: event!.target!.result as string, option: 1 })
    }
    imageReader.readAsDataURL(event.target.files![0])
    setFileName(event.target.files![0].name)
    setFileObject(event.target.files![0])
  }

  const handleHistoryPreview = (value: string, option: number) => {
    if (option === 1) setMenuBar({ ...menuBar, image: `url(${value}) center center / cover`, option: 1 })
    if (option === 2) setMenuBar({ ...menuBar, color: value, option: 2 })
  }

  return (
    <div className={style.editorPageTwo}>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.NAVG.LABEL.MENU} />
        <EditorImage mainImage={mainImage} />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.NAVG.LABEL.OPTION} />
        <EditorDropDown
          span={18}
          loading={loading}
          sourceList={RS.COMM.selectionNoGrad}
          selection={selection}
          saveStyle={saveStyle}
          onChangeItem={onChangeOption}
        />
      </Row>
      <Row className={style.editorRow} style={showPickerRow}>
        <EditorImagePicker
          type={EM.COMM.IMAGETYPE.MENUBAR}
          images={RS.BKGD.pickers}
          selection={selection}
          imageRef={imageRef}
          saveStyle={saveStyle}
          fileName={fileName}
          uploading={uploading}
          uploadBar={uploadBar}
          loading={loading}
          setUploading={setUploading}
          setUploadbar={setUploadbar}
          handlePreviewImage={handlePreviewImage}
          handlePreviewURL={handlePreviewURL}
          handlePreviewUpload={handlePreviewUpload}
        />
        <EditorColorPicker
          color={menuBar.color}
          showColorPicker={showColorPicker}
          handlePreviewColor={handlePreviewColor}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.NAVG.LABEL.WIDTH} />
        <EditorSlider
          min={1}
          max={500}
          span={18}
          value={menuBar.width}
          width={RS.COMM.width90}
          attribute={EM.NAVG.FORM.WIDTH}
          handleAttribute={handleAttribute}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.NAVG.LABEL.POSITION} />
        <EditorSlider
          min={0}
          max={100}
          span={18}
          value={menuBar.position}
          width={RS.COMM.width90}
          attribute={EM.NAVG.FORM.POSITION}
          handleAttribute={handleAttribute}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.NAVG.LABEL.TITLETEXT} />
        <EditorInput
          max={RS.COMM.max30}
          span={18}
          value={menuBar.title}
          inputType={EM.COMM.TYPE.TEXT}
          attribute={EM.NAVG.FORM.TITLE}
          handleAttribute={handleAttribute}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.NAVG.LABEL.TITLESIZE} />
        <EditorSlider
          min={1}
          max={80}
          span={18}
          value={menuBar.titleSize}
          width={RS.COMM.width90}
          attribute={EM.NAVG.FORM.TITLESIZE}
          handleAttribute={handleAttribute}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.NAVG.LABEL.TITLECOLOR} />
        <EditorHuePicker
          span={18}
          width={'70%'}
          color={menuBar.titleColor}
          attribute={EM.NAVG.FORM.TITLECOLOR}
          undoStyle={undoStyle}
          handleAttribute={handleAttribute}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.NAVG.LABEL.TITLEFONT} />
        <EditorFontPicker
          span={18}
          text={menuBar.title}
          buttonWidth={'100px'}
          textWidth={'140px'}
          buttonColor={control.color}
          textColor={menuBar.titleColor}
          snapShot={menuBar.titleFont}
          fontSize={menuBar.titleSize}
          fontFamily={menuBar.titleFont}
          fontWeight={2}
          attribute={EM.NAVG.FORM.TITLEFONT}
          handleAttribute={handleAttribute}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.NAVG.LABEL.ITEMSIZE} />
        <EditorSlider
          min={1}
          max={80}
          span={18}
          value={menuBar.itemSize}
          width={RS.COMM.width90}
          attribute={EM.NAVG.FORM.ITEMSIZE}
          handleAttribute={handleAttribute}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.NAVG.LABEL.ITEMCOLOR} />
        <EditorHuePicker
          span={18}
          width={'70%'}
          color={menuBar.itemColor}
          attribute={EM.NAVG.FORM.ITEMCOLOR}
          undoStyle={undoStyle}
          handleAttribute={handleAttribute}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.NAVG.LABEL.ITEMFONT} />
        <EditorFontPicker
          span={18}
          text={modules[0].title}
          buttonWidth={'100px'}
          textWidth={'140px'}
          buttonColor={control.color}
          textColor={menuBar.itemColor}
          snapShot={menuBar.itemFont}
          fontSize={menuBar.itemSize}
          fontFamily={menuBar.itemFont}
          fontWeight={2}
          attribute={EM.NAVG.FORM.ITEMFONT}
          handleAttribute={handleAttribute}
        />
      </Row>
      <Row className={style.editorRowHistory}>
        <EditorHistoryLabel history={menuBar.menuHistory} />
        <EditorHistoryColorPicker history={menuBar.menuHistory} handleHistoryPreview={handleHistoryPreview} />
      </Row>
    </div>
  )
}

export default MenuBarSetting
