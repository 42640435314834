export enum TYPE {
    CONTROL = 'control',
    BACKGROUND = 'background',
    NAVIGATION = 'navigation',
    MODULE = 'module',
    BGLABEL = 'Backgrounds',
    MDLABEL = 'Modules',
}

export enum COLOR {
    WHITE = 'rgb(255, 255, 255)',
    BLACK = 'rgb(0, 0, 0)',
    TRANSPARENT = 'rgba(0, 0, 0, 0)',
    SIZE = '150px',
}

export enum BUTTON {
    NAVIBAR = 'Navigation',
    CONTROL = 'Control Center',
    BACKGROUND = 'Backgound',
    MODULE = 'Module',
    HINTS = 'Click here to show hints',
    SAVE = 'Save',
    UNDO = 'Undo',
}

export enum OPTION {
    SELECT = 'Select Image',
    URL = 'Image Address',
    UPLOAD = 'Upload Image',
    ICON = 'Select Icon',
    COLOR = 'Color Setting'
}

export enum DRAWER {
    IMAGE = 'Image',
    TITLE = 'Title',
    SIZE = 'Size',
    MODE = 'Setting',
    COLOR = 'Color',
    HISTORY = 'History'
}

export enum URL {
    UPDATE = '/api/talent/control/'
}

export enum ATTR {
    SIZE = 'size'
}