export const fonts = [
    { name: 'Arial', value: '"Arial", serif' },
    { name: 'Avenir', value: '"Avenir", serif' },
    { name: 'Bodoni', value: '"Bodoni", serif' },
    { name: 'Brush Script MT', value: '"Brush Script MT", cursive' },
    { name: 'Courier New', value: '"Courier New", serif' },
    { name: 'Franklin Gothic', value: '"Franklin Gothic", serif' },
    { name: 'Futura', value: '"Futura", serif' },
    { name: 'Frutiger', value: '"Frutiger", serif' },
    { name: 'Garamond', value: '"Garamond", serif' },
    { name: 'Georgia', value: '"Georgia", serif' },
    { name: 'Helvetica', value: '"Helvetica", serif' },
    { name: 'Rockwell', value: '"Rockwell", serif' },
    { name: 'Tahoma', value: '"Tahoma Web", sans - serif' },
    { name: 'Times New Roman', value: '"Times New Roman", serif' },
    { name: 'Trebuchet', value: '"Trebuchet", sans - serif' },
    { name: 'Univers', value: '"Univers", serif' },
    { name: 'Verdana', value: '"Verdana", sans - serif' },
    { name: 'divide', value: 'divide' },
    { name: 'Architects Daughter', value: '"Architects Daughter", cursive' },
    { name: 'Alegreya', value: '"Alegreya", serif' },
    { name: 'Antic Slab', value: '"Antic Slab", serif' },
    { name: 'Arvo', value: '"Arvo", serif' },
    { name: 'Asar', value: ' "Asar", serif' },
    { name: 'Assistant', value: '"Assistant", sans - serif' },
    { name: 'Baloo Tammudu 2', value: '"Baloo Tammudu 2", cursive' },
    { name: 'Barlow', value: '"Barlow", sans - serif' },
    { name: 'Basic', value: '"Basic", sans - serif' },
    { name: 'Bellota', value: '"Bodoni Moda", serif' },
    { name: 'Bodoni Moda', value: '"Bodoni Moda", serif' },
    { name: 'Cormorant', value: '"Cormorant", serif' },
    { name: 'Courgette', value: '"Courgette", cursive' },
    { name: 'Dosis', value: '"Dosis", sans - serif' },
    { name: 'EB Garamond', value: '"EB Garamond", serif' },
    { name: 'Eagle Lake', value: ' "Eagle Lake", cursive' },
    { name: 'Fenix', value: '"Fenix", serif' },
    { name: 'Fira Sans', value: '"Fira Sans", sans - serif' },
    { name: 'Fjord One', value: ' "Fjord One", serif' },
    { name: 'Glass Antiqua', value: '"Glass Antiqua", cursive' },
    { name: 'Gothic A1', value: '"Gothic A1", sans - serif' },
    { name: 'Gotu', value: '"Gotu", sans - serif' },
    { name: 'Gupter', value: '"Gupter", serif' },
    { name: 'IM Fell Great Primer SC', value: '"IM Fell Great Primer SC", serif' },
    { name: 'Ibarra Real Nova', value: '"Ibarra Real Nova", serif' },
    { name: 'Inconsolata', value: '"Inconsolata", monospace' },
    { name: 'Karla', value: '"Karla", sans - serif' },
    { name: 'Kodchasan', value: '"Kodchasan", sans - serif' },
    { name: 'Kulim Park', value: '"Kulim Park", sans - serif' },
    { name: 'Lato', value: '"Lato", sans-serif' },
    { name: 'Lora', value: '"Lora", serif' },
    { name: 'Mate SC', value: '"Mate SC", serif' },
    { name: 'Merriweather', value: '"Merriweather", serif' },
    { name: 'Montserrat', value: '"Montserrat", sans-serif' },
    { name: 'Mukta', value: '"Mukta", sans - serif' },
    { name: 'Nanum Gothic', value: '"Nanum Gothic", sans - serif' },
    { name: 'Nanum Myeongjo', value: '"Nanum Myeongjo", sans-serif' },
    { name: 'Neucha', value: '"Neucha", cursive' },
    { name: 'News Cycle', value: '"News Cycle", sans - serif' },
    { name: 'Noto Sans', value: '"Noto Sans", sans-serif' },
    { name: 'Noto Sans JP', value: '"Noto Sans JP", sans-serif' },
    { name: 'Noto Sans KR', value: '"Noto Sans KR", sans-serif' },
    { name: 'Noto Sans SC', value: '"Noto Sans SC", sans-serif' },
    { name: 'Noto Sans TC', value: '"Noto Sans TC", sans-serif' },
    { name: 'Nunito', value: '"Nunito", sans - serif' },
    { name: 'Nunito Sans', value: '"Nunito Sans", sans - serif' },
    { name: 'Odibee Sans', value: '"Odibee Sans", cursive' },
    { name: 'Open Sans', value: '"Open Sans", sans-serif' },
    { name: 'Open Sans Condensed', value: '"Open Sans Condensed", sans-serif' },
    { name: 'Oswald', value: '"Oswald", sans-serif' },
    { name: 'Overpass', value: '"Overpass", sans - serif' },
    { name: 'Oxygen', value: '"Oxygen", sans-serif' },
    { name: 'PT Sans', value: '"PT Sans", sans-serif' },
    { name: 'Play', value: '"Play", sans - serif' },
    { name: 'Playfair Display', value: '"Playfair Display", serif' },
    { name: 'Poppins', value: '"Poppins", sans - serif' },
    { name: 'Rajdhani', value: '"Rajdhani", sans - serif' },
    { name: 'Raleway', value: '"Raleway", sans-serif' },
    { name: 'Red Hat Display', value: '"Red Hat Display", sans - serif' },
    { name: 'Risque', value: '"Risque", cursive' },
    { name: 'Roboto', value: '"Roboto", sans-serif' },
    { name: 'Roboto Condensed', value: '"Roboto Condensed", sans-serif' },
    { name: 'Roboto Mono', value: '"Roboto Mono", monospace' },
    { name: 'Roboto Mono Sans', value: '"Roboto Mono", sans-serif' },
    { name: 'Roboto Slab', value: '"Roboto Slab", serif' },
    { name: 'Rubik', value: '"Rubik", sans - serif' },
    { name: 'Sacramento', value: '"Sacramento", cursive' },
    { name: 'Sanchez', value: '"Sanchez", serif' },
    { name: 'Sevillana', value: '"Sevillana", cursive' },
    { name: 'Shadows Into Light', value: '"Shadows Into Light", cursive' },
    { name: 'Signika Negative', value: '"Signika Negative", sans - serif' },
    { name: 'Snippet', value: '"Snippet", sans - serif' },
    { name: 'Source Sans Pro', value: '"Source Sans Pro", sans-serif' },
    { name: 'Srisakdi', value: '"Srisakdi", cursive' },
    { name: 'Staatliches', value: '"Staatliches", cursive' },
    { name: 'Stint Ultra Condensed', value: '"Stint Ultra Condensed", cursive' },
    { name: 'Sulphur Point', value: '"Sulphur Point", sans - serif' },
    { name: 'Supermercado One', value: '"Supermercado One", cursive' },
    { name: 'Sura', value: '"Sura", serif' },
    { name: 'Suravaram', value: '"Suravaram", serif' },
    { name: 'Syne', value: '"Syne", sans - serif' },
    { name: 'Syne Mono', value: '"Syne Mono", monospace' },
    { name: 'Titillium Web', value: '"Titillium Web", sans - serif' },
    { name: 'Trykker', value: '"Trykker", serif' },
    { name: 'Ubuntu', value: '"Ubuntu", sans - serif' },
    { name: 'Voces', value: '"Voces", cursive' },
    { name: 'Warnes', value: '"Warnes", cursive' },
    { name: 'Work Sans', value: '"Work Sans", sans - serif' }
]