import React from 'react'
import style from '../../assets/editor.module.scss'
import { isEqual } from 'lodash'
import { Button, Col, Row, Input, Slider, Tooltip } from 'antd'
import { ArrowLeftOutlined, ArrowRightOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons'
import {
  EditorDropDown,
  EditorImage,
  EditorLabel,
  EditorParallel,
  EditorSlider,
  EditorVertical,
} from '../Common/EditorParts'
import { EM, IF, RS } from '../imports'
import { FrameImage, FrameLine } from './Frame'
import { handleFrameAttribute, updateFramePreview } from './methods'
import { handleColorPickerWidth, handleGradientPointer } from '../Editor/methods'
import EditorImagePicker from '../Common/EditorImagePicker'
import EditorColorPicker from '../Common/EditorColorPicker'
import EditorGradientPicker from '../Common/EditorGradientPicker'
import EditorSVG from '../Common/EditorSVG'

const FrameEditor = (props: any) => {
  /** Properties */
  const {
    loading,
    frame,
    control,
    module,
    modules,
    snapShot,
    saveStyle,
    undoStyle,
    uploading,
    uploadBar,
    setModules,
    setUploading,
    setUploadbar,
    setFileObject,
  } = props

  /** React Methods */
  const [selection, setSelection] = React.useState<IF.COMM.ValueNameIF>(RS.COMM.selectOption)
  const [justify, setJustify] = React.useState<IF.COMM.ValueNameIF>(RS.COMM.selectOption)
  const [fileName, setFileName] = React.useState<string>(EM.COMM.FILENAME.NOTAVAILABLE)
  const [palette, setPalette] = React.useState<IF.COMM.SVGIF[]>(frame.svg)
  const [imageStyle, setImageStyle] = React.useState<IF.FRME.PreviewIF>(RS.FRME.previewBorder)
  const imageRef: any = React.useRef(null)
  const gradientRef: any = React.useRef(null)

  /**
   * Obtains the color picker width upon loading the background setting component.
   */
  React.useEffect(() => {
    handleColorPickerWidth()
  }, [])

  /**
   * Updates the frame preview.
   */
  React.useEffect(() => {
    updateFramePreview(frame, setImageStyle)
  }, [frame])

  /**
   * Updates the SVG palette preview for the selected background and the CSS style for the cross hair
   * pointers of the SVG palette component when either palette or gradientRef is updated with the useEffect hook.
   */
  React.useEffect(() => {
    onChangePalettePreview(palette)
    handleGradientPointer(gradientRef)
  }, [palette, gradientRef])

  /** Local Variables */
  const lineBtn = frame.style === 1 ? saveStyle : undoStyle
  const imageBtn = frame.style === 2 ? saveStyle : undoStyle
  const showPickerRow = selection === RS.COMM.selectOption ? RS.COMM.hideBlock : RS.COMM.showFlex
  const showColorPicker = isEqual(selection, RS.BKGD.selectColor)
  const showGradientPicker = isEqual(selection, RS.BKGD.selectGradient)

  /** Local Methods */
  const onChangeOption = (item: IF.COMM.ValueNameIF) => {
    setSelection(item)
    let number = 1
    if (selection === RS.COMM.selectColor) number = 2
    if (selection === RS.COMM.selectGradient) number = 3
    const thisFrame = { ...frame, option: number }
    setModules([
      ...modules.slice(0, control.index),
      { ...module, frame: thisFrame },
      ...modules.slice(control.index + 1),
    ])
  }

  const onChangeJustify = (item: IF.COMM.ValueNameIF) => {
    setJustify(item)
    const thisFrame = { ...frame, justify: item.value }
    setModules([
      ...modules.slice(0, control.index),
      { ...module, frame: thisFrame },
      ...modules.slice(control.index + 1),
    ])
  }

  /**
   * Update the frame image.
   * @param image string.
   * @returns void.
   */
  const handlePreviewImage = (image: string) => {
    const newFrame = { ...module.frame }
    newFrame.image = `url("${image}") center center / cover`
    newFrame.option = 1
    setModules([
      ...modules.slice(0, control.index),
      { ...module, frame: newFrame },
      ...modules.slice(control.index + 1),
    ])
    setFileName(EM.COMM.FILENAME.NOTAVAILABLE)
    setFileObject('')
  }

  /**
   * Update the frame image URL.
   * @param url string.
   * @returns void.
   */
  const handlePreviewURL = (url: string) => {
    const newFrame = { ...module.frame }
    newFrame.image = `url("${url}") center center / cover`
    newFrame.option = 1
    setModules([
      ...modules.slice(0, control.index),
      { ...module, frame: newFrame },
      ...modules.slice(control.index + 1),
    ])
    setFileName(EM.COMM.FILENAME.NOTAVAILABLE)
    setFileObject('')
  }

  /**
   * Update the frame image upload file.
   * @param event React change event for the HTML input element.
   * @returns void.
   */
  const handlePreviewUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files![0] === undefined) return
    if (event.target.files![0].size > RS.COMM.size3MB) {
      setModules([...modules.slice(0, control.index), snapShot, ...modules.slice(control.index + 1)])
      setFileName(EM.COMM.FILENAME.NOTAVAILABLE)
      setFileObject('')
    }
    const imageReader = new FileReader()
    imageReader.onload = (event) => {
      const newFrame: IF.FRME.FrameIF = {
        ...module.frame,
        image: `url("${event?.target?.result}") center center / cover`,
        option: 1,
      }
      setModules([
        ...modules.slice(0, control.index),
        { ...module, frame: newFrame },
        ...modules.slice(control.index + 1),
      ])
    }
    imageReader.readAsDataURL(event.target.files![0])
    setFileName(event.target.files![0].name)
    setFileObject(event.target.files![0])
  }

  const handlePreviewColor = (color: string) => {
    setModules([
      ...modules.slice(0, control.index),
      { ...module, frame: { ...module.frame, color: color, option: 2 } },
      ...modules.slice(control.index + 1),
    ])
    setFileName(EM.COMM.FILENAME.NOTAVAILABLE)
    setFileObject('')
  }

  const onChangePalettePreview = (palette: IF.COMM.SVGIF[]) => {
    if (selection.value === EM.COMM.OPTION.GRADIENT) {
      const newFrame = { ...module.frame }
      newFrame.svg = palette
      newFrame.option = 3
      setModules([
        ...modules.slice(0, control.index),
        { ...module, frame: newFrame },
        ...modules.slice(control.index + 1),
      ])
    }
    setFileName(EM.COMM.FILENAME.NOTAVAILABLE)
    setFileObject('')
  }

  const handleAttribute = (event: any, attribute: string) => {
    handleFrameAttribute(event, attribute, control, module, modules, setModules)
  }

  /** Local JSX.Element */
  const mainImage = (
    <div className={style.editorFrame}>
      <div style={imageStyle}>
        {frame.option === 3 && <EditorSVG svg={frame.svg} height={120} radius={imageStyle.borderRadius} />}
      </div>
    </div>
  )

  const dimension = [
    <Tooltip placement="left" title={EM.FRME.FORM.WIDTH} key={'1'}>
      <div className={style.editorFrameDiv}>
        <Slider
          defaultValue={frame.width}
          min={0}
          max={100}
          style={RS.COMM.width90}
          onChange={(event: any) => handleAttribute(event, EM.FRME.FORM.WIDTH)}
        />
      </div>
    </Tooltip>,
    <Tooltip placement="left" title={EM.FRME.FORM.HEIGHT} key={'2'}>
      <div className={style.editorFrameDiv}>
        <Slider
          defaultValue={frame.height}
          min={0}
          max={100}
          style={RS.COMM.width90}
          onChange={(event: any) => handleAttribute(event, EM.FRME.FORM.HEIGHT)}
        />
      </div>
    </Tooltip>,
  ]

  const position = [
    <Tooltip
      placement="left"
      title={
        <div>
          <ArrowLeftOutlined />
          <ArrowRightOutlined />
        </div>
      }
      key={'1'}
    >
      <div className={style.editorFrameDiv}>
        <Slider
          defaultValue={frame.left}
          min={-50}
          max={150}
          style={RS.COMM.width90}
          onChange={(value: any) => handleAttribute(value, EM.FRME.FORM.LEFT)}
        />
      </div>
    </Tooltip>,
    <Tooltip
      placement="left"
      title={
        <div>
          <ArrowUpOutlined />
          <ArrowDownOutlined />
        </div>
      }
      key={'2'}
    >
      <div className={style.editorFrameDiv}>
        <Input
          type="number"
          defaultValue={frame.top}
          style={RS.COMM.width90}
          onChange={(event: any) => handleAttribute(event, EM.FRME.FORM.TOP)}
        />
      </div>
    </Tooltip>,
  ]

  const styles = [
    <Button style={lineBtn} onClick={() => handleAttribute(1, EM.FRME.FORM.LINE)} key={'1'}>
      {EM.FRME.FORM.LINE}
    </Button>,
    <Button style={imageBtn} onClick={() => handleAttribute(2, EM.FRME.FORM.IMAGE)} key={'2'}>
      {EM.FRME.FORM.IMAGE}
    </Button>,
  ]

  return (
    <div className={style.editorPageTwo}>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.CTRL.DRAWER.IMAGE} />
        <EditorImage mainImage={mainImage} />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.COMM.EDITOR.OPTION} />
        <EditorDropDown
          span={18}
          loading={loading}
          sourceList={RS.COMM.selection}
          selection={selection}
          saveStyle={saveStyle}
          onChangeItem={onChangeOption}
        />
      </Row>
      <Row className={style.editorRow} style={showPickerRow}>
        <EditorImagePicker
          type={EM.COMM.IMAGETYPE.FRAME}
          images={RS.BKGD.pickers}
          selection={selection}
          imageRef={imageRef}
          saveStyle={saveStyle}
          fileName={fileName}
          uploading={uploading}
          uploadBar={uploadBar}
          loading={loading}
          setUploading={setUploading}
          setUploadbar={setUploadbar}
          handlePreviewImage={handlePreviewImage}
          handlePreviewURL={handlePreviewURL}
          handlePreviewUpload={handlePreviewUpload}
        />
        <EditorColorPicker
          color={frame.color}
          showColorPicker={showColorPicker}
          handlePreviewColor={handlePreviewColor}
        />
        <EditorGradientPicker
          palette={palette}
          gradientRef={gradientRef}
          showGradientPicker={showGradientPicker}
          setPalette={setPalette}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.FRME.EDITOR.DIMENSION} />
        <EditorVertical elements={dimension} />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.FRME.EDITOR.POSITION} />
        <EditorVertical elements={position} />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.FRME.EDITOR.JUSTIFY} />
        <EditorDropDown
          span={18}
          loading={loading}
          sourceList={RS.FRME.arrangements}
          selection={justify}
          saveStyle={saveStyle}
          onChangeItem={onChangeJustify}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.FRME.EDITOR.EDGE} />
        <EditorSlider
          min={0}
          max={200}
          span={18}
          value={control.size}
          width={RS.COMM.width90}
          attribute={EM.FRME.FORM.PADDING}
          handleAttribute={handleAttribute}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.FRME.EDITOR.RADIUS} />
        <Col span={18}>
          <div className={style.editorDivP}>
            <Slider
              defaultValue={frame.radius}
              min={0}
              max={500}
              style={RS.COMM.width73}
              onChange={(event: any) => handleAttribute(event, EM.FRME.FORM.RADIUS)}
            />
            <Button
              style={undoStyle}
              size={EM.COMM.BUTTON.SMALL}
              onClick={() => handleAttribute(1000, EM.FRME.FORM.ROUND)}
            >
              {EM.FRME.FORM.ROUND}
            </Button>
          </div>
        </Col>
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.FRME.EDITOR.STYLE} />
        <EditorParallel span={18} elements={styles} />
      </Row>
      <FrameLine
        frame={frame}
        saveStyle={saveStyle}
        undoStyle={undoStyle}
        loading={loading}
        handleAttribute={handleAttribute}
      />
      <FrameImage
        frame={frame}
        control={control}
        module={module}
        modules={modules}
        saveStyle={saveStyle}
        undoStyle={undoStyle}
        loading={loading}
        setModules={setModules}
        handleAttribute={handleAttribute}
      />
    </div>
  )
}

export default FrameEditor
