import React from 'react'
import style from '../../assets/editor.module.scss'
import { Col, Input } from 'antd'
import { EM, IF } from '../imports'

/**
 * Editor border picker component to pick borders.
 * @param images image array to select.
 * @param selection dropdown selection options.
 * @param handlePreviewImage callback function to update image preview.
 * @param handlePreviewURL callback function to update image URL preview.
 * @returns editor border picker component.
 */
const EditorBorderPicker: React.FC<{
  images: IF.COMM.ImageIF[]
  selection: IF.COMM.ValueNameIF
  handlePreviewImage: (image: string) => void
  handlePreviewURL: (url: string) => void
}> = ({ images, selection, handlePreviewImage, handlePreviewURL }) => {
  /**
   * The image component to display the images to be selected.
   * @returns JSX element to render the array of images.
   */
  const getImages = () => {
    return images.map((item: IF.COMM.ImageIF, index: number) => (
      <Col xs={12} sm={8} key={index}>
        <div className={style.pickerImageDiv}>
          <img
            className={style.pickerImage}
            src={item.url}
            alt={`editor-image-${index}`}
            loading="lazy"
            onClick={() => handlePreviewImage(item.url)}
          />
        </div>
      </Col>
    ))
  }

  /**
   * The URL component for users to enter the image URL.
   * @returns JSX element to render the input element for entering the image URL.
   */
  const getURL = () => (
    <div className={style.pickerURL}>
      <Input placeholder={EM.COMM.IMAGE.HOLDER} onChange={(event) => handlePreviewURL(event.target.value)} />
    </div>
  )

  return (
    <>
      {selection.value === EM.COMM.OPTION.IMAGE && getImages()}
      {selection.value === EM.COMM.OPTION.URL && getURL()}
    </>
  )
}

export default EditorBorderPicker
