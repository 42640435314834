import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { state as initialState } from '../resources/state'
import { RootState } from '../../store'
import { IF } from '../components/imports'

export const talents = createSlice({
  name: 'talents',
  initialState,
  reducers: {
    updateAuth(state: IF.STAT.StateIF, action: PayloadAction<IF.AUTH.AuthIF>) {
      state.auth = action.payload
    },
    updateStatus(state: IF.STAT.StateIF, action: PayloadAction<IF.STAT.StatusIF>) {
      state.status = action.payload
    },
    updateProfile(state: IF.STAT.StateIF, action: PayloadAction<IF.STAT.ProfileIF>) {
      state.profile = action.payload
    },
    updateMENUSnapshot(state: IF.STAT.StateIF, action: PayloadAction<IF.NAVG.MenuBarIF>) {
      state.MENUSnapshot = action.payload
    },
    updateNAVGSnapshot(state: IF.STAT.StateIF, action: PayloadAction<IF.NAVG.NaviBarIF>) {
      state.NAVGSnapshot = action.payload
    },
    updateCTRLSnapshot(state: IF.STAT.StateIF, action: PayloadAction<IF.CTRL.ControlIF>) {
      state.CTRLSnapshot = action.payload
    },
    updateBKGDSnapshot(state: IF.STAT.StateIF, action: PayloadAction<IF.BKGD.BackgroundIF[]>) {
      state.BKGDSnapshot = action.payload
    },
    updateMODLSnapshot(state: IF.STAT.StateIF, action: PayloadAction<IF.STAT.ModuleIF[]>) {
      state.MODLSnapshot = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  updateAuth,
  updateStatus,
  updateProfile,
  updateMENUSnapshot,
  updateNAVGSnapshot,
  updateCTRLSnapshot,
  updateBKGDSnapshot,
  updateMODLSnapshot,
} = talents.actions

export const auth = (state: RootState) => state.talentsReducer.auth
export const status = (state: RootState) => state.talentsReducer.status
export const profile = (state: RootState) => state.talentsReducer.profile
export const CTRLSnapshot = (state: RootState) => state.talentsReducer.CTRLSnapshot
export const BKGDSnapshot = (state: RootState) => state.talentsReducer.BKGDSnapshot
export const MODLSnapshot = (state: RootState) => state.talentsReducer.MODLSnapshot
export const NAVGSnapshot = (state: RootState) => state.talentsReducer.NAVGSnapshot
export const MENUSnapshot = (state: RootState) => state.talentsReducer.MENUSnapshot

export default talents.reducer
