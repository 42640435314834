import React from 'react'
import style from '../../../assets/editor.intro.module.scss'
import { Col, Collapse, Row } from 'antd'
import {
    FacebookFilled, GithubFilled, InstagramFilled,
    LinkedinFilled, TwitterSquareFilled, YoutubeFilled
} from '@ant-design/icons'
import {
    EditorHuePicker,
    EditorInput,
    EditorLabel,
    EditorOrder,
    EditorSlider,
    EditorSwitch
} from '../../Common/EditorParts'
import { IF, RS, EM } from '../../imports'
import * as m from '../methods'

const { Panel } = Collapse

const IconsSetting = (props: {
    intro: IF.INTR.IntroductionIF,
    control: IF.CTRL.ControlIF,
    socialIcons: IF.INTR.IntroContentIF[],
    label: string,
    attribute: string,
    module: IF.STAT.ModuleIF,
    modules: IF.STAT.ModuleIF[],
    undoStyle: React.CSSProperties,
    editorStyle: React.CSSProperties,
    switchStyle: React.CSSProperties,
    setModules: React.Dispatch<React.SetStateAction<IF.STAT.ModuleIF[]>>,
    onChangeAttribute: (value: any, attribute: string) => void,
    onChangeOrder: (moveUp: boolean, attribute: string) => void
}) => {
    const { intro, control, socialIcons, label, attribute, module, modules, undoStyle,
        editorStyle, switchStyle, setModules, onChangeAttribute, onChangeOrder } = props

    const iStyle = { paddingLeft: '16px' }
    const syncStyle = { backgroundColor: control.color, width: '120px' }
    const labels = [
        EM.INTR.LABEL.FACEBOOK,
        EM.INTR.LABEL.TWITTER,
        EM.INTR.LABEL.LINKEDIN,
        EM.INTR.LABEL.INSTAGRAM,
        EM.INTR.LABEL.YOUTUBE,
        EM.INTR.LABEL.GITHUB,
    ]

    const order = intro.order.find((item: IF.INTR.IntroOrderIF) => (item.key === EM.INTR.ATTRIBUTE.ICONS))!.order

    const getSocial = () => {
        return <Row className={style.introFieldRow}>
            <EditorLabel span={6} label={label} iStyle={iStyle} />
            <Col span={18}>
                <div className={style.introSocialMediaEditor}>
                    <FacebookFilled style={m.socialStyle(intro.facebook, intro.icons)}
                        twoToneColor={intro.facebook.color} />
                    <TwitterSquareFilled style={m.socialStyle(intro.twitter, intro.icons)}
                        twoToneColor={intro.twitter.color} />
                    <LinkedinFilled style={m.socialStyle(intro.linkedin, intro.icons)}
                        twoToneColor={intro.linkedin.color} />
                    <InstagramFilled style={m.socialStyle(intro.instagram, intro.icons)}
                        twoToneColor={intro.instagram.color} />
                    <YoutubeFilled style={m.socialStyle(intro.youtube, intro.icons)}
                        twoToneColor={intro.youtube.color} />
                    <GithubFilled style={m.socialStyle(intro.github, intro.icons)}
                        twoToneColor={intro.github.color} />
                </div>
            </Col>
        </Row>
    }

    const showCollapse = () => {
        if (intro.icons.meta.syncShow === EM.COMM.LOWER.N) return true
        return intro.icons.show === EM.COMM.DISPLAY.FLEX
    }

    const onChangeIcons = (value: boolean, attribute: string) => {
        const newVal = value ? EM.COMM.LOWER.Y : EM.COMM.LOWER.N
        onChangeAttribute(newVal, attribute)
    }

    const onChangeIcon = (icon: IF.INTR.IntroContentIF) => {
        const newModule = { ...module }
        let newIntro = { ...module.value.introduction! }
        newIntro = { ...newIntro, [icon.label]: icon }
        newModule.value = { introduction: newIntro }
        setModules([
            ...modules.slice(0, control.index),
            newModule,
            ...modules.slice(control.index + 1),
        ])
    }

    const onChangeText = (value: string, attribute: string, item: IF.INTR.IntroContentIF) => {
        onChangeIcon({ ...item, [attribute]: value })
    }

    const onChangeSize = (value: number, attribute: string, item: IF.INTR.IntroContentIF) => {
        onChangeIcon({ ...item, [attribute]: value })
    }

    const onChangeShow = (value: boolean, attribute: string, item: IF.INTR.IntroContentIF) => {
        const newVal = value ? EM.COMM.DISPLAY.FLEX : EM.COMM.DISPLAY.NONE
        onChangeIcon({ ...item, [attribute]: newVal })
    }

    return <>
        <div>{getSocial()}</div>
        <>
            <Row className={style.introFieldRow}>
                <EditorLabel span={8} label={EM.INTR.FIELDLABEL.SYNCSIZE} iStyle={iStyle} />
                <EditorSwitch
                    span={16}
                    show={intro.icons.meta.syncSize === EM.COMM.LOWER.Y}
                    attribute={EM.INTR.ATTRIBUTE.SYNCSIZE}
                    switchStyle={syncStyle}
                    checkedChildren={EM.INTR.VAR.SYNCHRONIZED}
                    unCheckedChildren={EM.INTR.VAR.DESYNCHRONIZED}
                    handleAttribute={onChangeIcons} />
            </Row>
            <Row className={style.introFieldRow}>
                <EditorLabel span={8} label={EM.INTR.FIELDLABEL.SYNCCOLOR} iStyle={iStyle} />
                <EditorSwitch
                    span={16}
                    show={intro.icons.meta.syncColor === EM.COMM.LOWER.Y}
                    attribute={EM.INTR.ATTRIBUTE.SYNCCOLOR}
                    switchStyle={syncStyle}
                    checkedChildren={EM.INTR.VAR.SYNCHRONIZED}
                    unCheckedChildren={EM.INTR.VAR.DESYNCHRONIZED}
                    handleAttribute={onChangeIcons} />
            </Row>
            <Row className={style.introFieldRow}>
                <EditorLabel span={8} label={EM.INTR.FIELDLABEL.SYNCSHOW} iStyle={iStyle} />
                <EditorSwitch
                    span={16}
                    show={intro.icons.meta.syncShow === EM.COMM.LOWER.Y}
                    attribute={EM.INTR.ATTRIBUTE.SYNCSHOW}
                    switchStyle={syncStyle}
                    checkedChildren={EM.INTR.VAR.SYNCHRONIZED}
                    unCheckedChildren={EM.INTR.VAR.DESYNCHRONIZED}
                    handleAttribute={onChangeIcons} />
            </Row>
            <Row className={style.introFieldRow}>
                <EditorLabel span={8} label={EM.INTR.FIELDLABEL.SYNCORDER} iStyle={iStyle} />
                <EditorOrder
                    span={16}
                    undoStyle={undoStyle}
                    disableUp={order === 1}
                    disableDn={order === intro.order.length}
                    onChangeOrder={(up) => onChangeOrder(up, attribute)} />
            </Row>
            {intro.icons.meta.syncSize === EM.COMM.LOWER.Y &&
                <Row className={style.introFieldRow}>
                    <EditorLabel span={8} label={EM.INTR.FIELDLABEL.ICONSSIZE} iStyle={iStyle} />
                    <EditorSlider
                        min={1}
                        max={100}
                        span={16}
                        value={intro.icons.size}
                        width={RS.COMM.width90}
                        attribute={EM.INTR.FIELDKEY.SIZE}
                        handleAttribute={onChangeAttribute} />
                </Row>}
            {intro.icons.meta.syncColor === EM.COMM.LOWER.Y &&
                <Row className={style.introFieldRow}>
                    <EditorLabel span={8} label={EM.INTR.FIELDLABEL.ICONSCOLOR} iStyle={iStyle} />
                    <EditorHuePicker
                        span={16}
                        width={'80%'}
                        color={intro.icons.color}
                        attribute={EM.INTR.FIELDKEY.COLOR}
                        undoStyle={undoStyle}
                        handleAttribute={onChangeAttribute} />
                </Row>}
            {intro.icons.meta.syncShow === EM.COMM.LOWER.Y &&
                <Row className={style.introFieldRow}>
                    <EditorLabel span={8} label={EM.INTR.FIELDLABEL.ICONSSHOW} iStyle={iStyle} />
                    <EditorSwitch
                        span={16}
                        show={intro.icons.show === EM.COMM.DISPLAY.FLEX}
                        attribute={EM.INTR.FIELDKEY.SHOW}
                        switchStyle={syncStyle}
                        checkedChildren={EM.COMM.VAR.SHOW}
                        unCheckedChildren={EM.COMM.VAR.HIDE}
                        handleAttribute={onChangeAttribute} />
                </Row>}
        </>
        {showCollapse() &&
            <Collapse defaultActiveKey={['-1']} accordion ghost className='fieldCollapse'>
                {socialIcons.map((item: IF.INTR.IntroContentIF, index: number) => {
                    return <Panel header={labels[index]} key={index}>
                        {(item.show === EM.COMM.DISPLAY.FLEX || (intro.icons.meta.syncShow === EM.COMM.LOWER.Y &&
                            intro.icons.show === EM.COMM.DISPLAY.FLEX)) && <>
                                <Row className={style.introFieldRow}>
                                    <EditorLabel span={6} label={labels[index]} iStyle={editorStyle} />
                                    <EditorInput
                                        max={120}
                                        span={18}
                                        value={item.text}
                                        inputType={EM.COMM.TYPE.TEXT}
                                        attribute={EM.INTR.FIELDKEY.TEXT}
                                        iStyle={editorStyle}
                                        handleAttribute={(value, attribute) => onChangeText(value, attribute, item)} />
                                </Row>
                                {intro.icons.meta.syncSize === EM.COMM.LOWER.N &&
                                    <Row className={style.introFieldRow}>
                                        <EditorLabel span={6} label={EM.INTR.FIELDLABEL.ICONSIZE} iStyle={editorStyle} />
                                        <EditorSlider
                                            min={1}
                                            max={100}
                                            span={18}
                                            value={item.size}
                                            width={RS.COMM.width100}
                                            attribute={EM.INTR.FIELDKEY.SIZE}
                                            handleAttribute={(value, attribute) => onChangeSize(value, attribute, item)} />
                                    </Row>}
                                {intro.icons.meta.syncColor === EM.COMM.LOWER.N &&
                                    <Row className={style.introFieldRow}>
                                        <EditorLabel span={6} label={EM.INTR.FIELDLABEL.ICONCOLOR} iStyle={editorStyle} />
                                        <EditorHuePicker
                                            span={18}
                                            width={'80%'}
                                            color={item.color}
                                            attribute={EM.INTR.FIELDKEY.COLOR}
                                            undoStyle={undoStyle}
                                            handleAttribute={(value, attribute) => onChangeText(value, attribute, item)} />
                                    </Row>}
                            </>}
                        {intro.icons.meta.syncShow === EM.COMM.LOWER.N &&
                            <Row className={style.introFieldRow}>
                                <EditorLabel span={6} label={EM.INTR.FIELDLABEL.ICONSHOW} iStyle={editorStyle} />
                                <EditorSwitch
                                    span={18}
                                    show={item.show === EM.COMM.DISPLAY.FLEX}
                                    attribute={EM.INTR.FIELDKEY.SHOW}
                                    switchStyle={switchStyle}
                                    checkedChildren={EM.COMM.VAR.SHOW}
                                    unCheckedChildren={EM.COMM.VAR.HIDE}
                                    handleAttribute={(checked, attribute) => onChangeShow(checked, attribute, item)} />
                            </Row>}
                    </Panel>
                })}
            </Collapse>}
    </>
}

export default IconsSetting