import React from 'react'
import style from '../../assets/editor.module.scss'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { Button, Drawer, Space, Tooltip } from 'antd'
import { BackwardOutlined, CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { EM, IF, RS, RX } from '../imports'
import { isEmpty, isEqual } from 'lodash'
import { websocketHost } from '../../utils/request'
import ControlEditor from './ControlEditor'
import ModuleEditor from './ModuleEditor'
import NavigationEditor from './NavigationEditor'
import BackgroundEditor from './BackgroundEditor'
import * as m from './methods'

/**
 * Editor component that renders the Ant Drawer component for other editor components.
 * @param auth authentication data.
 * @param naviBar navigation bar data.
 * @param menuBar menu bar data.
 * @param control control data.
 * @param modules module array data.
 * @param backgrounds background array data.
 * @param NAVGSnapshot navigation snap data.
 * @param MENUSnapshot menu snap data.
 * @param controlSnap control snap data.
 * @param BKGDSnapshot background array snap data.
 * @param MODLSnapshot module array snap data.
 * @param currentBGTop current background top number.
 * @param controlIconRef control icon React ref.
 * @param dispatch Thunder dispatch.
 * @param setNaviBar React dispatcher callback function to update naviBar state.
 * @param setMenuBar React dispatcher callback function to update menuBar state.
 * @param setControl React dispatcher callback function to update control state.
 * @param setModules React dispatcher callback function to update modules state.
 * @param setOpenIcon React dispatcher callback function to update openIcon state.
 * @param setOpenMenu React dispatcher callback function to update openMenu state.
 * @param setShowMenu React dispatcher callback function to update showMenu state.
 * @param setBackgrounds React dispatcher callback function to update backgrounds state.
 * @param setCurrentBGTop React dispatcher callback function to update currentBGTop state.
 * @returns editor JSX component.
 */
const Editor: React.FC<{
  auth: IF.AUTH.AuthIF
  naviBar: IF.NAVG.NaviBarIF
  menuBar: IF.NAVG.MenuBarIF
  control: IF.CTRL.ControlIF
  modules: IF.STAT.ModuleIF[]
  backgrounds: IF.BKGD.BackgroundIF[]
  NAVGSnapshot: IF.NAVG.NaviBarIF
  MENUSnapshot: IF.NAVG.MenuBarIF
  controlSnap: IF.CTRL.ControlIF
  BKGDSnapshot: IF.BKGD.BackgroundIF[]
  MODLSnapshot: IF.STAT.ModuleIF[]
  currentBGTop: number
  controlIconRef: any
  dispatch: any
  setNaviBar: React.Dispatch<React.SetStateAction<IF.NAVG.NaviBarIF>>
  setMenuBar: React.Dispatch<React.SetStateAction<IF.NAVG.MenuBarIF>>
  setControl: React.Dispatch<React.SetStateAction<IF.CTRL.ControlIF>>
  setModules: React.Dispatch<React.SetStateAction<IF.STAT.ModuleIF[]>>
  setOpenIcon: React.Dispatch<React.SetStateAction<boolean>>
  setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>
  setBackgrounds: React.Dispatch<React.SetStateAction<IF.BKGD.BackgroundIF[]>>
  setCurrentBGTop: React.Dispatch<React.SetStateAction<number>>
}> = ({
  auth,
  naviBar,
  menuBar,
  control,
  modules,
  backgrounds,
  NAVGSnapshot,
  MENUSnapshot,
  controlSnap,
  BKGDSnapshot,
  MODLSnapshot,
  currentBGTop,
  controlIconRef,
  dispatch,
  setNaviBar,
  setMenuBar,
  setControl,
  setModules,
  setOpenIcon,
  setOpenMenu,
  setShowMenu,
  setBackgrounds,
  setCurrentBGTop,
}) => {
  /** React Methods */
  const session = auth.session?.split(':')[2]
  const socketUrl = `${websocketHost()}/ws/media/${session}/`
  const [fileObject, setFileObject] = React.useState<string | File>('')
  const [uploading, setUploading] = React.useState<boolean>(false)
  const [uploadBar, setUploadbar] = React.useState<IF.COMM.UploadStatusIF>(RS.COMM.initialUploadPercent)
  const [selection, setSelection] = React.useState<IF.COMM.ValueNameIF>(RS.COMM.selectOption)
  const { lastMessage, readyState } = useWebSocket(socketUrl)

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState]

  React.useEffect(() => {
    if (lastMessage !== null) setUploadbar(JSON.parse(lastMessage.data))
    console.info('The WebSocket connection status is', connectionStatus)
  }, [lastMessage, connectionStatus, setUploadbar])

  React.useEffect(() => {
    if (control.showDrawer) m.myAntDrawer()
  }, [control.showDrawer])

  // React.useEffect(() => {
  //   // handleSelection()
  // }, [modules[control.index].type])

  /** Local Variables */
  const isBackgroundDrawer = control.type === EM.CTRL.TYPE.BACKGROUND
  const isModuleDrawer = control.type === EM.CTRL.TYPE.MODULE
  const isControlDrawer = control.type === EM.CTRL.TYPE.CONTROL
  const isNavibarDrawer = control.type === EM.CTRL.TYPE.NAVIGATION
  const module = modules[control.index]
  const background = backgrounds[control.index]
  const doneStyle = { backgroundColor: control.color, borderColor: control.color }
  const saveStyle = { backgroundColor: control.color, borderColor: control.color, color: 'white' }
  const backStyle = { backgroundColor: control.color, borderColor: control.color, width: '100px' }
  const undoStyle = { color: control.color, borderColor: control.color }
  const prevStyle: any = { float: 'left', backgroundColor: control.color, borderColor: control.color }
  const drawerWidth = window.innerWidth < 576 ? window.innerWidth - 100 : 500
  const isNewControl =
    control.top !== controlSnap.top ||
    control.left !== controlSnap.left ||
    control.image !== controlSnap.image ||
    control.color !== controlSnap.color ||
    control.icon !== controlSnap.icon ||
    control.size !== controlSnap.size ||
    control.option !== controlSnap.option
  const isNewBackground =
    background.title !== BKGDSnapshot[control.index].title ||
    background.option !== BKGDSnapshot[control.index].option ||
    background.height !== BKGDSnapshot[control.index].height ||
    background.image !== BKGDSnapshot[control.index].image ||
    background.color !== BKGDSnapshot[control.index].color ||
    background.svg !== BKGDSnapshot[control.index].svg

  const isNewBKGDOrder = isEqual(
    backgrounds.map((item: IF.BKGD.BackgroundIF) => item.id),
    BKGDSnapshot.map((item: IF.BKGD.BackgroundIF) => item.id)
  )

  const isNewMODLOrder = isEqual(
    modules.map((item: IF.STAT.ModuleIF) => item.id),
    MODLSnapshot.map((item: IF.STAT.ModuleIF) => item.id)
  )

  const hideSaveBtn =
    (isNavibarDrawer && !m.isNewNavigation(naviBar, menuBar, NAVGSnapshot, MENUSnapshot, selection)) ||
    (isControlDrawer && !isNewControl) ||
    (isBackgroundDrawer && control.page === 1 && isNewBKGDOrder) ||
    (isBackgroundDrawer && control.page === 2 && !isNewBackground) ||
    (isModuleDrawer && control.page === 1 && isNewMODLOrder) ||
    (isModuleDrawer && control.page === 3 && !m.isNewModule(module, MODLSnapshot[control.index], selection))

  /** Local Methods */
  const getTitle = () => {
    if (isBackgroundDrawer) {
      if (control.page === 1) return EM.COMM.BUTTON.BACKGROUND
      return isEmpty(background.title) ? `background - ${background.order}` : background.title
    }
    if (isModuleDrawer) return isEmpty(module.title) ? `${module.type} - ${module.order}` : module.title
    if (isControlDrawer) return EM.COMM.BUTTON.CONTROL
    if (isNavibarDrawer) return EM.COMM.BUTTON.NAVIBAR
  }

  const handleSave = () => {
    if (fileObject !== '') setUploading(true)
    if (isBackgroundDrawer) {
      if (control.page === 1) {
        RX.BKGD.updateOrder(auth, dispatch, backgrounds)
      } else {
        RX.BKGD.updateBKGD(auth, dispatch, control.index, background, BKGDSnapshot, fileObject, setBackgrounds)
      }
    }
    if (isControlDrawer) {
      RX.CTRL.updateCTRL(auth, dispatch, control, controlSnap, controlIconRef, fileObject, setControl)
    }
    if (isNavibarDrawer) {
      if (fileObject === '') {
        RX.NAVG.updateNavigation(
          auth,
          dispatch,
          naviBar,
          menuBar,
          selection,
          NAVGSnapshot,
          MENUSnapshot,
          setNaviBar,
          setMenuBar
        )
      } else {
        RX.NAVG.updateNavImages(
          auth,
          dispatch,
          naviBar,
          menuBar,
          selection,
          NAVGSnapshot,
          MENUSnapshot,
          fileObject,
          setNaviBar,
          setMenuBar,
          setFileObject
        )
      }
    }
    if (isModuleDrawer) {
      if (control.page === 1) {
        RX.MODL.updateOrder(auth, dispatch, modules)
      } else {
        switch (module.type) {
          case EM.MODL.TYPE.INTRODUCTION:
            if (selection.name === EM.INTR.SETTING.CUSTOM) {
              RX.INTR.updateIntroCustomField(auth, dispatch, control.index, module, MODLSnapshot, setModules)
            } else if (fileObject === '') {
              RX.INTR.updateIntroduction(
                auth,
                dispatch,
                control.index,
                selection,
                control,
                module,
                MODLSnapshot,
                setControl,
                setModules
              )
            } else {
              RX.INTR.updateIntroAvatar(auth, dispatch, control.index, module, MODLSnapshot, fileObject, setModules)
            }
            break
          default:
            break
        }
      }
    }
    setFileObject('')
  }

  const handleCancel = () => {
    m.handleCancelDrawer(
      control,
      module,
      background,
      NAVGSnapshot,
      MENUSnapshot,
      controlSnap,
      BKGDSnapshot,
      MODLSnapshot,
      currentBGTop,
      setOpenIcon,
      setOpenMenu,
      setShowMenu,
      setNaviBar,
      setMenuBar,
      setControl,
      setModules,
      setBackgrounds
    )
  }

  const handlePreview = () => {
    setOpenMenu(false)
    setControl({ ...control, showDrawer: false })
    m.myAntDrawer()
    setTimeout(() => {
      setShowMenu(false)
      const body = document.getElementsByTagName('body')[0]
      body.style.overflowY = EM.COMM.BODY.INITIAL
    }, 200)
  }

  const handleFlip = () => {
    let pages: HTMLElement[] = []
    if (control.type === EM.CTRL.TYPE.NAVIGATION) {
      setOpenMenu(false)
      setTimeout(() => setShowMenu(false), 500)
    }
    pages = Array.from(document.getElementsByClassName(style.editorPageOne) as HTMLCollectionOf<HTMLElement>)
    if (control.page === 2) pages.forEach((pages: any) => (pages.style.transform = 'translateX(0%)'))
    if (control.page === 3) pages.forEach((pages: any) => (pages.style.transform = 'translateX(-100%)'))
    setControl({ ...control, page: control.page - 1 })
  }

  const footer = [
    [
      <Button key="preview" style={prevStyle} type="primary" onClick={handlePreview} disabled={auth.loading}>
        {EM.COMM.BUTTON.PREVIEW}
      </Button>,
      <Button key="back" style={undoStyle} onClick={handleCancel} disabled={auth.loading}>
        {EM.COMM.BUTTON.UNDO}
      </Button>,
      <Button
        key="submit"
        style={doneStyle}
        type="primary"
        onClick={handleSave}
        disabled={hideSaveBtn}
        loading={auth.loading}
      >
        {EM.COMM.BUTTON.SAVE}
      </Button>,
    ],
  ]

  const extraButton = () => {
    if (control.page === 1) {
      if (isBackgroundDrawer || isModuleDrawer) {
        return (
          <Space>
            <Tooltip title={EM.BKGD.HINT.EDIT} placement="left">
              <ExclamationCircleOutlined style={{ color: control.color, fontSize: '22px' }} />
            </Tooltip>
          </Space>
        )
      }
    } else {
      return (
        <Space>
          <Button
            type="primary"
            onClick={handleFlip}
            style={backStyle}
            shape="round"
            size={EM.COMM.BUTTON.SMALL}
            icon={<BackwardOutlined />}
          >
            {EM.COMM.BUTTON.BACK}
          </Button>
        </Space>
      )
    }
  }

  const drawerStyle: React.CSSProperties = {
    height: '60px',
    padding: '15px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: 'space-between',
    alignItems: 'center',
  }

  const showFooter =
    isBackgroundDrawer ||
    isControlDrawer ||
    (isModuleDrawer && control.page !== 2) ||
    (isNavibarDrawer && control.page > 1)
      ? footer
      : [[]]

  const closeIcon = <CloseOutlined style={{ fontSize: '22px' }} />

  return (
    <Drawer
      title={getTitle()}
      width={drawerWidth}
      open={control.showDrawer}
      closeIcon={closeIcon}
      onClose={handleCancel}
      footer={showFooter}
      footerStyle={drawerStyle}
      extra={extraButton()}
      style={{ borderRadius: '15px' }}
    >
      {isNavibarDrawer && (
        <NavigationEditor
          loading={auth.loading}
          control={control}
          naviBar={naviBar}
          menuBar={menuBar}
          modules={modules}
          selection={selection}
          saveStyle={saveStyle}
          undoStyle={undoStyle}
          uploading={uploading}
          uploadBar={uploadBar}
          NAVGSnapshot={NAVGSnapshot}
          MENUSnapshot={MENUSnapshot}
          setControl={setControl}
          setNaviBar={setNaviBar}
          setMenuBar={setMenuBar}
          setShowMenu={setShowMenu}
          setOpenIcon={setOpenIcon}
          setOpenMenu={setOpenMenu}
          setSelection={setSelection}
          setUploading={setUploading}
          setUploadbar={setUploadbar}
          setFileObject={setFileObject}
        />
      )}
      {isControlDrawer && (
        <ControlEditor
          auth={auth}
          control={control}
          saveStyle={saveStyle}
          undoStyle={undoStyle}
          uploading={uploading}
          uploadBar={uploadBar}
          snapShot={controlSnap}
          setControl={setControl}
          setUploading={setUploading}
          setUploadbar={setUploadbar}
          setFileObject={setFileObject}
        />
      )}
      {isBackgroundDrawer && (
        <BackgroundEditor
          auth={auth}
          control={control}
          saveStyle={saveStyle}
          undoStyle={undoStyle}
          uploading={uploading}
          uploadBar={uploadBar}
          background={background}
          backgrounds={backgrounds}
          snapShot={BKGDSnapshot[control.index]}
          setControl={setControl}
          setUploading={setUploading}
          setUploadbar={setUploadbar}
          setFileObject={setFileObject}
          setBackgrounds={setBackgrounds}
          setCurrentBGTop={setCurrentBGTop}
        />
      )}
      {isModuleDrawer && (
        <ModuleEditor
          loading={auth.loading}
          control={control}
          module={module}
          modules={modules}
          snapShot={MODLSnapshot[control.index]}
          saveStyle={saveStyle}
          undoStyle={undoStyle}
          uploadBar={uploadBar}
          uploading={uploading}
          selection={selection}
          setControl={setControl}
          setModules={setModules}
          setSelection={setSelection}
          setUploading={setUploading}
          setUploadbar={setUploadbar}
          setFileObject={setFileObject}
        />
      )}
    </Drawer>
  )
}

export default Editor
