import React from 'react'
import style from '../../assets/editor.module.scss'
import { isEqual } from 'lodash'
import { Row } from 'antd'
import { EditorDropDown, EditorHistoryLabel, EditorImage, EditorLabel } from '../Common/EditorParts'
import { EditorHistoryIconPicker } from '../Common/History'
import { handleColorPickerWidth } from '../Editor/methods'
import { EM, IF, RS } from '../imports'
import { handleShowMenuIcon } from './methods'
import EditorAntIconPicker from '../Common/EditorAntIconPicker'
import EditorHuePicker from '../Common/EditorHuePicker'
import EditorImagePicker from '../Common/EditorImagePicker'
import EditorSlider from '../Common/EditorSlider'

const IconSetting = (props: {
  control: IF.CTRL.ControlIF
  menuBar: IF.NAVG.MenuBarIF
  snapShot: IF.NAVG.MenuBarIF
  saveStyle: React.CSSProperties
  undoStyle: React.CSSProperties
  uploading: boolean
  uploadBar: any
  loading: boolean
  setMenuBar: React.Dispatch<React.SetStateAction<IF.NAVG.MenuBarIF>>
  setUploading: React.Dispatch<React.SetStateAction<boolean>>
  setUploadbar: React.Dispatch<React.SetStateAction<any>>
  setFileObject: React.Dispatch<React.SetStateAction<string | File>>
}) => {
  /** Properties */
  const {
    control,
    menuBar,
    snapShot,
    saveStyle,
    undoStyle,
    uploading,
    uploadBar,
    loading,
    setMenuBar,
    setUploading,
    setUploadbar,
    setFileObject,
  } = props

  /** React Methods */
  const [selection, setSelection] = React.useState<IF.COMM.ValueNameIF>(RS.COMM.selectOption)
  const [fileName, setFileName] = React.useState<string>(EM.COMM.FILENAME.NOTAVAILABLE)
  const imageRef: any = React.useRef(null)

  React.useEffect(() => {
    handleColorPickerWidth()
  }, [menuBar])

  /* Local Variables */
  const iconStyle: React.CSSProperties = { fontSize: `${menuBar.iconSize}px`, color: menuBar.iconColor }
  const showPickerRow = isEqual(selection, RS.COMM.selectOption) ? RS.COMM.hideBlock : RS.COMM.showFlex
  const showIconRow =
    isEqual(selection, RS.COMM.selectIcon) || menuBar.iconOption === 2 ? RS.COMM.showFlex : RS.COMM.hideBlock
  const showIcon = (
    <div className={style.editorPreviewIconB} style={iconStyle}>
      {handleShowMenuIcon(menuBar.icon, menuBar.iconSize, menuBar.iconColor)}
    </div>
  )
  const showImage = (
    <div className={style.editorPreviewIconA}>
      <img
        src={menuBar.iconImage}
        alt={'control-image'}
        style={{ width: `${menuBar.iconSize}px`, height: `${menuBar.iconSize}px` }}
        loading="lazy"
      />
    </div>
  )

  const mainImage = (
    <div className={style.editorPreviewImage}>
      {menuBar.iconOption === 1 && showImage}
      {menuBar.iconOption === 2 && showIcon}
    </div>
  )

  /**
   * Update the dropdown selection option.
   * @param item selection option.
   * @returns void.
   */
  const onChangeOption = (item: IF.COMM.ValueNameIF) => {
    setSelection(item)
    const option = isEqual(item, RS.COMM.selectIcon) ? 2 : 1
    setMenuBar({ ...menuBar, iconOption: option })
  }

  /**
   * Update the avatar image.
   * @param image string.
   * @returns void.
   */
  const handlePreviewImage = (image: string) => {
    setMenuBar({ ...menuBar, iconImage: image, iconOption: 1 })
    setFileName(EM.COMM.FILENAME.NOTAVAILABLE)
    setFileObject('')
  }

  /**
   * Update the avatar image URL.
   * @param url string.
   * @returns void.
   */
  const handlePreviewURL = (url: string) => {
    setMenuBar({ ...menuBar, iconImage: url, iconOption: 1 })
    setFileName(EM.COMM.FILENAME.NOTAVAILABLE)
    setFileObject('')
  }

  /**
   * Update the avatar image upload file.
   * @param event React change event for the HTML input element.
   * @returns void.
   */
  const handlePreviewUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newMenuBar = { ...menuBar }
    if (event.target.files![0] === undefined) return
    if (event.target.files![0].size > RS.COMM.size5MB) {
      setMenuBar(snapShot)
      setFileName(EM.COMM.FILENAME.NOTAVAILABLE)
      setFileObject('')
    }
    const imageReader = new FileReader()
    imageReader.onload = (event) => {
      setMenuBar({ ...newMenuBar, iconImage: event!.target!.result as string, iconOption: 1 })
    }
    imageReader.readAsDataURL(event.target.files![0])
    setFileName(event.target.files![0].name)
    setFileObject(event.target.files![0])
  }

  /**
   * Update the menu icon color.
   * @param color menu icon color.
   * @returns void.
   */
  const handlePreviewColor = (color: string) => {
    setMenuBar({ ...menuBar, iconColor: color, iconOption: 2 })
    setFileName(EM.COMM.FILENAME.NOTAVAILABLE)
    setFileObject('')
  }

  /**
   * Update the menu icon size.
   * @param size menu icon size.
   * @returns void.
   */
  const handlePreviewSize = (size: number) => {
    setMenuBar({ ...menuBar, iconSize: size })
    setFileName(EM.COMM.FILENAME.NOTAVAILABLE)
    setFileObject('')
  }

  /**
   * Update the icon for the menu icon.
   * @param icon icon selection.
   * @returns void.
   */
  const handlePreviewIcon = (icon: string) => {
    setMenuBar({ ...menuBar, icon: icon, iconOption: 2 })
    setFileName(EM.COMM.FILENAME.NOTAVAILABLE)
    setFileObject('')
  }

  /**
   * Update the menu history icon.
   * @param value menu history icon.
   * @param option icon option.
   * @returns void.
   */
  const handlePreviewHistoryIcon = (value: string, option: number) => {
    if (option === 1) setMenuBar({ ...menuBar, iconImage: value, iconOption: 1 })
    if (option === 2) setMenuBar({ ...menuBar, icon: value, iconOption: 2 })
    setFileName(EM.COMM.FILENAME.NOTAVAILABLE)
    setFileObject('')
  }

  return (
    <div className={style.editorPageTwo}>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.NAVG.LABEL.ICON} />
        <EditorImage mainImage={mainImage} />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.NAVG.LABEL.OPTION} />
        <EditorDropDown
          span={18}
          loading={loading}
          sourceList={RS.COMM.selectionIcon}
          selection={selection}
          saveStyle={saveStyle}
          onChangeItem={onChangeOption}
        />
      </Row>
      <Row className={style.editorRow} style={showPickerRow}>
        <EditorImagePicker
          type={EM.COMM.IMAGETYPE.MENUICON}
          images={RS.CTRL.orbsList}
          selection={selection}
          imageRef={imageRef}
          saveStyle={saveStyle}
          fileName={fileName}
          uploading={uploading}
          uploadBar={uploadBar}
          loading={loading}
          setUploading={setUploading}
          setUploadbar={setUploadbar}
          handlePreviewImage={handlePreviewImage}
          handlePreviewURL={handlePreviewURL}
          handlePreviewUpload={handlePreviewUpload}
        />
        <EditorAntIconPicker
          color={menuBar.iconColor}
          selection={selection}
          iconList={RS.NAVG.menuIconList}
          handlePreviewIcon={handlePreviewIcon}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.NAVG.LABEL.ICONSIZE} />
        <EditorSlider
          min={1}
          max={150}
          span={18}
          value={control.size}
          width={RS.COMM.width90}
          handleAttribute={handlePreviewSize}
        />
      </Row>
      <Row className={style.editorRow} style={showIconRow}>
        <EditorLabel span={6} label={EM.NAVG.LABEL.ICONCOLOR} />
        <EditorHuePicker
          span={18}
          width={'70%'}
          color={menuBar.iconColor}
          undoStyle={undoStyle}
          handlePreviewColor={handlePreviewColor}
        />
      </Row>
      <Row className={style.editorRowHistory}>
        <EditorHistoryLabel history={menuBar.iconHistory} />
        <EditorHistoryIconPicker
          color={menuBar.iconColor}
          history={menuBar.iconHistory}
          handlePreviewHistoryIcon={handlePreviewHistoryIcon}
        />
      </Row>
    </div>
  )
}

export default IconSetting
