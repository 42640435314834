import {
  AppstoreTwoTone,
  ControlTwoTone,
  SnippetsTwoTone,
  PieChartTwoTone,
  FileTextTwoTone,
  ProjectTwoTone,
  ReconciliationTwoTone,
  RocketTwoTone,
  SettingTwoTone,
  StarTwoTone,
  TrophyTwoTone,
  ToolTwoTone,
} from '@ant-design/icons'
import style from '../assets/editor.module.scss'
import { IF } from '../components/imports'

export const control: IF.CTRL.ControlIF = {
  id: 0,
  top: -200,
  left: -200,
  type: 'background',
  index: 0,
  option: 2,
  image: 'https://havkonstudio-publicfiles.s3.us-west-2.amazonaws.com/havkonstudio/home_orb0.gif',
  icon: 'ControlTwoTone',
  color: 'rgb(249, 102, 102)',
  size: 40,
  page: 1,
  element: null,
  showSave: false,
  showPanel: false,
  showDrawer: false,
  hoverSave: false,
  savingPose: false,
  history: [
    {
      value: 'https://havkonstudio-publicfiles.s3.us-west-2.amazonaws.com/havkonstudio/home_orb0.gif',
      option: 1,
    },
    {
      value: 'FileTextTwoTone',
      option: 2,
    },
  ],
}

export const panelPosition: IF.CTRL.PositionIF = {
  top: '50px',
  left: '50px',
}

export const orbsList: IF.COMM.ImageIF[] = [
  { url: 'https://havkonstudio-publicfiles.s3.us-west-2.amazonaws.com/havkonstudio/home_orb0.gif', order: 1 },
  { url: 'https://havkonstudio-publicfiles.s3.us-west-2.amazonaws.com/havkonstudio/home_orb1.gif', order: 2 },
  { url: 'https://havkonstudio-publicfiles.s3.us-west-2.amazonaws.com/havkonstudio/home_orb2.gif', order: 3 },
  { url: 'https://havkonstudio-publicfiles.s3.us-west-2.amazonaws.com/havkonstudio/home_orb3.gif', order: 4 },
  { url: 'https://havkonstudio-publicfiles.s3.us-west-2.amazonaws.com/havkonstudio/home_orb4.gif', order: 5 },
  { url: 'https://havkonstudio-publicfiles.s3.us-west-2.amazonaws.com/havkonstudio/home_orb5.gif', order: 6 },
  { url: 'https://havkonstudio-publicfiles.s3.us-west-2.amazonaws.com/havkonstudio/home_orb6.gif', order: 7 },
  { url: 'https://havkonstudio-publicfiles.s3.us-west-2.amazonaws.com/havkonstudio/home_orb7.gif', order: 8 },
  { url: 'https://havkonstudio-publicfiles.s3.us-west-2.amazonaws.com/havkonstudio/home_orb8.gif', order: 9 },
  { url: 'https://havkonstudio-publicfiles.s3.us-west-2.amazonaws.com/havkonstudio/home_orb9.gif', order: 10 },
  { url: 'https://havkonstudio-publicfiles.s3.us-west-2.amazonaws.com/havkonstudio/home_center.gif', order: 11 },
  { url: 'https://havkonstudio-publicfiles.s3.us-west-2.amazonaws.com/havkonstudio/home_menu.gif', order: 12 },
]

export const controlIconList = (color: string) => {
  return [
    { icon: 'AppstoreTwoTone', jsx: <AppstoreTwoTone className={style.pickerIconTT} twoToneColor={color} /> },
    { icon: 'ControlTwoTone', jsx: <ControlTwoTone className={style.pickerIconTT} twoToneColor={color} /> },
    { icon: 'FileTextTwoTone', jsx: <FileTextTwoTone className={style.pickerIconTT} twoToneColor={color} /> },
    { icon: 'PieChartTwoTone', jsx: <PieChartTwoTone className={style.pickerIconTT} twoToneColor={color} /> },
    { icon: 'ProjectTwoTone', jsx: <ProjectTwoTone className={style.pickerIconTT} twoToneColor={color} /> },
    { icon: 'Reconciliation', jsx: <ReconciliationTwoTone className={style.pickerIconTT} twoToneColor={color} /> },
    { icon: 'RocketTwoTone', jsx: <RocketTwoTone className={style.pickerIconTT} twoToneColor={color} /> },
    { icon: 'SettingTwoTone', jsx: <SettingTwoTone className={style.pickerIconTT} twoToneColor={color} /> },
    { icon: 'SnippetsTwoTone', jsx: <SnippetsTwoTone className={style.pickerIconTT} twoToneColor={color} /> },
    { icon: 'StarTwoTone', jsx: <StarTwoTone className={style.pickerIconTT} twoToneColor={color} /> },
    { icon: 'TrophyTwoTone', jsx: <TrophyTwoTone className={style.pickerIconTT} twoToneColor={color} /> },
    { icon: 'ToolTwoTone', jsx: <ToolTwoTone className={style.pickerIconTT} twoToneColor={color} /> },
  ]
}

export const controlSnap: IF.STAT.ControlStateIF = {
  controlSnap: control,
}
