export enum URL {
    ORDER = '/api/talent/module/order/',
    FRAME = '/api/talent/module/frame/',
    POSITION = '/api/talent/module/position/',
    STATUS = '/api/talent/module/status/'
}

export enum TYPE {
    INTRODUCTION = 'introduction',
    ACTIVE = 'active',
    DELETED = 'deleted',
}

export enum EDITOR {
    TITLE = 'Module Title',
    DISPLAY = 'Module Display',
    STATUS = 'Module Status',
    ORDER = 'Module Order',
}

export enum FORM {
    SHOW = 'Show Module',
    HIDE = 'Hide Module',
    ACTIVE = 'Active',
    DELETED = 'Deleted',
}