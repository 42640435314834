import React from 'react'
import { request } from '../../utils/request'
import { EM, IF } from '../../components/imports'
import { authStart, authFail, authSuccess, authUpdateNAVGSnapshot, authUpdateMENUSnapshot } from './auth'
import _ from 'lodash'

const getIput = (selection: IF.COMM.ValueNameIF, naviBar: IF.NAVG.NaviBarIF, menuBar: IF.NAVG.MenuBarIF) => {
  let input = {}
  let url = ''
  if (selection.name === EM.NAVG.SETTING.NAVITOP) {
    input = {
      id: naviBar.id,
      widthOne: naviBar.widthOne,
      heightOne: naviBar.heightOne,
      sizeOne: naviBar.sizeOne,
      textOne: naviBar.textOne,
      colorOne: naviBar.colorOne,
      imageOne: naviBar.imageOne,
      fontOne: naviBar.fontOne,
      weightOne: naviBar.weightOne,
      optionOne: naviBar.optionOne,
      positionOne: naviBar.positionOne,
    }
    url = EM.NAVG.URL.NAVITOP
  } else if (selection.name === EM.NAVG.SETTING.NAVIBOT) {
    input = {
      id: naviBar.id,
      widthTwo: naviBar.widthTwo,
      heightTwo: naviBar.heightTwo,
      sizeTwo: naviBar.sizeTwo,
      textTwo: naviBar.textTwo,
      colorTwo: naviBar.colorTwo,
      imageTwo: naviBar.imageTwo,
      fontTwo: naviBar.fontTwo,
      weightTwo: naviBar.weightTwo,
      optionTwo: naviBar.optionTwo,
      positionTwo: naviBar.positionTwo,
    }
    url = EM.NAVG.URL.NAVIBOT
  } else if (selection.name === EM.NAVG.SETTING.MENU) {
    input = {
      id: menuBar.id,
      width: menuBar.width,
      image: menuBar.image,
      color: menuBar.color,
      position: menuBar.position,
      title: menuBar.title,
      titleSize: menuBar.titleSize,
      titleColor: menuBar.titleColor,
      titleFont: menuBar.titleFont,
      itemSize: menuBar.itemSize,
      itemColor: menuBar.itemColor,
      itemFont: menuBar.itemFont,
      option: menuBar.option,
    }
    url = EM.NAVG.URL.MENU
  } else if (selection.name === EM.NAVG.SETTING.ICON) {
    input = {
      id: menuBar.id,
      icon: menuBar.icon,
      iconImage: menuBar.iconImage,
      iconSize: menuBar.iconSize,
      iconColor: menuBar.iconColor,
      iconOption: menuBar.iconOption,
    }
    url = EM.NAVG.URL.ICON
  } else if (selection.name === EM.NAVG.SETTING.SYNC) {
    input = { id: naviBar.id, sync: naviBar.sync }
    url = EM.NAVG.URL.SYNC
  }
  return { input, url }
}

const getFormData = (selection: IF.COMM.ValueNameIF, naviBar: IF.NAVG.NaviBarIF, menuBar: IF.NAVG.MenuBarIF) => {
  let url = ''
  const formData = new FormData()
  if (selection.name === EM.NAVG.SETTING.NAVITOP) {
    formData.append('id', naviBar.id.toString())
    formData.append('widthOne', naviBar.widthOne.toString())
    formData.append('heightOne', naviBar.heightOne.toString())
    formData.append('sizeOne', naviBar.sizeOne.toString())
    formData.append('textOne', naviBar.textOne.toString())
    formData.append('colorOne', naviBar.colorOne.toString())
    formData.append('imageOne', naviBar.imageOne.toString())
    formData.append('fontOne', naviBar.fontOne.toString())
    formData.append('weightOne', naviBar.weightOne.toString())
    formData.append('optionOne', naviBar.optionOne.toString())
    formData.append('positionOne', naviBar.positionOne.toString())
    url = EM.NAVG.URL.NAVITOP
  } else if (selection.name === EM.NAVG.SETTING.NAVIBOT) {
    formData.append('id', naviBar.id.toString())
    formData.append('widthTwo', naviBar.widthTwo.toString())
    formData.append('heightTwo', naviBar.heightTwo.toString())
    formData.append('sizeTwo', naviBar.sizeTwo.toString())
    formData.append('textTwo', naviBar.textTwo.toString())
    formData.append('colorTwo', naviBar.colorTwo.toString())
    formData.append('imageTwo', naviBar.imageTwo.toString())
    formData.append('fontTwo', naviBar.fontTwo.toString())
    formData.append('weightTwo', naviBar.weightTwo.toString())
    formData.append('optionTwo', naviBar.optionTwo.toString())
    formData.append('positionTwo', naviBar.positionTwo.toString())
    url = EM.NAVG.URL.NAVIBOT
  } else if (selection.name === EM.NAVG.SETTING.MENU) {
    formData.append('id', menuBar.id.toString())
    formData.append('width', menuBar.width.toString())
    formData.append('image', menuBar.image.toString())
    formData.append('color', menuBar.color.toString())
    formData.append('position', menuBar.position.toString())
    formData.append('title', menuBar.title.toString())
    formData.append('titleSize', menuBar.titleSize.toString())
    formData.append('titleColor', menuBar.titleColor.toString())
    formData.append('titleFont', menuBar.titleFont.toString())
    formData.append('itemSize', menuBar.itemSize.toString())
    formData.append('itemColor', menuBar.itemColor.toString())
    formData.append('itemFont', menuBar.itemFont.toString())
    formData.append('option', menuBar.option.toString())
    url = EM.NAVG.URL.MENU
  } else if (selection.name === EM.NAVG.SETTING.ICON) {
    formData.append('id', menuBar.id.toString())
    formData.append('icon', menuBar.icon.toString())
    formData.append('iconImage', menuBar.iconImage.toString())
    formData.append('iconSize', menuBar.iconSize.toString())
    formData.append('iconColor', menuBar.iconColor.toString())
    formData.append('iconOption', menuBar.iconOption.toString())
    url = EM.NAVG.URL.ICON
  }
  return { formData, url }
}

export const updateNavigation = async (
  auth: IF.AUTH.AuthIF,
  dispatch: any,
  naviBar: IF.NAVG.NaviBarIF,
  menuBar: IF.NAVG.MenuBarIF,
  selection: IF.COMM.ValueNameIF,
  NAVGSnapshot: IF.NAVG.NaviBarIF,
  MENUSnapshot: IF.NAVG.MenuBarIF,
  setNaviBar: React.Dispatch<React.SetStateAction<IF.NAVG.NaviBarIF>>,
  setMenuBar: React.Dispatch<React.SetStateAction<IF.NAVG.MenuBarIF>>
) => {
  authStart(auth, dispatch)
  const inputData = getIput(selection, naviBar, menuBar)
  const input = inputData.input
  const url = inputData.url
  try {
    const response = await request(url, auth.token, input, EM.COMM.APIMETHOD.PATCH)
    const data = response.data
    if (
      selection.name === EM.NAVG.SETTING.NAVITOP ||
      selection.name === EM.NAVG.SETTING.NAVIBOT ||
      selection.name === EM.NAVG.SETTING.SYNC
    ) {
      const result = _.merge({}, NAVGSnapshot, data)
      authUpdateNAVGSnapshot(result, dispatch)
      setNaviBar(result)
    } else {
      const result = _.merge({}, MENUSnapshot, data)
      authUpdateMENUSnapshot(result, dispatch)
      setMenuBar(result)
    }
    authSuccess(auth, dispatch)
  } catch (error: any) {
    authFail(auth, dispatch, error.response.data)
    return
  }
}

export const updateNavImages = async (
  auth: IF.AUTH.AuthIF,
  dispatch: any,
  naviBar: IF.NAVG.NaviBarIF,
  menuBar: IF.NAVG.MenuBarIF,
  selection: IF.COMM.ValueNameIF,
  NAVGSnapshot: IF.NAVG.NaviBarIF,
  MENUSnapshot: IF.NAVG.MenuBarIF,
  fileObject: string | File,
  setNaviBar: React.Dispatch<React.SetStateAction<IF.NAVG.NaviBarIF>>,
  setMenuBar: React.Dispatch<React.SetStateAction<IF.NAVG.MenuBarIF>>,
  setFileObject: React.Dispatch<React.SetStateAction<string | File>>
) => {
  authStart(auth, dispatch)
  const inputData = getFormData(selection, naviBar, menuBar)
  const formData = inputData.formData
  const url = inputData.url
  formData.append('session', auth.session!.split(':')[2])
  formData.append('file', fileObject)
  try {
    const response = await request(url, auth.token, formData, EM.COMM.APIMETHOD.PUT)
    const data = response.data
    if (selection.name === EM.NAVG.SETTING.NAVITOP || selection.name === EM.NAVG.SETTING.NAVIBOT) {
      const result = _.merge({}, NAVGSnapshot, data)
      authUpdateNAVGSnapshot(result, dispatch)
      setNaviBar(result)
    } else {
      const result = _.merge({}, MENUSnapshot, data)
      authUpdateMENUSnapshot(result, dispatch)
      setMenuBar(result)
    }
    authSuccess(auth, dispatch)
    setFileObject('')
  } catch (error: any) {
    authFail(auth, dispatch, error.response.data)
    return
  }
}
