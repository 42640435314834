import { EM } from '../imports'
import FrameSetting from '../Modules/FrameSetting'
import AvatarSetting from '../Introduction/AvatarSetting'
import PresetSetting from '../Introduction/PresetSetting'
import CustomSetting from '../Introduction/CustomSetting'
import OrderSetting from '../Introduction/OrderSetting'
import ModuleSetting from '../Modules/ModuleSetting'
import TextSetting from '../Introduction/TextSetting'
import ColorSetting from '../Introduction/ColorSetting'
import FontSetting from '../Introduction/FontSetting'
import SizeSetting from '../Introduction/SizeSetting'

const IntroductionEditor = (props: any) => {
  /** Properties */
  const {
    loading,
    control,
    module,
    modules,
    snapShot,
    saveStyle,
    undoStyle,
    uploading,
    uploadBar,
    selection,
    setControl,
    setModules,
    setUploading,
    setUploadbar,
    setFileObject,
  } = props

  return (
    <>
      {selection.name === EM.INTR.SETTING.FRAME && (
        <FrameSetting
          loading={loading}
          frame={module.frame}
          control={control}
          module={module}
          modules={modules}
          snapShot={snapShot}
          saveStyle={saveStyle}
          undoStyle={undoStyle}
          uploading={uploading}
          uploadBar={uploadBar}
          setModules={setModules}
          setUploading={setUploading}
          setUploadbar={setUploadbar}
          setFileObject={setFileObject}
        />
      )}
      {selection.name === EM.INTR.SETTING.AVATAR && (
        <AvatarSetting
          loading={loading}
          avatar={module.value.introduction!.avatar}
          control={control}
          module={module}
          modules={modules}
          snapShot={snapShot}
          saveStyle={saveStyle}
          undoStyle={undoStyle}
          uploading={uploading}
          uploadBar={uploadBar}
          setModules={setModules}
          setUploadbar={setUploadbar}
          setUploading={setUploading}
          setFileObject={setFileObject}
        />
      )}
      {selection.name === EM.INTR.SETTING.PRESET && (
        <PresetSetting
          loading={loading}
          intro={module.value.introduction!}
          control={control}
          module={module}
          modules={modules}
          saveStyle={saveStyle}
          undoStyle={undoStyle}
          setModules={setModules}
        />
      )}
      {selection.name === EM.INTR.SETTING.CUSTOM && (
        <CustomSetting
          loading={loading}
          intro={module.value.introduction!}
          snapShot={snapShot}
          control={control}
          module={module}
          modules={modules}
          saveStyle={saveStyle}
          undoStyle={undoStyle}
          setModules={setModules}
        />
      )}
      {selection.name === EM.INTR.SETTING.ORDER && (
        <OrderSetting
          control={control}
          order={module.value.introduction!.order}
          intro={module.value.introduction!}
          module={module}
          modules={modules}
          setModules={setModules}
        />
      )}
      {selection.name === EM.INTR.SETTING.MODULE && (
        <ModuleSetting
          control={control}
          module={module}
          modules={modules}
          undoStyle={undoStyle}
          setControl={setControl}
          setModules={setModules}
        />
      )}
      {selection.name === EM.INTR.SETTING.TEXT && (
        <TextSetting
          intro={module.value.introduction!}
          control={control}
          module={module}
          modules={modules}
          setModules={setModules}
        />
      )}
      {selection.name === EM.INTR.SETTING.COLOR && (
        <ColorSetting
          intro={module.value.introduction!}
          content={module.value.introduction!.content}
          control={control}
          undoStyle={undoStyle}
          module={module}
          modules={modules}
          setModules={setModules}
        />
      )}
      {selection.name === EM.INTR.SETTING.FONT && (
        <FontSetting
          intro={module.value.introduction!}
          snapShot={snapShot.value.introduction!}
          control={control}
          module={module}
          modules={modules}
          setModules={setModules}
        />
      )}
      {selection.name === EM.INTR.SETTING.SIZE && (
        <SizeSetting
          intro={module.value.introduction!}
          control={control}
          module={module}
          modules={modules}
          setModules={setModules}
        />
      )}
    </>
  )
}

export default IntroductionEditor
