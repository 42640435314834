import React from 'react'
import {
  AppstoreTwoTone,
  ControlTwoTone,
  SnippetsTwoTone,
  PieChartTwoTone,
  FileTextTwoTone,
  ProjectTwoTone,
  ReconciliationTwoTone,
  RocketTwoTone,
  SettingTwoTone,
  StarTwoTone,
  TrophyTwoTone,
  ToolTwoTone,
} from '@ant-design/icons'
import { EM, IF, RX } from '../imports'

export const controlTitle = (order: number, title: string, type: EM.CTRL.TYPE) => {
  const getTitle = title.slice(0, 10)
  if (title) return title.length > 13 ? `${getTitle}...` : title
  return `${type} - ${order}`
}

export const handleTitle = (control: IF.CTRL.ControlIF, hover: boolean, e: any) => {
  if (hover) {
    const color = control.color.replace(')', ',0)')
    e.target.style.backgroundColor = color
    e.target.style.color = EM.COMM.COLOR.WHITE
    e.target.style.borderColor = control.color
  } else {
    e.target.style.backgroundColor = EM.COMM.COLOR.NONE
    e.target.style.color = EM.COMM.COLOR.BLACK
    e.target.style.borderColor = EM.COMM.COLOR.NONE
  }
}

export const handleHover = (
  index: number,
  type: string,
  hover: boolean,
  backgrounds: IF.BKGD.BackgroundIF[],
  modules: IF.STAT.ModuleIF[],
  setModules: React.Dispatch<React.SetStateAction<IF.STAT.ModuleIF[]>>,
  setBackgrounds: React.Dispatch<React.SetStateAction<IF.BKGD.BackgroundIF[]>>
) => {
  if (type === EM.CTRL.TYPE.BACKGROUND) {
    setBackgrounds([
      ...backgrounds.slice(0, index),
      { ...backgrounds[index], hover: hover },
      ...backgrounds.slice(index + 1),
    ])
  } else {
    setModules([...modules.slice(0, index), { ...modules[index], hover: hover }, ...modules.slice(index + 1)])
  }
}

export const handleHoverModule = (
  index: number,
  hover: boolean,
  modules: IF.STAT.ModuleIF[],
  setModules: React.Dispatch<React.SetStateAction<IF.STAT.ModuleIF[]>>
) => {
  setModules([...modules.slice(0, index), { ...modules[index], hover: hover }, ...modules.slice(index + 1)])
}

export const compareMDOrder = (MODLSnapshot: IF.STAT.ModuleIF[], modules: IF.STAT.ModuleIF[]): boolean => {
  for (let i = 0; i < MODLSnapshot.length; i++) {
    if (MODLSnapshot[i].id !== modules[i].id) return true
  }
  return false
}

export const compareBGOrder = (BKGDSnapshot: IF.BKGD.BackgroundIF[], backgrounds: IF.BKGD.BackgroundIF[]): boolean => {
  for (let i = 0; i < BKGDSnapshot.length; i++) {
    if (BKGDSnapshot[i].id !== backgrounds[i].id) return true
  }
  return false
}

export const allowDropBG = (ev: any, dragType: string) => {
  if (dragType === EM.CTRL.TYPE.BACKGROUND) ev.preventDefault()
}

export const allowDropMD = (ev: any, dragType: string) => {
  if (dragType === EM.CTRL.TYPE.MODULE) ev.preventDefault()
}

export const dragElement = (
  control: IF.CTRL.ControlIF,
  setControl: React.Dispatch<React.SetStateAction<IF.CTRL.ControlIF>>,
  setShowEdit: React.Dispatch<React.SetStateAction<IF.COMM.DisplayIF>>
) => {
  let pos1 = 0,
    pos2 = 0,
    pos3 = 0,
    pos4 = 0,
    posx = 0,
    posy = 0,
    top = 0,
    left = 0
  /* if present, the header is where you move the DIV from:*/
  if (control.element) control.element.onmousedown = dragMouseDown
  function dragMouseDown(e: any) {
    e = e || window.event
    e.preventDefault()
    // get the mouse cursor position at startup:
    pos3 = e.clientX
    pos4 = e.clientY
    posx = e.clientX
    posy = e.clientY
    document.onmouseup = closeDragElement
    document.onmousemove = elementDrag
  }

  function elementDrag(e: any) {
    e = e || window.event
    e.preventDefault()
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX
    pos2 = pos4 - e.clientY
    pos3 = e.clientX
    pos4 = e.clientY
    top = control.element.offsetTop - pos2
    left = control.element.offsetLeft - pos1
    // set the element's new position:
    setControl({
      ...control,
      top: top,
      left: left,
      showSave: false,
      showPanel: false,
      showDrawer: false,
    })
  }

  function closeDragElement() {
    /* stop moving when mouse button is released:*/
    document.onmouseup = null
    document.onmousemove = null
    if (posx !== pos3 || posy !== pos4) {
      setControl({
        ...control,
        top: top,
        left: left,
        showSave: true,
        showPanel: false,
        showDrawer: false,
      })
    } else {
      setControl({
        ...control,
        showSave: false,
        showPanel: !control.showPanel,
        showDrawer: false,
      })
    }
    setShowEdit({ display: 'none' })
  }
}

export const dragModule = (
  index: number,
  type: string,
  setDragIndex: React.Dispatch<React.SetStateAction<number>>,
  setDragType: React.Dispatch<React.SetStateAction<string>>
) => {
  setDragIndex(index)
  setDragType(type)
}

export const dropBackground = (
  ev: any,
  style: any,
  dropIndex: number,
  dragIndex: number,
  dragType: string,
  backgrounds: IF.BKGD.BackgroundIF[],
  setBackgrounds: React.Dispatch<React.SetStateAction<IF.BKGD.BackgroundIF[]>>
) => {
  ev.preventDefault()
  if (dragIndex !== dropIndex && dragType === EM.CTRL.TYPE.BACKGROUND) {
    const dropOrder = backgrounds[dropIndex].order
    const dragOrder = backgrounds[dragIndex].order
    const dropBackground = { ...backgrounds[dropIndex], order: dragOrder }
    const dragBackground = { ...backgrounds[dragIndex], order: dropOrder }

    const nowBackgrounds = [...backgrounds.slice(0, dropIndex), dragBackground, ...backgrounds.slice(dropIndex + 1)]
    const newBackgrounds = [
      ...nowBackgrounds.slice(0, dragIndex),
      dropBackground,
      ...nowBackgrounds.slice(dragIndex + 1),
    ]
    setBackgrounds(newBackgrounds)
    // const titleCls = document.getElementsByClassName(style.controlListTitle)
    // if (titleCls.length > 0) {
    //     const titles = Array.from(titleCls as HTMLCollectionOf<HTMLElement>)
    //     titles.forEach(item => {
    //         item.style.backgroundColor = EM.COMM.COLOR.WHITE
    //         item.style.color = EM.COMM.COLOR.BLACK
    //     })
    // }
  }
}

export const dropModule = (
  ev: any,
  style: any,
  dropIndex: number,
  dragIndex: number,
  dragType: string,
  modules: IF.STAT.ModuleIF[],
  setModules: React.Dispatch<React.SetStateAction<IF.STAT.ModuleIF[]>>
) => {
  ev.preventDefault()
  if (dragIndex !== dropIndex && dragType === EM.CTRL.TYPE.MODULE) {
    const dropTop = modules[dropIndex].frame!.top
    const dropLeft = modules[dropIndex].frame!.left
    const dragTop = modules[dragIndex].frame!.top
    const dragLeft = modules[dragIndex].frame!.left

    const dropModule = {
      ...modules[dropIndex],
      order: modules[dragIndex].order,
      frame: { ...modules[dropIndex].frame, top: dragTop, left: dragLeft },
    }
    const dragModule = {
      ...modules[dragIndex],
      order: modules[dropIndex].order,
      frame: { ...modules[dragIndex].frame, top: dropTop, left: dropLeft },
    }
    const nowModules = [...modules.slice(0, dropIndex), dragModule, ...modules.slice(dropIndex + 1)]
    const newModules = [...nowModules.slice(0, dragIndex), dropModule, ...nowModules.slice(dragIndex + 1)]

    setModules(newModules)
    // const titleCls = document.getElementsByClassName(style.controlListTitle)
    // if (titleCls.length > 0) {
    //     const titles = Array.from(titleCls as HTMLCollectionOf<HTMLElement>)
    //     titles.forEach(item => {
    //         item.style.backgroundColor = EM.COMM.COLOR.WHITE
    //         item.style.color = EM.COMM.COLOR.BLACK
    //     })
    // }
  }
}

export const saveBKGDOrder = (auth: IF.AUTH.AuthIF, dispatch: any, backgrounds: IF.BKGD.BackgroundIF[]) => {
  RX.BKGD.updateOrder(auth, dispatch, backgrounds)
}

export const saveMODLOrder = (auth: IF.AUTH.AuthIF, dispatch: any, modules: IF.STAT.ModuleIF[]) => {
  RX.MODL.updateOrder(auth, dispatch, modules)
}

export const undoOrder = (
  MODLSnapshot: IF.STAT.ModuleIF[],
  setModules: React.Dispatch<React.SetStateAction<IF.STAT.ModuleIF[]>>
) => {
  setModules(MODLSnapshot)
}

export const positionPanel = (
  control: IF.CTRL.ControlIF,
  controlPanelRef: any,
  setPanelPosition: React.Dispatch<React.SetStateAction<IF.CTRL.PositionIF>>
) => {
  let top = control.top - controlPanelRef.current.clientHeight / 2 + controlPanelRef.current.clientHeight / (2 * 4) + 20
  let left = control.left + control.size
  // top
  if (top / window.innerHeight < 0.1 || top - controlPanelRef.current.clientHeight / 2 < 0) {
    top = control.top - 10
  }
  // bottom
  if (
    (top + controlPanelRef.current.clientHeight) / window.innerHeight > 0.9 ||
    top + controlPanelRef.current.clientHeight > window.innerHeight
  ) {
    top = control.top - controlPanelRef.current.clientHeight + control.size + 10
  }
  if (control.left / window.innerWidth > 0.8 || control.left + 280 > window.innerWidth) {
    left = control.left - controlPanelRef.current.clientWidth
  }
  setPanelPosition({ top: `${top}px`, left: `${left}px` })
}

export const handleControlIcon = (control: IF.CTRL.ControlIF) => {
  const iconObject: any = {
    AppstoreTwoTone: <AppstoreTwoTone twoToneColor={control.color} style={{ fontSize: `${control.size}px` }} />,
    ControlTwoTone: <ControlTwoTone twoToneColor={control.color} style={{ fontSize: `${control.size}px` }} />,
    FileTextTwoTone: <FileTextTwoTone twoToneColor={control.color} style={{ fontSize: `${control.size}px` }} />,
    PieChartTwoTone: <PieChartTwoTone twoToneColor={control.color} style={{ fontSize: `${control.size}px` }} />,
    ProjectTwoTone: <ProjectTwoTone twoToneColor={control.color} style={{ fontSize: `${control.size}px` }} />,
    Reconciliation: <ReconciliationTwoTone twoToneColor={control.color} style={{ fontSize: `${control.size}px` }} />,
    RocketTwoTone: <RocketTwoTone twoToneColor={control.color} style={{ fontSize: `${control.size}px` }} />,
    SettingTwoTone: <SettingTwoTone twoToneColor={control.color} style={{ fontSize: `${control.size}px` }} />,
    SnippetsTwoTone: <SnippetsTwoTone twoToneColor={control.color} style={{ fontSize: `${control.size}px` }} />,
    StarTwoTone: <StarTwoTone twoToneColor={control.color} style={{ fontSize: `${control.size}px` }} />,
    TrophyTwoTone: <TrophyTwoTone twoToneColor={control.color} style={{ fontSize: `${control.size}px` }} />,
    ToolTwoTone: <ToolTwoTone twoToneColor={control.color} style={{ fontSize: `${control.size}px` }} />,
  }
  return iconObject[control.icon]
}

// export const handleHistoryPreview = (
//     value: string,
//     option: number,
//     control: IF.CTRL.ControlIF,
//     setControl: React.Dispatch<React.SetStateAction<IF.CTRL.ControlIF>>,
//     setFileObject: React.Dispatch<React.SetStateAction<string | File>>
// ) => {
//     if (option === 1) {
//         setControl({ ...control, image: value, option: 1 })
//     } else if (option === 2) {
//         setControl({ ...control, icon: value, option: 2 })
//     }
//     setFileObject('')
// }

// export const handleControlAttribute = (
//     event: any,
//     attribute: string,
//     control: IF.CTRL.ControlIF,
//     setControl: React.Dispatch<React.SetStateAction<IF.CTRL.ControlIF>>
// ) => {
//     const newBackground = { ...background }
//     if (attribute === EM.COMM.EDITOR.TITLE) {
//         newBackground.title = event.target.value
//     } else if (attribute === EM.COMM.EDITOR.HEIGHT) {
//         newBackground.height = `${event.target.value}vh`
//     }
// }
