import React from 'react'
import style from '../../assets/editor.module.scss'
import { Row, Button } from 'antd'
import { DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons'
import { EditorInput, EditorLabel, EditorParallel, EditorSwitch } from '../Common/EditorParts'
import { EM, IF } from '../imports'


const ModuleSetting = (props: {
    control: IF.CTRL.ControlIF,
    module: IF.STAT.ModuleIF,
    modules: IF.STAT.ModuleIF[],
    undoStyle: React.CSSProperties,
    setControl: React.Dispatch<React.SetStateAction<IF.CTRL.ControlIF>>,
    setModules: React.Dispatch<React.SetStateAction<IF.STAT.ModuleIF[]>>
}) => {
    /** Properties */
    const { control, module, modules, undoStyle, setControl, setModules } = props

    /** Local Variables */
    const switchStyle = { backgroundColor: control.color, width: '120px' }
    const orders = [
        <Button style={undoStyle} size={EM.COMM.BUTTON.SMALL}
            onClick={() => handleAttribute(true, EM.MODL.EDITOR.ORDER)} key='1'
            disabled={module.order === 1}>
            {EM.COMM.BUTTON.MOVEUP} <UpCircleOutlined style={{ fontSize: '16px' }} />
        </Button>,
        <Button style={undoStyle} size={EM.COMM.BUTTON.SMALL}
            onClick={() => handleAttribute(false, EM.MODL.EDITOR.ORDER)} key='2'
            disabled={module.order === modules.length}>
            {EM.COMM.BUTTON.MOVEDN} <DownCircleOutlined style={{ fontSize: '16px' }} />
        </Button>
    ]

    const handleAttribute = (value: boolean | string, attribute: string) => {
        const newModule = { ...module }
        if (attribute === EM.MODL.EDITOR.DISPLAY) {
            const newVal = value as boolean
            newModule.show = newVal
        } else if (attribute === EM.MODL.EDITOR.STATUS) {
            const status = value ? EM.MODL.TYPE.ACTIVE : EM.MODL.TYPE.DELETED
            newModule.status = status
        } else if (attribute === EM.MODL.EDITOR.TITLE) {
            const newVal = value as string
            newModule.title = newVal
        } else if (attribute === EM.MODL.EDITOR.ORDER) {
            let index = control.index
            if (value && module.order === 1) return
            if (!value && module.order === modules.length) return
            const aboveItems = index === 0 ? [] : modules.slice(0, index - 1)
            let oneAbove = modules[index - 1]
            let thisItem = { ...module }
            let oneBelow = modules[index + 1]
            let middleItems = [oneAbove, thisItem, oneBelow]
            const order = thisItem.order
            const belowItems = modules.slice(index + 2)
            const thisLeft = thisItem.frame.left
            let thisTop = thisItem.frame.top
            if (value) {
                const aboveTop = oneAbove.frame.top
                const aboveLeft = oneAbove.frame.left
                oneAbove = { ...oneAbove, order: order + 1, frame: { ...oneAbove.frame, top: thisTop, left: thisLeft } }
                thisItem = { ...thisItem, order: order - 1, frame: { ...thisItem.frame, top: aboveTop, left: aboveLeft } }
                middleItems = [thisItem, oneAbove, oneBelow]
                index -= 1
                thisTop = aboveTop
            } else {
                const belowTop = oneBelow.frame.top
                const belowLeft = oneBelow.frame.left
                oneBelow = { ...oneBelow, order: order - 1, frame: { ...oneBelow.frame, top: thisTop, left: thisLeft } }
                thisItem = { ...thisItem, order: order + 1, frame: { ...thisItem.frame, top: belowTop, left: belowLeft } }
                middleItems = [oneAbove, oneBelow, thisItem]
                index += 1
                thisTop = belowTop
            }
            const newModules = aboveItems.concat(middleItems).concat(belowItems).filter((item: any) => item !== undefined)
            setModules(newModules)
            setControl({ ...control, index: index })
            const height = window.innerHeight * module.frame.height / 400
            const body = document.getElementsByTagName('body')[0]
            body.style.overflowY = EM.COMM.BODY.INITIAL
            setTimeout(() => {
                const html = document.documentElement
                html.scrollTop = thisTop - height
            }, 100)
            setTimeout(() => {
                body.style.overflowY = EM.COMM.BODY.HIDDEN
            }, 200)
            return
        }
        setModules([
            ...modules.slice(0, control.index),
            newModule,
            ...modules.slice(control.index + 1)
        ])
    }

    return <div className={style.editorPageTwo}>
        <div className={style.editorModule}>
            <Row className={style.editorRowSelect}>
                <EditorLabel span={6} label={EM.MODL.EDITOR.TITLE} />
                <EditorInput
                    max={60}
                    span={18}
                    value={module.title}
                    inputType={EM.COMM.TYPE.TEXT}
                    attribute={EM.MODL.EDITOR.TITLE}
                    handleAttribute={handleAttribute} />
            </Row>
            <Row>
                <EditorLabel span={6} label={EM.MODL.EDITOR.ORDER} />
                <EditorParallel span={18} elements={orders} />
            </Row>
            <Row className={style.editorRowSelect}>
                <EditorLabel span={6} label={EM.MODL.EDITOR.DISPLAY} />
                <EditorSwitch
                    span={18}
                    show={module.show}
                    attribute={EM.MODL.EDITOR.DISPLAY}
                    switchStyle={switchStyle}
                    checkedChildren={EM.MODL.FORM.SHOW}
                    unCheckedChildren={EM.MODL.FORM.HIDE}
                    handleAttribute={handleAttribute} />
            </Row>
            <Row className={style.editorRowSelect}>
                <EditorLabel span={6} label={EM.MODL.EDITOR.STATUS} />
                <EditorSwitch
                    span={18}
                    show={module.status === EM.MODL.TYPE.ACTIVE}
                    attribute={EM.MODL.EDITOR.STATUS}
                    switchStyle={switchStyle}
                    checkedChildren={EM.MODL.FORM.ACTIVE}
                    unCheckedChildren={EM.MODL.FORM.DELETED}
                    handleAttribute={handleAttribute} />
            </Row>
        </div>
    </div>
}

export default ModuleSetting