import React from 'react'
import style from '../../assets/editor.module.scss'
import { CirclePicker, ChromePicker, ColorResult } from 'react-color'
import { UT } from '../imports'

/**
 * Editor color picker component to pick colors.
 * @param color color data.
 * @param showColorPicker indicating whether to display the color picker.
 * @param handlePreviewColor callback function to update color preview.
 * @returns editor color picker JSX element component.
 */
const EditorColorPicker: React.FC<{
  color: string
  showColorPicker: boolean
  handlePreviewColor: (color: string) => void
}> = ({ color, showColorPicker, handlePreviewColor }) => {
  const colorStyle = UT.toggleDisplay(showColorPicker)

  const onChangeColor = (value: ColorResult) => {
    const rgba = value.rgb
    const opacity = rgba.a ?? 1
    const color = `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${opacity})`
    handlePreviewColor(color)
  }
  return (
    <div className={style.pickerColorDiv} style={colorStyle}>
      <CirclePicker
        color={color}
        circleSpacing={18}
        width={'175px'}
        onChange={(value: ColorResult) => onChangeColor(value)}
      />
      <br />
      <ChromePicker color={color} onChange={(value: ColorResult) => onChangeColor(value)} />
    </div>
  )
}

export default EditorColorPicker
