import React from 'react'
import style from '../../assets/frame.module.scss'
import {
  FacebookFilled,
  GithubFilled,
  LinkedinFilled,
  TwitterSquareFilled,
  InstagramFilled,
  YoutubeFilled,
  CalendarTwoTone,
  HomeTwoTone,
  MailTwoTone,
  PhoneTwoTone,
} from '@ant-design/icons'
import { BsGlobe2 } from 'react-icons/bs'
import { isEmpty } from 'lodash'
import { countryCodes, canadaCodes, usCodes } from '../../resources/countryCodes'
import { EM, IF, RS } from '../imports'
import { IconMappingIF } from '../../interfaces/introduction'

// Avatar
export const handleAvatarPreview = (
  event: any,
  control: IF.CTRL.ControlIF,
  selection: string,
  module: IF.STAT.ModuleIF,
  modules: IF.STAT.ModuleIF[],
  snapShot: IF.STAT.ModuleIF,
  setModules: React.Dispatch<React.SetStateAction<IF.STAT.ModuleIF[]>>,
  setFileName: React.Dispatch<React.SetStateAction<string>>,
  setFileObject: React.Dispatch<React.SetStateAction<string | File>>
) => {
  const newModule = { ...module }
  let newIntro = { ...module.value.introduction! }
  let newAvatar = newIntro.avatar
  if (selection === EM.COMM.OPTION.UPLOAD) {
    if (event.target.files[0] === undefined) return
    if (event.target.files[0].size > RS.COMM.size5MB) {
      setModules([...modules.slice(0, control.index), snapShot, ...modules.slice(control.index + 1)])
      setFileName(EM.COMM.FILENAME.NOTAVAILABLE)
      setFileObject('')
      return
    }
    const imageReader = new FileReader()
    imageReader.onload = (event) => {
      newAvatar = { ...newAvatar, image: event!.target!.result! as string }
      newIntro = { ...newIntro, avatar: newAvatar }
      newModule.value = { introduction: newIntro }
      setModules([...modules.slice(0, control.index), newModule, ...modules.slice(control.index + 1)])
    }
    imageReader.readAsDataURL(event.target.files[0])
    setFileName(event.target.files[0].name)
    setFileObject(event.target.files[0])
    return
  } else if (selection === EM.COMM.OPTION.IMAGE) {
    newAvatar = { ...newAvatar, image: event.target.src }
  } else if (selection === EM.COMM.OPTION.URL) {
    newAvatar = { ...newAvatar, image: event.target.value }
  } else if (selection === EM.COMM.OPTION.DISABLE) {
    newAvatar = { ...newAvatar, image: event }
  }
  newIntro = { ...newIntro, avatar: newAvatar }
  newModule.value = { introduction: newIntro }
  setModules([...modules.slice(0, control.index), newModule, ...modules.slice(control.index + 1)])
  setFileObject('')
}

export const handleAvatarAttribute = (
  event: any,
  attribute: string,
  control: IF.CTRL.ControlIF,
  module: IF.STAT.ModuleIF,
  modules: IF.STAT.ModuleIF[],
  setModules: React.Dispatch<React.SetStateAction<IF.STAT.ModuleIF[]>>
) => {
  const newModule = { ...module }
  let newIntro = { ...module.value.introduction! }
  let newAvatar = newIntro.avatar
  if (attribute === EM.INTR.AVATAR.SELECT) {
    newAvatar = { ...newAvatar, image: event!.target!.result! as string }
  } else if (attribute == EM.INTR.AVATAR.FRAME) {
    newAvatar = { ...newAvatar, frame: event }
  } else if (attribute == EM.INTR.AVATAR.WIDTH) {
    newAvatar = { ...newAvatar, width: event }
  } else if (attribute == EM.INTR.AVATAR.HEIGHT) {
    newAvatar = { ...newAvatar, height: event }
  } else if (attribute == EM.INTR.AVATAR.HORIZON) {
    newAvatar = { ...newAvatar, horizon: event }
  } else if (attribute == EM.INTR.AVATAR.UPRIGHT) {
    newAvatar = { ...newAvatar, upright: event }
  } else if (attribute === EM.INTR.AVATAR.DISPLAY) {
    newAvatar = { ...newAvatar, show: event ? 'flex' : 'none' }
  } else if (attribute === EM.INTR.AVATAR.OBJECT) {
    newAvatar = { ...newAvatar, fitting: event.toLowerCase() }
  } else if (attribute === EM.INTR.AVATAR.STYLE) {
    newAvatar = { ...newAvatar, style: event, radius: 5 }
    if (event === 2) newAvatar = { ...newAvatar, style: event, radius: 50 }
  } else if (attribute === EM.INTR.AVATAR.RADIUS) {
    newAvatar = { ...newAvatar, radius: event }
  } else if (attribute === EM.INTR.AVATAR.CLIPING) {
    newAvatar = { ...newAvatar, clip: event }
  } else if (attribute === EM.INTR.AVATAR.EFFECT) {
    const index = RS.INTR.effects.findIndex((item: string) => item === event)
    const value = RS.INTR.effectValues[index]
    newAvatar = { ...newAvatar, effect: event, value: value }
  } else if (attribute === EM.INTR.AVATAR.COLOR) {
    newAvatar = { ...newAvatar, color: event }
  } else if (RS.INTR.effects.includes(attribute)) {
    if (attribute === EM.INTR.EFFECT.SHADOW) {
      newAvatar = { ...newAvatar, shadow: event }
    } else {
      newAvatar = { ...newAvatar, value: event }
    }
  }
  newIntro = { ...newIntro, avatar: newAvatar }
  newModule.value = { introduction: newIntro }
  setModules([...modules.slice(0, control.index), newModule, ...modules.slice(control.index + 1)])
}

const returnFilter = (effect: string, value: number) => {
  if (effect === 'none') {
    return 'none'
  } else if (effect === 'blur') {
    return `${effect}(${value}px)`
  } else if (effect === 'saturate') {
    return `${effect}(${value})`
  } else if (effect === 'hue-rotate') {
    return `${effect}(${value}deg)`
  } else {
    return `${effect}(${value}%)`
  }
}

export const updateAvatarStyle = (
  editor: boolean,
  ratio: string | undefined,
  avatar: IF.INTR.IntroAvatarIF,
  setNormalStyle: React.Dispatch<React.SetStateAction<IF.INTR.IntroNormalIF>>,
  setPolygonStyle: React.Dispatch<React.SetStateAction<IF.INTR.IntroPolygonIF>>
) => {
  const width: string = editor ? 200 * Number(ratio) * (avatar.width / 100) + 'px' : ''
  const height: string = editor ? 200 * (avatar.height / 100) + 'px' : ''
  const clip = avatar.clip.split(',')
  const polygon = `polygon(${clip[0]}% ${clip[1]}%, ${clip[2]}% ${clip[3]}%, ${clip[4]}% ${clip[5]}%, ${clip[6]}% ${clip[7]}%)`
  const split = avatar.shadow.split(',')
  const shadow = `drop-shadow(${split[0]}px ${split[1]}px ${split[2]}px ${avatar.color})`
  const filter = avatar.effect === 'drop-shadow' ? shadow : returnFilter(avatar.effect, avatar.value)
  const show = isEmpty(avatar.image) ? EM.COMM.DISPLAY.NONE : avatar.show
  const radius = avatar.style === 2 ? `${avatar.radius}%` : editor ? `${avatar.radius / 3}px` : `${avatar.radius}px`
  setNormalStyle({
    display: show,
    width: editor ? width : `${avatar.width}%`,
    height: editor ? height : `${avatar.height}%`,
    filter: filter,
    objectFit: avatar.fitting,
    borderRadius: radius,
    objectPosition: `${avatar.horizon}% ${avatar.upright}%`,
  })
  setPolygonStyle({
    display: show,
    width: editor ? width : `${avatar.width}%`,
    height: editor ? height : `${avatar.height}%`,
    filter: filter,
    clipPath: polygon,
    objectFit: avatar.fitting,
    borderRadius: radius,
    objectPosition: `${avatar.horizon}% ${avatar.upright}%`,
  })
}

// Biography
export const mainComponent = (index: number, thisObject: IF.INTR.IntroContentIF, intro: IF.INTR.IntroductionIF) => {
  switch (thisObject.meta.dataType) {
    case 1:
      return (
        <div key={index} style={{ display: thisObject.show }}>
          {introTextObject(thisObject, false)}
        </div>
      )
    case 2:
      return (
        <div key={index} style={{ display: thisObject.show }}>
          {introIconObject(thisObject, false)}
        </div>
      )
    case 3:
      return (
        <div key={index} style={{ display: thisObject.show }}>
          {IntroListObject(thisObject, intro)}
        </div>
      )
    default:
      return <div></div>
  }
}

export const introTextObject = (thisObject: IF.INTR.IntroContentIF, fixSize: boolean) => {
  const itemStyle: React.CSSProperties = {
    color: thisObject.color,
    fontFamily: thisObject.font,
    fontWeight: weightObject(thisObject.weight),
    fontSize: fixSize ? '1rem' : thisObject.size,
  }
  let thisText: string | JSX.Element = fixSize ? thisObject.text.slice(0, 20) + '...' : thisObject.text
  if (thisObject.label === EM.INTR.ATTRIBUTE.WEBSITE) thisText = <a href={thisObject.text}>{thisObject.text}</a>
  return <span style={{ ...itemStyle }}>{thisText}</span>
}

export const introIconObject = (thisObject: IF.INTR.IntroContentIF, fixSize: boolean) => {
  const twoToneColor = thisObject.meta.icon?.color
  const iconStyle = {
    color: thisObject.meta.icon?.color,
    fontSize: fixSize ? '1rem' : thisObject.meta.icon?.size,
  }
  const iconMapping: IconMappingIF = {
    CalendarTwoTone: <CalendarTwoTone className={style.introBioIcons} style={iconStyle} twoToneColor={twoToneColor} />,
    MailTwoTone: <MailTwoTone className={style.introBioIcons} style={iconStyle} twoToneColor={twoToneColor} />,
    PhoneTwoTone: <PhoneTwoTone className={style.introBioIcons} style={iconStyle} twoToneColor={twoToneColor} />,
    HomeTwoTone: <HomeTwoTone className={style.introBioIcons} style={iconStyle} twoToneColor={twoToneColor} />,
    BsGlobe2: <BsGlobe2 className={style.introBioIcons} style={iconStyle} />,
  }
  const thisKey = thisObject.meta.icon?.name
  if (thisKey && thisKey in iconMapping) {
    return (
      <div className={style.introRowMultiple} style={{ justifyContent: 'flex-start' }}>
        {iconMapping[thisKey as keyof IconMappingIF]}
        {introTextObject(thisObject, fixSize)}
      </div>
    )
  }
}

export const IntroListObject = (thisObject: IF.INTR.IntroContentIF, intro: IF.INTR.IntroductionIF) => {
  const mediaStyle: React.CSSProperties = {
    color: thisObject.color,
    fontFamily: thisObject.font,
    fontWeight: weightObject(thisObject.weight),
    fontSize: thisObject.size,
  }
  const syncShow = thisObject.meta.syncShow === EM.COMM.LOWER.Y
  const showAll = thisObject.show === EM.COMM.DISPLAY.FLEX
  const twitter = intro.content?.find(
    (item: IF.INTR.IntroContentIF) => item.label === EM.INTR.ATTRIBUTE.TWITTER
  ) as IF.INTR.IntroContentIF
  const facebook = intro.content?.find(
    (item: IF.INTR.IntroContentIF) => item.label === EM.INTR.ATTRIBUTE.FACEBOOK
  ) as IF.INTR.IntroContentIF
  const linkedin = intro.content?.find(
    (item: IF.INTR.IntroContentIF) => item.label === EM.INTR.ATTRIBUTE.LINKEDIN
  ) as IF.INTR.IntroContentIF
  const instagram = intro.content?.find(
    (item: IF.INTR.IntroContentIF) => item.label === EM.INTR.ATTRIBUTE.INSTAGRAM
  ) as IF.INTR.IntroContentIF
  const youtube = intro.content?.find(
    (item: IF.INTR.IntroContentIF) => item.label === EM.INTR.ATTRIBUTE.YOUTUBE
  ) as IF.INTR.IntroContentIF
  const github = intro.content?.find(
    (item: IF.INTR.IntroContentIF) => item.label === EM.INTR.ATTRIBUTE.GITHUB
  ) as IF.INTR.IntroContentIF

  const showTwitter = syncShow ? showAll : twitter.show === EM.COMM.DISPLAY.FLEX
  const showFacebook = syncShow ? showAll : facebook.show === EM.COMM.DISPLAY.FLEX
  const showLinkedIn = syncShow ? showAll : linkedin.show === EM.COMM.DISPLAY.FLEX
  const showInstagram = syncShow ? showAll : instagram.show === EM.COMM.DISPLAY.FLEX
  const showYoutube = syncShow ? showAll : youtube.show === EM.COMM.DISPLAY.FLEX
  const showGithub = syncShow ? showAll : github.show === EM.COMM.DISPLAY.FLEX
  let twitterStyle = mediaStyle
  let facebookStyle = mediaStyle
  let linkedinStyle = mediaStyle
  let instagramStyle = mediaStyle
  let youtubeStyle = mediaStyle
  let githubStyle = mediaStyle
  if (thisObject.meta.syncColor === EM.COMM.LOWER.N) {
    twitterStyle = { ...twitterStyle, color: twitter.color }
    facebookStyle = { ...facebookStyle, color: facebook.color }
    linkedinStyle = { ...linkedinStyle, color: linkedin.color }
    instagramStyle = { ...instagramStyle, color: instagram.color }
    youtubeStyle = { ...youtubeStyle, color: youtube.color }
    githubStyle = { ...githubStyle, color: github.color }
  }
  if (thisObject.meta.syncSize === EM.COMM.LOWER.N) {
    twitterStyle = { ...twitterStyle, fontSize: twitter.size + 'px' }
    facebookStyle = { ...facebookStyle, fontSize: facebook.size + 'px' }
    linkedinStyle = { ...linkedinStyle, fontSize: linkedin.size + 'px' }
    instagramStyle = {
      ...instagramStyle,
      fontSize: instagram.size + 'px',
    }
    youtubeStyle = { ...youtubeStyle, fontSize: youtube.size + 'px' }
    githubStyle = { ...githubStyle, fontSize: github.size + 'px' }
  }
  return (
    <div className={style.introRowMultiple} style={{ justifyContent: 'space-between' }}>
      {!isEmpty(facebook.text) && showFacebook && (
        <div className={style.introIcons}>
          <FacebookFilled style={facebookStyle} onClick={() => window.open(facebook.text)} />
        </div>
      )}
      {!isEmpty(twitter.text) && showTwitter && (
        <div className={style.introIcons}>
          <TwitterSquareFilled style={twitterStyle} onClick={() => window.open(twitter.text)} />
        </div>
      )}
      {!isEmpty(linkedin.text) && showLinkedIn && (
        <div className={style.introIcons}>
          <LinkedinFilled style={linkedinStyle} onClick={() => window.open(linkedin.text)} />
        </div>
      )}
      {!isEmpty(instagram.text) && showInstagram && (
        <div className={style.introIcons}>
          <InstagramFilled style={instagramStyle} onClick={() => window.open(instagram.text)} />
        </div>
      )}
      {!isEmpty(youtube.text) && showYoutube && (
        <div className={style.introIcons}>
          <YoutubeFilled style={youtubeStyle} onClick={() => window.open(youtube.text)} />
        </div>
      )}
      {!isEmpty(github.text) && showGithub && (
        <div className={style.introIcons}>
          <GithubFilled style={githubStyle} onClick={() => window.open(github.text)} />
        </div>
      )}
    </div>
  )
}

export const introIconItem = (thisKey: string, color: string) => {
  const iconMapping: IF.INTR.IconMappingIF = {
    twitter: <TwitterSquareFilled className={style.introEditorIcons} style={{ color: color }} />,
    facebook: <FacebookFilled className={style.introEditorIcons} style={{ color: color }} />,
    linkedin: <LinkedinFilled className={style.introEditorIcons} style={{ color: color }} />,
    instagram: <InstagramFilled className={style.introEditorIcons} style={{ color: color }} />,
    youtube: <YoutubeFilled className={style.introEditorIcons} style={{ color: color }} />,
    github: <GithubFilled className={style.introEditorIcons} style={{ color: color }} />,
  }
  return iconMapping[thisKey as keyof IF.INTR.IconMappingIF]
}

export const weightObject = (weight: number) => {
  if (weight === 1) return 400
  if (weight === 2) return 600
  return 800
}

// Order Setting
export const dropItem = (
  ev: any,
  style: any,
  dropIndex: number,
  dragIndex: number,
  switchMode: boolean,
  control: IF.CTRL.ControlIF,
  module: IF.STAT.ModuleIF,
  modules: IF.STAT.ModuleIF[],
  introOrders: IF.INTR.IntroOrderIF[],
  setModules: React.Dispatch<React.SetStateAction<IF.STAT.ModuleIF[]>>,
  setIntroOrders: React.Dispatch<React.SetStateAction<IF.INTR.IntroOrderIF[]>>
) => {
  ev.preventDefault()
  if (dragIndex == dropIndex) return
  let newOrders: IF.INTR.IntroOrderIF[] = []
  if (switchMode) {
    const dropItem = {
      ...introOrders[dragIndex],
      order: introOrders[dropIndex].order,
    }
    const dragItem = {
      ...introOrders[dropIndex],
      order: introOrders[dragIndex].order,
    }
    const nowItems = [
      ...introOrders.slice(0, dropIndex),
      { ...introOrders[dropIndex], ...dropItem },
      ...introOrders.slice(dropIndex + 1),
    ]
    newOrders = [
      ...nowItems.slice(0, dragIndex),
      { ...nowItems[dragIndex], ...dragItem },
      ...nowItems.slice(dragIndex + 1),
    ]
  } else {
    if (dragIndex < dropIndex) {
      const aboveItems = introOrders.slice(0, dragIndex)
      const dropItem = {
        ...introOrders[dragIndex],
        order: introOrders[dropIndex].order,
      }
      const betweenItems = introOrders
        .slice(dragIndex + 1, dropIndex + 1)
        .map((item) => (item = { ...item, order: item.order - 1 }))
      betweenItems.push({ ...introOrders[dropIndex], ...dropItem })
      const belowItems = introOrders.slice(dropIndex + 1)
      newOrders = aboveItems.concat(betweenItems).concat(belowItems)
    } else {
      const aboveItems = introOrders.slice(0, dropIndex)
      const dropItem = {
        ...introOrders[dragIndex],
        order: introOrders[dropIndex].order,
      }
      const betweenItems = introOrders
        .slice(dropIndex, dragIndex)
        .map((item) => (item = { ...item, order: item.order + 1 }))
      const belowItems = introOrders.slice(dragIndex + 1)
      aboveItems.push(dropItem)
      newOrders = aboveItems.concat(betweenItems).concat(belowItems)
    }
  }
  setIntroOrders(newOrders)
  const titleCls = document.getElementsByClassName(style.introOrderTitle)
  if (titleCls.length > 0) {
    const titles = Array.from(titleCls as HTMLCollectionOf<HTMLElement>)
    titles.forEach((item) => {
      item.style.backgroundColor = EM.COMM.COLOR.WHITE
      item.style.color = EM.COMM.COLOR.BLACK
    })
  }
  const newModule = { ...module }
  let newIntroduction = { ...module.value.introduction! }
  let newExtra = newIntroduction.extra
  for (const order of newOrders) {
    if (order.key === EM.INTR.ATTRIBUTE.META) {
      const index = newExtra.findIndex((item: IF.INTR.IntroCustomIF) => item.key === order.value)
      newExtra = [...newExtra.slice(0, index), { ...newExtra[index], order: order.order }, ...newExtra.slice(index + 1)]
    }
  }
  newIntroduction = { ...newIntroduction, order: newOrders, extra: newExtra }
  newModule.value = { introduction: newIntroduction }
  setModules([...modules.slice(0, control.index), newModule, ...modules.slice(control.index + 1)])
}

export const introOrderTitle = (
  item: IF.INTR.IntroOrderIF,
  thisItem: IF.INTR.IntroContentIF,
  thisExtra: IF.INTR.IntroCustomIF
) => {
  let textValue = ''
  if (item.key === EM.INTR.ATTRIBUTE.ICONS) {
    textValue = EM.INTR.LABEL.ICONS
  } else if (item.key === EM.INTR.ATTRIBUTE.META) {
    textValue = thisExtra.text
  } else {
    textValue = thisItem.text
  }
  return textValue.slice(0, 50)
}

export const handleTitle = (control: IF.CTRL.ControlIF, hover: boolean, e: any) => {
  if (hover) {
    e.target.style.backgroundColor = control.color
    e.target.style.color = EM.COMM.COLOR.WHITE
    e.target.style.borderColor = control.color
  } else {
    e.target.style.backgroundColor = EM.COMM.COLOR.NONE
    e.target.style.color = EM.COMM.COLOR.BLACK
    e.target.style.borderColor = EM.COMM.COLOR.NONE
  }
}

export const handleHover = (
  index: number,
  hover: boolean,
  introOrders: IF.INTR.IntroOrderIF[],
  setIntroOrders: React.Dispatch<React.SetStateAction<IF.INTR.IntroOrderIF[]>>
) => {
  setIntroOrders([
    ...introOrders.slice(0, index),
    { ...introOrders[index], hover: hover },
    ...introOrders.slice(index + 1),
  ])
}

export const onHoverItem = (
  e: any,
  index: number,
  hover: boolean,
  control: IF.CTRL.ControlIF,
  introOrders: IF.INTR.IntroOrderIF[],
  setIntroOrders: React.Dispatch<React.SetStateAction<IF.INTR.IntroOrderIF[]>>
) => {
  handleTitle(control, hover, e)
  handleHover(index, hover, introOrders, setIntroOrders)
}

export const toggleItemDisplay = (
  e: any,
  index: number,
  item: IF.INTR.IntroOrderIF,
  control: IF.CTRL.ControlIF,
  module: IF.STAT.ModuleIF,
  modules: IF.STAT.ModuleIF[],
  introOrders: IF.INTR.IntroOrderIF[],
  setModules: React.Dispatch<React.SetStateAction<IF.STAT.ModuleIF[]>>,
  setIntroOrders: React.Dispatch<React.SetStateAction<IF.INTR.IntroOrderIF[]>>
) => {
  const intro = module.value.introduction!
  const display = e.target.checked ? EM.COMM.DISPLAY.FLEX : EM.COMM.DISPLAY.NONE
  const newOrders = [
    ...introOrders.slice(0, index),
    { ...introOrders[index], show: display },
    ...introOrders.slice(index + 1),
  ]
  setIntroOrders(newOrders)
  const newModule = { ...module }
  let newIntroduction = { ...module.value.introduction! }
  let newContent: IF.INTR.IntroContentIF[] = intro.content
  let thisObject = intro.content?.find(
    (item: IF.INTR.IntroContentIF) => item.label === introOrders[index].key
  ) as IF.INTR.IntroContentIF
  if (thisObject) {
    const contentIndx = intro.content.findIndex((item) => item === thisObject)
    thisObject = { ...thisObject, show: display }
    if (item.key === EM.INTR.ATTRIBUTE.ICONS) {
      thisObject = { ...thisObject, show: display, meta: { ...thisObject.meta, syncShow: 'y' } }
    }
    newContent = [...intro.content.slice(0, contentIndx), thisObject, ...intro.content.slice(contentIndx + 1)]
  }
  newIntroduction = { ...newIntroduction, order: newOrders, content: newContent }
  newModule.value = { introduction: newIntroduction }
  setModules([...modules.slice(0, control.index), newModule, ...modules.slice(control.index + 1)])
}

export const onChangeOrder = (
  value: boolean,
  attribute: string,
  intro: IF.INTR.IntroductionIF,
  control: IF.CTRL.ControlIF,
  module: IF.STAT.ModuleIF,
  modules: IF.STAT.ModuleIF[],
  setModules: React.Dispatch<React.SetStateAction<IF.STAT.ModuleIF[]>>
) => {
  let newIntro = intro
  const newModule: IF.STAT.ModuleIF = { ...module }
  const indx = intro.order.findIndex((item: IF.INTR.IntroOrderIF) => item.key === attribute)
  if (value && indx === 0) return
  if (!value && indx === intro.order.length - 1) return
  const aboveItems = indx === 0 ? [] : intro.order.slice(0, indx - 1)
  let oneAbove = intro.order[indx - 1]
  let thisItem = { ...intro.order[indx] }
  let oneBelow = intro.order[indx + 1]
  let middleItems = [oneAbove, thisItem, oneBelow]
  const belowItems = intro.order.slice(indx + 2)
  if (value) {
    oneAbove = { ...oneAbove, order: oneAbove.order + 1 }
    thisItem = { ...thisItem, order: thisItem.order - 1 }
    middleItems = [thisItem, oneAbove, oneBelow]
  } else {
    oneBelow = { ...oneBelow, order: oneBelow.order - 1 }
    thisItem = { ...thisItem, order: thisItem.order + 1 }
    middleItems = [oneAbove, oneBelow, thisItem]
  }
  const newOrder = aboveItems
    .concat(middleItems)
    .concat(belowItems)
    .filter((item: any) => item !== undefined)
  newIntro = { ...newIntro, order: newOrder }
  newModule.value = { introduction: newIntro }
  setModules([...modules.slice(0, control.index), newModule, ...modules.slice(control.index + 1)])
}

// Extra Setting
export const handleCustomField = (
  intro: IF.INTR.IntroductionIF,
  formValue: any,
  formKey: string,
  module: IF.STAT.ModuleIF,
  modules: IF.STAT.ModuleIF[],
  control: IF.CTRL.ControlIF,
  customField: IF.INTR.IntroCustomIF,
  setModules: React.Dispatch<React.SetStateAction<IF.STAT.ModuleIF[]>>,
  setCustomField: React.Dispatch<React.SetStateAction<IF.INTR.IntroCustomIF>>
) => {
  const content = intro.content
  const contentIndx = content.findIndex((item: IF.INTR.IntroContentIF) => item.label === customField.key)
  let newCustomField: any = customField
  let newFormValue = formValue
  if (formKey === EM.INTR.FIELDKEY.ORDER) {
    newFormValue = formValue ? customField.order - 1 : customField.order + 1
  }
  newCustomField = { ...newCustomField, [formKey]: newFormValue }
  setCustomField(newCustomField)

  let newIntro = intro
  if (formKey === EM.INTR.FIELDKEY.ORDER) {
    const indx = intro.order.findIndex((item: IF.INTR.IntroOrderIF) => item.key === customField.key)
    if (formValue && indx === 0) return
    if (!formValue && indx === intro.order.length - 1) return
    const aboveItems = indx === 0 ? [] : intro.order.slice(0, indx - 1)
    let oneAbove = intro.order[indx - 1]
    let thisItem = { ...intro.order[indx] }
    let oneBelow = intro.order[indx + 1]
    let middleItems = [oneAbove, thisItem, oneBelow]
    let order = thisItem.order
    const belowItems = intro.order.slice(indx + 2)
    if (formValue) {
      oneAbove = { ...oneAbove, order: oneAbove.order + 1 }
      thisItem = { ...thisItem, order: thisItem.order - 1 }
      middleItems = [thisItem, oneAbove, oneBelow]
      order -= 1
    } else {
      oneBelow = { ...oneBelow, order: oneBelow.order - 1 }
      thisItem = { ...thisItem, order: thisItem.order + 1 }
      middleItems = [oneAbove, oneBelow, thisItem]
      order += 1
    }
    const newOrder = aboveItems
      .concat(middleItems)
      .concat(belowItems)
      .filter((item: any) => item !== undefined)
    newIntro = {
      ...newIntro,
      order: newOrder,
      content: [
        ...content.slice(0, contentIndx),
        { ...content[contentIndx], meta: { ...content[contentIndx].meta, order: order } },
        ...content.slice(contentIndx + 1),
      ],
    }
  } else if (formKey === EM.INTR.FIELDKEY.TYPE) {
    newIntro = {
      ...newIntro,
      content: [
        ...content.slice(0, contentIndx),
        { ...content[contentIndx], meta: { ...content[contentIndx].meta, type: formValue } },
        ...content.slice(contentIndx + 1),
      ],
    }
  } else if (formKey === EM.INTR.FIELDKEY.FONT) {
    newIntro = {
      ...newIntro,
      content: [
        ...content.slice(0, contentIndx),
        { ...content[contentIndx], font: formValue.value, meta: { ...content[contentIndx].meta, font: formValue } },
        ...content.slice(contentIndx + 1),
      ],
    }
  } else {
    newIntro = {
      ...newIntro,
      content: [
        ...content.slice(0, contentIndx),
        { ...content[contentIndx], [formKey]: formValue },
        ...content.slice(contentIndx + 1),
      ],
    }
  }
  const newModule: IF.STAT.ModuleIF = { ...module }
  newModule.value = { introduction: newIntro }
  setModules([...modules.slice(0, control.index), newModule, ...modules.slice(control.index + 1)])
}

export const handleDeleteExtra = (
  intro: IF.INTR.IntroductionIF,
  module: IF.STAT.ModuleIF,
  modules: IF.STAT.ModuleIF[],
  control: IF.CTRL.ControlIF,
  snapShot: IF.STAT.ModuleIF,
  customField: IF.INTR.IntroCustomIF,
  hideBlock: IF.COMM.DisplayIF,
  setModules: React.Dispatch<React.SetStateAction<IF.STAT.ModuleIF[]>>,
  setEditStyle: React.Dispatch<React.SetStateAction<IF.COMM.DisplayIF>>,
  setSelection: React.Dispatch<React.SetStateAction<IF.COMM.ValueKeyIF>>
) => {
  const customFieldSnapshot = snapShot.value.introduction!.content
  if (intro.content.length !== customFieldSnapshot.length) {
    setModules([...modules.slice(0, control.index), snapShot, ...modules.slice(control.index + 1)])
  } else {
    if (intro.order.length === 0) return
    const orderIndx = intro.order.findIndex((item: IF.INTR.IntroOrderIF) => item.key === customField.key)
    const newOrder = [...intro.order.slice(0, orderIndx), ...intro.order.slice(orderIndx + 1)]
    const contentIndx = intro.content.findIndex((item: IF.INTR.IntroContentIF) => item.label === customField.key)
    const newContent = [...intro.content.slice(0, contentIndx), ...intro.content.slice(contentIndx + 1)]
    const newModule = { ...module }
    let newIntro = newModule.value.introduction!
    newIntro = { ...newIntro, order: newOrder, content: newContent }
    newModule.value = { introduction: newIntro }
    setModules([...modules.slice(0, control.index), newModule, ...modules.slice(control.index + 1)])
  }
  setEditStyle(hideBlock)
  setSelection(RS.INTR.valueKeySelect)
}

export const filterOption = (input: any, option: any) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

export const optionPhone = countryCodes.map((item) => ({
  label: `${item.name}: ${item.dialCode}`,
  value: `${item.code}:${item.dialCode}`,
}))

export const optionCanada = canadaCodes.map((item) => ({
  label: `${item.name}`,
  value: `${item.abbr}:${item.name}`,
}))

export const optionUS = usCodes.map((item) => ({
  label: `${item.name}`,
  value: `${item.abbr}:${item.name}`,
}))

export const optionCountry = countryCodes.map((item) => ({
  label: `${item.name}`,
  value: `${item.code}:${item.name}:${item.dialCode}`,
}))

// Text Setting
export const onChangeIntroText = (
  value: string,
  attribute: string,
  control: IF.CTRL.ControlIF,
  module: IF.STAT.ModuleIF,
  modules: IF.STAT.ModuleIF[],
  setModules: React.Dispatch<React.SetStateAction<IF.STAT.ModuleIF[]>>
) => {
  let newIntro = { ...module.value.introduction! }
  let newContent: IF.INTR.IntroContentIF[] = newIntro.content
  let index = 0
  let thisObject = { ...newContent[index] }
  if (attribute === EM.INTR.ATTRIBUTE.PHONECODE) {
    index = newContent!.findIndex((item: IF.INTR.IntroContentIF) => item.label === EM.INTR.ATTRIBUTE.PHONE)
    const meta = newContent[index].meta
    thisObject = {
      ...newContent[index],
      text: `${value.split(':')[1]} ${meta.number}`,
      meta: {
        ...meta,
        country: value.split(':')[0],
        code: value.split(':')[1],
      },
    }
  } else if (attribute === EM.INTR.ATTRIBUTE.PHONENUMBER) {
    index = newContent!.findIndex((item: IF.INTR.IntroContentIF) => item.label === EM.INTR.ATTRIBUTE.PHONE)
    const meta = newContent[index].meta
    thisObject = {
      ...newContent[index],
      text: `${meta.code} ${value}`,
      meta: {
        ...meta,
        number: value,
      },
    }
  } else if (attribute === EM.INTR.ATTRIBUTE.STREETONE) {
    index = newContent!.findIndex((item: IF.INTR.IntroContentIF) => item.label === EM.INTR.ATTRIBUTE.ADDRESS)
    const meta = newContent[index].meta
    thisObject = {
      ...newContent[index],
      text: `${value} ${meta.city} ${meta.stateCode} ${meta.countryName}`,
      meta: { ...meta, streetOne: value },
    }
  } else if (attribute === EM.INTR.ATTRIBUTE.CITY) {
    index = newContent!.findIndex((item: IF.INTR.IntroContentIF) => item.label === EM.INTR.ATTRIBUTE.ADDRESS)
    const meta = newContent[index].meta
    thisObject = {
      ...newContent[index],
      text: `${meta.streetOne} ${value} ${meta.stateCode} ${meta.countryName}`,
      meta: { ...meta, city: value },
    }
  } else if (attribute === EM.INTR.ATTRIBUTE.STATECODE) {
    const stateCode = value.split(':')[0]
    const stateName = value.split(':')[1]
    index = newContent!.findIndex((item: IF.INTR.IntroContentIF) => item.label === EM.INTR.ATTRIBUTE.ADDRESS)
    const meta = newContent[index].meta
    thisObject = {
      ...newContent[index],
      text: `${meta.streetOne} ${meta.city} ${stateCode} ${meta.countryName}`,
      meta: {
        ...meta,
        stateCode: stateCode,
        stateName: stateName,
      },
    }
  } else if (attribute === EM.INTR.ATTRIBUTE.STATENAME) {
    index = newContent!.findIndex((item: IF.INTR.IntroContentIF) => item.label === EM.INTR.ATTRIBUTE.ADDRESS)
    const meta = newContent[index].meta
    thisObject = {
      ...newContent[index],
      text: `${meta.streetOne} ${meta.city} ${value} ${meta.countryName}`,
      meta: { ...meta, stateName: value },
    }
  } else if (attribute === EM.INTR.ATTRIBUTE.COUNTRYCODE) {
    const countryCode = value.split(':')[0]
    const countryName = value.split(':')[1]
    index = newContent!.findIndex((item: IF.INTR.IntroContentIF) => item.label === EM.INTR.ATTRIBUTE.ADDRESS)
    const meta = newContent[index].meta
    const stateCode = countryCode === meta.countryCode ? meta.stateCode : ''
    const stateName = countryName === meta.countryName ? meta.stateName : ''
    const state = stateCode || stateName
    thisObject = {
      ...newContent[index],
      text: `${meta.streetOne} ${meta.city} ${state} ${countryName}`,
      meta: {
        ...meta,
        stateCode: stateCode,
        stateName: stateName,
        countryCode: countryCode,
        countryName: countryName,
      },
    }
  } else if (attribute === EM.INTR.ATTRIBUTE.FIRSTNAME) {
    index = newContent!.findIndex((item: IF.INTR.IntroContentIF) => item.label === EM.INTR.ATTRIBUTE.NAME)
    const meta = newContent[index].meta
    thisObject = {
      ...newContent[index],
      text: `${value} ${meta.middleName} ${meta.lastName}`,
      meta: { ...meta, firstName: value },
    }
  } else if (attribute === EM.INTR.ATTRIBUTE.MIDDLENAME) {
    index = newContent!.findIndex((item: IF.INTR.IntroContentIF) => item.label === EM.INTR.ATTRIBUTE.NAME)
    const meta = newContent[index].meta
    thisObject = {
      ...newContent[index],
      text: `${meta.firstName} ${value} ${meta.lastName}`,
      meta: { ...meta, middleName: value },
    }
  } else if (attribute === EM.INTR.ATTRIBUTE.LASTNAME) {
    index = newContent!.findIndex((item: IF.INTR.IntroContentIF) => item.label === EM.INTR.ATTRIBUTE.NAME)
    const meta = newContent[index].meta
    thisObject = {
      ...newContent[index],
      text: `${meta.firstName} ${meta.middleName} ${value}`,
      meta: { ...meta, lastName: value },
    }
  } else {
    index = newContent!.findIndex((item: IF.INTR.IntroContentIF) => item.label === attribute)
    thisObject = { ...newContent[index], text: value }
  }
  newContent = [...newContent.slice(0, index), thisObject, ...newContent.slice(index + 1)]
  newIntro = { ...newIntro, content: newContent }
  const newModule = { ...module, value: { introduction: newIntro } }
  setModules([...modules.slice(0, control.index), newModule, ...modules.slice(control.index + 1)])
}

// Color/Size Setting
export const onChangeIntroColor = (
  intro: IF.INTR.IntroductionIF,
  value: string,
  attribute: string,
  control: IF.CTRL.ControlIF,
  module: IF.STAT.ModuleIF,
  modules: IF.STAT.ModuleIF[],
  setModules: React.Dispatch<React.SetStateAction<IF.STAT.ModuleIF[]>>
) => {
  let newIntro = { ...intro }
  let newContent: IF.INTR.IntroContentIF[] = newIntro.content
  let index = 0
  let meta = newContent[index].meta
  let thisObject = { ...newContent[index] }
  if (attribute === EM.INTR.ATTRIBUTE.BIRTHDAYICON) {
    index = newContent!.findIndex((item: IF.INTR.IntroContentIF) => item.label === EM.INTR.ATTRIBUTE.BIRTHDAY)
    meta = newContent[index].meta
    thisObject = {
      ...newContent[index],
      meta: {
        ...meta,
        icon: { ...meta.icon!, color: value },
      },
    }
  } else if (attribute === EM.INTR.ATTRIBUTE.EMAILICON) {
    index = newContent!.findIndex((item: IF.INTR.IntroContentIF) => item.label === EM.INTR.ATTRIBUTE.EMAIL)
    meta = newContent[index].meta
    thisObject = {
      ...newContent[index],
      meta: {
        ...meta,
        icon: { ...meta.icon!, color: value },
      },
    }
  } else if (attribute === EM.INTR.ATTRIBUTE.PHONEICON) {
    index = newContent!.findIndex((item: IF.INTR.IntroContentIF) => item.label === EM.INTR.ATTRIBUTE.PHONE)
    meta = newContent[index].meta
    thisObject = {
      ...newContent[index],
      meta: {
        ...meta,
        icon: { ...meta.icon!, color: value },
      },
    }
  } else if (attribute === EM.INTR.ATTRIBUTE.ADDRESSICON) {
    index = newContent!.findIndex((item: IF.INTR.IntroContentIF) => item.label === EM.INTR.ATTRIBUTE.ADDRESS)
    meta = newContent[index].meta
    thisObject = {
      ...newContent[index],
      meta: {
        ...meta,
        icon: { ...meta.icon!, color: value },
      },
    }
  } else if (attribute === EM.INTR.ATTRIBUTE.WEBSITEICON) {
    index = newContent!.findIndex((item: IF.INTR.IntroContentIF) => item.label === EM.INTR.ATTRIBUTE.WEBSITE)
    meta = newContent[index].meta
    thisObject = {
      ...newContent[index],
      meta: {
        ...meta,
        icon: { ...meta.icon!, color: value },
      },
    }
  } else if (attribute === EM.INTR.ATTRIBUTE.SYNCCOLOR) {
    index = newContent!.findIndex((item: IF.INTR.IntroContentIF) => item.label === EM.INTR.ATTRIBUTE.ICONS)
    meta = newContent[index].meta
    thisObject = {
      ...newContent[index],
      meta: {
        ...meta,
        syncColor: value,
      },
    }
  } else {
    index = newContent!.findIndex((item: IF.INTR.IntroContentIF) => item.label === attribute)
    meta = newContent[index].meta
    thisObject = { ...newContent[index], color: value }
  }
  newContent = [...newContent.slice(0, index), thisObject, ...newContent.slice(index + 1)]
  newIntro = { ...newIntro, content: newContent }
  const newModule = { ...module, value: { introduction: newIntro } }
  setModules([...modules.slice(0, control.index), newModule, ...modules.slice(control.index + 1)])
}

// Font Setting
export const onChangeIntroFont = (
  value: string,
  attribute: string,
  control: IF.CTRL.ControlIF,
  module: IF.STAT.ModuleIF,
  modules: IF.STAT.ModuleIF[],
  setModules: React.Dispatch<React.SetStateAction<IF.STAT.ModuleIF[]>>
) => {
  let newIntro = { ...module.value.introduction! }
  const newValue = newIntro[attribute as keyof IF.INTR.IntroductionIF] as IF.INTR.IntroContentIF
  newIntro = { ...newIntro, [attribute]: { ...newValue, font: value } }
  const newModule = { ...module, value: { introduction: newIntro } }
  setModules([...modules.slice(0, control.index), newModule, ...modules.slice(control.index + 1)])
  newModule.value = { introduction: newIntro }
  setModules([...modules.slice(0, control.index), newModule, ...modules.slice(control.index + 1)])
}

// Weight Setting
export const onChangeIntroWeight = (
  value: number,
  attribute: string,
  control: IF.CTRL.ControlIF,
  module: IF.STAT.ModuleIF,
  modules: IF.STAT.ModuleIF[],
  setModules: React.Dispatch<React.SetStateAction<IF.STAT.ModuleIF[]>>
) => {
  let newIntro = { ...module.value.introduction! }
  const newValue = newIntro[attribute as keyof IF.INTR.IntroductionIF] as IF.INTR.IntroContentIF
  newIntro = { ...newIntro, [attribute]: { ...newValue, weight: value } }
  const newModule = { ...module, value: { introduction: newIntro } }
  setModules([...modules.slice(0, control.index), newModule, ...modules.slice(control.index + 1)])
  newModule.value = { introduction: newIntro }
  setModules([...modules.slice(0, control.index), newModule, ...modules.slice(control.index + 1)])
}

// Size Setting
export const onChangeIntroSize = (
  intro: IF.INTR.IntroductionIF,
  value: number,
  attribute: string,
  control: IF.CTRL.ControlIF,
  module: IF.STAT.ModuleIF,
  modules: IF.STAT.ModuleIF[],
  setModules: React.Dispatch<React.SetStateAction<IF.STAT.ModuleIF[]>>
) => {
  let newIntro = { ...intro }
  if (attribute === EM.INTR.ATTRIBUTE.BIRTHDAYICON) {
    newIntro = {
      ...newIntro,
      birthday: {
        ...newIntro.birthday,
        meta: { ...newIntro.birthday.meta, icon: { ...newIntro.email.meta.icon!, size: value } },
      },
    }
  } else if (attribute === EM.INTR.ATTRIBUTE.EMAILICON) {
    newIntro = {
      ...newIntro,
      email: {
        ...newIntro.email,
        meta: { ...newIntro.email.meta, icon: { ...newIntro.email.meta.icon!, size: value } },
      },
    }
  } else if (attribute === EM.INTR.ATTRIBUTE.PHONEICON) {
    newIntro = {
      ...newIntro,
      phone: {
        ...newIntro.phone,
        meta: { ...newIntro.phone.meta, icon: { ...newIntro.phone.meta.icon!, size: value } },
      },
    }
  } else if (attribute === EM.INTR.ATTRIBUTE.ADDRESSICON) {
    newIntro = {
      ...newIntro,
      address: {
        ...newIntro.address,
        meta: { ...newIntro.address.meta, icon: { ...newIntro.address.meta.icon!, size: value } },
      },
    }
  } else if (attribute === EM.INTR.ATTRIBUTE.WEBSITEICON) {
    newIntro = {
      ...newIntro,
      website: {
        ...newIntro.website,
        meta: { ...newIntro.website.meta, icon: { ...newIntro.website.meta.icon!, size: value } },
      },
    }
  } else {
    const newValue = newIntro[attribute as keyof IF.INTR.IntroductionIF] as IF.INTR.IntroContentIF
    newIntro = { ...newIntro, [attribute]: { ...newValue, size: value } }
  }
  const newModule = { ...module, value: { introduction: newIntro } }
  setModules([...modules.slice(0, control.index), newModule, ...modules.slice(control.index + 1)])
}

export const previewStyle = (item: IF.INTR.IntroContentIF) => {
  const thisStyle: React.CSSProperties = {
    color: item.color,
    fontSize: item.size + 'px',
    fontWeight: weightObject(item.weight),
    fontFamily: item.font,
    display: item.show,
    flexDirection: 'column',
  }
  return thisStyle
}

export const socialStyle = (item: IF.INTR.IntroContentIF, icons: IF.INTR.IntroContentIF) => {
  const meta = icons.meta
  const thisStyle: React.CSSProperties = {
    color: meta.syncColor === EM.COMM.LOWER.Y ? icons.color : item.color,
    fontFamily: item.font,
    fontWeight: weightObject(item.weight),
    fontSize: meta.syncSize === EM.COMM.LOWER.Y ? icons.size + 'px' : item.size + 'px',
    display: meta.syncShow === EM.COMM.LOWER.Y ? icons.show : item.show,
    padding: '0 5px',
  }
  return thisStyle
}
