import { request } from '../../utils/request'
import {
  updateAuth,
  updateProfile,
  updateMENUSnapshot,
  updateNAVGSnapshot,
  updateCTRLSnapshot,
  updateBKGDSnapshot,
  updateMODLSnapshot,
} from '../reducer'
import { EM, IF } from '../../components/imports'
import { updateControlSnap } from '../reducers/controlReducer'

export const authStart = (auth: IF.AUTH.AuthIF, dispatch: any) => {
  dispatch(
    updateAuth({
      ...auth,
      error: null,
      loading: true,
    })
  )
}

export const authFail = (auth: IF.AUTH.AuthIF, dispatch: any, error: string) => {
  dispatch(
    updateAuth({
      ...auth,
      error: error,
      loading: false,
    })
  )
}

export const authFailUsers = (auth: IF.AUTH.AuthIF, dispatch: any, error: string, users: string[]) => {
  dispatch(
    updateAuth({
      ...auth,
      error: error,
      loading: false,
      users: users,
    })
  )
}

export const authSuccess = (auth: IF.AUTH.AuthIF, dispatch: any) => {
  dispatch(
    updateAuth({
      ...auth,
      error: null,
      loading: false,
    })
  )
}

const authAddToken = (
  auth: IF.AUTH.AuthIF,
  dispatch: any,
  token: string | null,
  refresh: string | null,
  session: string | null,
  message: string,
  account: string | null
) => {
  dispatch(
    updateAuth({
      ...auth,
      token: token,
      refresh: refresh,
      session: session,
      message: message,
      loading: false,
      account: account,
    })
  )
}

const authDelToken = (auth: IF.AUTH.AuthIF, dispatch: any) => {
  localStorage.removeItem('session')
  dispatch(
    updateAuth({
      ...auth,
      token: null,
      refresh: null,
      session: null,
      message: null,
      loading: false,
    })
  )
}

const authMessage = (auth: IF.AUTH.AuthIF, dispatch: any, message: any, users: string[]) => {
  dispatch(
    updateAuth({
      ...auth,
      message: message,
      loading: false,
      users: users,
      error: null,
    })
  )
}

const authError = (auth: IF.AUTH.AuthIF, dispatch: any, error: string | null, users: string[]) => {
  dispatch(
    updateAuth({
      ...auth,
      error: error,
      users: users,
    })
  )
}

const authGetUsers = (
  auth: IF.AUTH.AuthIF,
  dispatch: any,
  token: string | null,
  refresh: string | null,
  session: string | null,
  message: string | null,
  account: string | null,
  users: string[]
) => {
  dispatch(
    updateAuth({
      ...auth,
      token: token,
      refresh: refresh,
      session: session,
      message: message,
      loading: false,
      account: account,
      users: users,
    })
  )
}

const authCheckTimeout = (auth: IF.AUTH.AuthIF, dispatch: any, expirationTime: number) => {
  setTimeout(() => {
    localStorage.removeItem('session')
    authDelToken(auth, dispatch)
    authUpdateToken(auth, dispatch, null, null, null, null, auth.users)
    window.location.reload()
  }, expirationTime * 1000)
}

export const authUpdateToken = (
  auth: IF.AUTH.AuthIF,
  dispatch: any,
  refresh: string | null,
  session: string | null,
  message: string | null,
  account: string | null,
  users: string[]
) => {
  const fetchToken = async () => {
    const response = await request('/api/talent/users/simple-jwt-token/refresh/', null, { refresh: refresh }, 'post')
    dispatch(
      updateAuth({
        ...auth,
        token: response.data.access,
        refresh: refresh,
        session: session,
        message: message,
        account: account,
        loading: false,
        users: users,
      })
    )
  }
  const updateToken = setInterval(async () => fetchToken(), 280000)
  if (refresh) {
    fetchToken()
    updateToken
  } else {
    clearInterval(updateToken)
  }
}

export const authAutoSignin = async (auth: IF.AUTH.AuthIF, dispatch: any) => {
  const session = localStorage.getItem('session')
  try {
    const response = await request(`/api/talent/users/list/${session}/`, null, null, 'get')
    const data = await response.data
    let message: string | null = null
    if (data.token) {
      const expirationDate = new Date(String(localStorage.getItem('expirationDate')))
      if (expirationDate <= new Date()) {
        localStorage.removeItem('session')
        authDelToken(auth, dispatch)
        window.location.reload()
      } else {
        message = EM.RSTR.TEXT.SIGNEDIN
        authCheckTimeout(auth, dispatch, (expirationDate.getTime() - new Date().getTime()) / 1000)
      }
    } else {
      authDelToken(auth, dispatch)
    }
    authGetUsers(auth, dispatch, data.token, data.refresh, session, message, data.account, data.users)
    authUpdateToken(auth, dispatch, data.refresh, session, message, data.account, data.users)
  } catch (error: any) {
    authFailUsers(auth, dispatch, error.response.data.error, error.response.data.users)
  }
}

export const authSignin = async (
  auth: IF.AUTH.AuthIF,
  dispatch: any,
  email: string,
  password: string,
  time: number
) => {
  authStart(auth, dispatch)
  const input = { username: email, password: password, time: time }
  try {
    const response = await request('/api/talent/users/signin/', null, input, 'post')
    const data = await response.data
    const expirationDate = new Date(new Date().getTime() + 1000 * time)
    localStorage.setItem('session', data.session)
    localStorage.setItem('expirationDate', String(expirationDate))
    localStorage.setItem('sessionCookie', data.cookie)
    authAddToken(auth, dispatch, data.token, data.refresh, data.session, EM.RSTR.TEXT.SIGNEDIN, data.account)
    authUpdateToken(auth, dispatch, data.refresh, data.session, EM.RSTR.TEXT.SIGNEDIN, data.account, auth.users)
  } catch (error: any) {
    authFailUsers(auth, dispatch, error.response.data.error, error.response.data.users)
  }
}

export const authSignout = async (auth: IF.AUTH.AuthIF, dispatch: any, token: string) => {
  authStart(auth, dispatch)
  const session = localStorage.getItem('session')
  try {
    const response = await request(`/api/talent/users/logout/${session}/`, token, null, 'get')
    if (response.status === 200) authDelToken(auth, dispatch)
    authUpdateToken(auth, dispatch, null, null, null, null, auth.users)
    localStorage.removeItem('sessionCookie')
    window.location.reload()
  } catch (error: any) {
    authFailUsers(auth, dispatch, error.response.data.error, auth.users)
  }
}

export const authSignup = async (
  auth: IF.AUTH.AuthIF,
  dispatch: any,
  email: string,
  password: string,
  first_name: string,
  last_name: string
) => {
  authStart(auth, dispatch)
  const input: IF.RSTR.SignupIF = {
    email: email,
    username: email,
    password: password,
    first_name: first_name,
    last_name: last_name,
  }
  try {
    const response = await request('/api/talent/users/signup/', null, input, 'post')
    const data = await response.data
    authMessage(auth, dispatch, data.message, data.users)
  } catch (error: any) {
    authFailUsers(auth, dispatch, error.response.data.error, error.response.data.users)
  }
}

export const authSetMessage = async (auth: IF.AUTH.AuthIF, dispatch: any, message: string | null) => {
  authMessage(auth, dispatch, message, auth.users)
}

export const authSetError = async (auth: IF.AUTH.AuthIF, dispatch: any, error: string | null) => {
  authError(auth, dispatch, error, auth.users)
}

export const authActivate = async (
  auth: IF.AUTH.AuthIF,
  dispatch: any,
  method: string,
  name: string | null,
  uuid: string | null
) => {
  authStart(auth, dispatch)
  try {
    const response = await request(`/api/talent/users/active/${name}/${uuid}/`, null, null, method)
    const data = await response.data
    authMessage(auth, dispatch, data.message, data.users)
  } catch (error: any) {
    authFailUsers(auth, dispatch, error.response.data.error, error.response.data.users)
  }
}

export const authForget = async (auth: IF.AUTH.AuthIF, dispatch: any, email: string) => {
  authStart(auth, dispatch)
  const input = { username: email }
  try {
    const response = await request('/api/talent/users/forget/', null, input, 'post')
    const data = await response.data
    authMessage(auth, dispatch, data.message, data.users)
  } catch (error: any) {
    authFailUsers(auth, dispatch, error.response.data.error, error.response.data.users)
  }
}

export const authResetPWD = async (
  auth: IF.AUTH.AuthIF,
  dispatch: any,
  method: string,
  name: string | null,
  uuid: string | null,
  password: string | null
) => {
  authStart(auth, dispatch)
  const input = { password: password }
  try {
    const response = await request(`/api/talent/users/reset/${name}/${uuid}/`, null, input, method)
    const data = await response.data
    authMessage(auth, dispatch, data.message, data.users)
  } catch (error: any) {
    authFailUsers(auth, dispatch, error.response.data.error, error.response.data.users)
  }
}

export const authUpdateSnapshots = (
  dispatch: any,
  profile: IF.STAT.ProfileIF,
  NAVGSnapshot: IF.NAVG.NaviBarIF,
  MENUSnapshot: IF.NAVG.MenuBarIF,
  controlSnap: IF.CTRL.ControlIF,
  BKGDSnapshot: IF.BKGD.BackgroundIF[],
  MODLSnapshot: IF.STAT.ModuleIF[]
) => {
  dispatch(updateProfile(profile))
  dispatch(updateNAVGSnapshot(NAVGSnapshot))
  dispatch(updateMENUSnapshot(MENUSnapshot))
  dispatch(updateCTRLSnapshot(controlSnap))
  dispatch(updateBKGDSnapshot(BKGDSnapshot))
  dispatch(updateMODLSnapshot(MODLSnapshot))
  dispatch(updateControlSnap(controlSnap))
}

export const authUpdateControlSnap = (dispatch: any, controlSnap: IF.CTRL.ControlIF) => {
  dispatch(updateControlSnap(controlSnap))
}

export const authUpdateBKGDSnapshot = (BKGDSnapshot: IF.BKGD.BackgroundIF[], dispatch: any) => {
  dispatch(updateBKGDSnapshot(BKGDSnapshot))
}

export const authUpdateMODLSnapshot = (MODLSnapshot: IF.STAT.ModuleIF[], dispatch: any) => {
  dispatch(updateMODLSnapshot(MODLSnapshot))
}

export const authUpdateCTRLSnapshot = (controlSnap: IF.CTRL.ControlIF, dispatch: any) => {
  dispatch(updateCTRLSnapshot(controlSnap))
}

export const authUpdateNAVGSnapshot = (NAVGSnapshot: IF.NAVG.NaviBarIF, dispatch: any) => {
  dispatch(updateNAVGSnapshot(NAVGSnapshot))
}

export const authUpdateMENUSnapshot = (MENUSnapshot: IF.NAVG.MenuBarIF, dispatch: any) => {
  dispatch(updateMENUSnapshot(MENUSnapshot))
}
