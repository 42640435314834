export enum URL {
  AVATR = '/api/talent/introduction/avatar/',
  TEXT = '/api/talent/introduction/text/',
  COLOR = '/api/talent/introduction/color/',
  FONT = '/api/talent/introduction/font/',
  SIZE = '/api/talent/introduction/size/',
  WEIGHT = '/api/talent/introduction/weight/',
  SHOW = '/api/talent/introduction/show/',
  ORDER = '/api/talent/introduction/order/',
  EXTRA = '/api/talent/introduction/extra/',
  CONTENT = '/api/talent/introduction/content/',
  CUSTOM = '/api/talent/introduction/custom/',
}

export enum AVATAR {
  AVATAR = 'Avatar',
  FRAME = 'Frame Width',
  WIDTH = 'Avatar Width',
  HEIGHT = 'Avatar Height',
  UPRIGHT = 'Up and Down',
  HORIZON = 'Left and Right',
  DISPLAY = 'Avatar Display',
  OBJECT = 'Avatar Object',
  STYLE = 'Avatar Style',
  RADIUS = 'Avatar Radius',
  CLIPING = 'Avatar Clipping',
  EFFECT = 'Avatar Effect',
  SELECT = 'Avatar Select',
  HISTORY = 'History',
  NORMAL = 'Normal',
  CIRCLE = 'Circle',
  POLYGON = 'Polygon',
  ROUND = 'Roundness',
  BLUR = 'Blur',
  BRIGHT = 'Brightness',
  CONTRAST = 'Contrast',
  SHADOW = 'Shadow',
  GRAY = 'Grayscale',
  HUE = 'Hue Rotate',
  INVERT = 'Invert',
  OPACITY = 'Opacity',
  SATURATE = 'Saturate',
  SEPIA = 'Sepia',
  COLOR = 'Color',
}

export enum EFFECT {
  SELECT = 'select',
  NONE = 'none',
  BLUR = 'blur',
  BRIGHT = 'brightness',
  CONTRAST = 'contrast',
  SHADOW = 'drop-shadow',
  GRAY = 'grayscale',
  HUE = 'hue-rotate',
  INVERT = 'invert',
  OPACITY = 'opacity',
  SATURATE = 'saturate',
  SEPIA = 'sepia',
}

export enum SETTING {
  FRAME = 'Frame Setting',
  AVATAR = 'Avatar Setting',
  NAME = 'Name Setting',
  GREETING = 'Greeting Setting',
  TITLE = 'Title Setting',
  DETAIL = 'Detail Setting',
  ADDRESS = 'Address Setting',
  BIRTHDAY = 'Birthday Setting',
  EMAIL = 'Email Setting',
  PHONE = 'Phone Setting',
  WEBSITE = 'Website Setting',
  ICONS = 'Icons Setting',
  ORDER = 'Order Setting',
  PRESET = 'Preset Setting',
  CUSTOM = 'Custom Setting',
  MODULE = 'Module Setting',
  TEXT = 'Text Setting',
  COLOR = 'Color Setting',
  FONT = 'Font Setting',
  SIZE = 'Size Setting',
}

export enum LABEL {
  GREETING = 'Greeting',
  FIRSTNAME = 'First Name',
  MIDDLENAME = 'Middle Name',
  LASTNAME = 'Last Name',
  NAME = 'Full Name',
  TITLE = 'Job Title',
  DETAIL = 'Description',
  STREETONE = 'Street',
  STREETTWO = 'Street Two',
  CITY = 'City',
  STATE = 'State/Prov.',
  COUNTRY = 'Country',
  ADDRESS = 'Address',
  BIRTHDAY = 'Birthday',
  EMAIL = 'Email',
  PHONE = 'Phone',
  WEBSITE = 'Website',
  TWITTER = 'Twitter',
  FACEBOOK = 'Facebook',
  LINKEDIN = 'LinkedIn',
  INSTAGRAM = 'Instagram',
  YOUTUBE = 'YouTube',
  GITHUB = 'Github',
  ADDRESSICON = 'Address Icon',
  BIRTHDAYICON = 'Birthday Icon',
  EMAILICON = 'Email Icon',
  PHONEICON = 'Phone Icon',
  WEBSITEICON = 'Website Icon',
  ICONS = 'Media Icons',
}

export enum ATTRIBUTE {
  GREETING = 'greeting',
  FIRSTNAME = 'firstName',
  MIDDLENAME = 'middleName',
  LASTNAME = 'lastName',
  NAME = 'name',
  TITLE = 'title',
  DETAIL = 'detail',
  BIRTHDAY = 'birthday',
  EMAIL = 'email',
  PHONECODE = 'phoneCode',
  PHONENUMBER = 'phoneNumber',
  PHONE = 'phone',
  ADDRESS = 'address',
  STREETONE = 'streetOne',
  STREETTWO = 'streetTwo',
  CITY = 'city',
  STATECODE = 'stateCode',
  STATENAME = 'stateName',
  COUNTRYCODE = 'countryCode',
  COUNTRYNAME = 'countryName',
  WEBSITE = 'website',
  TWITTER = 'twitter',
  FACEBOOK = 'facebook',
  LINKEDIN = 'linkedin',
  INSTAGRAM = 'instagram',
  YOUTUBE = 'youtube',
  GITHUB = 'github',
  ICONS = 'icons',
  SYNCCOLOR = 'syncColor',
  SYNCSIZE = 'syncSize',
  SYNCSHOW = 'syncShow',
  BIRTHDAYICON = 'birthdayIcon',
  EMAILICON = 'emailIcon',
  PHONEICON = 'phoneIcon',
  ADDRESSICON = 'addressIcon',
  WEBSITEICON = 'websiteIcon',
  META = 'meta',
}

export enum FIELDKEY {
  KEY = 'key',
  TYPE = 'type',
  TEXT = 'text',
  SHOW = 'show',
  FONT = 'font',
  SIZE = 'size',
  COLOR = 'color',
  ORDER = 'order',
  WEIGHT = 'weight',
  AVATAR = 'avatar',
  EXTRA = 'extra',
  SYNC = 'sync',
  ICONSIZE = 'iconSize',
  ICONCOLOR = 'iconColor',
}

export enum FIELDLABEL {
  PREVIEW = 'Field Preview',
  LABEL = 'Field Label',
  TYPE = 'Field Type',
  VALUE = 'Field Value',
  ORDER = 'Field Order',
  SHOW = 'Field Display',
  FONT = 'Text Font',
  COLOR = 'Text Color',
  SIZE = 'Text Size',
  WEIGHT = 'Text Weight',
  DELETE = 'Delete',
  ICONSIZE = 'Icon Size',
  ICONCOLOR = 'Icon Color',
  ICONSHOW = 'Icon Display',
  ICONSSIZE = 'Icons Size',
  ICONSCOLOR = 'Icons Color',
  ICONSSHOW = 'Icons Display',
  SYNCSIZE = 'Sync Size',
  SYNCCOLOR = 'Sync Color',
  SYNCSHOW = 'Sync Display',
  SYNCORDER = 'Sync Order',
}

export enum FIELDSELECT {
  INPUTLABEL = 'Input Box',
  INPUT = 'input',
  TEXT = 'text',
  DATE = 'date',
}

export enum ORDER {
  ITEM = 'Item',
  DISPLAY = 'Display',
}

export enum VAR {
  ADD = 'add',
  SWAP = 'Swap Mode',
  INSERT = 'Insert Mode',
  EMPTY = 'Key name cannot be empty',
  LETTER = 'Key name can only contain alphabets',
  LENGTH = 'Key name must be at leat 4 alphabets',
  NAME = ') already exists. Please use a different key name',
  CREATE = 'Create Key',
  SYNCHRONIZED = 'Synchronized',
  DESYNCHRONIZED = 'Desynchronized',
  SWAPMSG = 'Swapping the orders of the two elements',
  INSTMSG = 'Re-ordering the order of the elements',
}
