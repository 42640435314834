import {
    AppstoreTwoTone,
    ControlTwoTone,
    SnippetsTwoTone,
    PieChartTwoTone,
    FileTextTwoTone,
    ProjectTwoTone,
    ReconciliationTwoTone,
    RocketTwoTone,
    SettingTwoTone,
    StarTwoTone,
    TrophyTwoTone,
    ToolTwoTone,
    MenuUnfoldOutlined,
    PicCenterOutlined,
    RightSquareOutlined,
    ProfileOutlined,
} from '@ant-design/icons'


export const weightObject = (weight: number) => {
    if (weight === 1) return 400
    if (weight === 2) return 600
    return 800
}

export const handleHistoryIcon = (color: string, icon: string) => {
    let thisStyle = { fontSize: '60px' }
    if (window.innerWidth < 420) {
        thisStyle = { fontSize: '32px' }
    } else if (window.innerWidth < 576) {
        thisStyle = { fontSize: '45px' }
    }
    const iconObject: any = {
        AppstoreTwoTone: <AppstoreTwoTone twoToneColor={color} style={thisStyle} />,
        ControlTwoTone: <ControlTwoTone twoToneColor={color} style={thisStyle} />,
        FileTextTwoTone: <FileTextTwoTone twoToneColor={color} style={thisStyle} />,
        PieChartTwoTone: <PieChartTwoTone twoToneColor={color} style={thisStyle} />,
        ProjectTwoTone: <ProjectTwoTone twoToneColor={color} style={thisStyle} />,
        Reconciliation: <ReconciliationTwoTone twoToneColor={color} style={thisStyle} />,
        RocketTwoTone: <RocketTwoTone twoToneColor={color} style={thisStyle} />,
        SettingTwoTone: <SettingTwoTone twoToneColor={color} style={thisStyle} />,
        SnippetsTwoTone: <SnippetsTwoTone twoToneColor={color} style={thisStyle} />,
        StarTwoTone: <StarTwoTone twoToneColor={color} style={thisStyle} />,
        TrophyTwoTone: <TrophyTwoTone twoToneColor={color} style={thisStyle} />,
        ToolTwoTone: <ToolTwoTone twoToneColor={color} style={thisStyle} />,
        MenuUnfoldOutlined: <MenuUnfoldOutlined style={{ ...thisStyle, color: color }} />,
        PicCenterOutlined: <PicCenterOutlined style={{ ...thisStyle, color: color }} />,
        RightSquareOutlined: <RightSquareOutlined style={{ ...thisStyle, color: color }} />,
        ProfileOutlined: <ProfileOutlined style={{ ...thisStyle, color: color }} />,
    }
    return iconObject[icon]
}

