import React from 'react'
import style from '../../assets/base.module.scss'
import { IF, UT } from '../imports'

/**
 * Editor SVG JSX component.
 * @param svg SVG data.
 * @param height height of the SVG component.
 * @param radius border radius CSS style value.
 * @returns editor SVG JSX component.
 */
const EditorSVG: React.FC<{
  svg: IF.COMM.SVGIF[]
  height: string | number
  radius?: string
}> = ({ svg, height, radius }) => {
  const svgStyle: React.CSSProperties = { borderRadius: radius }
  const [svgId, setSvgId] = React.useState<string>('eml21mrrs')
  React.useEffect(() => {
    setSvgId(UT.randomText(9))
  }, [])
  return (
    <div className={style.baseSVG} style={{ height: height }}>
      <svg className={style.svgStyle} style={svgStyle}>
        <defs>
          <linearGradient id={svgId} x1="0" y1="0.5" x2="1" y2="0.5">
            {svg.map((item: IF.COMM.SVGIF, index: number) => {
              return <stop key={index} offset={item.offset} style={{ stopColor: item.color, stopOpacity: 1 }}></stop>
            })}
          </linearGradient>
        </defs>
        <rect x="0" y="0" width="100%" height="100%" fill={`url(#${svgId})`}></rect>
      </svg>
    </div>
  )
}

export default EditorSVG
