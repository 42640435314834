import React from 'react'
import { request } from '../../utils/request'
import { EM, IF, RS } from '../../components/imports'
import { authStart, authFail, authSuccess, authUpdateMODLSnapshot } from './auth'
import { updateFrame, updateStatus } from './module'
import { isEqual, difference } from 'lodash'

const selectionMapper = (
  intro: IF.INTR.IntroductionIF,
  snapShot: IF.INTR.IntroductionIF,
  selection: IF.COMM.ValueNameIF
) => {
  const payload: { input: any; url: string; type: string }[] = []
  if (selection.name === EM.INTR.SETTING.AVATAR) {
    intro.avatar !== snapShot.avatar &&
      payload.push({
        input: intro.avatar,
        url: EM.INTR.URL.AVATR,
        type: EM.INTR.FIELDKEY.AVATAR,
      })
  } else if (selection.name === EM.INTR.SETTING.ORDER || selection.name === EM.INTR.SETTING.CUSTOM) {
    !isEqual(intro.extra, snapShot.extra) &&
      payload.push({
        input: { id: intro.id, extra: intro.extra },
        url: EM.INTR.URL.EXTRA,
        type: EM.INTR.FIELDKEY.EXTRA,
      })
    !isEqual(intro.order, snapShot.order) &&
      payload.push({
        input: { id: intro.id, order: intro.order },
        url: EM.INTR.URL.ORDER,
        type: EM.INTR.FIELDKEY.ORDER,
      })
  } else {
    RS.INTR.payloadKeys.forEach((item: string) => {
      if (!isEqual(intro[item as keyof IF.INTR.IntroductionIF], snapShot[item as keyof IF.INTR.IntroductionIF])) {
        const content = intro[item as keyof IF.INTR.IntroductionIF] as IF.INTR.IntroContentIF
        payload.push({ input: content, url: EM.INTR.URL.CONTENT, type: item })
      }
    })
  }
  return payload
}

export const updateIntroduction = async (
  auth: IF.AUTH.AuthIF,
  dispatch: any,
  index: number,
  selection: IF.COMM.ValueNameIF,
  control: IF.CTRL.ControlIF,
  module: IF.STAT.ModuleIF,
  MODLSnapshot: IF.STAT.ModuleIF[],
  setControl: React.Dispatch<React.SetStateAction<IF.CTRL.ControlIF>>,
  setModules: React.Dispatch<React.SetStateAction<IF.STAT.ModuleIF[]>>
) => {
  authStart(auth, dispatch)
  if (selection.name === EM.INTR.SETTING.FRAME) {
    return updateFrame(auth, dispatch, index, module, MODLSnapshot)
  }
  if (selection.name === EM.INTR.SETTING.MODULE) {
    return updateStatus(auth, dispatch, index, control, module, MODLSnapshot, setControl, setModules)
  }
  const snapShot = MODLSnapshot[index]
  const mapper = selectionMapper(module.value.introduction!, snapShot.value.introduction!, selection)
  let intro = snapShot.value.introduction!
  let success = true
  for (const payload of mapper) {
    try {
      const key = payload.type as keyof IF.INTR.IntroductionIF
      const response = await request(payload.url, auth.token, payload.input, EM.COMM.APIMETHOD.PATCH)
      if (selection.name === EM.INTR.SETTING.AVATAR || selection.name === EM.INTR.SETTING.ORDER) {
        intro = { ...intro, [key]: response.data }
      } else {
        intro = { ...intro, [key]: module.value.introduction![key] }
      }
    } catch (error: any) {
      authFail(auth, dispatch, error.response.data)
      success = false
    }
  }
  if (success) {
    const newSnapshot = { ...snapShot, value: { introduction: intro } }
    const newModules = [...MODLSnapshot.slice(0, index), newSnapshot, ...MODLSnapshot.slice(index + 1)]
    setModules(newModules)
    authUpdateMODLSnapshot(newModules, dispatch)
    authSuccess(auth, dispatch)
  }
}

export const updateIntroAvatar = async (
  auth: IF.AUTH.AuthIF,
  dispatch: any,
  index: number,
  module: IF.STAT.ModuleIF,
  snapShots: IF.STAT.ModuleIF[],
  fileObject: string | File,
  setModules: React.Dispatch<React.SetStateAction<IF.STAT.ModuleIF[]>>
) => {
  const avatar = module.value.introduction!.avatar
  try {
    const formData = new FormData()
    formData.append('id', avatar.id.toString())
    formData.append('image', avatar.image)
    formData.append('show', avatar.show)
    formData.append('frame', avatar.frame.toString())
    formData.append('width', avatar.width.toString())
    formData.append('height', avatar.height.toString())
    formData.append('horizon', avatar.horizon.toString())
    formData.append('upright', avatar.upright.toString())
    formData.append('fitting', avatar.fitting!.toString())
    formData.append('style', avatar.style.toString())
    formData.append('clip', avatar.clip)
    formData.append('radius', avatar.radius.toString())
    formData.append('effect', avatar.effect)
    formData.append('value', avatar.value.toString())
    formData.append('shadow', avatar.shadow)
    formData.append('color', avatar.color)
    formData.append('session', auth.session!.split(':')[2])
    formData.append('file', fileObject)
    const response = await request(EM.INTR.URL.AVATR, auth.token, formData, EM.COMM.APIMETHOD.PUT)
    let intro = snapShots[index].value.introduction!
    intro = { ...intro, avatar: response.data }
    const newModule = { ...module, value: { introduction: intro } }
    const newModules = [...snapShots.slice(0, index), newModule, ...snapShots.slice(index + 1)]
    setModules(newModules)
    authUpdateMODLSnapshot(newModules, dispatch)
    authSuccess(auth, dispatch)
  } catch (error: any) {
    authFail(auth, dispatch, error.response.data)
  }
}

export const updateIntroCustomField = async (
  auth: IF.AUTH.AuthIF,
  dispatch: any,
  index: number,
  module: IF.STAT.ModuleIF,
  MODLSnapshot: IF.STAT.ModuleIF[],
  setModules: React.Dispatch<React.SetStateAction<IF.STAT.ModuleIF[]>>
) => {
  // ok
  const moduleLength = module.value.introduction!.content.length
  const snapShotLength = MODLSnapshot[index].value.introduction!.content.length
  const newContent = difference(module.value.introduction!.content, MODLSnapshot[index].value.introduction!.content)[0]
  let url: string = EM.INTR.URL.CUSTOM
  let method: string = EM.COMM.APIMETHOD.POST
  if (moduleLength < snapShotLength) {
    method = EM.COMM.APIMETHOD.DELETE
  } else if (moduleLength === snapShotLength) {
    method = EM.COMM.APIMETHOD.PATCH
  }
  if (method !== EM.COMM.APIMETHOD.POST) url = url + `${newContent.id}/`
  try {
    await request(url, auth.token, newContent, method)
    const newModules = [...MODLSnapshot.slice(0, index), module, ...MODLSnapshot.slice(index + 1)]
    setModules(newModules)
    authUpdateMODLSnapshot(newModules, dispatch)
    authSuccess(auth, dispatch)
  } catch (error: any) {
    authFail(auth, dispatch, error.response.data)
  }
}
