import { Button, Dropdown } from 'antd'
import type { MenuProps } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { IF, RS } from '../imports'
import React from 'react'


const AvatarObject = (props: {
    loading: boolean,
    objectFit: IF.COMM.ValueNameIF,
    saveStyle: React.CSSProperties
    handleAttribute: (item: IF.COMM.ValueNameIF) => void
}) => {
    /** Properties */
    const { loading, objectFit, saveStyle, handleAttribute } = props

    /** Local Variables */
    const buttonStyle = { ...saveStyle, width: '120px' }
    const items: MenuProps['items'] = RS.INTR.objectFits.map((item: IF.COMM.ValueNameIF, index: number) => {
        return {
            key: index,
            label: <div style={RS.COMM.width100} onClick={() => handleAttribute(item)}>{item.name}</div>
        }
    })

    return <Dropdown menu={{ items }} trigger={['click']} disabled={loading}>
        <Button size='small' type='primary' style={buttonStyle}>
            <span>{objectFit.name}</span><DownOutlined />
        </Button>
    </Dropdown>
}

export default AvatarObject