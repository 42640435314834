import React from 'react'
import style from '../../assets/editor.module.scss'
import BackgroundSetting from '../Background/BackgroundSetting'
import { EM, IF } from '../imports'
import EditorSVG from '../Common/EditorSVG'
import * as m from './methods'

const BackgroundEditor = (props: {
  auth: IF.AUTH.AuthIF
  control: IF.CTRL.ControlIF
  saveStyle: React.CSSProperties
  undoStyle: React.CSSProperties
  uploading: boolean
  uploadBar: any
  background: IF.BKGD.BackgroundIF
  backgrounds: IF.BKGD.BackgroundIF[]
  snapShot: IF.BKGD.BackgroundIF
  setControl: React.Dispatch<React.SetStateAction<IF.CTRL.ControlIF>>
  setUploading: React.Dispatch<React.SetStateAction<boolean>>
  setUploadbar: React.Dispatch<React.SetStateAction<any>>
  setFileObject: React.Dispatch<React.SetStateAction<string | File>>
  setBackgrounds: React.Dispatch<React.SetStateAction<IF.BKGD.BackgroundIF[]>>
  setCurrentBGTop: React.Dispatch<React.SetStateAction<number>>
}) => {
  /** Properties */
  const {
    auth,
    control,
    saveStyle,
    undoStyle,
    uploading,
    uploadBar,
    background,
    backgrounds,
    snapShot,
    setControl,
    setUploading,
    setUploadbar,
    setFileObject,
    setBackgrounds,
    setCurrentBGTop,
  } = props

  /** React Methods */
  const [isEditing, setIsEditing] = React.useState<boolean>(false)
  const [dragIndex, setDragIndex] = React.useState<number>(0)

  const scrollBG = (item: IF.BKGD.BackgroundIF, index: number) => {
    setIsEditing(true)
    setControl({ ...control, index: index, page: 2 })
    document.getElementById(`${EM.CTRL.TYPE.BACKGROUND}-${item.title}`)!.scrollIntoView()
    const pages = Array.from(document.getElementsByClassName(style.editorPageOne) as HTMLCollectionOf<HTMLElement>)
    pages.forEach((pages: any) => (pages.style.transform = 'translateX(-100%)'))
    const currentBGTop = document.getElementById(`${EM.CTRL.TYPE.BACKGROUND}-${item.title}`)!.offsetTop
    setCurrentBGTop(currentBGTop)
  }

  return (
    <div className={style.editorPageDiv}>
      <div className={style.editorPage}>
        <div className={style.editorPageOne}>
          <div className={style.editorPageOneDiv}>
            <div className={style.editorPageOneAdd}>
              <div className={style.editorPageOneInner} style={saveStyle}>
                {EM.COMM.BUTTON.ADDBKGD}
              </div>
            </div>
            {backgrounds.map((item: IF.BKGD.BackgroundIF, index: number) => {
              let thisStyle = { background: item.image }
              if (item.option === 2) thisStyle = { background: item.color }
              return (
                <div
                  key={index}
                  className={style.editorPageOneOuterDrag}
                  onDrop={(ev) => m.dropBackground(ev, style, index, dragIndex, backgrounds, setBackgrounds)}
                  onDragOver={(ev: any) => ev.preventDefault()}
                >
                  {item.option !== 3 && (
                    <div
                      className={style.editorPageOneInner}
                      draggable={true}
                      style={thisStyle}
                      onClick={() => scrollBG(item, index)}
                      onDragStart={() => setDragIndex(index)}
                    ></div>
                  )}
                  {item.option === 3 && (
                    <div
                      className={style.editorPageOneInner}
                      draggable={true}
                      onClick={() => scrollBG(item, index)}
                      onDragStart={() => setDragIndex(index)}
                    >
                      <div style={{ width: '100%', height: '100%' }}>
                        <EditorSVG svg={background.svg} height={'100%'} />
                      </div>
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        </div>
        <div className={style.editorPageOne}>
          {isEditing && (
            <BackgroundSetting
              auth={auth}
              control={control}
              saveStyle={saveStyle}
              undoStyle={undoStyle}
              uploading={uploading}
              uploadBar={uploadBar}
              background={background}
              backgrounds={backgrounds}
              snapShot={snapShot}
              setControl={setControl}
              setUploading={setUploading}
              setUploadbar={setUploadbar}
              setFileObject={setFileObject}
              setBackgrounds={setBackgrounds}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default BackgroundEditor
