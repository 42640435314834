import React from 'react'
import style from '../../assets/editor.module.scss'
import { Row } from 'antd'
import { EditorDropDown, EditorHistoryLabel, EditorImage, EditorLabel } from '../Common/EditorParts'
import { EditorHistoryIconPicker } from '../Common/History'
import { EM, IF, RS } from '../imports'
import { isEqual } from 'lodash'
import EditorAntIconPicker from '../Common/EditorAntIconPicker'
import EditorHuePicker from '../Common/EditorHuePicker'
import EditorImagePicker from '../Common/EditorImagePicker'
import EditorSlider from '../Common/EditorSlider'
import { handleControlIcon } from '../Control/methods'
import { handleColorPickerWidth } from './methods'
import { toggleDisplay } from '../../utils'

/**
 * Control editor component to edit background properties.
 * @param auth authentication state.
 * @param control control state.
 * @param saveStyle CSS style for the save button.
 * @param undoStyle CSS style for the undo button.
 * @param uploading indicating whether the file component is uploading.
 * @param uploadBar upload status while uploading a file.
 * @param snapShot control state on the reducer level.
 * @param setControl React dispatcher callback function to update control state.
 * @param setUploading React dispatcher callback function to update uploading state.
 * @param setUploadbar React dispatcher callback function to update uploadbar state.
 * @param setFileObject React dispatcher callback function to update fileObject state.
 * @returns control editor JSX component.
 */
const ControlEditor: React.FC<{
  auth: IF.AUTH.AuthIF
  control: IF.CTRL.ControlIF
  saveStyle: React.CSSProperties
  undoStyle: React.CSSProperties
  uploading: boolean
  uploadBar: IF.COMM.UploadStatusIF
  snapShot: IF.CTRL.ControlIF
  setControl: React.Dispatch<React.SetStateAction<IF.CTRL.ControlIF>>
  setUploading: React.Dispatch<React.SetStateAction<boolean>>
  setUploadbar: React.Dispatch<React.SetStateAction<any>>
  setFileObject: React.Dispatch<React.SetStateAction<string | File>>
}> = ({
  auth,
  control,
  saveStyle,
  undoStyle,
  uploading,
  uploadBar,
  snapShot,
  setControl,
  setUploading,
  setUploadbar,
  setFileObject,
}) => {
  /**
   * React hooks on the control editor level.
   */
  const [selection, setSelection] = React.useState<IF.COMM.ValueNameIF>(RS.COMM.selectOption)
  const [fileName, setFileName] = React.useState<string>(EM.COMM.FILENAME.NOTAVAILABLE)
  const imageRef: any = React.useRef(null)

  /**
   * Obtains the color picker width upon loading the background setting component.
   */
  React.useEffect(() => {
    handleColorPickerWidth()
  }, [])

  /* Local Variables */
  /**
   * Local dynamic variables.
   * - showPickerRow: toggle to display the picker row component.
   * - showHueRow: toggle to display the hue component.
   */
  const showPickerRow = toggleDisplay(!isEqual(selection, RS.COMM.selectOption))
  const showHueRow = toggleDisplay(isEqual(selection, RS.COMM.selectIcon) || control.option === 2)

  /**
   * The main image component to be rendered for the background setting component.
   * @returns the JSX element to be rendered.
   */
  const mainImage = (): JSX.Element => {
    return (
      <div className={style.editorPreviewImage}>
        {control.option === 1 && (
          <div className={style.editorPreviewIconA}>
            <img
              src={control.image}
              alt={'control-image'}
              style={{ width: `${control.size}px`, height: `${control.size}px` }}
              loading="lazy"
            />
          </div>
        )}
        {control.option === 2 && <div className={style.editorPreviewIconB}>{handleControlIcon(control)}</div>}
      </div>
    )
  }

  /**
   * Update the dropdown selection option.
   * @param item selection option.
   * @returns void.
   */
  const onChangeOption = (item: IF.COMM.ValueNameIF) => {
    setSelection(item)
    const option = isEqual(item, RS.COMM.selectIcon) ? 2 : 1
    setControl({ ...control, option: option })
  }

  /**
   * Update the control image.
   * @param image string.
   * @returns void.
   */
  const handlePreviewImage = (image: string) => {
    setControl({ ...control, image: image, option: 1 })
    setFileName(EM.COMM.FILENAME.NOTAVAILABLE)
    setFileObject('')
  }

  /**
   * Update the control icon.
   * @param icon string.
   * @returns void.
   */
  const handlePreviewIcon = (icon: string) => {
    setControl({ ...control, icon: icon, option: 2 })
    setFileName(EM.COMM.FILENAME.NOTAVAILABLE)
    setFileObject('')
  }

  /**
   * Update the control image URL.
   * @param url string.
   * @returns void.
   */
  const handlePreviewURL = (url: string) => {
    setControl({ ...control, image: url, option: 1 })
    setFileName(EM.COMM.FILENAME.NOTAVAILABLE)
    setFileObject('')
  }

  /**
   * Update the control image upload file.
   * @param event React change event for the HTML input element.
   * @returns void.
   */
  const handlePreviewUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files![0] === undefined) return
    if (event.target.files![0].size > RS.COMM.size5MB) {
      setControl({ ...control, image: snapShot.image, option: snapShot.option })
      setFileName(EM.COMM.FILENAME.NOTAVAILABLE)
      setFileObject('')
      return
    }
    const imageReader = new FileReader()
    imageReader.onload = (event) => {
      setControl({ ...control, image: `${event?.target?.result}`, option: 1 })
    }
    imageReader.readAsDataURL(event.target.files![0])
    setFileName(event.target.files![0].name)
    setFileObject(event.target.files![0])
  }

  /**
   * Update the control icon color.
   * @param color control icon color.
   * @returns void.
   */
  const handlePreviewColor = (color: string) => {
    setControl({ ...control, color: color, option: 2 })
  }

  /**
   * Update the control icon color.
   * @param color control icon color.
   * @returns void.
   */
  const handlePreviewHistoryIcon = (value: string, option: number) => {
    setControl({ ...control, icon: value, option: option })
    setFileObject('')
  }

  /**
   * Update the control size.
   * @param size control size.
   * @returns void.
   */
  const handlePreviewSize = (size: number) => {
    setControl({ ...control, size: size })
  }

  return (
    <div>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.CTRL.DRAWER.IMAGE} />
        <EditorImage mainImage={mainImage()} />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.CTRL.DRAWER.MODE} />
        <EditorDropDown
          span={18}
          loading={auth.loading}
          sourceList={RS.COMM.selectionIcon}
          selection={selection}
          saveStyle={saveStyle}
          onChangeItem={onChangeOption}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.CTRL.DRAWER.SIZE} />
        <EditorSlider
          min={1}
          max={150}
          span={18}
          value={control.size}
          width={RS.COMM.width90}
          handleAttribute={handlePreviewSize}
        />
      </Row>
      <Row className={style.editorRow} style={showPickerRow}>
        <EditorImagePicker
          type={EM.COMM.IMAGETYPE.CTRL}
          images={RS.CTRL.orbsList}
          selection={selection}
          imageRef={imageRef}
          saveStyle={saveStyle}
          fileName={fileName}
          uploading={uploading}
          uploadBar={uploadBar}
          loading={auth.loading}
          setUploading={setUploading}
          setUploadbar={setUploadbar}
          handlePreviewImage={handlePreviewImage}
          handlePreviewURL={handlePreviewURL}
          handlePreviewUpload={handlePreviewUpload}
        />
        <EditorAntIconPicker
          color={control.color}
          selection={selection}
          iconList={RS.CTRL.controlIconList}
          handlePreviewIcon={handlePreviewIcon}
        />
      </Row>
      <Row className={style.editorRow} style={showHueRow}>
        <EditorLabel span={6} label={EM.CTRL.DRAWER.COLOR} />
        <EditorHuePicker
          span={18}
          width={'70%'}
          color={control.color}
          undoStyle={undoStyle}
          handlePreviewColor={handlePreviewColor}
        />
      </Row>
      <Row className={style.editorRowHistory}>
        <EditorHistoryLabel history={control.history} />
        <EditorHistoryIconPicker
          color={control.color}
          history={control.history}
          handlePreviewHistoryIcon={handlePreviewHistoryIcon}
        />
      </Row>
    </div>
  )
}

export default ControlEditor
