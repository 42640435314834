import React from 'react'
import style from '../../assets/editor.intro.module.scss'
import { Button, Col, Row, Switch } from 'antd'
import { HuePicker } from 'react-color'
import { EM, IF, RS } from '../imports'
import {
  EditorDropDown,
  EditorHistoryLabel,
  EditorImage,
  EditorLabel,
  EditorParallel,
  EditorSlider,
} from '../Common/EditorParts'
import { EditorHistoryValuePicker } from '../Common/History'
import { handleHistoryMODL } from '../Editor/methods'
import AvatarObject from './AvatarObject'
import AvatarStyle from './AvatarStyle'
import AvatarValue from './AvatarValue'
import * as m from './methods'
import EditorImagePicker from '../Common/EditorImagePicker'

const AvatarSetting = (props: any) => {
  /** Properties */
  const {
    loading,
    avatar,
    control,
    module,
    modules,
    snapShot,
    saveStyle,
    undoStyle,
    uploading,
    uploadBar,
    setModules,
    setUploading,
    setUploadbar,
    setFileObject,
  } = props

  /** React Methods */
  const [selection, setSelection] = React.useState<IF.COMM.ValueNameIF>(RS.COMM.selectOption)
  const [objectFit, setObjectFit] = React.useState<IF.COMM.ValueNameIF>(RS.COMM.select)
  const [effect, setEffect] = React.useState<IF.COMM.ValueNameIF>(RS.COMM.selectOption)
  const [clipPath, setClipPath] = React.useState<number[]>(avatar.clip.split(','))
  const [fileName, setFileName] = React.useState<string>(EM.COMM.FILENAME.NOTAVAILABLE)
  // const [divWidth, setDivWidth] = React.useState<number>(avatar.avatarWidth)
  const imageRef: any = React.useRef(null)

  const [normalStyle, setNormalStyle] = React.useState<IF.INTR.IntroNormalIF>(avatar)
  const [polygonStyle, setPolygonStyle] = React.useState<IF.INTR.IntroPolygonIF>(avatar)

  React.useEffect(() => {
    const ratio = localStorage.getItem('ratios')?.split(',')[control.index]
    m.updateAvatarStyle(true, ratio, avatar, setNormalStyle, setPolygonStyle)
  }, [avatar])

  /** Local Variables */
  const avatarStyle = avatar.style !== 3 ? normalStyle : polygonStyle
  const syncStyle = { backgroundColor: control.color, width: '120px' }
  const normalBtn = avatar.style === 1 ? saveStyle : undoStyle
  const circleBtn = avatar.style === 2 ? saveStyle : undoStyle
  const polygonBtn = avatar.style === 3 ? saveStyle : undoStyle
  const showPickerRow =
    selection.value === EM.COMM.OPTION.DEFAULT || selection.value === EM.COMM.OPTION.DISABLE
      ? RS.COMM.hideBlock
      : RS.COMM.showFlex
  const mainImage = (
    <div className={style.editorPreviewImage} style={{ height: '200px' }}>
      <img src={avatar.image} style={avatarStyle} alt={`intro-avatar-${control.index}`} />
    </div>
  )

  /**
   * Update the avatar image.
   * @param image string.
   * @returns void.
   */
  const handlePreviewImage = (image: string) => {
    const newModule = { ...module }
    let newIntro = { ...module.value.introduction! }
    let newAvatar = newIntro.avatar
    newAvatar = { ...newAvatar, image: image }
    newIntro = { ...newIntro, avatar: newAvatar }
    newModule.value = { introduction: newIntro }
    setModules([...modules.slice(0, control.index), newModule, ...modules.slice(control.index + 1)])
    setFileName(EM.COMM.FILENAME.NOTAVAILABLE)
    setFileObject('')
  }

  /**
   * Update the avatar image URL.
   * @param url string.
   * @returns void.
   */
  const handlePreviewURL = (url: string) => {
    const newModule = { ...module }
    let newIntro = { ...module.value.introduction! }
    let newAvatar = newIntro.avatar
    newAvatar = { ...newAvatar, image: url }
    newIntro = { ...newIntro, avatar: newAvatar }
    newModule.value = { introduction: newIntro }
    setModules([...modules.slice(0, control.index), newModule, ...modules.slice(control.index + 1)])
    setFileName(EM.COMM.FILENAME.NOTAVAILABLE)
    setFileObject('')
  }

  /**
   * Update the avatar image upload file.
   * @param event React change event for the HTML input element.
   * @returns void.
   */
  const handlePreviewUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newModule = { ...module }
    let newIntro = { ...module.value.introduction! }
    let newAvatar = newIntro.avatar
    if (event.target.files![0] === undefined) return
    if (event.target.files![0].size > RS.COMM.size5MB) {
      setModules([...modules.slice(0, control.index), snapShot, ...modules.slice(control.index + 1)])
      setFileName(EM.COMM.FILENAME.NOTAVAILABLE)
      setFileObject('')
    }
    const imageReader = new FileReader()
    imageReader.onload = (event) => {
      newAvatar = { ...newAvatar, image: event!.target!.result! as string }
      newIntro = { ...newIntro, avatar: newAvatar }
      newModule.value = { introduction: newIntro }
      setModules([...modules.slice(0, control.index), newModule, ...modules.slice(control.index + 1)])
    }
    imageReader.readAsDataURL(event.target.files![0])
    setFileName(event.target.files![0].name)
    setFileObject(event.target.files![0])
  }

  const handlePreview = (event: any, selection: string) => {
    m.handleAvatarPreview(event, control, selection, module, modules, snapShot, setModules, setFileName, setFileObject)
  }

  const handleAttribute = (event: any, attribute: string) => {
    m.handleAvatarAttribute(event, attribute, control, module, modules, setModules)
  }

  const handleHistoryPreview = (value: string) => {
    handleHistoryMODL(EM.COMM.MODL.INTRAVTR, value, control, module, modules, setModules, setFileObject)
  }

  const onChangeSelect = (item: IF.COMM.ValueNameIF) => {
    setSelection(item)
    if (item.value === EM.COMM.OPTION.DISABLE) {
      handlePreview('', EM.COMM.OPTION.DISABLE)
    }
  }

  const onChangeEffect = (item: IF.COMM.ValueNameIF) => {
    setEffect(item)
    handleAttribute(item.value, EM.INTR.AVATAR.EFFECT)
  }

  const onChangeObjectFit = (item: IF.COMM.ValueNameIF) => {
    setObjectFit(item)
    handleAttribute(item.value, EM.INTR.AVATAR.OBJECT)
  }

  const styleElements = [
    <Button key="1" style={normalBtn} onClick={() => handleAttribute(1, EM.INTR.AVATAR.STYLE)}>
      {EM.INTR.AVATAR.NORMAL}
    </Button>,
    <Button key="2" style={circleBtn} onClick={() => handleAttribute(2, EM.INTR.AVATAR.STYLE)}>
      {EM.INTR.AVATAR.CIRCLE}
    </Button>,
    <Button key="3" style={polygonBtn} onClick={() => handleAttribute(3, EM.INTR.AVATAR.STYLE)}>
      {EM.INTR.AVATAR.POLYGON}
    </Button>,
  ]

  return (
    <div className={style.editorPageTwo}>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.INTR.AVATAR.AVATAR} />
        <EditorImage mainImage={mainImage} />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.INTR.AVATAR.SELECT} />
        <EditorDropDown
          span={18}
          loading={loading}
          sourceList={RS.INTR.avatarSelect}
          selection={selection}
          saveStyle={{ ...saveStyle, width: '150px' }}
          onChangeItem={onChangeSelect}
        />
      </Row>
      <Row className={style.editorRow} style={showPickerRow}>
        <EditorImagePicker
          type={EM.COMM.IMAGETYPE.AVATAR}
          images={RS.BKGD.pickers}
          selection={selection}
          imageRef={imageRef}
          saveStyle={saveStyle}
          fileName={fileName}
          uploading={uploading}
          uploadBar={uploadBar}
          loading={loading}
          setUploading={setUploading}
          setUploadbar={setUploadbar}
          handlePreviewImage={handlePreviewImage}
          handlePreviewURL={handlePreviewURL}
          handlePreviewUpload={handlePreviewUpload}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.INTR.AVATAR.FRAME} />
        <EditorSlider
          min={0}
          max={200}
          span={18}
          value={avatar.frame}
          width={RS.COMM.width90}
          attribute={EM.INTR.AVATAR.FRAME}
          handleAttribute={handleAttribute}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.INTR.AVATAR.WIDTH} />
        <EditorSlider
          min={0}
          max={100}
          span={18}
          value={avatar.width}
          width={RS.COMM.width90}
          attribute={EM.INTR.AVATAR.WIDTH}
          handleAttribute={handleAttribute}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.INTR.AVATAR.HEIGHT} />
        <EditorSlider
          min={0}
          max={100}
          span={18}
          value={avatar.height}
          width={RS.COMM.width90}
          attribute={EM.INTR.AVATAR.HEIGHT}
          handleAttribute={handleAttribute}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.INTR.AVATAR.HORIZON} />
        <EditorSlider
          min={0}
          max={100}
          span={18}
          value={avatar.horizon}
          width={RS.COMM.width90}
          attribute={EM.INTR.AVATAR.HORIZON}
          handleAttribute={handleAttribute}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.INTR.AVATAR.UPRIGHT} />
        <EditorSlider
          min={0}
          max={100}
          span={18}
          value={avatar.upright}
          width={RS.COMM.width90}
          attribute={EM.INTR.AVATAR.UPRIGHT}
          handleAttribute={handleAttribute}
        />
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.INTR.AVATAR.DISPLAY} />
        <Col span={18}>
          <div className={style.introAvatarRound}>
            <Switch
              style={syncStyle}
              defaultChecked={avatar.show === EM.COMM.DISPLAY.FLEX}
              checkedChildren={EM.COMM.VAR.SHOW}
              unCheckedChildren={EM.COMM.VAR.HIDE}
              onChange={(checked: boolean) => handleAttribute(checked, EM.INTR.AVATAR.DISPLAY)}
            />
            <AvatarObject
              loading={loading}
              objectFit={objectFit}
              saveStyle={saveStyle}
              handleAttribute={onChangeObjectFit}
            />
          </div>
        </Col>
      </Row>
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.INTR.AVATAR.STYLE} />
        <EditorParallel span={18} elements={styleElements} />
      </Row>
      <AvatarStyle
        avatar={avatar}
        style={style}
        clipPath={clipPath}
        setClipPath={setClipPath}
        handleAttribute={handleAttribute}
      />
      <Row className={style.editorRow}>
        <EditorLabel span={6} label={EM.INTR.AVATAR.EFFECT} />
        <EditorDropDown
          span={18}
          loading={loading}
          sourceList={RS.INTR.effectObjects}
          selection={effect}
          saveStyle={{ ...saveStyle, width: '150px' }}
          onChangeItem={onChangeEffect}
        />
      </Row>
      <AvatarValue
        style={style}
        value={avatar.value}
        effect={avatar.effect}
        shadow={avatar.shadow}
        color={avatar.color}
        HuePicker={HuePicker}
        handleAttribute={handleAttribute}
      />
      <Row className={style.editorRowHistory}>
        <EditorHistoryLabel history={avatar.history} />
        <EditorHistoryValuePicker history={avatar.history} handleHistoryPreview={handleHistoryPreview} />
      </Row>
    </div>
  )
}

export default AvatarSetting
