import { MenuUnfoldOutlined, PicCenterOutlined, RightSquareOutlined, ProfileOutlined } from '@ant-design/icons'
import style from '../assets/editor.module.scss'
import { IF } from '../components/imports'
import { SETTING } from '../enums/navigation'

export const navibar: IF.NAVG.NaviBarIF = {
  id: 1,
  type: 1,
  widthOne: 100,
  widthTwo: 100,
  heightOne: 50,
  heightTwo: 40,
  sizeOne: 20,
  sizeTwo: 15,
  textOne: 'rgba(240, 240, 240, 1)',
  textTwo: 'rgba(240, 240, 240, 1)',
  colorOne: 'rgba(240, 240, 240, 1)',
  colorTwo: 'rgba(240, 240, 240, 1)',
  imageOne: 'https://havkonstudio-publicfiles.s3.us-west-2.amazonaws.com/havkonstudio/web_bg3.jpg',
  imageTwo: 'https://havkonstudio-publicfiles.s3.us-west-2.amazonaws.com/havkonstudio/web_bg5.jpg',
  fontOne: '"Garamond", serif',
  fontTwo: '"Garamond", serif',
  weightOne: 1,
  weightTwo: 1,
  optionOne: 1,
  optionTwo: 1,
  positionOne: 100,
  positionTwo: 100,
  historyOne: [
    { value: 'https://havkonstudio-publicfiles.s3.us-west-2.amazonaws.com/havkonstudio/web_bg1.jpg', option: 1 },
    { value: 'rgba(69, 94, 211, 1)', option: 2 },
  ],
  historyTwo: [
    { value: 'https://havkonstudio-publicfiles.s3.us-west-2.amazonaws.com/havkonstudio/web_bg1.jpg', option: 1 },
    { value: 'rgba(69, 94, 211, 1)', option: 2 },
  ],
  sync: 0,
}

export const navPreview: IF.NAVG.NaviStyleIF = {
  color: 'rgba(255, 255, 255, 1)',
  width: '100%',
  height: '50px',
  fontSize: '36px',
  background:
    'url(https://havkonstudio-publicfiles.s3.us-west-2.amazonaws.com/havkonstudio/web_bg3.jpg) center center / cover',
  fontFamily: '"Garamond", serif',
  fontWeight: 1,
}

export const menu: IF.NAVG.MenuBarIF = {
  id: 1,
  width: 300,
  image: 'https://havkonstudio-publicfiles.s3.us-west-2.amazonaws.com/havkonstudio/web_bg3.jpg',
  color: 'rgba(255, 255, 255, 0.3)',
  position: 50,
  title: 'My Menu',
  titleSize: 40,
  titleColor: 'rgba(255, 255, 255, 1)',
  titleFont: '"Garamond", serif',
  itemSize: 48,
  itemColor: 'rgba(255, 255, 255, 1)',
  itemFont: '"Garamond", serif',
  icon: 'MenuUnfoldOutlined',
  iconImage: 'https://havkonstudio-publicfiles.s3.us-west-2.amazonaws.com/havkonstudio/home_orb0.gif',
  iconSize: 48,
  iconColor: 'rgba(100, 100, 100, 1)',
  iconOption: 1,
  option: 1,
  menuHistory: [
    { value: 'https://havkonstudio-publicfiles.s3.us-west-2.amazonaws.com/havkonstudio/web_bg1.jpg', option: 1 },
    { value: 'rgba(69, 94, 211, 1)', option: 2 },
  ],
  iconHistory: [
    { value: 'https://havkonstudio-publicfiles.s3.us-west-2.amazonaws.com/havkonstudio/home_orb0.gif', option: 1 },
    { value: 'MenuUnfoldOutlined', option: 2 },
  ],
}

export const menuList: IF.COMM.ValueIF[] = [
  { name: SETTING.NAVITOP, value: '', color: '', allow: true, number: 1 },
  { name: SETTING.NAVIBOT, value: '', color: '', allow: true, number: 1 },
  { name: SETTING.MENU, value: '', color: '', allow: true, number: 1 },
  { name: SETTING.ICON, value: '', color: '', allow: true, number: 1 },
  { name: SETTING.SYNC, value: '#468B97', color: '#FFFF', allow: true, number: 1 },
]

export const naviCollapse = [{ title: 'Navbar Top', key: '0' }]

export const quickSync: IF.NAVG.QuickSyncIF = {
  syncZero: false,
  syncOne: false,
  syncTwo: false,
  syncThree: false,
  syncFour: false,
}

export const menuIconList = (color: string) => {
  return [
    { icon: 'MenuUnfoldOutlined', jsx: <MenuUnfoldOutlined className={style.pickerIconTT} style={{ color: color }} /> },
    { icon: 'PicCenterOutlined', jsx: <PicCenterOutlined className={style.pickerIconTT} style={{ color: color }} /> },
    {
      icon: 'RightSquareOutlined',
      jsx: <RightSquareOutlined className={style.pickerIconTT} style={{ color: color }} />,
    },
    { icon: 'ProfileOutlined', jsx: <ProfileOutlined className={style.pickerIconTT} style={{ color: color }} /> },
  ]
}
