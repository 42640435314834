import React from 'react'
import { request } from '../../utils/request'
import { EM, IF } from '../../components/imports'
import { authStart, authFail, authSuccess, authUpdateBKGDSnapshot } from './auth'

export const updateOrder = async (auth: IF.AUTH.AuthIF, dispatch: any, backgrounds: IF.BKGD.BackgroundIF[]) => {
  authStart(auth, dispatch)
  const input = { input: backgrounds, keys: ['order'] }
  try {
    const url = EM.BKGD.URL.ORDER
    const response = await request(url, auth.token, input, 'patch')
    const backgrounds = await response.data
    authUpdateBKGDSnapshot(backgrounds, dispatch)
    authSuccess(auth, dispatch)
  } catch (error: any) {
    authFail(auth, dispatch, error.response.data.error)
  }
}

export const updateBKGD = async (
  auth: IF.AUTH.AuthIF,
  dispatch: any,
  index: number,
  newBG: IF.BKGD.BackgroundIF,
  backgrounds: IF.BKGD.BackgroundIF[],
  fileObject: string | File,
  setBackgrounds: React.Dispatch<React.SetStateAction<IF.BKGD.BackgroundIF[]>>
) => {
  authStart(auth, dispatch)
  const oldBG = backgrounds[index]
  try {
    let response: any
    const url = EM.BKGD.URL.UPDATE
    if (fileObject === '') {
      // FUTURE: use this if we need backend fetching image
      // const pickerItem = pickerImages.find(item => (item.url === background.image))
      // let pickerImage: IF.PickerImageIF | undefined
      // if (pickerItem) pickerImage = RS.pickerImages.find(item => item.order === pickerItem.order)
      // if (pickerImage) background.image = pickerImage.url
      const isNewImage =
        newBG.color !== oldBG.color ||
        newBG.image !== oldBG.image ||
        newBG.svg !== oldBG.svg ||
        newBG.option !== oldBG.option
      const isNew = newBG.title !== oldBG.title || newBG.height !== oldBG.height
      if (!isNewImage && !isNew) {
        authSuccess(auth, dispatch)
        return
      }
      const input = { ...newBG, session: auth.session, new: isNewImage }
      response = await request(url, auth.token, input, 'patch')
    } else {
      const formData = new FormData()
      formData.append('id', newBG.id.toString())
      formData.append('option', newBG.option.toString())
      formData.append('title', newBG.title)
      formData.append('height', newBG.height!)
      formData.append('session', auth.session!.split(':')[2])
      formData.append('file', fileObject)
      response = await request(url, auth.token, formData, 'put')
    }
    const bg = await response.data
    const newBackgrounds = [...backgrounds.slice(0, index), bg, ...backgrounds.slice(index + 1)]
    authUpdateBKGDSnapshot(newBackgrounds, dispatch)
    setBackgrounds(newBackgrounds)
    authSuccess(auth, dispatch)
  } catch (error: any) {
    authFail(auth, dispatch, error.response.data.error)
  }
}
