import { IF } from '../components/imports'

export const profile: IF.STAT.ProfileIF = {
  firstName: 'John',
  lastName: 'Doe',
  email: 'johndoe@example.com',
  gender: 'male',
  phone: '1234',
  avatar: 'hello',
  synced: true,
}
