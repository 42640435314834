import React from 'react'
import style from '../../assets/editor.module.scss'
import { ChromePicker, ColorResult, RGBColor } from 'react-color'
import { GradientPicker } from 'react-linear-gradient-picker'
import { IF, RS, UT } from '../imports'

/**
 * Editor gradient picker component to pick gradient colors.
 * @param palette palette data.
 * @param gradientRef React gradient ref.
 * @param showGradientPicker indicating whether to display the color picker.
 * @param setPalette React dispatcher callback function to update palette state.
 * @returns editor gradient picker React functional component.
 */
const EditorGradientPicker: React.FC<{
  palette: IF.BKGD.SVGComponentIF[]
  gradientRef: React.RefObject<HTMLDivElement>
  showGradientPicker: boolean
  setPalette: React.Dispatch<React.SetStateAction<IF.BKGD.SVGComponentIF[]>>
}> = ({ palette, gradientRef, showGradientPicker, setPalette }) => {
  const [gradientColor, setGradientColor] = React.useState<RGBColor>(RS.BKGD.introRBG)
  const gradientStyle = UT.toggleDisplay(showGradientPicker)

  /**
   * Update the gradient color upon picking a color on the chrome picker.
   * @param color color picked on the chrome picker.
   * @returns void.
   */
  const handleGradientColor = (color: ColorResult) => {
    setGradientColor(color.rgb)
    const newPal = { offset: `${color.rgb.a}`, color: `rgb(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b})` }
    const index = palette.findIndex((item: IF.COMM.SVGIF) => item.active === true)
    console.info('index: ', index)
    const newPalette = [...palette]
    const newItem = { ...newPalette[index], color: newPal.color }
    newPalette[index] = newItem
    setPalette(newPalette)
  }

  return (
    <div ref={gradientRef} className={style.pickerGradientDiv} style={gradientStyle}>
      <GradientPicker {...{ width: 300, paletteHeight: 32, palette, onPaletteChange: setPalette }} />
      <br />
      <ChromePicker disableAlpha color={gradientColor} onChange={handleGradientColor} />
    </div>
  )
}

export default EditorGradientPicker
