import base from '../../assets/base.module.scss'
import { SyncOutlined } from '@ant-design/icons'

/**
 * Loading JSX component.
 * @returns loading JSX component.
 */
const Loading = () => {
  return (
    <div className={base.baseColContainer} style={{ height: '100vh' }}>
      <SyncOutlined spin style={{ fontSize: '4rem' }} />
      <br />
      <p>Loading ...</p>
    </div>
  )
}

export default Loading
