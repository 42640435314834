import React from 'react'
import base from '../../assets/base.module.scss'
import style from '../../assets/register.module.scss'
import * as m from './methods'


const Background = () => {
    /** React Methods */
    const firefly: any = React.useRef(null)
    React.useEffect(() => {
        m.handleRain(firefly, 15)
        // eslint-disable-next-line
    }, []);

    return (
        <div className={base.baseColContainer} ref={firefly}>
            <div className={style.registerBackground} />
        </div>
    )
}

export default Background
