import React from 'react'
import style from '../../assets/editor.module.scss'
import { Checkbox, Col, Row } from 'antd'
import { EM, IF, RS } from '../imports'
import * as m from './methods'
import { EditorText } from '../Common/EditorParts'


const SyncSetting = (props: {
    naviBar: IF.NAVG.NaviBarIF,
    setNaviBar: React.Dispatch<React.SetStateAction<IF.NAVG.NaviBarIF>>,
}) => {
    /** Properties */
    const { naviBar, setNaviBar } = props

    /** React Methods */
    const [quickSync, setQuickSync] = React.useState<IF.NAVG.QuickSyncIF>(RS.NAVG.quickSync)

    React.useEffect(() => {
        setQuickSync({
            syncZero: naviBar.sync === 0,
            syncOne: naviBar.sync === 1,
            syncTwo: naviBar.sync === 2,
            syncThree: naviBar.sync === 3,
            syncFour: naviBar.sync === 4,
        })
    }, [naviBar])

    const onChangeSync = (value: number) => {
        m.handleNavBarAttribute(
            value,
            EM.NAVG.LABEL.SYNC,
            naviBar,
            setNaviBar
        )
        setQuickSync({
            syncZero: naviBar.sync === value,
            syncOne: naviBar.sync === value,
            syncTwo: naviBar.sync === value,
            syncThree: naviBar.sync === value,
            syncFour: naviBar.sync === value,
        })
    }

    return <div className={style.editorPageTwo}>
        <Row className={style.editorRowSync}
            onClick={() => onChangeSync(0)}>
            <EditorText span={22} text={EM.NAVG.LABEL.SYNCZERO} />
            <Col span={2}>
                <div className={style.editorSyncCenter}>
                    <Checkbox checked={quickSync.syncZero}
                        onChange={() => onChangeSync(0)} />
                </div>
            </Col>
        </Row>
        <Row className={style.editorRowSync}
            onClick={() => onChangeSync(1)}>
            <EditorText span={22} text={EM.NAVG.LABEL.SYNCONE} />
            <Col span={2}>
                <div className={style.editorSyncCenter}>
                    <Checkbox checked={quickSync.syncOne}
                        onChange={() => onChangeSync(1)} />
                </div>
            </Col>
        </Row>
        <Row className={style.editorRowSync}
            onClick={() => onChangeSync(2)}>
            <EditorText span={22} text={EM.NAVG.LABEL.SYNCTWO} />
            <Col span={2}>
                <div className={style.editorSyncCenter}>
                    <Checkbox checked={quickSync.syncTwo}
                        onChange={() => onChangeSync(2)} />
                </div>
            </Col>
        </Row>
        <Row className={style.editorRowSync}
            onClick={() => onChangeSync(3)}>
            <EditorText span={22} text={EM.NAVG.LABEL.SYNCTHREE} />
            <Col span={2}>
                <div className={style.editorSyncCenter}>
                    <Checkbox checked={quickSync.syncThree}
                        onChange={() => onChangeSync(3)} />
                </div>
            </Col>
        </Row>
        <Row className={style.editorRowSync}
            onClick={() => onChangeSync(4)}>
            <EditorText span={22} text={EM.NAVG.LABEL.SYNCFOUR} />
            <Col span={2}>
                <div className={style.editorSyncCenter}>
                    <Checkbox checked={quickSync.syncFour}
                        onChange={() => onChangeSync(4)} />
                </div>
            </Col>
        </Row>
    </div>
}

export default SyncSetting