import style from '../../assets/register.module.scss'
import { Button } from 'antd'
import { isEmpty } from 'lodash'
import { EM, RX } from '../imports'


const Signout = (props: any) => {
    /** Properties */
    const { auth, dispatch } = props

    /** Local Methods */
    const onSignout = async () => {
        await RX.AUTH.authSignout(auth, dispatch, auth.token)
    }

    return (
        <div className={style.signoutSection}>
            {!isEmpty(auth.message) && <p className={style.signinMessage}>{auth.message}</p>}
            <Button size="large" type="primary" onClick={() => onSignout()}
                disabled={auth.loading} className={style.registerButton}>
                {EM.RSTR.TEXT.SIGNOUT}
            </Button>
        </div>
    )
}

export default Signout
