import React from 'react'
import style from '../../assets/control.module.scss'
import { handleControlIcon } from './methods'
import * as m from './methods'
import { IF } from '../imports'

const ControlIcon = (props: {
  control: IF.CTRL.ControlIF
  controlSnap: IF.CTRL.ControlIF
  controlIconRef: any
  setControl: React.Dispatch<React.SetStateAction<IF.CTRL.ControlIF>>
  setShowEdit: React.Dispatch<React.SetStateAction<IF.COMM.DisplayIF>>
}) => {
  /** Properties */
  const { control, controlSnap, controlIconRef, setControl, setShowEdit } = props

  /** React Methods */
  React.useEffect(() => {
    if (controlIconRef.current !== null) {
      setControl({ ...control, ...controlSnap, element: controlIconRef.current })
    }
  }, [controlIconRef.current, controlSnap])

  /** Local Variables */
  const imageStyle = { width: `${control.size}px`, height: `${control.size}px` }
  const iconStyle = {
    top: `${control.top}px`,
    left: `${control.left}px`,
    fontSize: `${control.size}px`,
    color: control.color,
  }

  /** Local Methods */
  m.dragElement(control, setControl, setShowEdit)

  return (
    <div id={style.controlIcon} style={iconStyle} ref={controlIconRef}>
      {control.option === 1 && <img style={imageStyle} src={control.image} alt={'control-icon'} />}
      {control.option === 2 && handleControlIcon(control)}
    </div>
  )
}

export default ControlIcon
