import React from 'react'

/**
 * Toggle whether to return the display CSS property based on the boolean parameter.
 * @param enableDisplay if the method should return the CSS property.
 * @returns display CSS property for displaying HTML elements.
 */
const toggleDisplay = (enableDisplay: boolean): React.CSSProperties => {
  return enableDisplay ? { display: 'flex' } : { display: 'none' }
}

export default toggleDisplay
