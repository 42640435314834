import React from 'react'
import { MenuUnfoldOutlined, PicCenterOutlined, RightSquareOutlined, ProfileOutlined } from '@ant-design/icons'
import { EM, IF, RS } from '../imports'
import { weightObject } from '../Introduction/methods'

export const handleScrollMenu = (
  naviBar: IF.NAVG.NaviBarIF,
  setNavStyle: React.Dispatch<React.SetStateAction<IF.NAVG.NaviStyleIF | IF.NAVG.NaviHideIF>>
) => {
  if (window.scrollY < 20) {
    setNavStyle({
      width: `${naviBar.widthOne}%`,
      height: `${naviBar.heightOne}px`,
      color: naviBar.textOne,
      fontSize: `${naviBar.sizeOne}px`,
      fontFamily: naviBar.fontOne,
      fontWeight: weightObject(naviBar.weightOne),
      background:
        naviBar.optionOne === 1 ? `url(${naviBar.imageOne}) center ${naviBar.positionOne}% / cover` : naviBar.colorOne,
    })
  } else if (naviBar.sync === 0 || naviBar.sync === 2 || naviBar.sync === 3) {
    setNavStyle({
      width: `${naviBar.widthTwo}%`,
      height: `${naviBar.heightTwo}px`,
      color: naviBar.textTwo,
      fontSize: `${naviBar.sizeTwo}px`,
      fontFamily: naviBar.fontTwo,
      fontWeight: weightObject(naviBar.weightTwo),
      background:
        naviBar.optionTwo === 1 ? `url(${naviBar.imageTwo}) center ${naviBar.positionTwo}% / cover` : naviBar.colorTwo,
    })
  } else {
    setNavStyle({
      width: `${naviBar.widthOne}%`,
      height: `${naviBar.heightOne - 10}px`,
      color: naviBar.textOne,
      fontSize: `${naviBar.sizeOne - 2}px`,
      fontFamily: naviBar.fontOne,
      fontWeight: weightObject(naviBar.weightOne),
      background:
        naviBar.optionOne === 1 ? `url(${naviBar.imageOne}) center ${naviBar.positionOne}% / cover` : naviBar.colorOne,
    })
  }

  window.onscroll = () => {
    if (window.scrollY < 20) {
      setNavStyle({
        width: `${naviBar.widthOne}%`,
        height: `${naviBar.heightOne}px`,
        color: naviBar.textOne,
        fontSize: `${naviBar.sizeOne}px`,
        fontFamily: naviBar.fontOne,
        fontWeight: weightObject(naviBar.weightOne),
        background:
          naviBar.optionOne === 1
            ? `url(${naviBar.imageOne}) center ${naviBar.positionOne}% / cover`
            : naviBar.colorOne,
      })
    } else if (naviBar.sync === 0 || naviBar.sync === 2 || naviBar.sync === 3) {
      setNavStyle({
        width: `${naviBar.widthTwo}%`,
        height: `${naviBar.heightTwo}px`,
        color: naviBar.textTwo,
        fontSize: `${naviBar.sizeTwo}px`,
        fontFamily: naviBar.fontTwo,
        fontWeight: weightObject(naviBar.weightTwo),
        background:
          naviBar.optionTwo === 1
            ? `url(${naviBar.imageTwo}) center ${naviBar.positionTwo}% / cover`
            : naviBar.colorTwo,
      })
    } else {
      setNavStyle({
        width: `${naviBar.widthOne}%`,
        height: `${naviBar.heightOne - 10}px`,
        color: naviBar.textOne,
        fontSize: `${naviBar.sizeOne - 2}px`,
        fontFamily: naviBar.fontOne,
        fontWeight: weightObject(naviBar.weightOne),
        background:
          naviBar.optionOne === 1
            ? `url(${naviBar.imageTwo}) center ${naviBar.positionTwo}% / cover`
            : naviBar.colorTwo,
      })
    }
  }
}

export const scrollToModule = (module: IF.STAT.ModuleIF) => {
  const height = (window.innerHeight * module.frame.height) / 400
  const html = document.documentElement
  html.scrollTop = module.frame.top - height
}

export const showNaviBar = (
  naviBar: IF.NAVG.NaviBarIF,
  setNaviStyle: React.Dispatch<React.SetStateAction<IF.NAVG.NaviStyleIF | IF.NAVG.NaviHideIF>>
) => {
  if (window.innerWidth < 576) {
    setNaviStyle(RS.COMM.hideBlock)
  } else {
    handleScrollMenu(naviBar, setNaviStyle)
  }
}

export const showMenuIcon = (
  menuBar: IF.NAVG.MenuBarIF,
  openIcon: boolean,
  setIconStyle: React.Dispatch<React.SetStateAction<IF.NAVG.menuIconIF>>
) => {
  if (window.innerWidth < 576) {
    setIconStyle({ ...RS.COMM.showBlock, fontSize: `${menuBar.iconSize}px`, color: menuBar.iconColor })
  } else {
    setIconStyle(openIcon ? RS.COMM.showBlock : RS.COMM.hideBlock)
  }
}

export const handleNavBarAttribute = (
  event: any,
  attribute: string,
  naviBar: IF.NAVG.NaviBarIF,
  setNaviBar: React.Dispatch<React.SetStateAction<IF.NAVG.NaviBarIF>>
) => {
  const newNaviBar: IF.NAVG.NaviBarIF = { ...naviBar }
  if (attribute === EM.NAVG.FORM.WIDTHTONE) {
    newNaviBar.widthOne = event
  } else if (attribute === EM.NAVG.FORM.WIDTHTWO) {
    newNaviBar.widthTwo = event
  } else if (attribute === EM.NAVG.FORM.HEIGHTONE) {
    newNaviBar.heightOne = event
  } else if (attribute === EM.NAVG.FORM.HEIGHTTWO) {
    newNaviBar.heightTwo = event
  } else if (attribute === EM.NAVG.FORM.SIZEONE) {
    newNaviBar.sizeOne = event
  } else if (attribute === EM.NAVG.FORM.SIZETWO) {
    newNaviBar.sizeTwo = event
  } else if (attribute === EM.NAVG.FORM.FONTONE) {
    newNaviBar.fontOne = event.value
  } else if (attribute === EM.NAVG.FORM.FONTTWO) {
    newNaviBar.fontTwo = event.value
  } else if (attribute === EM.NAVG.FORM.TEXTONE) {
    const rgba = event?.rgb
    const icolor = `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`
    newNaviBar.textOne = icolor
  } else if (attribute === EM.NAVG.FORM.TEXTTWO) {
    newNaviBar.textTwo = event
  } else if (attribute === EM.NAVG.FORM.WEIGHTONE) {
    newNaviBar.weightOne = event
  } else if (attribute === EM.NAVG.FORM.WEIGHTTWO) {
    newNaviBar.weightTwo = event
  } else if (attribute === EM.NAVG.FORM.POSITIONONE) {
    newNaviBar.positionOne = event
  } else if (attribute === EM.NAVG.FORM.POSITIONTWO) {
    newNaviBar.positionTwo = event
  } else if (attribute === EM.NAVG.LABEL.SYNC) {
    newNaviBar.sync = event
  }
  setNaviBar(newNaviBar)
}

export const handleNaviBarPreview = (
  position: number,
  event: any,
  selection: string,
  naviBar: IF.NAVG.NaviBarIF,
  snapShot: IF.NAVG.NaviBarIF,
  setNaviBar: React.Dispatch<React.SetStateAction<IF.NAVG.NaviBarIF>>,
  setFileName: React.Dispatch<React.SetStateAction<string>>,
  setFileObject: React.Dispatch<React.SetStateAction<string | File>>
) => {
  const newNaviBar = { ...naviBar }
  if (selection === EM.COMM.OPTION.UPLOAD) {
    if (event.target.files[0] === undefined) return
    if (event.target.files[0].size > RS.COMM.size5MB) {
      setNaviBar(snapShot)
      setFileName(EM.COMM.FILENAME.NOTAVAILABLE)
      setFileObject('')
      return
    }
    const imageReader = new FileReader()
    imageReader.onload = (event) => {
      if (position === 1) {
        setNaviBar({ ...newNaviBar, imageOne: event!.target!.result as string, optionOne: 1 })
      } else {
        setNaviBar({ ...newNaviBar, imageTwo: event!.target!.result as string, optionTwo: 1 })
      }
    }
    imageReader.readAsDataURL(event.target.files[0])
    setFileName(event.target.files[0].name)
    setFileObject(event.target.files[0])
    return
  } else if (selection === EM.COMM.OPTION.IMAGE) {
    if (position === 1) {
      newNaviBar.imageOne = event.target.src
      newNaviBar.optionOne = 1
    } else {
      newNaviBar.imageTwo = event.target.src
      newNaviBar.optionTwo = 1
    }
  } else if (selection === EM.COMM.OPTION.URL) {
    if (position === 1) {
      newNaviBar.imageOne = event.target.value
      newNaviBar.optionOne = 1
    } else {
      newNaviBar.imageTwo = event.target.value
      newNaviBar.optionTwo = 1
    }
  }
  setNaviBar(newNaviBar)
  setFileObject('')
}

export const handleMenuBarAttribute = (
  event: any,
  attribute: string,
  menuBar: IF.NAVG.MenuBarIF,
  setMenuBar: React.Dispatch<React.SetStateAction<IF.NAVG.MenuBarIF>>
) => {
  const newMenuBar: IF.NAVG.MenuBarIF = { ...menuBar }
  if (attribute === EM.NAVG.FORM.WIDTH) {
    newMenuBar.width = event
  } else if (attribute === EM.NAVG.FORM.POSITION) {
    newMenuBar.position = event
  } else if (attribute === EM.NAVG.FORM.TITLE) {
    newMenuBar.title = event
  } else if (attribute === EM.NAVG.FORM.TITLESIZE) {
    newMenuBar.titleSize = event
  } else if (attribute === EM.NAVG.FORM.TITLECOLOR) {
    const rgba = event?.rgb
    const icolor = `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`
    newMenuBar.titleColor = icolor
  } else if (attribute === EM.NAVG.FORM.TITLEFONT) {
    newMenuBar.titleFont = event.value
  } else if (attribute === EM.NAVG.FORM.ITEMSIZE) {
    newMenuBar.itemSize = event
  } else if (attribute === EM.NAVG.FORM.ITEMCOLOR) {
    newMenuBar.itemColor = event
  } else if (attribute === EM.NAVG.FORM.ITEMFONT) {
    newMenuBar.itemFont = event.value
  }
  setMenuBar(newMenuBar)
}

export const handleMenuBarPreview = (
  event: any,
  selection: string,
  menuBar: IF.NAVG.MenuBarIF,
  snapShot: IF.NAVG.MenuBarIF,
  setMenuBar: React.Dispatch<React.SetStateAction<IF.NAVG.MenuBarIF>>,
  setFileName: React.Dispatch<React.SetStateAction<string>>,
  setFileObject: React.Dispatch<React.SetStateAction<string | File>>
) => {
  const newMenuBar = { ...menuBar }
  if (selection === EM.COMM.OPTION.UPLOAD) {
    if (event.target.files[0] === undefined) return
    if (event.target.files[0].size > RS.COMM.size5MB) {
      setMenuBar(snapShot)
      setFileName(EM.COMM.FILENAME.NOTAVAILABLE)
      setFileObject('')
      return
    }
    const imageReader = new FileReader()
    imageReader.onload = (event) => {
      setMenuBar({ ...newMenuBar, image: event!.target!.result as string, option: 1 })
    }
    imageReader.readAsDataURL(event.target.files[0])
    setFileName(event.target.files[0].name)
    setFileObject(event.target.files[0])
    return
  } else if (selection === EM.COMM.OPTION.IMAGE) {
    newMenuBar.image = event.target.src
    newMenuBar.option = 1
  } else if (selection === EM.COMM.OPTION.URL) {
    newMenuBar.image = event.target.value
    newMenuBar.option = 1
  }
  setMenuBar(newMenuBar)
  setFileObject('')
}

export const handleShowMenuIcon = (icon: string, size: number, color: string) => {
  const iconObject: any = {
    MenuUnfoldOutlined: <MenuUnfoldOutlined style={{ fontSize: `${size}px`, color: color }} />,
    PicCenterOutlined: <PicCenterOutlined style={{ fontSize: `${size}px`, color: color }} />,
    RightSquareOutlined: <RightSquareOutlined style={{ fontSize: `${size}px`, color: color }} />,
    ProfileOutlined: <ProfileOutlined style={{ fontSize: `${size}px`, color: color }} />,
  }
  return iconObject[icon]
}
