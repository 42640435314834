import React from 'react'
import base from '../../assets/base.module.scss'
import style from '../../assets/register.module.scss'
import { useAppSelector, useAppDispatch } from '../../../hooks'
import { useHistory } from 'react-router-dom'
import { Button } from 'antd'
import { Link } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { EM, RX } from '../imports'
import * as m from './methods'

const Activate = () => {
    /** Redux Properties */
    const history = useHistory()
    const auth = useAppSelector(state => state.talentsReducer.auth)
    const dispatch = useAppDispatch()

    /** Local Variables */
    const pathName = window.location.pathname
    const name = pathName.split('/')[3]
    const uuid = pathName.split('/')[4]

    /** React Methods */
    React.useEffect(() => {
        RX.AUTH.authActivate(auth, dispatch, 'get', name, uuid)
    }, [])

    /** Local Methods */
    const resetMessage = () => { RX.AUTH.authSetMessage(auth, dispatch, null) }
    const resetError = () => { RX.AUTH.authSetError(auth, dispatch, null) }
    const activateUser = () => { RX.AUTH.authActivate(auth, dispatch, 'post', name, uuid) }
    const goToSignIn = () => {
        RX.AUTH.authSetMessage(auth, dispatch, null)
        history.push(EM.RSTR.URL.SIGNIN)
    }

    return (
        <div className={base.baseColContainer}>
            <div className={style.registerFrame}>
                <div className={style.registerActiveDiv}>
                    <div className={style.registerTitle}>{EM.RSTR.TITLE.ACTIVATE}</div>
                    {auth.loading && <p>{EM.RSTR.TEXT.LOADING}</p>}
                    {!isEmpty(auth.error) && auth.error === EM.RSTR.AUTH.NONE &&
                        <>
                            <div className={style.registerMessage}>
                                {m.activationNone}
                            </div>
                            <div className={style.registerCenter}>
                                {EM.RSTR.TEXT.ACCOUNTEXITS}&nbsp;
                                <Link
                                    className={style.registerCreate}
                                    onClick={resetError}
                                    to={EM.RSTR.URL.SIGNIN}>
                                    {EM.RSTR.TEXT.SIGNIN}
                                </Link>
                            </div>
                        </>
                    }
                    {!isEmpty(auth.error) && auth.error === EM.RSTR.AUTH.ACTIVE &&
                        <>
                            <div className={style.registerMessage}>
                                {m.activationActive}
                            </div>
                            <div className={style.registerCenter}>
                                <Link
                                    className={style.registerCreate}
                                    onClick={resetError}
                                    to={EM.RSTR.URL.SIGNIN}>
                                    {EM.RSTR.TEXT.SIGNIN}
                                </Link>
                            </div>
                        </>
                    }
                    {isEmpty(auth.error) && auth.message === EM.RSTR.AUTH.INACTIVE &&
                        <>
                            <div className={style.registerMessage}>
                                {m.activationInactive}
                            </div>
                            <div className={style.registerMessage} onClick={activateUser}>
                                <Button size="large" type="primary" htmlType="submit"
                                    disabled={auth.loading} className={style.registerButton}>
                                    {EM.RSTR.TEXT.ACTIVATE}
                                </Button>
                            </div>
                            <div className={style.registerCenter}>
                                {EM.RSTR.TEXT.ACCOUNTEXITS}&nbsp;
                                <Link
                                    className={style.registerCreate}
                                    onClick={resetMessage}
                                    to={EM.RSTR.URL.SIGNIN}>
                                    {EM.RSTR.TEXT.SIGNIN}
                                </Link>
                            </div>
                        </>
                    }
                    {isEmpty(auth.error) && auth.message === EM.RSTR.AUTH.ACTIVATED &&
                        <>
                            <div className={style.registerMessage}>
                                {m.activationActivated}
                            </div>
                            <div className={style.registerMessage} onClick={goToSignIn}>
                                <Button size="large" type="primary" htmlType="submit"
                                    disabled={auth.loading} className={style.registerButton}>
                                    {EM.RSTR.TEXT.SIGNIN}
                                </Button>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default Activate
