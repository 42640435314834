import { Row, Col } from 'antd'
import { EM, RS } from '../imports'
import { EditorLabel, EditorSlider } from '../Common/EditorParts'


const AvatarStyle = (props: any) => {
    /** Properties */
    const { avatar, style, clipPath, setClipPath, handleAttribute } = props

    /** Local Variables */
    const displayOne = avatar.style === 1 ? RS.COMM.showFlex : RS.COMM.hideBlock
    const displayTwo = avatar.style === 3 ? RS.COMM.showFlex : RS.COMM.hideBlock
    const handleClipPath = (value: number, index: number) => {
        const newClipPath = [
            ...clipPath.slice(0, index),
            value,
            ...clipPath.slice(index + 1)
        ]
        setClipPath(newClipPath)
        handleAttribute(
            newClipPath.join(),
            EM.INTR.AVATAR.CLIPING
        )
    }

    return <>
        <Row className={style.editorRow} style={displayOne}>
            <EditorLabel span={6} label={EM.INTR.AVATAR.ROUND} />
            <EditorSlider
                min={0}
                max={100}
                span={18}
                value={avatar.radius}
                width={RS.COMM.width90}
                attribute={EM.INTR.AVATAR.RADIUS}
                handleAttribute={handleAttribute} />
        </Row>
        <Row className={style.editorRow} style={displayTwo}>
            <EditorLabel span={6} label={EM.INTR.AVATAR.CLIPING} />
            <Col span={18}>
                {Array(8).fill(0).map((_, index) => (
                    <EditorSlider
                        key={index}
                        min={-100}
                        max={100}
                        span={18}
                        value={clipPath[index]}
                        width={RS.COMM.width90}
                        attribute={EM.INTR.AVATAR.HEIGHT}
                        handleAttribute={(value: any) => handleClipPath(value, index)} />
                ))}
            </Col>
        </Row>
    </>
}

export default AvatarStyle