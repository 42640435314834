import { IC, IF } from '../components/imports'
import { OPTION } from '../enums/common'
import { ATTRIBUTE, FIELDSELECT, FIELDLABEL, LABEL, SETTING } from '../enums/introduction'

export const greeting: IF.INTR.IntroContentIF = {
  id: 1,
  label: 'greeting',
  text: 'Hello',
  font: '"Verdana", sans - serif',
  color: 'rgb(90, 255, 0)',
  size: 28,
  weight: 1,
  show: 'flex',
  solo: true,
  meta: {
    hasIcon: false,
    isCustom: false,
    dataType: 1,
  },
}

export const name: IF.INTR.IntroNameIF = {
  id: 1,
  label: 'name',
  text: 'John Doe',
  font: '"Univers", serif',
  color: 'rgb(0, 255, 147)',
  size: 48,
  weight: 2,
  show: 'flex',
  solo: true,
  meta: {
    hasIcon: false,
    isCustom: false,
    dataType: 1,
    firstName: 'John',
    middleName: '',
    lastName: 'Doe',
  },
}

export const title: IF.INTR.IntroContentIF = {
  id: 1,
  label: 'title',
  text: 'Software Engineer',
  font: '"Brush Script MT", cursive',
  color: 'rgb(0, 2, 34)',
  size: 16,
  weight: 3,
  show: 'flex',
  solo: true,
  meta: {
    hasIcon: false,
    isCustom: false,
    dataType: 1,
  },
}

export const detail: IF.INTR.IntroContentIF = {
  id: 1,
  label: 'detail',
  text: 'Welcome to my homepage',
  font: '"Brush Script MT", cursive',
  color: 'rgba(244, 67, 54, 1)',
  size: 16,
  weight: 1,
  show: 'flex',
  solo: true,
  meta: {
    hasIcon: false,
    isCustom: false,
    dataType: 1,
  },
}

export const birthday: IF.INTR.IntroIconIF = {
  id: 1,
  label: 'birthday',
  text: '2022-12-14',
  font: '"Brush Script MT", cursive',
  color: 'rgb(0, 85, 255)',
  size: 16,
  weight: 1,
  show: 'flex',
  solo: false,
  meta: {
    hasIcon: true,
    isCustom: false,
    dataType: 2,
    icon: {
      type: 'anticon',
      name: 'CalendarTwoTone',
      color: 'rgb(198, 145, 145)',
      size: 27,
    },
  },
}

export const email: IF.INTR.IntroIconIF = {
  id: 1,
  label: 'email',
  text: 'john.doe@example.com',
  font: '"Futura", serif',
  color: 'rgb(180, 0, 255)',
  size: 16,
  weight: 1,
  show: 'flex',
  solo: false,
  meta: {
    hasIcon: true,
    isCustom: false,
    dataType: 2,
    icon: {
      type: 'anticon',
      name: 'MailTwoTone',
      color: 'rgb(198, 145, 145)',
      size: 15,
    },
  },
}

export const phone: IF.INTR.IntroPhoneIF = {
  id: 1,
  label: 'phone',
  text: '+1 (123) 456-7890',
  font: '"Times New Roman", serif',
  color: 'rgb(165, 0, 255)',
  size: 16,
  weight: 1,
  show: 'flex',
  solo: false,
  meta: {
    hasIcon: true,
    isCustom: false,
    dataType: 2,
    code: '+1',
    number: '(123) 456-7890',
    country: 'CA',
    icon: {
      type: 'anticon',
      name: 'PhoneTwoTone',
      color: 'rgb(25, 0, 255)',
      size: 20,
    },
  },
}

export const address: IF.INTR.IntroAddressIF = {
  id: 1,
  label: 'address',
  text: '12411 Jack Bell Dr Richmond BC Canada',
  font: '"Brush Script MT", cursive',
  color: 'rgb(50, 0, 255)',
  size: 16,
  weight: 1,
  show: 'flex',
  solo: false,
  meta: {
    hasIcon: true,
    isCustom: false,
    dataType: 2,
    icon: {
      type: 'anticon',
      name: 'HomeTwoTone',
      color: 'rgb(198, 145, 145)',
      size: 28,
    },
    streetOne: '12411 Jack Bell Dr',
    streetTwo: '',
    city: 'Richmond',
    stateCode: 'BC',
    stateName: 'British Columbia',
    countryCode: 'CA',
    countryName: 'Canada',
  },
}

export const website: IF.INTR.IntroIconIF = {
  id: 1,
  label: 'website',
  text: 'https://www.youtue.com',
  font: '"Georgia", serif',
  color: 'rgba(255, 224, 178, 1)',
  size: 16,
  weight: 1,
  show: 'flex',
  solo: false,
  meta: {
    hasIcon: true,
    isCustom: false,
    dataType: 2,
    icon: {
      type: 'reacticon',
      name: 'BsGlobe2',
      color: 'rgb(198, 145, 145)',
      size: 20,
    },
  },
}

export const icons: IF.INTR.IntroIconsIF = {
  id: 1,
  label: 'icons',
  text: '',
  font: '',
  color: 'rgba(240, 98, 146, 1)',
  size: 35,
  weight: 1,
  show: 'flex',
  solo: false,
  meta: {
    hasIcon: false,
    isCustom: false,
    dataType: 3,
    syncColor: 'n',
    syncSize: 'n',
    syncShow: 'n',
  },
}

export const twitter: IF.INTR.IntroContentIF = {
  id: 1,
  label: 'twitter',
  text: 'https://www.youtube.com',
  font: '',
  color: 'rgb(119, 0, 255)',
  size: 20,
  weight: 1,
  show: 'flex',
  solo: true,
  meta: {
    hasIcon: true,
    isCustom: false,
    dataType: 2,
    icon: {
      type: 'anticon',
      name: 'TwitterSquareFilled',
      color: 'rgb(119, 0, 255)',
      size: 20,
    },
  },
}

export const facebook: IF.INTR.IntroContentIF = {
  id: 1,
  label: 'facebook',
  text: 'https://www.youtube.com',
  font: '',
  color: 'rgb(74, 64, 214)',
  size: 32,
  weight: 1,
  show: 'flex',
  solo: true,
  meta: {
    hasIcon: true,
    isCustom: false,
    dataType: 2,
    icon: {
      type: 'anticon',
      name: 'FacebookFilled',
      color: 'rgb(74, 64, 214)',
      size: 32,
    },
  },
}

export const linkedin: IF.INTR.IntroContentIF = {
  id: 1,
  label: 'linkedin',
  text: 'https://www.youtube.com',
  font: '',
  color: 'rgb(64, 174, 214)',
  size: 57,
  weight: 1,
  show: 'flex',
  solo: true,
  meta: {
    hasIcon: true,
    isCustom: false,
    dataType: 2,
    icon: {
      type: 'anticon',
      name: 'LinkedinFilled',
      color: 'rgb(64, 174, 214)',
      size: 57,
    },
  },
}

export const instagram: IF.INTR.IntroContentIF = {
  id: 1,
  label: 'instagram',
  text: 'https://www.fwefwe.comee',
  font: '',
  color: 'rgb(0, 121, 107)',
  size: 20,
  weight: 1,
  show: 'none',
  solo: true,
  meta: {
    hasIcon: true,
    isCustom: false,
    dataType: 2,
    icon: {
      type: 'anticon',
      name: 'InstagramFilled',
      color: 'rgb(0, 121, 107)',
      size: 20,
    },
  },
}

export const youtube: IF.INTR.IntroContentIF = {
  id: 1,
  label: 'youtube',
  text: 'https://www.youtube.com',
  font: '',
  color: 'rgb(253, 0, 255)',
  size: 20,
  weight: 1,
  show: 'flex',
  solo: true,
  meta: {
    hasIcon: true,
    isCustom: false,
    dataType: 2,
    icon: {
      type: 'anticon',
      name: 'YoutubeFilled',
      color: 'rgb(0, 121, 107)',
      size: 20,
    },
  },
}

export const github: IF.INTR.IntroContentIF = {
  id: 1,
  label: 'github',
  text: 'https://www.youtube.com',
  font: '',
  color: 'rgb(64, 214, 144)',
  size: 20,
  weight: 1,
  show: 'flex',
  solo: true,
  meta: {
    hasIcon: true,
    isCustom: false,
    dataType: 2,
    icon: {
      type: 'anticon',
      name: 'GithubFilled',
      color: 'rgb(64, 214, 144)',
      size: 20,
    },
  },
}

export const avatar1: IF.INTR.IntroAvatarIF = {
  id: 1,
  module: 1,
  image: 'https://lcycstudio-staticfiles.s3.us-west-2.amazonaws.com/home/home_avatar1.jpg',
  show: 'flex',
  frame: 50,
  width: 100,
  height: 100,
  horizon: 100,
  upright: 100,
  fitting: 'cover',
  style: 1,
  clip: '0,0,100,0,100,86,0,100',
  radius: 5,
  effect: 'none',
  value: 100,
  shadow: '10,10,10',
  color: 'gray',
  history: [
    {
      value: 'https://havkonstudio-publicfiles.s3.us-west-2.amazonaws.com/havkonstudio/home_orb0.gif',
    },
    { value: 'FileTextTwoTone' },
  ],
}

export const avatar2: IF.INTR.IntroAvatarIF = {
  id: 1,
  module: 1,
  image: 'https://lcycstudio-staticfiles.s3.us-west-2.amazonaws.com/home/home_avatar1.jpg',
  show: 'none',
  frame: 50,
  width: 100,
  height: 100,
  horizon: 100,
  upright: 100,
  fitting: 'cover',
  style: 1,
  clip: '0,0,100,0,100,86,0,100',
  radius: 5,
  effect: 'none',
  value: 100,
  shadow: '10,10,10',
  color: 'gray',
  history: [],
}

export const order1: IF.INTR.IntroOrderIF[] = [
  { key: 'greeting', value: 'Greeting', order: 1, hover: false, show: 'flex' },
  { key: 'name', value: 'Full Name', order: 2, hover: false, show: 'flex' },
  { key: 'title', value: 'Job Title', order: 3, hover: false, show: 'flex' },
  { key: 'detail', value: 'Description', order: 4, hover: false, show: 'flex' },
  { key: 'birthday', value: 'Birthday', order: 5, hover: false, show: 'flex' },
  { key: 'email', value: 'Email', order: 6, hover: false, show: 'flex' },
  { key: 'phone', value: 'Phone', order: 7, hover: false, show: 'flex' },
  { key: 'address', value: 'Address', order: 8, hover: false, show: 'flex' },
  { key: 'website', value: 'Website', order: 9, hover: false, show: 'flex' },
  { key: 'icons', value: 'Media Icons', order: 10, hover: false, show: 'flex' },
  { key: 'meta', value: 'extra', order: 11, hover: false, show: 'flex' },
]

export const order2: IF.INTR.IntroOrderIF[] = [
  { key: 'greeting', value: 'Greeting', order: 1, hover: false, show: 'flex' },
  { key: 'name', value: 'Full Name', order: 2, hover: false, show: 'flex' },
  { key: 'title', value: 'Job Title', order: 3, hover: false, show: 'flex' },
  { key: 'detail', value: 'Description', order: 4, hover: false, show: 'flex' },
  { key: 'birthday', value: 'Birthday', order: 5, hover: false, show: 'flex' },
  { key: 'email', value: 'Email', order: 6, hover: false, show: 'flex' },
  { key: 'phone', value: 'Phone', order: 7, hover: false, show: 'flex' },
  { key: 'address', value: 'Address', order: 8, hover: false, show: 'flex' },
  { key: 'website', value: 'Website', order: 9, hover: false, show: 'flex' },
  {
    key: 'social',
    value: 'Social Media',
    order: 10,
    hover: false,
    show: 'flex',
  },
  { key: 'meta', value: 'extra', order: 11, hover: false, show: 'flex' },
]

export const custom1: IF.INTR.IntroCustomIF[] = [
  {
    key: 'extra',
    text: 'Hello here',
    type: { value: 'input', label: 'Input Box' },
    font: { name: 'Roboto', value: '"Roboto", sans-serif' },
    order: 11,
    show: 'flex',
    color: 'rgb(0,0,0)',
    size: 16,
    weight: 1,
  },
]

export const custom2: IF.INTR.IntroCustomIF[] = [
  {
    key: 'extra',
    text: 'Directive',
    type: { value: 'input', label: 'Input Box' },
    font: { name: 'Roboto', value: '"Roboto", sans-serif' },
    order: 11,
    show: 'flex',
    color: 'rgb(0,0,0)',
    size: 16,
    weight: 2,
  },
]

export const introduction1: IF.INTR.IntroductionIF = {
  id: 1,
  module: 1,
  type: 1,
  avatar: avatar1,
  order: order1,
  extra: custom1,
  content: [greeting],
  greeting: greeting,
  name: name,
  title: title,
  detail: detail,
  birthday: birthday,
  email: email,
  phone: phone,
  address: address,
  website: website,
  icons: icons,
  twitter: twitter,
  facebook: facebook,
  linkedin: linkedin,
  instagram: instagram,
  youtube: youtube,
  github: github,
}

export const introduction2: IF.INTR.IntroductionIF = {
  id: 2,
  module: 2,
  type: 1,
  avatar: avatar2,
  order: order2,
  extra: custom2,
  content: [greeting],
  greeting: greeting,
  name: name,
  title: title,
  detail: detail,
  birthday: birthday,
  email: email,
  phone: phone,
  address: address,
  website: website,
  icons: icons,
  twitter: twitter,
  facebook: facebook,
  linkedin: linkedin,
  instagram: instagram,
  youtube: youtube,
  github: github,
}

export const menuList: IF.COMM.EditorMenuIF[] = [
  { ...IC.EDTR.frameIcon, name: SETTING.FRAME, value: '' },
  { ...IC.EDTR.avatarBoyIcon, name: SETTING.AVATAR, value: '' },
  { ...IC.EDTR.presetIcon, name: SETTING.PRESET, value: '' },
  { ...IC.EDTR.customIcon, name: SETTING.CUSTOM, value: '' },
  { ...IC.EDTR.orderIcon, name: SETTING.ORDER, value: '' },
  { ...IC.EDTR.moduleIcon, name: SETTING.MODULE, value: '' },
  { ...IC.EDTR.textIcon, name: SETTING.TEXT, value: '' },
  { ...IC.EDTR.colorIcon, name: SETTING.COLOR, value: '' },
  { ...IC.EDTR.fontIcon, name: SETTING.FONT, value: '' },
  { ...IC.EDTR.sizeIcon, name: SETTING.SIZE, value: '' },
]

export const fieldSelection: IF.COMM.ValueNameIF[] = [
  { name: SETTING.NAME, value: ATTRIBUTE.NAME },
  { name: SETTING.GREETING, value: ATTRIBUTE.GREETING },
  { name: SETTING.TITLE, value: ATTRIBUTE.TITLE },
  { name: SETTING.DETAIL, value: ATTRIBUTE.DETAIL },
  { name: SETTING.BIRTHDAY, value: ATTRIBUTE.BIRTHDAY },
  { name: SETTING.EMAIL, value: ATTRIBUTE.EMAIL },
  { name: SETTING.PHONE, value: ATTRIBUTE.PHONE },
  { name: SETTING.ADDRESS, value: ATTRIBUTE.ADDRESS },
  { name: SETTING.WEBSITE, value: ATTRIBUTE.WEBSITE },
  { name: SETTING.ICONS, value: ATTRIBUTE.ICONS },
]

export const settingList1: IF.COMM.ValueLabelIF[] = [
  { label: LABEL.NAME, value: ATTRIBUTE.NAME },
  { label: LABEL.GREETING, value: ATTRIBUTE.GREETING },
  { label: LABEL.TITLE, value: ATTRIBUTE.TITLE },
  { label: LABEL.DETAIL, value: ATTRIBUTE.DETAIL },
]

export const settingList2: IF.COMM.LabelListIF[] = [
  { label: LABEL.BIRTHDAY, list: [ATTRIBUTE.BIRTHDAY, ATTRIBUTE.BIRTHDAYICON] },
  { label: LABEL.EMAIL, list: [ATTRIBUTE.EMAIL, ATTRIBUTE.EMAILICON] },
  { label: LABEL.PHONE, list: [ATTRIBUTE.PHONE, ATTRIBUTE.PHONEICON] },
  { label: LABEL.ADDRESS, list: [ATTRIBUTE.ADDRESS, ATTRIBUTE.ADDRESSICON] },
  { label: LABEL.WEBSITE, list: [ATTRIBUTE.WEBSITE, ATTRIBUTE.WEBSITEICON] },
]

export const settingListColor = [
  {
    value: ATTRIBUTE.ICONS,
    sync: FIELDLABEL.SYNCCOLOR,
    label: LABEL.ICONS,
    attr: ATTRIBUTE.SYNCCOLOR,
    list: [
      { label: ATTRIBUTE.TWITTER },
      { label: ATTRIBUTE.FACEBOOK },
      { label: ATTRIBUTE.LINKEDIN },
      { label: ATTRIBUTE.INSTAGRAM },
      { label: ATTRIBUTE.YOUTUBE },
      { label: ATTRIBUTE.GITHUB },
    ],
  },
]

export const payloadKeys: string[] = [
  'greeting',
  'name',
  'title',
  'detail',
  'birthday',
  'email',
  'phone',
  'address',
  'website',
  'icons',
  'facebook',
  'twitter',
  'linkedin',
  'instagram',
  'youtube',
  'github',
]

export const avatarSelect: IF.COMM.ValueNameIF[] = [
  { name: OPTION.IMAGE, value: OPTION.IMAGE },
  { name: OPTION.URL, value: OPTION.URL },
  { name: OPTION.UPLOAD, value: OPTION.UPLOAD },
  { name: OPTION.DISABLE, value: OPTION.DISABLE },
]

export const objectFits: IF.COMM.ValueNameIF[] = [
  { name: 'Fill', value: 'Fill' },
  { name: 'Contain', value: 'Contain' },
  { name: 'Cover', value: 'Cover' },
]

export const effects: string[] = [
  'none',
  'blur',
  'brightness',
  'contrast',
  'drop-shadow',
  'grayscale',
  'hue-rotate',
  'invert',
  'opacity',
  'saturate',
  'sepia',
]

export const effectWords: string[] = [
  'none',
  'Blur',
  'Brightness',
  'Contrast',
  'Shadow',
  'Grayscale',
  'Hue Rotate',
  'Invert',
  'Opacity',
  'Saturate',
  'Sepia',
]

export const effectValues: number[] = [4, 10, 50, 250, 4, 50, 180, 80, 50, 10, 50]

export const effectObjects: IF.COMM.ValueNameIF[] = [
  { name: 'None', value: 'none' },
  { name: 'Blur', value: 'blur' },
  { name: 'Brightness', value: 'brightness' },
  { name: 'Contrast', value: 'contrast' },
  { name: 'Drop Shadow', value: 'drop-shadow' },
  { name: 'Grayscale', value: 'grayscale' },
  { name: 'Hue Rotate', value: 'hue-rotate' },
  { name: 'Invert', value: 'invert' },
  { name: 'Opacity', value: 'opacity' },
  { name: 'Saturate', value: 'saturate' },
  { name: 'Sepia', value: 'sepia' },
]

export const valueLabelSelect: IF.COMM.ValueLabelIF[] = [
  { value: 'input', label: 'Input Box' },
  { value: 'text', label: 'Text Area' },
  { value: 'date', label: 'Date Box' },
]

export const valueNameSelect: IF.COMM.ValueNameIF = {
  name: 'Select Field',
  value: 'Select Field',
}

export const valueKeySelect: IF.COMM.ValueKeyIF = {
  key: 'Select Field',
  value: 'Select Field',
}

export const customFieldDefault: IF.INTR.IntroCustomIF = {
  key: '',
  type: { value: FIELDSELECT.INPUT, label: FIELDSELECT.INPUTLABEL },
  text: '',
  order: 0,
  show: 'flex',
  font: { name: 'Roboto', value: '"Roboto", sans-serif' },
  color: 'rgb(0, 0, 0)',
  size: 16,
  weight: 1,
}

export const customContentDefault: IF.INTR.IntroContentIF = {
  id: 999,
  label: 'custom',
  text: '',
  font: '"Roboto", sans-serif',
  color: 'rgb(0,0,0)',
  size: 16,
  weight: 1,
  show: 'flex',
  solo: true,
  meta: {
    hasIcon: false,
    isCustom: true,
    dataType: 2,
  },
}

// export const normal: IF.INTR.IntroNormalIF = {
//   display: 'flex',
//   width: '100%',
//   height: '100%',
//   filter: 'none',
//   objectFit: 'cover',
//   borderRadius: '5px',
//   objectPosition: '100% 50%',
// }

// export const polygon: IF.INTR.IntroPolygonIF = {
//   display: 'flex',
//   width: '100%',
//   height: '100%',
//   filter: 'none',
//   clipPath: 'polygon(0% 0%, 100% 0%, 100% 86%, 0% 100%)',
//   objectFit: 'cover',
//   borderRadius: '5px',
//   objectPosition: '100% 50%',
// }
