import { VS } from '../components/imports'


const Activate = () => {
    return (
        <>
            <VS.RegisterBackground />
            <VS.Activate />
        </>
    )
}
export default Activate
