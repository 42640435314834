import React from 'react'
import style from '../../assets/editor.intro.module.scss'
import { Checkbox, Col, List, Row, Switch } from 'antd'
import { EM, IF } from '../imports'
import * as m from './methods'

const OrderSetting = (props: {
  control: IF.CTRL.ControlIF
  intro: IF.INTR.IntroductionIF
  order: IF.INTR.IntroOrderIF[]
  module: IF.STAT.ModuleIF
  modules: IF.STAT.ModuleIF[]
  setModules: React.Dispatch<React.SetStateAction<IF.STAT.ModuleIF[]>>
}) => {
  const { control, intro, order, module, modules, setModules } = props

  const [dragIndex, setDragIndex] = React.useState<number>(0)
  const [introOrders, setIntroOrders] = React.useState<IF.INTR.IntroOrderIF[]>(order)
  const [switchMode, setSwitchMode] = React.useState<boolean>(true)

  const listStyle = { padding: '10px' }
  const switchStyle = { backgroundColor: control.color }

  const orderSettingHeader = (
    <Row className={style.introOrderHeader}>
      <Col span={20}>{EM.INTR.ORDER.ITEM}</Col>
      <Col span={4}>{EM.INTR.ORDER.DISPLAY}</Col>
    </Row>
  )

  const handleItemDisplay = (e: any, index: number, item: IF.INTR.IntroOrderIF) => {
    m.toggleItemDisplay(e, index, item, control, module, modules, introOrders, setModules, setIntroOrders)
  }

  const introOrderDiv = (item: IF.INTR.IntroOrderIF, index: number) => {
    if (item.key) {
      const key = item.key as string
      const thisObject = intro.content?.find(
        (item: IF.INTR.IntroContentIF) => item.label === key
      ) as IF.INTR.IntroContentIF
      if (thisObject) {
        const whiteColor: React.CSSProperties = {
          borderColor: control.color,
          backgroundColor: EM.COMM.COLOR.WHITE,
          color: thisObject.color,
          fontFamily: thisObject.font,
        }
        const thisText = item.key === EM.INTR.ATTRIBUTE.ICONS ? EM.INTR.LABEL.ICONS : thisObject.text.slice(0, 50)
        const saveBtnColor = { borderColor: control.color, backgroundColor: control.color, color: EM.COMM.COLOR.WHITE }
        return (
          <Row
            className={style.introOrderRow}
            draggable={true}
            style={item.hover ? saveBtnColor : whiteColor}
            onDragStart={() => {
              setDragIndex(index)
            }}
          >
            <Col
              span={20}
              className={style.introOrderColOne}
              style={item.hover ? saveBtnColor : whiteColor}
              onMouseOver={(e) => m.onHoverItem(e, index, true, control, introOrders, setIntroOrders)}
              onMouseOut={(e) => m.onHoverItem(e, index, false, control, introOrders, setIntroOrders)}
            >
              {thisText}
            </Col>
            <Col span={4} className={style.introOrderColTwo}>
              <Checkbox
                defaultChecked={item.show === EM.COMM.DISPLAY.FLEX}
                onChange={(e: any) => handleItemDisplay(e, index, item)}
              />
            </Col>
          </Row>
        )
      }
    }
  }

  return (
    <div className={style.editorPageTwo}>
      <div className={style.introOrderSwitchDiv}>
        <Switch
          style={switchStyle}
          defaultChecked
          checkedChildren={EM.INTR.VAR.SWAP}
          unCheckedChildren={EM.INTR.VAR.INSERT}
          onChange={(checked: boolean) => {
            setSwitchMode(checked)
          }}
        />
        {switchMode && <span className={style.introOrderText}>{EM.INTR.VAR.SWAPMSG}</span>}
        {!switchMode && <span className={style.introOrderText}>{EM.INTR.VAR.INSTMSG}</span>}
      </div>
      {orderSettingHeader}
      <List
        bordered
        dataSource={introOrders}
        rowKey={(item: IF.INTR.IntroOrderIF) => item.key + item.order}
        renderItem={(item: IF.INTR.IntroOrderIF, index: number) => (
          <List.Item
            style={listStyle}
            onDrop={(ev) =>
              m.dropItem(
                ev,
                style,
                index,
                dragIndex,
                switchMode,
                control,
                module,
                modules,
                introOrders,
                setModules,
                setIntroOrders
              )
            }
            onDragOver={(ev: any) => {
              ev.preventDefault()
            }}
          >
            {introOrderDiv(item, index)}
          </List.Item>
        )}
      />
    </div>
  )
}

export default OrderSetting
