import React from 'react'
import style from '../../../assets/frame.module.scss'
import { updateAvatarStyle } from '../methods'
import { isEmpty } from 'lodash'
import { EM, IF } from '../../imports'


const Avatar = (props: any) => {
    /** Properties */
    const { avatar, height } = props

    const [normalStyle, setNormalStyle] = React.useState<IF.INTR.IntroNormalIF>(avatar)
    const [polygonStyle, setPolygonStyle] = React.useState<IF.INTR.IntroPolygonIF>(avatar)

    React.useEffect(() => {
        updateAvatarStyle(false, '', avatar, setNormalStyle, setPolygonStyle)
        const avatars = Array.from(document.getElementsByClassName(style.introAvatarDiv) as HTMLCollectionOf<HTMLElement>)
        localStorage.setItem('ratios', avatars.map((item: HTMLElement) => (item.offsetWidth / item.offsetHeight)).join())
    }, [avatar, height])

    const divStyle = {
        width: `${avatar.frame}%`,
        height: `${height}px`,
        display: isEmpty(avatar.image) ? EM.COMM.DISPLAY.NONE : avatar.show
    }
    const avatarStyle = avatar.style !== 3 ? normalStyle : polygonStyle

    return <div className={style.introAvatarDiv} style={divStyle}>
        <img className={style.introAvatar} style={avatarStyle} src={avatar.image} alt='intro-avatar' />
    </div>
}

export default Avatar
