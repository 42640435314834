import { IF } from '../components/imports'

export const loginDuration: IF.RSTR.LoginDurationIF = {
    name: '3 hours',
    time: 10800
}

export const loginDisplay: string[] = [
    '30 min',
    '1 hour',
    '2 hours',
    '3 hours',
    '6 hours',
    '12 hours',
    '24 hours'
]

export const loginValue: number[] = [
    60 * 60 * 0.5,
    60 * 60 * 1,
    60 * 60 * 2,
    60 * 60 * 3,
    60 * 60 * 6,
    60 * 60 * 12,
    60 * 60 * 24,
]

export const passwordStrength: IF.RSTR.PasswordStrengthIF = {
    strength: 0,
    length: 0,
    letter: false,
    upper: false,
    number: false,
    symbol: false
}
