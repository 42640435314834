import React from 'react'
import style from '../../assets/editor.module.scss'
import dayjs from 'dayjs'
import type { MenuProps } from 'antd'
import {
  Button,
  Col,
  Checkbox,
  DatePicker,
  Divider,
  Dropdown,
  Input,
  Popover,
  Row,
  Select,
  Slider,
  Switch,
  Typography,
} from 'antd'
import { DownOutlined, DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons'
import { HuePicker, SwatchesPicker } from 'react-color'
import { weightObject } from '../Introduction/methods'
import { fonts } from '../../resources/fonts'
import { EM, IF, RS } from '../imports'

const { TextArea } = Input
const { Text } = Typography
const dateFormat = 'YYYY-MM-DD'

export const EditorLabel = (props: { span: number; label: string; iStyle?: React.CSSProperties | undefined }) => {
  const { span, label, iStyle } = props
  return (
    <Col span={span}>
      <div className={style.partLabel} style={iStyle}>
        {label}
      </div>
    </Col>
  )
}

export const EditorCheckbox = (props: { span: number; value: boolean; onChangeItem: (value: boolean) => void }) => {
  const { span, value, onChangeItem } = props
  return (
    <Col span={span}>
      <div className={style.partCenter}>
        <Checkbox defaultChecked={value} onChange={(e: any) => onChangeItem(e.target.checked)} />
      </div>
    </Col>
  )
}

export const EditorDropDown = (props: {
  span: number
  loading: boolean
  sourceList: IF.COMM.ValueNameIF[]
  selection: IF.COMM.ValueNameIF
  saveStyle: React.CSSProperties
  onChangeItem: (item: IF.COMM.ValueNameIF) => void
}) => {
  const { span, loading, sourceList, selection, saveStyle, onChangeItem } = props

  const items: MenuProps['items'] = sourceList.map((item: IF.COMM.ValueNameIF, index: number) => ({
    key: index,
    label: (
      <div className={style.partDropDownLabel} onClick={() => onChangeItem(item)}>
        {item.name}
      </div>
    ),
  }))

  return (
    <Col span={span}>
      <div className={style.partDiv}>
        <Dropdown menu={{ items }} trigger={['click']} disabled={loading}>
          <Button type="primary" style={saveStyle} className={style.partDropDownButton}>
            <span>{selection.name}</span>
            <DownOutlined />
          </Button>
        </Dropdown>
      </div>
    </Col>
  )
}

/**
 * Image JSX component on the editor page.
 * @param mainImage the image JSX component to be rendered here.
 * @returns image JSX component.
 */
export const EditorImage: React.FC<{ mainImage: JSX.Element }> = ({ mainImage }): JSX.Element => {
  return (
    <Col span={18}>
      <div className={style.partPreview}>{mainImage}</div>
    </Col>
  )
}

export const EditorHistoryLabel = (props: { history: IF.COMM.HistoryIF[] }) => {
  const { history } = props
  return (
    <Row>
      <EditorLabel span={6} label={EM.COMM.EDITOR.HISTORY} />
      <EditorText span={18} text={history.length === 0 ? EM.COMM.IMAGE.EMPTY : ''} iStyle={{ textAlign: 'center' }} />
    </Row>
  )
}

export const EditorEmail = (props: {
  max: number
  span: number
  stat: '' | 'error' | 'warning' | undefined
  value: string
  error: string
  iStyle?: React.CSSProperties | undefined
  inputType: string
  attribute: string
  handleAttribute: (value: string, attribute: string) => void
}) => {
  const { max, span, stat, value, error, iStyle, inputType, attribute, handleAttribute } = props
  return (
    <Col span={span}>
      <div className={style.partDiv} style={iStyle}>
        <Input
          type={inputType}
          defaultValue={value}
          showCount
          maxLength={max}
          status={stat}
          onChange={(event: any) => handleAttribute(event.target.value, attribute)}
        />
      </div>
      <Text type="danger" className={style.partDiv} style={iStyle}>
        {error}
      </Text>
    </Col>
  )
}

export const EditorInput = (props: {
  max: number
  span: number
  value: string
  inputType: string
  attribute: string
  iStyle?: React.CSSProperties | undefined
  handleAttribute: (value: string, attribute: string) => void
}) => {
  const { max, span, value, inputType, attribute, iStyle, handleAttribute } = props
  return (
    <Col span={span}>
      <div className={style.partDiv} style={iStyle}>
        <Input
          type={inputType}
          value={value}
          showCount
          maxLength={max}
          onChange={(event: any) => handleAttribute(event.target.value, attribute)}
        />
      </div>
    </Col>
  )
}

export const EditorInputAddon = (props: {
  max: number
  span: number
  value: string
  addon: React.ReactNode
  attribute: string
  iStyle?: React.CSSProperties | undefined
  handleAttribute: (value: string, attribute: string) => void
}) => {
  const { max, span, value, addon, attribute, iStyle, handleAttribute } = props
  return (
    <Col span={span}>
      <div className={style.partDiv} style={iStyle}>
        <Input
          addonBefore={addon}
          showCount
          defaultValue={value}
          maxLength={max}
          onChange={(event: any) => handleAttribute(event.target.value, attribute)}
        />
      </div>
    </Col>
  )
}

export const EditorText = (props: { span: number; text: string; iStyle?: React.CSSProperties | undefined }) => {
  const { span, text, iStyle } = props
  return (
    <Col span={span}>
      <div style={iStyle}>{text}</div>
    </Col>
  )
}

export const EditorTextArea = (props: {
  max: number
  span: number
  minRows: number
  maxRows: number
  value: string
  attribute: string
  iStyle?: React.CSSProperties
  handleAttribute: (value: string, attribute: string) => void
}) => {
  const { max, span, minRows, maxRows, value, attribute, iStyle, handleAttribute } = props
  return (
    <Col span={span}>
      <div className={style.partTextArea} style={iStyle}>
        <TextArea
          defaultValue={value}
          showCount
          maxLength={max}
          autoSize={{ minRows: minRows, maxRows: maxRows }}
          style={RS.COMM.width100}
          onChange={(event: any) => handleAttribute(event.target.value, attribute)}
        />
      </div>
    </Col>
  )
}

export const EditorDatePicker = (props: {
  span: number
  value: string
  iStyle?: React.CSSProperties | undefined
  handleAttribute: (date: dayjs.Dayjs | null, dateString: string) => void
}) => {
  const { span, value, iStyle, handleAttribute } = props
  // eslint-disable-next-line
  const regEx = /^\d{4}-\d{2}-\d{2}$/
  const currentDate = dayjs().format()
  const newVal = value.match(regEx) ? value : currentDate
  return (
    <Col span={span}>
      <div className={style.partDiv} style={iStyle}>
        <DatePicker style={RS.COMM.width100} defaultValue={dayjs(newVal, dateFormat)} onChange={handleAttribute} />
      </div>
    </Col>
  )
}

export const EditorSelect = (props: {
  value: IF.COMM.ValueLabelIF
  sourceList: IF.COMM.ValueLabelIF[]
  onChangeItem: (result: { value: string; label: React.ReactNode }) => void
}) => {
  const { value, sourceList, onChangeItem } = props
  return (
    <Col span={18}>
      <div className={style.partCenter}>
        <Select labelInValue value={value} onChange={onChangeItem} options={sourceList}></Select>
      </div>
    </Col>
  )
}

export const EditorSlider = (props: {
  min: number
  max: number
  span: number
  value: number
  width: React.CSSProperties
  attribute: string
  handleAttribute: (value: number, attribute: string) => void
}) => {
  const { min, max, span, value, width, attribute, handleAttribute } = props
  return (
    <Col span={span}>
      <div className={style.partCenter}>
        <Slider
          defaultValue={value}
          min={min}
          max={max}
          style={width}
          onChange={(value: number) => handleAttribute(value, attribute)}
        />
      </div>
    </Col>
  )
}

export const EditorSwitch = (props: {
  span: number
  show: boolean
  attribute: string
  switchStyle: React.CSSProperties
  checkedChildren: string
  unCheckedChildren: string
  handleAttribute: (value: boolean, attribute: string) => void
}) => {
  const { span, show, attribute, switchStyle, checkedChildren, unCheckedChildren, handleAttribute } = props
  return (
    <Col span={span}>
      <div className={style.partCenter}>
        <Switch
          style={switchStyle}
          defaultChecked={show}
          checkedChildren={checkedChildren}
          unCheckedChildren={unCheckedChildren}
          onChange={(checked: boolean) => handleAttribute(checked, attribute)}
        />
      </div>
    </Col>
  )
}

export const EditorHuePicker = (props: {
  span: number
  width: string
  color: string
  attribute: string
  undoStyle: React.CSSProperties
  handleAttribute: (color: string, attribute: string) => void
}) => {
  const { span, width, color, attribute, undoStyle, handleAttribute } = props

  const iColor = (color: any) => {
    const rgb = color?.rgb
    return `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`
  }

  const content = () => {
    return <SwatchesPicker color={color} onChange={(color: any) => handleAttribute(iColor(color), attribute)} />
  }

  return (
    <Col span={span}>
      <div className={style.partDivP}>
        <HuePicker width={width} color={color} onChange={(color) => handleAttribute(iColor(color), attribute)} />
        <Popover placement="bottomRight" content={content()} trigger="click">
          <Button style={undoStyle} size={EM.COMM.BUTTON.SMALL}>
            {EM.COMM.BUTTON.MORE}
          </Button>
        </Popover>
      </div>
    </Col>
  )
}

export const EditorHuePicker2 = (props: {
  width: string
  color: string
  attribute: string
  undoStyle: React.CSSProperties
  handleAttribute: (color: string, attribute: string) => void
}) => {
  const { width, color, attribute, undoStyle, handleAttribute } = props

  const iColor = (color: any) => {
    const rgb = color?.rgb
    return `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`
  }

  const content = () => {
    return <SwatchesPicker color={color} onChange={(color: any) => handleAttribute(iColor(color), attribute)} />
  }

  return (
    <div className={style.partDivP}>
      <HuePicker width={width} color={color} onChange={(color) => handleAttribute(iColor(color), attribute)} />
      <Popover placement="bottomRight" content={content()} trigger="click">
        <Button style={undoStyle} size={EM.COMM.BUTTON.SMALL}>
          {EM.COMM.BUTTON.MORE}
        </Button>
      </Popover>
    </div>
  )
}

export const EditorParallel = (props: { span: number; elements: JSX.Element[] }) => {
  const { span, elements } = props
  return (
    <Col span={span}>
      <div className={style.partDivP}>
        {elements.map((item: JSX.Element, index: number) => (
          <div key={index}>{item}</div>
        ))}
      </div>
    </Col>
  )
}

export const EditorVertical = (props: { elements: JSX.Element[] }) => {
  const { elements } = props
  return (
    <Col span={18}>
      <div className={style.partDivV}>
        {elements.map((item: JSX.Element, index: number) => (
          <div key={index} style={{ width: '100%' }}>
            {item}
          </div>
        ))}
      </div>
    </Col>
  )
}

export const EditorFontPicker = (props: {
  span: number
  text: string
  buttonWidth: string
  buttonColor: string
  textWidth: string
  textColor: string
  snapShot: string
  fontSize: number
  fontFamily: string
  fontWeight: number
  attribute: string
  handleAttribute: (value: IF.COMM.ValueNameIF, attribute: string) => void
}) => {
  const {
    span,
    text,
    buttonWidth,
    buttonColor,
    textWidth,
    textColor,
    snapShot,
    fontSize,
    fontFamily,
    fontWeight,
    attribute,
    handleAttribute,
  } = props

  const [openFont, setOpenFont] = React.useState<boolean>(false)
  const [thisFont, setThisFont] = React.useState<string>(fontFamily)

  const fontButton: React.CSSProperties = {
    color: buttonColor,
    borderColor: buttonColor,
    width: buttonWidth,
    whiteSpace: 'normal',
    overflow: EM.COMM.BODY.HIDDEN,
    padding: '4px 12px',
  }

  const textStyle: React.CSSProperties = {
    color: textColor,
    fontSize: `${fontSize}px`,
    fontFamily: thisFont,
    fontWeight: weightObject(fontWeight),
    width: textWidth,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: EM.COMM.BODY.HIDDEN,
  }

  const onHoverFontType = (e: any, value: string) => {
    e.target.style.backgroundColor = buttonColor
    setThisFont(value)
  }

  const onChangeFontType = (font: IF.COMM.ValueNameIF) => {
    setOpenFont(false)
    setThisFont(font.value)
    handleAttribute(font, attribute)
  }

  const onChangeFontOpen = (newOpen: boolean) => {
    setOpenFont(newOpen)
    if (!newOpen) setThisFont(snapShot)
  }

  const fontList = (
    <div className={style.partFontDiv}>
      {fonts.map((item: IF.COMM.ValueNameIF, index: number) => {
        return item.name === 'divide' ? (
          <Divider style={{ margin: '12px 0' }} key={index} />
        ) : (
          <option
            className={style.partFont}
            key={index}
            style={{ fontFamily: item.value }}
            onMouseOver={(e: any) => onHoverFontType(e, item.value)}
            onMouseOut={(e: any) => {
              e.target.style.backgroundColor = EM.COMM.COLOR.WHITE
            }}
            onClick={() => onChangeFontType(item)}
          >
            {item.name}
          </option>
        )
      })}
    </div>
  )

  return (
    <Col span={span}>
      <div className={style.partDivP}>
        <Popover
          content={fontList}
          trigger="click"
          placement={'left'}
          open={openFont}
          onOpenChange={(newOpen: boolean) => onChangeFontOpen(newOpen)}
        >
          <Button style={fontButton}>
            <span style={{ whiteSpace: 'nowrap', overflow: EM.COMM.BODY.HIDDEN, width: '100%' }}>
              {thisFont.split(',')[0].slice(1, -1).split(' ')[0]}
            </span>
          </Button>
        </Popover>
        <div style={textStyle}>{text}</div>
      </div>
    </Col>
  )
}

export const EditorNavPreview = (props: {
  naviBar: IF.NAVG.NaviBarIF
  modules: IF.STAT.ModuleIF[]
  naviPreview: IF.NAVG.NaviStyleIF
}) => {
  const { naviBar, modules, naviPreview } = props

  return (
    <Col span={18}>
      <div className={style.editorNavFrame}>
        <div style={naviPreview} className={style.editorNavPreview}>
          {modules.map((item: IF.STAT.ModuleIF) => (
            <span key={item.order} style={item.show ? RS.COMM.showFlex : RS.COMM.hideBlock}>
              {item.title}
            </span>
          ))}
        </div>
        <div
          className={style.editorNavPreview}
          style={{
            ...naviPreview,
            width: '50%',
            fontFamily: naviBar.fontOne,
            height: `${naviBar.heightOne}px`,
            fontSize: `${naviBar.sizeOne}px`,
          }}
        >
          {modules[0].title}
        </div>
      </div>
    </Col>
  )
}

export const EditorWeight = (props: {
  span: number
  size: number
  value: number
  saveStyle: React.CSSProperties
  undoStyle: React.CSSProperties
  attribute: string
  handleAttribute: (value: number, attribute: string) => void
}) => {
  const { span, size, value, saveStyle, undoStyle, attribute, handleAttribute } = props
  const thisSize = size === 1 ? 'small' : size === 3 ? 'large' : undefined
  return (
    <Col span={span}>
      <div className={style.partAround}>
        <Button
          size={thisSize}
          style={value === 1 ? saveStyle : undoStyle}
          onClick={() => handleAttribute(1, attribute)}
        >
          1{' '}
        </Button>
        <Button
          size={thisSize}
          style={value === 2 ? saveStyle : undoStyle}
          onClick={() => handleAttribute(2, attribute)}
        >
          2
        </Button>
        <Button
          size={thisSize}
          style={value === 3 ? saveStyle : undoStyle}
          onClick={() => handleAttribute(3, attribute)}
        >
          3
        </Button>
      </div>
    </Col>
  )
}

export const EditorOrder = (props: {
  span: number
  undoStyle: React.CSSProperties
  disableUp: boolean
  disableDn: boolean
  onChangeOrder: (up: boolean) => void
}) => {
  const { span, undoStyle, disableUp, disableDn, onChangeOrder } = props
  return (
    <Col span={span}>
      <div className={style.partAround}>
        <Button style={undoStyle} size={EM.COMM.BUTTON.SMALL} onClick={() => onChangeOrder(true)} disabled={disableUp}>
          {EM.COMM.BUTTON.MOVEUP} <UpCircleOutlined style={{ fontSize: '16px' }} />
        </Button>
        <Button style={undoStyle} size={EM.COMM.BUTTON.SMALL} onClick={() => onChangeOrder(false)} disabled={disableDn}>
          {EM.COMM.BUTTON.MOVEDN} <DownCircleOutlined style={{ fontSize: '16px' }} />
        </Button>
      </div>
    </Col>
  )
}
