import React from 'react'
import style from '../../assets/editor.intro.module.scss'
import { Row } from 'antd'
import { IF, EM } from '../imports'
import { EditorFontPicker, EditorLabel, EditorWeight } from '../Common/EditorParts'
import * as m from './methods'


const FontSetting = (props: {
    intro: IF.INTR.IntroductionIF,
    snapShot: IF.INTR.IntroductionIF,
    control: IF.CTRL.ControlIF,
    module: IF.STAT.ModuleIF,
    modules: IF.STAT.ModuleIF[],
    setModules: React.Dispatch<React.SetStateAction<IF.STAT.ModuleIF[]>>
}) => {
    const { intro, snapShot, control, module, modules, setModules } = props

    /** Local Variables */
    const undoStyle = { color: control.color, borderColor: control.color }
    const thisStyle = { color: EM.COMM.COLOR.WHITE, backgroundColor: control.color }

    /** Local Methods */
    const onChangeFontType = (font: IF.COMM.ValueNameIF, attribute: string) => {
        m.onChangeIntroFont(
            font.value,
            attribute,
            control,
            module,
            modules,
            setModules
        )
    }

    const onChangeWeight = (value: number, attribute: string) => {
        m.onChangeIntroWeight(
            value,
            attribute,
            control,
            module,
            modules,
            setModules
        )
    }

    return <div className={style.editorPageTwo}>
        <Row className={style.editorRow}>
            <EditorLabel span={6} label={EM.INTR.LABEL.NAME} />
            <EditorFontPicker
                span={12}
                text={intro.name.text}
                buttonWidth={'80px'}
                textWidth={'100px'}
                buttonColor={control.color}
                textColor={intro.name.color}
                snapShot={snapShot.name.text}
                fontSize={16}
                fontFamily={snapShot.name.text}
                fontWeight={intro.name.weight}
                attribute={EM.INTR.ATTRIBUTE.NAME}
                handleAttribute={onChangeFontType} />
            <EditorWeight
                span={6}
                size={1}
                value={intro.name.weight}
                saveStyle={thisStyle}
                undoStyle={undoStyle}
                attribute={EM.INTR.ATTRIBUTE.NAME}
                handleAttribute={onChangeWeight} />
        </Row>
        <Row className={style.editorRow}>
            <EditorLabel span={6} label={EM.INTR.LABEL.GREETING} />
            <EditorFontPicker
                span={12}
                text={intro.greeting.text}
                buttonWidth={'80px'}
                textWidth={'100px'}
                buttonColor={control.color}
                textColor={intro.greeting.color}
                snapShot={snapShot.greeting.text}
                fontSize={16}
                fontFamily={snapShot.greeting.text}
                fontWeight={intro.greeting.weight}
                attribute={EM.INTR.ATTRIBUTE.GREETING}
                handleAttribute={onChangeFontType} />
            <EditorWeight
                span={6}
                size={1}
                value={intro.greeting.weight}
                saveStyle={thisStyle}
                undoStyle={undoStyle}
                attribute={EM.INTR.ATTRIBUTE.GREETING}
                handleAttribute={onChangeWeight} />
        </Row>
        <Row className={style.editorRow}>
            <EditorLabel span={6} label={EM.INTR.LABEL.TITLE} />
            <EditorFontPicker
                span={12}
                text={intro.title.text}
                buttonWidth={'80px'}
                textWidth={'100px'}
                buttonColor={control.color}
                textColor={intro.title.color}
                snapShot={snapShot.title.text}
                fontSize={16}
                fontFamily={snapShot.title.text}
                fontWeight={intro.title.weight}
                attribute={EM.INTR.ATTRIBUTE.TITLE}
                handleAttribute={onChangeFontType} />
            <EditorWeight
                span={6}
                size={1}
                value={intro.title.weight}
                saveStyle={thisStyle}
                undoStyle={undoStyle}
                attribute={EM.INTR.ATTRIBUTE.TITLE}
                handleAttribute={onChangeWeight} />
        </Row>
        <Row className={style.editorRow}>
            <EditorLabel span={6} label={EM.INTR.LABEL.DETAIL} />
            <EditorFontPicker
                span={12}
                text={intro.detail.text}
                buttonWidth={'80px'}
                textWidth={'100px'}
                buttonColor={control.color}
                textColor={intro.detail.color}
                snapShot={snapShot.detail.text}
                fontSize={16}
                fontFamily={snapShot.detail.text}
                fontWeight={intro.detail.weight}
                attribute={EM.INTR.ATTRIBUTE.DETAIL}
                handleAttribute={onChangeFontType} />
            <EditorWeight
                span={6}
                size={1}
                value={intro.detail.weight}
                saveStyle={thisStyle}
                undoStyle={undoStyle}
                attribute={EM.INTR.ATTRIBUTE.DETAIL}
                handleAttribute={onChangeWeight} />
        </Row>
        <Row className={style.editorRow}>
            <EditorLabel span={6} label={EM.INTR.LABEL.BIRTHDAY} />
            <EditorFontPicker
                span={12}
                text={intro.birthday.text}
                buttonWidth={'80px'}
                textWidth={'100px'}
                buttonColor={control.color}
                textColor={intro.birthday.color}
                snapShot={snapShot.birthday.text}
                fontSize={16}
                fontFamily={snapShot.birthday.text}
                fontWeight={intro.birthday.weight}
                attribute={EM.INTR.ATTRIBUTE.BIRTHDAY}
                handleAttribute={onChangeFontType} />
            <EditorWeight
                span={6}
                size={1}
                value={intro.birthday.weight}
                saveStyle={thisStyle}
                undoStyle={undoStyle}
                attribute={EM.INTR.ATTRIBUTE.BIRTHDAY}
                handleAttribute={onChangeWeight} />
        </Row>
        <Row className={style.editorRow}>
            <EditorLabel span={6} label={EM.INTR.LABEL.EMAIL} />
            <EditorFontPicker
                span={12}
                text={intro.email.text}
                buttonWidth={'80px'}
                textWidth={'100px'}
                buttonColor={control.color}
                textColor={intro.email.color}
                snapShot={snapShot.email.text}
                fontSize={16}
                fontFamily={snapShot.email.text}
                fontWeight={intro.email.weight}
                attribute={EM.INTR.ATTRIBUTE.EMAIL}
                handleAttribute={onChangeFontType} />
            <EditorWeight
                span={6}
                size={1}
                value={intro.email.weight}
                saveStyle={thisStyle}
                undoStyle={undoStyle}
                attribute={EM.INTR.ATTRIBUTE.EMAIL}
                handleAttribute={onChangeWeight} />
        </Row>
        <Row className={style.editorRow}>
            <EditorLabel span={6} label={EM.INTR.LABEL.PHONE} />
            <EditorFontPicker
                span={12}
                text={intro.phone.text}
                buttonWidth={'80px'}
                textWidth={'100px'}
                buttonColor={control.color}
                textColor={intro.phone.color}
                snapShot={snapShot.phone.text}
                fontSize={16}
                fontFamily={snapShot.phone.text}
                fontWeight={intro.phone.weight}
                attribute={EM.INTR.ATTRIBUTE.PHONE}
                handleAttribute={onChangeFontType} />
            <EditorWeight
                span={6}
                size={1}
                value={intro.phone.weight}
                saveStyle={thisStyle}
                undoStyle={undoStyle}
                attribute={EM.INTR.ATTRIBUTE.PHONE}
                handleAttribute={onChangeWeight} />
        </Row>
        <Row className={style.editorRow}>
            <EditorLabel span={6} label={EM.INTR.LABEL.ADDRESS} />
            <EditorFontPicker
                span={12}
                text={intro.address.text}
                buttonWidth={'80px'}
                textWidth={'100px'}
                buttonColor={control.color}
                textColor={intro.address.color}
                snapShot={snapShot.address.text}
                fontSize={16}
                fontFamily={snapShot.address.text}
                fontWeight={intro.address.weight}
                attribute={EM.INTR.ATTRIBUTE.ADDRESS}
                handleAttribute={onChangeFontType} />
            <EditorWeight
                span={6}
                size={1}
                value={intro.address.weight}
                saveStyle={thisStyle}
                undoStyle={undoStyle}
                attribute={EM.INTR.ATTRIBUTE.ADDRESS}
                handleAttribute={onChangeWeight} />
        </Row>
        <Row className={style.editorRow}>
            <EditorLabel span={6} label={EM.INTR.LABEL.WEBSITE} />
            <EditorFontPicker
                span={12}
                text={intro.website.text}
                buttonWidth={'80px'}
                textWidth={'100px'}
                buttonColor={control.color}
                textColor={intro.website.color}
                snapShot={snapShot.website.text}
                fontSize={16}
                fontFamily={snapShot.website.text}
                fontWeight={intro.website.weight}
                attribute={EM.INTR.ATTRIBUTE.WEBSITE}
                handleAttribute={onChangeFontType} />
            <EditorWeight
                span={6}
                size={1}
                value={intro.website.weight}
                saveStyle={thisStyle}
                undoStyle={undoStyle}
                attribute={EM.INTR.ATTRIBUTE.WEBSITE}
                handleAttribute={onChangeWeight} />
        </Row>
        {/* {RS.INTR.fontFields.map((item: IF.COMM.ValueKeyIF, index: number) => {
            const thisFont = font[item.key as keyof IF.INTR.IntroFontIF] as string
            return <Row className={style.editorRow} key={index}>
                <EditorLabel
                    span={4}
                    label={item.value} />
                <EditorFontPicker
                    span={14}
                    text={text[item.key as keyof IF.INTR.IntroTextIF] as string}
                    buttonWidth={'80px'}
                    textWidth={'100px'}
                    buttonColor={control.color}
                    textColor={color[item.key as keyof IF.INTR.IntroColorIF] as string}
                    snapShot={thisFont}
                    fontSize={16}
                    fontFamily={thisFont}
                    fontWeight={weight[item.key as keyof IF.INTR.IntroWeightIF]}
                    attribute={item.value}
                    handleAttribute={onChangeFontType} />
                <EditorWeight
                    span={6}
                    size={1}
                    value={weight[item.key as keyof IF.INTR.IntroWeightIF]}
                    saveStyle={thisStyle}
                    undoStyle={undoStyle}
                    attribute={item.value}
                    handleAttribute={onChangeWeight} />
            </Row>
        })} */}
    </div>
}

export default FontSetting