export enum AUTH {
    DONE = 'done',
    NONE = 'none',
    ACTIVE = 'active',
    ACTIVATED = 'activated',
    INACTIVE = 'inactive',
}

export enum FORM {
    EMAILINVALID = 'Please enter a valid E-mail',
    EMAILMESSAGE = 'Please enter your E-mail',
    EMAILEXISTED = 'A user with this email already exists',
    EMAILISNULL = 'This email does not exist. Create account?',
    EMAILMAXERR = 'Email maximum length is 150',
    NAMEINVALID = 'Please remove symbols or numbers',
    FIRSTNAMEMESSAGE = 'Please enter your first name',
    FIRSTNAMEMAXERR = 'First name maximum length is 64',
    LASTNAMEMESSAGE = 'Please enter your last name',
    LASTNAMEMAXERR = 'Last name maximum length is 64',
    PASSWORDMESSAGE = 'Please enter your password',
    PASSWORDINVALID = 'Password must have at least 6 characters',
    PASSWORDCONFIRM = 'Please confirm your password',
    PASSWORDMISMATCH = 'The two passwords do not match',
}

export enum FORMNAME {
    FORGETFORM = 'forgotForm',
    RESETFORM = 'resetForm',
    LOGINFORM = 'loginForm',
    SIGNUPFORM = 'signupForm',
}

export enum PASSWORD {
    BLANK = '#FFFFFF',
    WEAK = '#F94C66',
    MEDIUM = '#F4E06D',
    STRONG = '#53BF9D',
    SIGNUP = '#00C851',
    LENGTH = 'it has at least 6 characters',
    UPPERC = 'it has at least an upper letter',
    LETTER = 'it has letters',
    NUMBER = 'it has numbers',
    SYMBOL = 'it has special symbols',
}

export enum PLACEHOLDER {
    EMAIL = 'Email',
    PASSWORD = 'Password',
}

export enum PREFIX {
    PASSWORD = 'Password: ',
    CONFIRM = 'Confirm: ',
    FIRSTNAME = 'First Name: ',
    LASTNAME = 'Last Name: '
}

export enum TEXT {
    ACCOUNTEXITS = 'Already have an account?',
    ACCOUNTISNULL = 'Don\'t have an account?',
    ACTIVATE = 'Activate Account',
    CREATE = 'Create Account',
    FORGET = 'Forget password?',
    REQUEST = 'Request Link',
    RESET = 'Reset Password',
    WEAK = 'weak',
    MEDIUM = 'medium',
    STRONG = 'strong',
    SIGNIN = 'Sign In',
    SIGNUP = 'Sign Up',
    SIGNOUT = 'Sign Out',
    SUCCESS = 'Sign Up Successful',
    RETURN = 'Return to Sign Up',
    LOADING = 'Loading ...',
    TOOLTIP = 'The system can safely log out your account after the selected time.',
    SIGNEDIN = 'You are successfully signed in.'
}

export enum TITLE {
    ACTIVATE = 'Account Activation',
    FORGET = 'Enter Your Email',
    PASSWORD = 'Password strength:',
    RESET = 'Password Reset',
    SIGNIN = 'Account Sign In',
    SIGNUP = 'Account Sign Up'
}

export enum TYPE {
    EMAIL = 'email',
    PASSWORD = 'password',
    FIRSTNAME = 'firstname',
    LASTNAME = 'lastname',
    CONFIRM = 'confirm',
}

export enum URL {
    SIGNIN = '/users/signin',
    SIGNUP = '/users/signup',
    FORGET = '/users/forget',
    RESET = '/users/reset',
}
