import React from 'react'
import style from '../../../assets/editor.intro.module.scss'
import { Row } from 'antd'
import {
    EditorFontPicker,
    EditorHuePicker,
    EditorInput,
    EditorLabel,
    EditorOrder,
    EditorSlider,
    EditorSwitch,
    EditorText,
    EditorWeight
} from '../../Common/EditorParts'
import { EM, IF, RS } from '../../imports'
import * as m from '../methods'

const NameSetting = (props: {
    value: IF.INTR.IntroContentIF,
    order: IF.INTR.IntroOrderIF[],
    control: IF.CTRL.ControlIF,
    label: string,
    attribute: string,
    saveStyle: React.CSSProperties,
    undoStyle: React.CSSProperties,
    switchStyle: React.CSSProperties,
    onChangeAttribute: (value: any, attribute: string) => void,
    onChangeOrder: (value: boolean, attribute: string) => void,
}) => {
    const { value, order, control, label, attribute, saveStyle, undoStyle,
        switchStyle, onChangeAttribute, onChangeOrder } = props

    const fontText = value.text.slice(0, 15) + '...'
    const disableUp = order.find((item: IF.INTR.IntroOrderIF) => (item.key === attribute))!.order === 1
    const disableDn = order.find((item: IF.INTR.IntroOrderIF) => (item.key === attribute))!.order === order.length

    return <>
        <Row className={style.introFieldRow}>
            <EditorLabel span={6} label={label} />
            <EditorText span={18} text={value.text} iStyle={m.previewStyle(value)} />
        </Row>
        <Row className={style.introFieldRow}>
            <EditorLabel span={6} label={EM.INTR.LABEL.FIRSTNAME} />
            <EditorInput
                max={60}
                span={18}
                value={value.meta.firstName!}
                inputType={EM.COMM.TYPE.TEXT}
                attribute={EM.INTR.ATTRIBUTE.FIRSTNAME}
                handleAttribute={onChangeAttribute} />
        </Row>
        <Row className={style.introFieldRow}>
            <EditorLabel span={6} label={EM.INTR.LABEL.MIDDLENAME} />
            <EditorInput
                max={60}
                span={18}
                value={value.meta.middleName!}
                inputType={EM.COMM.TYPE.TEXT}
                attribute={EM.INTR.ATTRIBUTE.MIDDLENAME}
                handleAttribute={onChangeAttribute} />
        </Row>
        <Row className={style.introFieldRow}>
            <EditorLabel span={6} label={EM.INTR.LABEL.LASTNAME} />
            <EditorInput
                max={60}
                span={18}
                value={value.meta.lastName!}
                inputType={EM.COMM.TYPE.TEXT}
                attribute={EM.INTR.ATTRIBUTE.LASTNAME}
                handleAttribute={onChangeAttribute} />
        </Row>
        <Row className={style.introFieldRow}>
            <EditorLabel span={6} label={EM.INTR.FIELDLABEL.FONT} />
            <EditorFontPicker
                span={18}
                text={fontText}
                buttonWidth={'100px'}
                textWidth={'140px'}
                buttonColor={control.color}
                textColor={value.color}
                snapShot={value.font}
                fontSize={16}
                fontFamily={value.font}
                fontWeight={1}
                attribute={EM.INTR.FIELDKEY.FONT}
                handleAttribute={(value: IF.COMM.ValueNameIF, attribute: string) => onChangeAttribute(value.value, attribute)} />
        </Row>
        <Row className={style.introFieldRow}>
            <EditorLabel span={6} label={EM.INTR.FIELDLABEL.SIZE} />
            <EditorSlider
                min={1}
                max={50}
                span={18}
                value={value.size}
                width={RS.COMM.width90}
                attribute={EM.INTR.FIELDKEY.SIZE}
                handleAttribute={onChangeAttribute} />
        </Row>
        <Row className={style.introFieldRow}>
            <EditorLabel span={6} label={EM.INTR.FIELDLABEL.WEIGHT} />
            <EditorWeight
                span={18}
                size={1}
                value={value.weight}
                saveStyle={saveStyle}
                undoStyle={undoStyle}
                attribute={EM.INTR.FIELDKEY.WEIGHT}
                handleAttribute={onChangeAttribute} />
        </Row>
        <Row className={style.introFieldRow}>
            <EditorLabel span={6} label={EM.INTR.FIELDLABEL.COLOR} />
            <EditorHuePicker
                span={18}
                width={'70%'}
                color={value.color}
                attribute={EM.INTR.FIELDKEY.COLOR}
                undoStyle={undoStyle}
                handleAttribute={onChangeAttribute} />
        </Row>
        <Row className={style.introFieldRow}>
            <EditorLabel span={6} label={EM.INTR.FIELDLABEL.ORDER} />
            <EditorOrder
                span={18}
                undoStyle={undoStyle}
                disableUp={disableUp}
                disableDn={disableDn}
                onChangeOrder={(up) => onChangeOrder(up, attribute)} />
        </Row>
        <Row className={style.introFieldRow}>
            <EditorLabel span={6} label={EM.INTR.FIELDLABEL.SHOW} />
            <EditorSwitch
                span={18}
                show={value.show === EM.COMM.DISPLAY.FLEX}
                attribute={EM.INTR.FIELDKEY.SHOW}
                switchStyle={switchStyle}
                checkedChildren={EM.COMM.VAR.SHOW}
                unCheckedChildren={EM.COMM.VAR.HIDE}
                handleAttribute={(checked: boolean, attribute: string) => onChangeAttribute(checked, attribute)} />
        </Row>
    </>
}

export default NameSetting