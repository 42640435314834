import React from 'react'
import { request } from '../../utils/request'
import { EM, IF } from '../../components/imports'
import { authStart, authFail, authSuccess, authUpdateMODLSnapshot } from './auth'

export const updateOrder = async (auth: IF.AUTH.AuthIF, dispatch: any, modules: IF.STAT.ModuleIF[]) => {
  authStart(auth, dispatch)
  const newModules = modules.map((module: IF.STAT.ModuleIF) => {
    const frame = { ...module.frame }
    const newFrame = { id: frame.id, module: frame.module, top: frame.top, left: frame.left }
    return { ...module, frame: newFrame }
  })
  //eslint-disable-next-line
  const input = newModules.map(({ value, ...keepAttrs }) => keepAttrs)
  try {
    await request(EM.MODL.URL.ORDER, auth.token, input, EM.COMM.APIMETHOD.PATCH)
    authUpdateMODLSnapshot(modules, dispatch)
    authSuccess(auth, dispatch)
  } catch (error: any) {
    authFail(auth, dispatch, error.response.data.error)
  }
}

export const updateFrame = async (
  auth: IF.AUTH.AuthIF,
  dispatch: any,
  index: number,
  module: IF.STAT.ModuleIF,
  snapShots: IF.STAT.ModuleIF[]
) => {
  authStart(auth, dispatch)
  const input = { ...module.frame }
  try {
    await request(EM.MODL.URL.FRAME, auth.token, input, EM.COMM.APIMETHOD.PATCH)
    const newModules = [...snapShots.slice(0, index), module, ...snapShots.slice(index + 1)]
    authUpdateMODLSnapshot(newModules, dispatch)
    authSuccess(auth, dispatch)
  } catch (error: any) {
    authFail(auth, dispatch, error.response.data.error)
  }
}

export const updatePosition = async (
  auth: IF.AUTH.AuthIF,
  dispatch: any,
  index: number,
  modules: IF.STAT.ModuleIF[]
) => {
  authStart(auth, dispatch)
  const frame = modules[index].frame
  const input = { id: frame.id, module: frame.module, top: frame.top, left: frame.left }
  try {
    await request(EM.MODL.URL.POSITION, auth.token, input, EM.COMM.APIMETHOD.PATCH)
    authUpdateMODLSnapshot(modules, dispatch)
    authSuccess(auth, dispatch)
  } catch (error: any) {
    authFail(auth, dispatch, error.response.data.error)
  }
}

export const updateStatus = async (
  auth: IF.AUTH.AuthIF,
  dispatch: any,
  index: number,
  control: IF.CTRL.ControlIF,
  module: IF.STAT.ModuleIF,
  snapShots: IF.STAT.ModuleIF[],
  setControl: React.Dispatch<React.SetStateAction<IF.CTRL.ControlIF>>,
  setModules: React.Dispatch<React.SetStateAction<IF.STAT.ModuleIF[]>>
) => {
  authStart(auth, dispatch)
  const snapShot = snapShots[index]
  const input = {
    id: module.id,
    order: module.order,
    title: module.title,
    status: module.status,
    show: module.show,
  }
  try {
    const response = await request(EM.MODL.URL.STATUS, auth.token, input, EM.COMM.APIMETHOD.PATCH)
    const data = response.data
    let modules: IF.STAT.ModuleIF[] = []
    if (data.status === 'deleted') {
      modules = [...snapShots.slice(0, index), ...snapShots.slice(index + 1)]
      setControl({ ...control, showDrawer: false })
    } else {
      modules = [
        ...snapShots.slice(0, index),
        {
          ...snapShot,
          order: data.order,
          status: data.status,
          show: data.show,
          title: data.title,
          deletedTime: data.deletedTime,
        },
        ...snapShots.slice(index + 1),
      ]
    }
    setModules(modules)
    authUpdateMODLSnapshot(modules, dispatch)
    authSuccess(auth, dispatch)
  } catch (error: any) {
    authFail(auth, dispatch, error.response.data.error)
  }
}
