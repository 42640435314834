import { VS } from '../components/imports'


const Register = () => {
    return (
        <>
            <VS.RegisterBackground />
            <VS.RegisterFrame />
        </>
    )
}
export default Register
