import React from 'react'
import style from '../../../assets/navigation.module.scss'
import { Drawer } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { IF, RS } from '../../imports'
import * as m from '.././methods'


const MenuBar = (props: {
    naviBar: IF.NAVG.NaviBarIF,
    menuBar: IF.NAVG.MenuBarIF,
    modules: IF.STAT.ModuleIF[],
    openIcon: boolean,
    openMenu: boolean,
    showMenu: boolean,
    setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>,
    setShowMenu: React.Dispatch<React.SetStateAction<boolean>>
}) => {
    const { naviBar, menuBar, modules, openIcon, openMenu, showMenu, setOpenMenu, setShowMenu } = props

    const [iconStyle, setIconStyle] = React.useState<IF.NAVG.menuIconIF>(RS.COMM.hideBlock)

    const menuRef: any = React.useRef(null)

    React.useEffect(() => {
        m.showMenuIcon(menuBar, openIcon, setIconStyle)
    }, [menuBar, openIcon])

    React.useEffect(() => {
        if (openMenu && menuRef.current !== null) {
            const drawer = document.getElementsByClassName('ant-drawer-title')
            const title = Array.from(drawer as HTMLCollectionOf<HTMLElement>)
            if (title.length > 0) {
                titleStyle(title[0])
            }
        }
    }, [openMenu, naviBar, menuBar])

    const closeIcon = <CloseOutlined style={{ color: menuBar.titleColor }} />
    const imageStyle = { width: `${menuBar.iconSize}px`, height: `${menuBar.iconSize}px` }
    const iconImage = <img src={menuBar.iconImage} style={imageStyle} alt={'menu-icon'} />

    const titleStyle = (title: HTMLElement) => {
        if (naviBar.sync === 0 || naviBar.sync === 1) {
            title.style.color = menuBar.titleColor
            title.style.fontSize = `${menuBar.titleSize}px`
            title.style.fontFamily = menuBar.titleFont
        } else if (naviBar.sync === 2 || naviBar.sync === 4) {
            title.style.color = naviBar.colorOne
            title.style.fontSize = `${menuBar.titleSize}px`
            title.style.fontFamily = naviBar.fontOne
        } else if (naviBar.sync === 3) {
            title.style.color = naviBar.colorTwo
            title.style.fontSize = `${menuBar.titleSize}px`
            title.style.fontFamily = naviBar.fontTwo
        }
    }

    const backgroundStyle = () => {
        let thisStyle: React.CSSProperties = RS.COMM.hideBlock
        if (showMenu) {
            if (naviBar.sync === 0 || naviBar.sync === 1) {
                if (menuBar.option === 1) thisStyle = { background: `url(${menuBar.image}) ${menuBar.position}% center / cover` }
                if (menuBar.option === 2) thisStyle = { background: menuBar.color }
            } else if (naviBar.sync === 2 || naviBar.sync === 4) {
                thisStyle = { background: naviBar.optionOne === 1 ? `url(${naviBar.imageOne}) ${naviBar.positionOne}% center / cover` : naviBar.colorOne }
            } else if (naviBar.sync === 3) {
                thisStyle = { background: naviBar.optionTwo === 1 ? `url(${naviBar.imageTwo}) ${naviBar.positionTwo}% center / cover` : naviBar.colorTwo }
            }
        }
        return thisStyle
    }

    const itemStyle = () => {
        let thisStyle: React.CSSProperties = {}
        if (naviBar.sync === 0 || naviBar.sync === 1) {
            thisStyle = {
                fontSize: `${menuBar.itemSize}px`,
                color: menuBar.itemColor,
                fontFamily: menuBar.itemFont
            }
        } else if (naviBar.sync === 2 || naviBar.sync === 4) {
            thisStyle = {
                fontSize: `${menuBar.itemSize}px`,
                color: naviBar.colorOne,
                fontFamily: naviBar.fontOne
            }
        } else if (naviBar.sync === 3) {
            thisStyle = {
                fontSize: `${menuBar.itemSize}px`,
                color: naviBar.colorTwo,
                fontFamily: naviBar.fontTwo
            }
        }
        return thisStyle
    }


    const onCloseMenu = () => {
        setOpenMenu(false)
        if (window.innerWidth < 576) {
            setIconStyle({ ...iconStyle, display: 'block' })
        } else {
            setIconStyle({ ...iconStyle, display: 'none' })
        }
    }

    const toggleMenu = () => {
        setIconStyle({ ...iconStyle, display: 'none' })
        setOpenMenu(true)
        setShowMenu(true)
    }

    return (
        <div ref={menuRef}>
            <div className={style.menuIcon} style={iconStyle} onClick={toggleMenu}>
                {menuBar.iconOption === 1 && iconImage}
                {menuBar.iconOption === 2 && m.handleShowMenuIcon(menuBar.icon, menuBar.iconSize, menuBar.iconColor)}
            </div>
            <Drawer
                title={menuBar.title}
                placement={'left'}
                closeIcon={closeIcon}
                onClose={onCloseMenu}
                open={openMenu}
                width={menuBar.width}
                style={backgroundStyle()}
            >
                {modules.map((module: IF.STAT.ModuleIF) => (
                    <p className={style.navItems} key={module.order}
                        style={(module.show && module.status === 'active') ? itemStyle() : RS.COMM.hideBlock}
                        onClick={() => m.scrollToModule(module)}>
                        {module.title}
                    </p>
                ))}
            </Drawer>
        </div>
    )
}

export default MenuBar