import React from 'react'
import style from '../../assets/editor.module.scss'
import IntroductionEditor from './IntroductionEditor'
import { EM, IF, RS } from '../imports'
import * as m from './methods'

const ModuleEditor = (props: {
  loading: boolean
  control: IF.CTRL.ControlIF
  module: IF.STAT.ModuleIF
  modules: IF.STAT.ModuleIF[]
  snapShot: IF.STAT.ModuleIF
  saveStyle: React.CSSProperties
  undoStyle: React.CSSProperties
  uploadBar: any
  uploading: boolean
  selection: IF.COMM.ValueNameIF
  setControl: React.Dispatch<React.SetStateAction<IF.CTRL.ControlIF>>
  setModules: React.Dispatch<React.SetStateAction<IF.STAT.ModuleIF[]>>
  setSelection: React.Dispatch<React.SetStateAction<IF.COMM.ValueNameIF>>
  setUploading: React.Dispatch<React.SetStateAction<boolean>>
  setUploadbar: React.Dispatch<React.SetStateAction<any>>
  setFileObject: React.Dispatch<React.SetStateAction<string | File>>
}) => {
  /** Properties */
  const {
    loading,
    control,
    module,
    modules,
    snapShot,
    saveStyle,
    undoStyle,
    uploadBar,
    uploading,
    selection,
    setControl,
    setModules,
    setSelection,
    setUploading,
    setUploadbar,
    setFileObject,
  } = props

  const [menuList, setMenuList] = React.useState<IF.COMM.EditorMenuIF[]>(RS.INTR.menuList)
  const [dragIndex, setDragIndex] = React.useState<number>(0)

  React.useEffect(() => {
    const background = module.frame.option == 1 ? module.frame.image : module.frame.color
    let avatar = module.value.introduction!.avatar.image
    avatar = avatar
      ? `url(${module.value.introduction!.avatar.image}) center center / cover`
      : RS.INTR.menuList[1].value
    setMenuList([
      { ...RS.INTR.menuList[0], value: background || RS.INTR.menuList[0].value },
      { ...RS.INTR.menuList[1], value: avatar },
      ...RS.INTR.menuList.slice(2),
    ])
  }, [module])

  React.useEffect(() => {
    if (control.page === 2) scrollPage(2)
  }, [control.page])

  /** Local Variables */
  const scrollPage = (page: number) => {
    const pages = Array.from(document.getElementsByClassName(style.editorPageOne) as HTMLCollectionOf<HTMLElement>)
    pages.forEach((pages: any) => (pages.style.transform = `translateX(-${(page - 1) * 100}%)`))
    setControl({ ...control, page: page })
  }

  const scrollMD = (index: number) => {
    const height = (window.innerHeight * modules[index].frame.height) / 400
    const html = document.documentElement
    html.scrollTop = modules[index].frame.top - height
    setControl({ ...control, index: index })
    scrollPage(2)
  }

  const onSelectSetting = (item: IF.COMM.ValueNameIF) => {
    setSelection(item)
    scrollPage(3)
  }

  return (
    <div className={style.editorPageDiv}>
      <div className={style.editorPageMD}>
        <div className={style.editorPageOne}>
          <div className={style.editorPageOneDiv}>
            <div className={style.editorPageOneAdd}>
              <div className={style.editorPageOneInner} style={saveStyle}>
                {EM.COMM.BUTTON.ADDMODL}
              </div>
            </div>
            <div className={style.editorPageOneOuter}>
              {modules.map((item: IF.STAT.ModuleIF, index: number) => {
                let thisStyle = { background: item.frame.image }
                if (item.frame.option === 2) thisStyle = { background: item.frame.color }
                return (
                  <div
                    key={index}
                    className={style.editorPageOneOuterDrag}
                    onDrop={(ev) => m.dropModule(ev, style, index, dragIndex, modules, setModules)}
                    onDragOver={(ev: any) => ev.preventDefault()}
                  >
                    <div
                      className={style.editorPageOneInner}
                      draggable={true}
                      style={thisStyle}
                      onClick={() => scrollMD(index)}
                      onDragStart={() => setDragIndex(index)}
                    />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className={style.editorPageOne}>
          <div className={style.editorPageOneDivMD}>
            {module.show ? (
              menuList.map((item: IF.COMM.EditorMenuIF, index) => {
                const menuStyle: React.CSSProperties = {
                  color: control.color,
                  background: item.value,
                  borderColor: control.color,
                }
                return (
                  <div key={index} className={style.editorPageOneOuterMD}>
                    <div className={style.editorPageOneInner} style={menuStyle} onClick={() => onSelectSetting(item)}>
                      <img src={item.icon} alt={`${item.name}-icon`} />
                      <span>{item.name}</span>
                    </div>
                  </div>
                )
              })
            ) : (
              <div className={style.editorPageOneOuterMD}>
                <div className={style.editorPageOneInner} onClick={() => onSelectSetting(RS.INTR.menuList[8])}>
                  {EM.INTR.SETTING.MODULE}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={style.editorPageOne}>
          {module.type === EM.MODL.TYPE.INTRODUCTION && (
            <IntroductionEditor
              loading={loading}
              control={control}
              module={module}
              modules={modules}
              snapShot={snapShot}
              saveStyle={saveStyle}
              undoStyle={undoStyle}
              uploading={uploading}
              uploadBar={uploadBar}
              selection={selection}
              setControl={setControl}
              setModules={setModules}
              setUploading={setUploading}
              setUploadbar={setUploadbar}
              setFileObject={setFileObject}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default ModuleEditor
