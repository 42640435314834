import React from 'react'
import style from '../../assets/editor.intro.module.scss'
import type { MenuProps, DatePickerProps } from 'antd'
import { Button, Col, Dropdown, Row, Switch } from 'antd'
import { DownOutlined, DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons'
import { IF, RS, EM } from '../imports'
import {
  EditorDatePicker,
  EditorFontPicker,
  EditorHuePicker,
  EditorInput,
  EditorLabel,
  EditorParallel,
  EditorSelect,
  EditorSlider,
  EditorText,
  EditorTextArea,
} from '../Common/EditorParts'
import * as m from './methods'

const CustomSetting = (props: {
  loading: boolean
  intro: IF.INTR.IntroductionIF
  snapShot: IF.STAT.ModuleIF
  control: IF.CTRL.ControlIF
  module: IF.STAT.ModuleIF
  modules: IF.STAT.ModuleIF[]
  saveStyle: React.CSSProperties
  undoStyle: React.CSSProperties
  setModules: React.Dispatch<React.SetStateAction<IF.STAT.ModuleIF[]>>
}) => {
  const { loading, intro, snapShot, control, module, modules, saveStyle, undoStyle, setModules } = props

  /** React Methods */
  const hideBlock = { display: EM.COMM.DISPLAY.NONE }
  const showBlock = { display: EM.COMM.DISPLAY.BLOCK }
  const [selection, setSelection] = React.useState<IF.COMM.ValueKeyIF>(RS.INTR.valueKeySelect)
  const [customField, setCustomField] = React.useState<IF.INTR.IntroCustomIF>(RS.INTR.customFieldDefault)
  const [cacheValue, setCacheValue] = React.useState<IF.INTR.IntroCustomIF>(RS.INTR.customFieldDefault)
  const [editStyle, setEditStyle] = React.useState<IF.COMM.DisplayIF>(hideBlock)

  /** Local Variables */
  const addField = { key: EM.INTR.VAR.ADD, value: EM.INTR.VAR.ADD }
  const switchStyle = { backgroundColor: control.color, width: '80px' }
  const previewStyle = {
    color: customField.color,
    fontSize: customField.size + 'px',
    fontWeight: m.weightObject(customField.weight),
    fontFamily: customField.font.value,
    display: customField.show,
  }

  const customFields = intro.content.filter((item) => item.meta.custom === true)

  const dropItem = selection.key === 'add' ? RS.INTR.valueKeySelect.key : selection.key

  const items: MenuProps['items'] = customFields.map((item: IF.INTR.IntroContentIF, index) => ({
    key: index,
    label: item.label,
  }))

  /* Local Methods */
  const onClick: MenuProps['onClick'] = ({ key }) => {
    const thisContent = customFields[Number(key)]
    const selected: IF.COMM.ValueKeyIF = {
      key: thisContent.label,
      value: thisContent.text,
    }
    if (cacheValue.key === selected.key) {
      setCustomField(cacheValue)
    } else {
      const thisField = {
        key: thisContent.label,
        text: thisContent.text,
        show: thisContent.show,
        color: thisContent.color,
        size: thisContent.size,
        weight: thisContent.weight,
        order: thisContent.meta.order,
        type: { value: thisContent.meta.type.value, label: thisContent.meta.type.label },
        font: { name: thisContent.meta.font, value: thisContent.font },
      }
      setCustomField(thisField)
      setCacheValue(thisField)
    }
    setSelection(selected)
    setEditStyle(showBlock)
  }

  const handleCreateField = (item: IF.COMM.ValueKeyIF) => {
    let thisIndex = '01'
    const newItems = intro.content?.filter((item: IF.INTR.IntroContentIF) => item.meta.extra === true)
    if (newItems !== undefined) {
      const re = '/[0-9]{1,}/'
      const numbers = newItems!.map((item: IF.INTR.IntroContentIF) => {
        const match = item!.label!.match(re)
        if (match) return Number(match[0])
        return 1
      })
      const nextNum = Math.max(...numbers) + 1
      thisIndex = nextNum < 10 ? `0${nextNum}` : `${nextNum}`
    }
    const newKey = `Custom Field ${thisIndex}`
    setSelection(item)
    setEditStyle(showBlock)

    const newModule = { ...snapShot }
    let newIntro = snapShot.value.introduction!
    setCustomField({
      ...RS.INTR.customFieldDefault,
      key: newKey,
      order: newIntro.order.length + 1,
    })
    const place = newIntro.order.length + 1
    const newOrder = newIntro.order.concat({
      key: newKey,
      value: newKey,
      order: place,
      hover: false,
      show: 'flex',
    })
    const newContent = newIntro.content.concat({
      ...RS.INTR.customContentDefault,
      label: newKey,
      meta: {
        hasIcon: false,
        iconType: '',
        iconName: '',
        isCustom: true,
        dataType: 1,
        font: RS.INTR.customFieldDefault.font,
        type: RS.INTR.customFieldDefault.type,
        order: newIntro.order.length + 1,
      },
    })
    newIntro = { ...newIntro, order: newOrder, content: newContent }
    newModule.value = { introduction: newIntro }
    setModules([...modules.slice(0, control.index), newModule, ...modules.slice(control.index + 1)])
  }

  const onClearField = () => {
    setEditStyle(hideBlock)
    setSelection(RS.INTR.valueKeySelect)
    setModules([...modules.slice(0, control.index), snapShot, ...modules.slice(control.index + 1)])
  }

  const onChangeCustomField = (formValue: any, formKey: string) => {
    m.handleCustomField(intro, formValue, formKey, module, modules, control, customField, setModules, setCustomField)
  }

  const onChangeFieldType = (result: { value: string; label: React.ReactNode }) => {
    onChangeCustomField({ value: result.value, label: result.label }, EM.INTR.FIELDKEY.TYPE)
  }

  const onChangeFieldOrder = (moveUp: boolean) => {
    onChangeCustomField(moveUp, EM.INTR.FIELDKEY.ORDER)
  }
  const onChangeFieldText = (value: string, attribute: string) => {
    onChangeCustomField(value, attribute)
  }

  const onChangeFieldSize = (value: number, attribute: string) => {
    onChangeCustomField(value, attribute)
  }

  const onChangeFieldDate: DatePickerProps['onChange'] = (date, dateString) => {
    onChangeCustomField(dateString, EM.INTR.FIELDKEY.TEXT)
  }

  const onChangeFieldDisplay = (checked: boolean) => {
    const show = checked ? EM.COMM.DISPLAY.FLEX : EM.COMM.DISPLAY.NONE
    onChangeCustomField(show, EM.INTR.FIELDKEY.SHOW)
  }

  const onChangeFieldFont = (value: IF.COMM.ValueNameIF, attribute: string) => {
    onChangeCustomField(value, attribute)
  }

  const onChangeFieldColor = (color: string, attribute: string) => {
    onChangeCustomField(color, attribute)
  }

  const onDeleteField = () => {
    m.handleDeleteExtra(
      intro,
      module,
      modules,
      control,
      snapShot,
      customField,
      hideBlock,
      setModules,
      setEditStyle,
      setSelection
    )
  }

  const fieldDisplay = [
    <Button
      style={undoStyle}
      size={EM.COMM.BUTTON.SMALL}
      onClick={() => onChangeFieldOrder(true)}
      key="1"
      disabled={customField.order === 1}
    >
      {EM.COMM.BUTTON.MOVEUP} <UpCircleOutlined style={{ fontSize: '16px' }} />
    </Button>,
    <Button
      style={undoStyle}
      size={EM.COMM.BUTTON.SMALL}
      onClick={() => onChangeFieldOrder(false)}
      key="2"
      disabled={customField.order === intro.order.length}
    >
      {EM.COMM.BUTTON.MOVEDN} <DownCircleOutlined style={{ fontSize: '16px' }} />
    </Button>,
    <Switch
      style={switchStyle}
      defaultChecked={customField.show === EM.COMM.DISPLAY.FLEX}
      checkedChildren={EM.COMM.VAR.SHOW}
      unCheckedChildren={EM.COMM.VAR.HIDE}
      key="3"
      onChange={(checked: boolean) => {
        onChangeFieldDisplay(checked)
      }}
    />,
  ]

  return (
    <div className={style.editorPageTwo}>
      <div className={style.introExtraHeader}>
        <Button size={'middle'} type="primary" style={saveStyle} onClick={() => handleCreateField(addField)}>
          {EM.COMM.BUTTON.ADDFIELD}
        </Button>
        <Dropdown menu={{ items, onClick }} trigger={['click']} disabled={loading || customFields.length === 0}>
          <Button size={'middle'} type="primary" style={saveStyle}>
            <span>{dropItem}</span>
            <DownOutlined />
          </Button>
        </Dropdown>
        <Button size={'middle'} style={undoStyle} onClick={() => onClearField()}>
          {EM.COMM.BUTTON.CLEARFIELD}
        </Button>
      </div>
      <div style={editStyle}>
        <Row className={style.introExtraRow}>
          <EditorLabel span={6} label={EM.INTR.FIELDLABEL.PREVIEW} />
          <EditorText span={18} text={customField.text} iStyle={previewStyle} />
        </Row>
        <Row className={style.introExtraRow}>
          <EditorLabel span={6} label={EM.INTR.FIELDLABEL.TYPE} />
          <EditorSelect
            value={{
              value: customField.type.value,
              label: customField.type.label,
            }}
            sourceList={RS.INTR.valueLabelSelect}
            onChangeItem={onChangeFieldType}
          />
        </Row>
        <Row className={style.introExtraRow}>
          <EditorLabel span={6} label={EM.INTR.FIELDLABEL.VALUE} />
          {customField.type.value === EM.INTR.FIELDSELECT.INPUT && (
            <EditorInput
              max={100}
              span={18}
              value={customField.text}
              inputType={EM.COMM.TYPE.TEXT}
              attribute={EM.INTR.FIELDKEY.TEXT}
              handleAttribute={onChangeFieldText}
            />
          )}
          {customField.type.value === EM.INTR.FIELDSELECT.TEXT && (
            <EditorTextArea
              max={300}
              span={18}
              minRows={3}
              maxRows={4}
              value={customField.text}
              attribute={EM.INTR.FIELDKEY.TEXT}
              handleAttribute={onChangeFieldText}
            />
          )}
          {customField.type.value === EM.INTR.FIELDSELECT.DATE && (
            <EditorDatePicker span={18} value={customField.text} handleAttribute={onChangeFieldDate} />
          )}
        </Row>
        <Row className={style.introExtraRow}>
          <EditorLabel span={6} label={EM.INTR.FIELDLABEL.SHOW} />
          <EditorParallel span={18} elements={fieldDisplay} />
        </Row>
        <Row className={style.introExtraRow}>
          <EditorLabel span={6} label={EM.INTR.FIELDLABEL.FONT} />
          <EditorFontPicker
            span={18}
            text={customField.text}
            buttonWidth={'100px'}
            textWidth={'140px'}
            buttonColor={control.color}
            textColor={customField.color}
            snapShot={customField.font.value}
            fontSize={customField.size}
            fontFamily={customField.font.value}
            fontWeight={customField.weight}
            attribute={EM.INTR.FIELDKEY.FONT}
            handleAttribute={onChangeFieldFont}
          />
        </Row>
        <Row className={style.introExtraRow}>
          <EditorLabel span={6} label={EM.INTR.FIELDLABEL.SIZE} />
          <EditorSlider
            min={1}
            max={50}
            span={18}
            value={customField.size}
            width={RS.COMM.width90}
            attribute={EM.INTR.FIELDKEY.SIZE}
            handleAttribute={onChangeFieldSize}
          />
        </Row>
        <Row className={style.introExtraRow}>
          <EditorLabel span={6} label={EM.INTR.FIELDLABEL.WEIGHT} />
          <EditorSlider
            min={1}
            max={3}
            span={18}
            value={customField.weight}
            width={RS.COMM.width90}
            attribute={EM.INTR.FIELDKEY.WEIGHT}
            handleAttribute={onChangeCustomField}
          />
        </Row>
        <Row className={style.introExtraRow}>
          <EditorLabel span={6} label={EM.INTR.FIELDLABEL.COLOR} />
          <EditorHuePicker
            span={18}
            width={'70%'}
            color={customField.color}
            attribute={EM.INTR.FIELDKEY.COLOR}
            undoStyle={undoStyle}
            handleAttribute={onChangeFieldColor}
          />
        </Row>
        <Row className={style.introExtraRow}>
          <EditorLabel span={6} label={EM.INTR.FIELDLABEL.DELETE} />
          <Col span={18}>
            <div className={style.introColCenter}>
              <Button type="primary" danger onClick={() => onDeleteField()}>
                {EM.COMM.BUTTON.DELETE}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default CustomSetting
