import style from '../../assets/register.module.scss'
import fireflies from '../../assets/firefly.module.sass'


export const handleRain = (firefly: any, count: number) => {
    for (let i = 1; i <= count; i++) {
        const drop0 = document.createElement('i')
        drop0.className = `${fireflies.firefly}`
        firefly.current.appendChild(drop0)
    }
    firefly.current.addEventListener('contextmenu', (e: Event) => {
        e.preventDefault()
    })
}


export const passwordValidation = (
    value: string,
    strength: any,
    setStrength: React.Dispatch<any>
) => {
    // eslint-disable-next-line
    const letterChars = /[a-zA-Z]/
    // eslint-disable-next-line
    const upperChars = /[A-Z]/
    // eslint-disable-next-line
    const numberChars = /[0-9]/
    // eslint-disable-next-line
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/

    let ns = strength
    ns = { ...ns, strength: 1, length: value.length }
    if (letterChars.test(value)) ns = { ...ns, letter: true }
    else ns = { ...ns, letter: false }
    if (upperChars.test(value)) ns = { ...ns, upper: true }
    else ns = { ...ns, upper: false }
    if (numberChars.test(value)) ns = { ...ns, number: true }
    else ns = { ...ns, number: false }
    if (specialChars.test(value)) ns = { ...ns, symbol: true }
    else ns = { ...ns, symbol: false }
    if (!ns.upper && !ns.number && !ns.symbol) {
        ns = { ...ns, strength: 1 }
    } else if ((!ns.letter || !ns.upper) && !ns.symbol) {
        ns = { ...ns, strength: 1 }
    } else if ((!ns.letter || !ns.upper) && !ns.number) {
        ns = { ...ns, strength: 1 }
    } else {
        ns = { ...ns, strength: 2 }
    }
    if (ns.strength > 1 && ns.length < 9) {
        ns = { ...ns, strength: 1 }
    } else if (ns.strength > 1 && ns.length > 8 && ns.length < 12) {
        ns = { ...ns, strength: 2 }
    } else if (ns.strength > 1 && ns.length > 11) {
        ns = { ...ns, strength: 3 }
    }
    setStrength(ns)
}


export const activationNone = <>
    Your <a href={window.location.href} className={style.registerCreate}>
        activation link</a> is either expired or incorrect.<br />
    Please check your email or request another link.<br />
    Thank you.
    <br /><br />
</>

export const activationActive = <>
    Thank you for registering with us.<br />
    Your account has been activated.<br />
    Please sign in with your credentials.
    <br /><br />
</>

export const activationInactive = <>
    Thank you for registering with us.<br />
    Please activate your account below.
</>

export const activationActivated = <>
    Thank you for registering with us.<br />
    Your account is activated. Please sign in below.
    <br /><br />
</>

export const emailSent = <>
    An email has been sent to your email address.<br />
    Please wait for one to two minutes if you haven&apos;t received it.<br />
    Thank you.
    <br /><br />
</>

export const passwordReset = <>
    Your password has been reset.<br />
    Please sign in below.
    <br /><br />
</>

export const invalidLink = <>
    Your <a href={window.location.href} className={style.registerCreate}>
        reset link</a> is either expired or incorrect.<br />
    Please check your email or request another link.<br />
    Thank you.
    <br /><br />
</>

export const regiterEmail = <>
    Thank you for registering with us.<br />
    An email has been sent to your email box.<br />
    Please activate your account as soon as possible.
</>