import { Route, Redirect, Switch } from 'react-router-dom'

// import Landing from './01_landing/container/landing'
import * as Templates from './talents/utils/index'

// import { Landing as }
function BaseRouter() {
  const talent = '/talents/:account'
  const signin = '/users/signin'
  const method = '/users/:method'
  const active = '/users/activate/:name/:uuid'
  const resets = '/users/resetpwd/:name/:uuid'
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to={signin} />
      </Route>
      <Route exact path={signin} render={() => <Templates.AppRegister />} />
      <Route exact path={method} render={() => <Templates.AppRegister />} />
      <Route exact path={talent} render={(props) => <Templates.AppTemplate {...props} />} />
      <Route exact path={active} render={() => <Templates.AppActivate />} />
      <Route exact path={resets} render={() => <Templates.AppResetPWD />} />
    </Switch>
  )
}

export { BaseRouter }
