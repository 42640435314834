import { BrowserRouter as Router, Route } from 'react-router-dom'
import { BaseRouter } from './routes'

function App() {
  return (
    <Router>
      <Route component={BaseRouter} />
    </Router>
  )
}

export default App
