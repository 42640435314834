import { Col, Row, Slider } from 'antd'
import { EM, IF, RS } from '../imports'
import { EditorLabel } from '../Common/EditorParts'


const AvatarValue = (props: any) => {
    /** Properties */
    const { style, value, effect, shadow, color, HuePicker, handleAttribute } = props

    /** Local Variables */
    const hide = (effect === EM.INTR.EFFECT.NONE || effect === EM.INTR.EFFECT.SELECT)
    const hideOthers = hide || effect === EM.INTR.EFFECT.SHADOW
    const showRow = hide ? RS.COMM.hideBlock : RS.COMM.showFlex
    const showShadow = effect === EM.INTR.EFFECT.SHADOW ? RS.COMM.showFlex : RS.COMM.hideBlock
    const showOthers = hideOthers ? RS.COMM.hideBlock : RS.COMM.showFlex
    const index = RS.INTR.effects.findIndex((item: string) => (item === effect))
    const label = RS.INTR.effectWords[index]
    const drop: number[] = shadow.split(',')

    const max = () => {
        switch (effect) {
            case EM.INTR.EFFECT.BLUR:
                return 20
            case EM.INTR.EFFECT.BRIGHT:
                return 300
            case EM.INTR.EFFECT.CONTRAST:
                return 500
            case EM.INTR.EFFECT.HUE:
                return 360
            case EM.INTR.EFFECT.SATURATE:
                return 20
            case EM.INTR.EFFECT.SHADOW:
                return 50
            default:
                return 100
        }
    }

    const step = () => {
        switch (effect) {
            case EM.INTR.EFFECT.BLUR:
                return 0.2
            case EM.INTR.EFFECT.SATURATE:
                return 0.1
            case EM.INTR.EFFECT.HUE:
                return 2
            case EM.INTR.EFFECT.BRIGHT:
                return 2
            default:
                return 1
        }
    }

    const onChangeColor = (event: IF.COMM.ColorIF) => {
        const value = `rgb(${event.rgb.r}, ${event.rgb.g}, ${event.rgb.b})`
        handleAttribute(value, EM.INTR.AVATAR.COLOR)
    }

    const onChangeShadow = (value: number, index: number) => {
        const newDrop = [
            ...drop.slice(0, index),
            value,
            ...drop.slice(index + 1)
        ]
        handleAttribute(newDrop.join(), effect)
    }

    return <Row className={style.editorRow} style={showRow}>
        <EditorLabel
            span={6}
            label={label} />
        <Col span={18}>
            <div className={style.introAvatarRound} style={showOthers}>
                <Slider min={0} max={max()} step={step()} value={value} style={RS.COMM.width90}
                    onChange={(value: number) => handleAttribute(value, effect)} />
            </div>
            <div className={style.introAvatarShadow} style={showShadow}>
                <Slider min={-100} max={max()} step={step()} value={drop[0]} style={RS.COMM.width90}
                    onChange={(value: number) => onChangeShadow(value, 0)} />
                <Slider min={-100} max={max()} step={step()} value={drop[1]} style={RS.COMM.width90}
                    onChange={(value: number) => onChangeShadow(value, 1)} />
                <Slider min={0} max={max()} step={step()} value={drop[2]} style={RS.COMM.width90}
                    onChange={(value: number) => onChangeShadow(value, 2)} />
                <HuePicker width={'90%'} color={color} onChange={(e: any) => onChangeColor(e)} />
            </div>
        </Col>
    </Row>
}

export default AvatarValue