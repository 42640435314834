import { EM, IF, RS } from '../imports'

export const updateFrameStyle = (
  frame: IF.FRME.FrameIF,
  isAuthorized: boolean,
  setFrameStyle: React.Dispatch<React.SetStateAction<IF.FRME.BorderIF>>
) => {
  const left = !isAuthorized && window.innerWidth < 900 ? window.innerWidth / 2 + 'px' : `${frame.left}%`
  const radius = frame.radius === 1000 ? '50%' : `${frame.radius}px`
  const source = `url(${frame.imageSource}) ${frame.imageSlice} / ${frame.lineWidth}px / ${frame.imageSpace}px ${frame.imageStyle}`
  const thisFrame = {
    width: `${frame.width}%`,
    height: `${(window.innerHeight * frame.height) / 100}px`,
    top: `${frame.top}px`,
    left: left,
    padding: `${frame.padding}px`,
    background: frame.option === 1 ? frame.image : frame.color,
    borderWidth: `${frame.lineWidth}px`,
    borderRadius: radius,
    justifyContent: frame.justify,
  }
  const newFrame =
    frame.style === 1
      ? { ...thisFrame, borderStyle: frame.lineStyle, borderColor: frame.lineColor }
      : { ...thisFrame, borderImage: source, borderStyle: frame.lineStyle }

  if (frame.option === 1) {
    setFrameStyle({ ...newFrame, background: frame.image })
  } else if (frame.option === 2) {
    setFrameStyle({ ...newFrame, background: frame.color })
  } else {
    setFrameStyle({ ...newFrame, background: 'none' })
  }
}

export const dragModule = (
  evt: any,
  index: number,
  frame: IF.FRME.FrameIF,
  frameRef: any,
  modules: IF.STAT.ModuleIF[],
  isAuthorized: boolean | '' | null,
  setModules: React.Dispatch<React.SetStateAction<IF.STAT.ModuleIF[]>>,
  setShowHint: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (!isAuthorized) return

  let posx = 0,
    posy = 0,
    top = 0,
    left = 0
  /* if present, the header is where you move the DIV from:*/
  if (frameRef.current) frameRef.current.onmousedown = dragMouseDown(evt)

  function dragMouseDown(e: any) {
    e.preventDefault()
    // get the mouse cursor position at startup:
    posx = e.clientX - (frame.left * window.innerWidth) / 100
    posy = e.clientY - frame.top
    document.onmouseup = closeDragElement
    document.onmousemove = elementDrag
  }

  function elementDrag(e: any) {
    e.preventDefault()
    // calculate the new cursor position:
    top = e.clientY - posy
    left = Math.round(((e.clientX - posx) / window.innerWidth) * 1000000) / 10000
    // set the element's new position:
    const newFrame = {
      ...frame,
      top: top,
      left: left,
    }
    setModules([...modules.slice(0, index), { ...modules[index], frame: newFrame }, ...modules.slice(index + 1)])
    setShowHint(false)
  }

  function closeDragElement() {
    /* stop moving when mouse button is released:*/
    document.onmouseup = null
    document.onmousemove = null
    setShowHint(true)
  }
}

export const revertPosition = (
  index: number,
  modules: IF.STAT.ModuleIF[],
  FRAMESnapshot: IF.FRME.FrameIF,
  setModules: React.Dispatch<React.SetStateAction<IF.STAT.ModuleIF[]>>,
  setShowHint: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setModules([...modules.slice(0, index), { ...modules[index], frame: FRAMESnapshot }, ...modules.slice(index + 1)])
  setShowHint(false)
}

export const handleHoverModule = (
  index: number,
  hover: boolean,
  modules: IF.STAT.ModuleIF[],
  setModules: React.Dispatch<React.SetStateAction<IF.STAT.ModuleIF[]>>
) => {
  setModules([...modules.slice(0, index), { ...modules[index], hover: hover }, ...modules.slice(index + 1)])
}

/* Frame Methods */
export const handleFrameAttribute = (
  event: any,
  attribute: string,
  control: IF.CTRL.ControlIF,
  module: IF.STAT.ModuleIF,
  modules: IF.STAT.ModuleIF[],
  setModules: React.Dispatch<React.SetStateAction<IF.STAT.ModuleIF[]>>
) => {
  const newFrame = { ...module.frame }
  if (attribute === EM.FRME.FORM.WIDTH) {
    newFrame.width = event
  } else if (attribute === EM.FRME.FORM.HEIGHT) {
    newFrame.height = event
  } else if (attribute === EM.FRME.FORM.LEFT) {
    newFrame.left = event
  } else if (attribute === EM.FRME.FORM.TOP) {
    newFrame.top = event.target.value
  } else if (attribute === EM.FRME.FORM.PADDING) {
    newFrame.padding = event
  } else if (attribute === EM.FRME.FORM.RADIUS) {
    newFrame.radius = event
  } else if (attribute === EM.FRME.FORM.ROUND) {
    newFrame.radius = event
  } else if (attribute === EM.FRME.FORM.LINE) {
    newFrame.style = event
  } else if (attribute === EM.FRME.FORM.IMAGE) {
    newFrame.style = event
    newFrame.imageStyle = EM.FRME.FORM.ROUND
  } else if (attribute === EM.FRME.FORM.LINESTYLE) {
    newFrame.lineStyle = event
  } else if (attribute === EM.FRME.FORM.LINEWIDTH || attribute === EM.FRME.FORM.IMAGEWIDTH) {
    newFrame.lineWidth = event
  } else if (attribute === EM.FRME.FORM.LINECOLOR) {
    newFrame.lineColor = event
  } else if (attribute === EM.FRME.FORM.IMAGESTYLE) {
    newFrame.imageStyle = event.toLowerCase()
  } else if (attribute === EM.FRME.FORM.IMAGESLICE) {
    newFrame.imageSlice = event
  } else if (attribute === EM.FRME.FORM.IMAGESPACE) {
    newFrame.imageSpace = event
  }

  setModules([...modules.slice(0, control.index), { ...module, frame: newFrame }, ...modules.slice(control.index + 1)])
}

export const updateFramePreview = (
  frame: IF.FRME.FrameIF,
  setImageStyle: React.Dispatch<React.SetStateAction<IF.FRME.PreviewIF>>
) => {
  const padding = Math.floor(frame.padding / 4)
  const borderWidth = Math.floor(frame.lineWidth / 4)
  const radius = frame.radius === 1000 ? '50%' : `${Math.floor(frame.radius / 4)}px`
  const frameStyle: IF.FRME.PreviewIF = {
    ...RS.FRME.previewBorder,
    width: `${frame.width}%`,
    height: `${frame.height}%`,
    borderStyle: frame.lineStyle,
    borderWidth: `${borderWidth}px`,
    borderRadius: radius,
  }
  if (frame.style === 1) {
    frameStyle.borderColor = frame.lineColor
  } else {
    const spaceOuter = Math.floor(frame.imageSpace / 4)
    frameStyle.borderImage = `url(${frame.imageSource}) ${frame.imageSlice} / ${borderWidth}px / ${spaceOuter}px ${frame.imageStyle}`
  }
  let background = 'none'
  if (frame.option === 1) background = frame.image
  if (frame.option === 2) background = frame.color
  setImageStyle({ ...frameStyle, background: background })
}

export const handleFrameBorder = (
  event: any,
  selection: string,
  control: IF.CTRL.ControlIF,
  module: IF.STAT.ModuleIF,
  modules: IF.STAT.ModuleIF[],
  setModules: React.Dispatch<React.SetStateAction<IF.STAT.ModuleIF[]>>
) => {
  const newFrame = { ...module.frame }
  if (selection === EM.COMM.OPTION.IMAGE) {
    newFrame.imageSource = event.target.src
  } else if (selection === EM.COMM.OPTION.URL) {
    newFrame.image = `url("${event.target.value}")`
  }

  setModules([...modules.slice(0, control.index), { ...module, frame: newFrame }, ...modules.slice(control.index + 1)])
}
