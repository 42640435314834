import React from 'react'
import style from '../../assets/register.module.scss'
import { Link } from 'react-router-dom'
import { Form, Input, Button, Row, Col, Dropdown, Tooltip, MenuProps } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { isEmpty } from 'lodash'
import { EM, IF, RS, RX } from '../imports'


const Signin = (props: any) => {
    /** Properties */
    const { auth, dispatch } = props

    /** React Methods */
    const [loginDuration, setLoginDuration] = React.useState<IF.RSTR.LoginDurationIF>(RS.RSTR.loginDuration)

    /** Local Methods */
    const onFinish = async (values: any) => {
        await RX.AUTH.authSignin(auth, dispatch, values.email, values.password, loginDuration.time)
    }

    const handleDropDown = (item: string, index: number) => {
        setLoginDuration({ ...loginDuration, name: item, time: RS.RSTR.loginValue[index] })
    }

    const items: MenuProps['items'] = RS.RSTR.loginDisplay.map((item: string, index: number) => ({
        key: index,
        label: <span onClick={() => handleDropDown(item, index)}>{item}</span>,
    }))


    return (
        <div className={style.registerDiv}>
            <div className={style.registerTitle}>
                {EM.RSTR.TITLE.SIGNIN}
            </div>
            <Form
                name={EM.RSTR.FORMNAME.LOGINFORM}
                onFinish={onFinish}
                className={style.signinForm}
            >
                <Form.Item
                    name={EM.RSTR.TYPE.EMAIL}
                    rules={[
                        {
                            type: EM.RSTR.TYPE.EMAIL,
                            message: EM.RSTR.FORM.EMAILINVALID,
                        },
                        {
                            required: true,
                            message: EM.RSTR.FORM.EMAILMESSAGE,
                        },
                    ]}
                >
                    <Input
                        size="large"
                        type={EM.RSTR.TYPE.EMAIL}
                        prefix={<UserOutlined />}
                        placeholder={EM.RSTR.PLACEHOLDER.EMAIL}
                        disabled={auth.loading}
                    />
                </Form.Item>

                <Form.Item
                    name={EM.RSTR.TYPE.PASSWORD}
                    rules={[
                        {
                            required: true,
                            message: EM.RSTR.FORM.PASSWORDMESSAGE,
                        },
                    ]}
                >
                    <Input
                        size="large"
                        type={EM.RSTR.TYPE.PASSWORD}
                        prefix={<LockOutlined />}
                        placeholder={EM.RSTR.PLACEHOLDER.PASSWORD}
                        disabled={auth.loading}
                    />
                </Form.Item>
                {!isEmpty(auth.error) && <p className={style.signinError}>{auth.error}</p>}
                {!isEmpty(auth.message) && <p className={style.signinMessage}>{auth.message}</p>}
                <Form.Item>
                    <Row className={style.signinThirdRow}>
                        <Col span={12}>
                            <Tooltip title={EM.RSTR.TEXT.TOOLTIP}>
                                <Dropdown menu={{ items }} trigger={['click']}>
                                    <Button size="middle">{loginDuration.name}</Button>
                                </Dropdown>
                            </Tooltip>
                        </Col>
                        <Col span={12}>
                            <Link className={style.signinCreate} to={EM.RSTR.URL.FORGET}>
                                {EM.RSTR.TEXT.FORGET}
                            </Link>
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item className={style.signinCenter}>
                    <Button size="large" type="primary" htmlType="submit"
                        disabled={auth.loading} className={style.registerButton}>
                        {EM.RSTR.TEXT.SIGNIN}
                    </Button>
                </Form.Item>
                <div className={style.signinCenter}>
                    {EM.RSTR.TEXT.ACCOUNTISNULL}&nbsp;
                    <Link className={style.signinCreate} to={EM.RSTR.URL.SIGNUP}>
                        {EM.RSTR.TEXT.CREATE}
                    </Link>
                </div>
            </Form>
        </div>
    )
}

export default Signin
