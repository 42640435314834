import React from 'react'
import { request } from '../../utils/request'
import { EM, IF } from '../../components/imports'
import { authStart, authFail, authSuccess, authUpdateControlSnap } from './auth'

export const updateCTRL = async (
  auth: IF.AUTH.AuthIF,
  dispatch: any,
  control: IF.CTRL.ControlIF,
  controlSnap: IF.CTRL.ControlIF,
  controlIconRef: any,
  fileObject: string | File,
  setControl: React.Dispatch<React.SetStateAction<IF.CTRL.ControlIF>>
) => {
  authStart(auth, dispatch)
  try {
    let response: any
    const url = EM.CTRL.URL.UPDATE + `${control.id}/`
    if (fileObject === '') {
      const isNew = control.image !== controlSnap.image || control.icon !== controlSnap.icon
      const input: any = {
        top: control.top,
        left: control.left,
        option: control.option,
        image: control.image,
        color: control.color,
        icon: control.icon,
        size: control.size,
        isNew: isNew,
      }
      response = await request(url, auth.token, input, 'patch')
    } else {
      const formData = new FormData()
      formData.append('top', control.top.toString())
      formData.append('left', control.left.toString())
      formData.append('option', control.option.toString())
      formData.append('color', control.color)
      formData.append('icon', control.icon)
      formData.append('size', control.size.toString())
      formData.append('session', auth.session!.split(':')[2])
      formData.append('file', fileObject)
      response = await request(url, auth.token, formData, 'put')
    }
    const updatedControl: IF.CTRL.ControlIF = {
      ...control,
      element: controlIconRef.current,
      ...response.data,
    }
    authUpdateControlSnap(dispatch, { ...updatedControl, element: null, showSave: false })
    setControl({ ...updatedControl, showSave: false })
    authSuccess(auth, dispatch)
  } catch (error: any) {
    authFail(auth, dispatch, error.response.data.error)
  }
}
