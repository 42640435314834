import React from 'react'
import style from '../../../assets/editor.intro.module.scss'
import { Col, Collapse, Input, Row, Select } from 'antd'
import { HomeTwoTone } from '@ant-design/icons'
import {
  EditorFontPicker,
  EditorHuePicker,
  EditorInput,
  EditorLabel,
  EditorOrder,
  EditorSlider,
  EditorSwitch,
  EditorWeight,
} from '../../Common/EditorParts'
import { EM, IF, RS } from '../../imports'
import * as m from '../methods'

const { Panel } = Collapse

const AddressSetting = (props: {
  value: IF.INTR.IntroAddressIF
  order: IF.INTR.IntroOrderIF[]
  control: IF.CTRL.ControlIF
  label: string
  attribute: string
  saveStyle: React.CSSProperties
  undoStyle: React.CSSProperties
  switchStyle: React.CSSProperties
  editorStyle: React.CSSProperties
  onChangeAttribute: (value: any, attribute: string) => void
  onChangeOrder: (value: boolean, attribute: string) => void
}) => {
  const {
    value,
    order,
    control,
    label,
    attribute,
    saveStyle,
    undoStyle,
    switchStyle,
    editorStyle,
    onChangeAttribute,
    onChangeOrder,
  } = props

  const fontText = value.text.slice(0, 15) + '...'
  const thisOrder = order.find((item: IF.INTR.IntroOrderIF) => item.key === attribute)!.order
  const disableUp = thisOrder === 1
  const disableDn = thisOrder === order.length
  const icon = value.meta.icon

  const onChangeStateName = (value: string) => {
    onChangeAttribute(value, EM.INTR.ATTRIBUTE.STATENAME)
  }

  const onChangeState = (result: { value: string; label: React.ReactNode }) => {
    onChangeAttribute(result.value, EM.INTR.ATTRIBUTE.STATECODE)
  }

  const onChangeCountry = (result: { value: string; label: React.ReactNode }) => {
    onChangeAttribute(result.value, EM.INTR.ATTRIBUTE.COUNTRYCODE)
  }

  return (
    <>
      <Row className={style.introFieldRow}>
        <EditorLabel span={6} label={label} iStyle={{ paddingLeft: '16px' }} />
        <Col span={18}>
          <div className={style.introColCenter} style={{ display: value.show }}>
            <div className={style.introBioIconDiv}>
              <HomeTwoTone style={{ fontSize: `${icon.size}px`, paddingRight: '10px' }} twoToneColor={icon.color} />
            </div>
            <div style={m.previewStyle(value)}>
              <div>{value.text}</div>
            </div>
          </div>
        </Col>
      </Row>
      <Collapse defaultActiveKey={['0']} accordion ghost className="fieldCollapse">
        <Panel header={label} key={0}>
          <Row className={style.introFieldRow}>
            <EditorLabel span={6} label={EM.INTR.LABEL.STREETONE} iStyle={editorStyle} />
            <EditorInput
              max={60}
              span={18}
              value={value.meta.streetOne}
              inputType={EM.COMM.TYPE.TEXT}
              attribute={EM.INTR.ATTRIBUTE.STREETONE}
              handleAttribute={onChangeAttribute}
            />
          </Row>
          <Row className={style.introFieldRow}>
            <EditorLabel span={6} label={EM.INTR.LABEL.CITY} iStyle={editorStyle} />
            <EditorInput
              max={60}
              span={18}
              value={value.meta.city!}
              inputType={EM.COMM.TYPE.TEXT}
              attribute={EM.INTR.ATTRIBUTE.CITY}
              handleAttribute={onChangeAttribute}
            />
          </Row>
          <Row className={style.introFieldRow}>
            <EditorLabel span={6} label={EM.INTR.LABEL.STATE} iStyle={editorStyle} />
            <Col span={18}>
              <div className={style.introColCenter}>
                {value.meta.countryCode !== EM.COMM.COUNTRY.US && value.meta.countryCode !== EM.COMM.COUNTRY.CA ? (
                  <Input
                    value={value.meta.stateName}
                    showCount
                    maxLength={60}
                    style={RS.COMM.width90}
                    onChange={(event: any) => onChangeStateName(event.target.value)}
                  />
                ) : (
                  <Select
                    value={{
                      value: `${value.meta.stateCode}:${value.meta.stateName}`,
                      label: value.meta.stateName,
                    }}
                    labelInValue
                    showSearch
                    optionFilterProp="children"
                    onChange={onChangeState}
                    filterOption={m.filterOption}
                    options={value.meta.countryCode === EM.COMM.COUNTRY.CA ? m.optionCanada : m.optionUS}
                    style={RS.COMM.width90}
                  ></Select>
                )}
              </div>
            </Col>
          </Row>
          <Row className={style.introFieldRow}>
            <EditorLabel span={6} label={EM.INTR.LABEL.COUNTRY} iStyle={editorStyle} />
            <Col span={18}>
              <div className={style.introColCenter}>
                <Select
                  value={{
                    value: `${value.meta.countryCode}:${value.meta.countryName}`,
                    label: value.meta.countryName,
                  }}
                  labelInValue
                  showSearch
                  optionFilterProp="children"
                  onChange={onChangeCountry}
                  filterOption={m.filterOption}
                  options={m.optionCountry}
                  style={RS.COMM.width90}
                ></Select>
              </div>
            </Col>
          </Row>
          <Row className={style.introFieldRow}>
            <EditorLabel span={6} label={EM.INTR.FIELDLABEL.FONT} iStyle={editorStyle} />
            <EditorFontPicker
              span={18}
              text={fontText}
              buttonWidth={'100px'}
              textWidth={'140px'}
              buttonColor={control.color}
              textColor={value.color}
              snapShot={value.font}
              fontSize={16}
              fontFamily={value.font}
              fontWeight={1}
              attribute={EM.INTR.FIELDKEY.FONT}
              handleAttribute={(value: IF.COMM.ValueNameIF, attribute: string) =>
                onChangeAttribute(value.value, attribute)
              }
            />
          </Row>
          <Row className={style.introFieldRow}>
            <EditorLabel span={6} label={EM.INTR.FIELDLABEL.SIZE} iStyle={editorStyle} />
            <EditorSlider
              min={1}
              max={50}
              span={18}
              value={value.size}
              width={RS.COMM.width90}
              attribute={EM.INTR.FIELDKEY.SIZE}
              handleAttribute={onChangeAttribute}
            />
          </Row>
          <Row className={style.introFieldRow}>
            <EditorLabel span={6} label={EM.INTR.FIELDLABEL.WEIGHT} iStyle={editorStyle} />
            <EditorWeight
              span={18}
              size={1}
              value={value.weight}
              saveStyle={saveStyle}
              undoStyle={undoStyle}
              attribute={EM.INTR.FIELDKEY.WEIGHT}
              handleAttribute={onChangeAttribute}
            />
          </Row>
          <Row className={style.introFieldRow}>
            <EditorLabel span={6} label={EM.INTR.FIELDLABEL.COLOR} iStyle={editorStyle} />
            <EditorHuePicker
              span={18}
              width={'70%'}
              color={value.color}
              attribute={EM.INTR.FIELDKEY.COLOR}
              undoStyle={undoStyle}
              handleAttribute={onChangeAttribute}
            />
          </Row>
          <Row className={style.introFieldRow}>
            <EditorLabel span={6} label={EM.INTR.FIELDLABEL.ORDER} iStyle={editorStyle} />
            <EditorOrder
              span={18}
              undoStyle={undoStyle}
              disableUp={disableUp}
              disableDn={disableDn}
              onChangeOrder={(up) => onChangeOrder(up, attribute)}
            />
          </Row>
          <Row className={style.introFieldRow}>
            <EditorLabel span={6} label={EM.INTR.FIELDLABEL.SHOW} iStyle={editorStyle} />
            <EditorSwitch
              span={18}
              show={value.show === EM.COMM.DISPLAY.FLEX}
              attribute={EM.INTR.FIELDKEY.SHOW}
              switchStyle={switchStyle}
              checkedChildren={EM.COMM.VAR.SHOW}
              unCheckedChildren={EM.COMM.VAR.HIDE}
              handleAttribute={(checked: boolean, attribute: string) => onChangeAttribute(checked, attribute)}
            />
          </Row>
        </Panel>
        <Panel header={EM.INTR.LABEL.ADDRESSICON} key={1}>
          <Row className={style.introFieldRow}>
            <EditorLabel span={6} label={EM.INTR.FIELDLABEL.ICONSSIZE} iStyle={editorStyle} />
            <EditorSlider
              min={1}
              max={50}
              span={18}
              value={value.meta.icon.size}
              width={RS.COMM.width90}
              attribute={EM.INTR.FIELDKEY.ICONSIZE}
              handleAttribute={onChangeAttribute}
            />
          </Row>
          <Row className={style.introFieldRow}>
            <EditorLabel span={6} label={EM.INTR.FIELDLABEL.ICONSCOLOR} iStyle={editorStyle} />
            <EditorHuePicker
              span={18}
              width={'70%'}
              color={value.meta.icon.color}
              attribute={EM.INTR.FIELDKEY.ICONCOLOR}
              undoStyle={undoStyle}
              handleAttribute={onChangeAttribute}
            />
          </Row>
        </Panel>
      </Collapse>
    </>
  )
}

export default AddressSetting
