import { OPTION } from '../enums/common'
import { IF } from '../components/imports'

export const frame1: IF.FRME.FrameIF = {
  id: 1,
  module: 1,
  width: 600,
  height: 450,
  top: 280,
  left: 840,
  option: 1,
  image: 'yellow',
  color: 'rgba(255, 87, 34, 1)',
  svg: [
    { color: 'rgb(55, 229, 19)', active: false, offset: '0.084' },
    { color: 'rgb(71, 28, 187)', active: true, offset: '0.520' },
    { color: 'rgb(238, 241, 11)', active: false, offset: '0.956' },
  ],
  justify: 'space-between',
  padding: 20,
  radius: 15,
  style: 2,
  lineWidth: 15,
  lineStyle: 'dotted',
  lineColor: 'rgb(132, 59, 59)',
  imageSource: 'https://www.w3schools.com/cssref/border.png',
  imageStyle: 'repeat',
  imageSlice: 30,
  imageSpace: 30,
  history: [],
}

export const frame2: IF.FRME.FrameIF = {
  id: 2,
  module: 2,
  width: 600,
  height: 450,
  top: 880,
  left: 840,
  option: 1,
  image: 'yellow',
  color: 'rgba(255, 87, 34, 1)',
  svg: [
    { color: 'rgb(55, 229, 19)', active: false, offset: '0.084' },
    { color: 'rgb(71, 28, 187)', active: true, offset: '0.520' },
    { color: 'rgb(238, 241, 11)', active: false, offset: '0.956' },
  ],
  justify: 'space-between',
  padding: 20,
  radius: 15,
  style: 2,
  lineWidth: 15,
  lineStyle: 'dotted',
  lineColor: 'rgb(132, 59, 59)',
  imageSource: 'https://www.w3schools.com/cssref/border.png',
  imageStyle: 'repeat',
  imageSlice: 30,
  imageSpace: 30,
  history: [],
}

export const previewBorder: IF.FRME.PreviewIF = {
  width: '70%',
  height: '150px',
  padding: '0px',
  background: 'grey',
  borderWidth: '15px',
  borderRadius: '15px',
  borderStyle: 'solid',
}

// export const imageOptions: string[] = [
//     'repeat',
//     'round',
//     'stretch',
//     'space',
// ]

export const borderList: IF.COMM.ImageIF[] = [{ url: 'https://www.w3schools.com/cssref/border.png', order: 1 }]

// export const selectImages: string[] = [
//     OPTION.SELECT,
//     OPTION.URL,
//     OPTION.UPLOAD,
// ]

export const selectBorders: IF.COMM.ValueNameIF[] = [
  { name: OPTION.IMAGE, value: OPTION.IMAGE },
  { name: OPTION.URL, value: OPTION.URL },
]

export const lineOptions: IF.COMM.ValueNameIF[] = [
  { name: 'None', value: 'none' },
  { name: 'Dashed', value: 'dashed' },
  { name: 'Dotted', value: 'dotted' },
  { name: 'Double', value: 'double' },
  { name: 'Groove', value: 'groove' },
  { name: 'Inset', value: 'inset' },
  { name: 'Outset', value: 'outset' },
  { name: 'Ridge', value: 'ridge' },
  { name: 'Solid', value: 'solid' },
]

export const arrangements: IF.COMM.ValueNameIF[] = [
  { name: 'Initial', value: 'initial' },
  { name: 'Start', value: 'flex-start' },
  { name: 'End', value: 'flex-end' },
  { name: 'Left', value: 'left' },
  { name: 'Center', value: 'center' },
  { name: 'Right', value: 'right' },
  { name: 'Space Between', value: 'space-between' },
  { name: 'Space Around', value: 'space-around' },
  { name: 'Space Evenly', value: 'space-evenly' },
]
