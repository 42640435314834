import { IF } from '../components/imports'
import { OPTIONNAME, OPTIONVALUE } from '../enums/background'
/**
 * Default select option for the editor dropdown component in the background setting.
 */
export const selectDefault: IF.BKGD.SelectOptionIF = {
  name: OPTIONNAME.DEFAULT,
  value: OPTIONVALUE.DEFAULT,
}

/**
 * Color select option for the editor dropdown component in the background setting.
 */
export const selectColor: IF.BKGD.SelectOptionIF = {
  name: OPTIONNAME.COLOR,
  value: OPTIONVALUE.COLOR,
}

/**
 * Gradient select option for the editor dropdown component in the background setting.
 */
export const selectGradient: IF.BKGD.SelectOptionIF = {
  name: OPTIONNAME.GRADIENT,
  value: OPTIONVALUE.GRADIENT,
}

/**
 * Select options for the editor dropdown component in the background setting.
 */
export const selectOptions: IF.BKGD.SelectOptionIF[] = [
  { name: OPTIONNAME.IMAGE, value: OPTIONVALUE.IMAGE },
  { name: OPTIONNAME.URL, value: OPTIONVALUE.URL },
  { name: OPTIONNAME.UPLOAD, value: OPTIONVALUE.UPLOAD },
  { name: OPTIONNAME.COLOR, value: OPTIONVALUE.COLOR },
  { name: OPTIONNAME.GRADIENT, value: OPTIONVALUE.GRADIENT },
]

export const backgrounds: IF.BKGD.BackgroundIF[] = []

export const background1: IF.BKGD.BackgroundIF = {
  id: 1,
  order: 1,
  title: '',
  hover: false,
  option: 1,
  height: '100vh',
  image: 'url("https://lcycstudio-staticfiles.s3.us-west-2.amazonaws.com/home/landing_1.png") center center / cover',
  color: 'yellow',
  svg: [
    { offset: '0.1', color: 'rgb(238, 241, 11)', active: true },
    { offset: '0.5', color: 'rgb(255, 34, 11)', active: false },
  ],
  history: [],
}

export const background2: IF.BKGD.BackgroundIF = {
  id: 2,
  order: 2,
  title: '',
  hover: false,
  option: 1,
  height: '100vh',
  image: 'url("https://lcycstudio-staticfiles.s3.us-west-2.amazonaws.com/home/landing_1.png") center center / cover',
  color: 'yellow',
  svg: [
    { offset: '0.1', color: 'rgb(238, 241, 11)', active: true },
    { offset: '0.5', color: 'rgb(255, 34, 11)', active: false },
  ],
  history: [],
}

export const introRBG: IF.COMM.RGBAIF = {
  r: 0,
  b: 0,
  g: 0,
  a: 0,
}

export const pickers: IF.COMM.ImageIF[] = [
  { url: 'https://havkonstudio-publicfiles.s3.us-west-2.amazonaws.com/havkonstudio/web_bg1.jpg', order: 1 },
  { url: 'https://havkonstudio-publicfiles.s3.us-west-2.amazonaws.com/havkonstudio/web_bg2.jpg', order: 2 },
  { url: 'https://havkonstudio-publicfiles.s3.us-west-2.amazonaws.com/havkonstudio/web_bg3.jpg', order: 3 },
  { url: 'https://havkonstudio-publicfiles.s3.us-west-2.amazonaws.com/havkonstudio/web_bg4.jpg', order: 4 },
  { url: 'https://havkonstudio-publicfiles.s3.us-west-2.amazonaws.com/havkonstudio/web_bg5.jpg', order: 5 },
  { url: 'https://havkonstudio-publicfiles.s3.us-west-2.amazonaws.com/havkonstudio/web_bg6.jpg', order: 6 },
  { url: 'https://havkonstudio-publicfiles.s3.us-west-2.amazonaws.com/havkonstudio/web_bg7.jpg', order: 7 },
  { url: 'https://havkonstudio-publicfiles.s3.us-west-2.amazonaws.com/havkonstudio/web_bg8.jpg', order: 8 },
  { url: 'https://havkonstudio-publicfiles.s3.us-west-2.amazonaws.com/havkonstudio/web_bg9.jpg', order: 9 },
]

export const history: IF.COMM.HistoryIF[] = [
  { value: 'https://havkonstudio-publicfiles.s3.us-west-2.amazonaws.com/havkonstudio/web_bg1.jpg', svg: [], option: 1 },
  { value: 'https://havkonstudio-publicfiles.s3.us-west-2.amazonaws.com/havkonstudio/web_bg2.jpg', svg: [], option: 1 },
  { value: 'https://havkonstudio-publicfiles.s3.us-west-2.amazonaws.com/havkonstudio/web_bg3.jpg', svg: [], option: 1 },
  { value: 'https://havkonstudio-publicfiles.s3.us-west-2.amazonaws.com/havkonstudio/web_bg4.jpg', svg: [], option: 1 },
  { value: 'rgb(69, 94, 211)', svg: [], option: 2 },
  {
    value: '',
    svg: [
      { id: 4, color: 'rgb(55, 229, 19)', active: false, offset: '0.084' },
      { id: 3, color: 'rgb(71, 28, 187)', active: true, offset: '0.520' },
      { id: 2, color: 'rgb(238, 241, 11)', active: false, offset: '0.956' },
    ],
    option: 3,
  },
]
