import { IF } from '../components/imports'
import { OPTION } from '../enums/common'

export const color: IF.COMM.ColorIF = { hex: '', rgb: '' }

export const bottomLine = '1px solid #f0f0f0'

export const showBlock = { display: 'block' }

export const showFlex = { display: 'flex' }

export const hideBlock = { display: 'none' }

export const width70 = { width: '70%' }

export const width73 = { width: '73%' }

export const width75 = { width: '75%' }

export const width80 = { width: '80%' }

export const width90 = { width: '90%' }

export const width100 = { width: '100%' }

/**
 * Default select option for select option dropdown element.
 */
export const selectOption: IF.COMM.ValueNameIF = {
  name: OPTION.DEFAULT,
  value: OPTION.DEFAULT,
}

export const select: IF.COMM.ValueNameIF = {
  name: OPTION.SELECT,
  value: OPTION.SELECT,
}

export const selectImage: IF.COMM.ValueNameIF = {
  name: OPTION.IMAGE,
  value: OPTION.IMAGE,
}

export const selectColor: IF.COMM.ValueNameIF = {
  name: OPTION.COLOR,
  value: OPTION.COLOR,
}

export const selectIcon: IF.COMM.ValueNameIF = {
  name: OPTION.ICON,
  value: OPTION.ICON,
}

export const selectGradient: IF.COMM.ValueNameIF = {
  name: OPTION.GRADIENT,
  value: OPTION.GRADIENT,
}

export const selection: IF.COMM.ValueNameIF[] = [
  { name: OPTION.IMAGE, value: OPTION.IMAGE },
  { name: OPTION.URL, value: OPTION.URL },
  { name: OPTION.UPLOAD, value: OPTION.UPLOAD },
  { name: OPTION.COLOR, value: OPTION.COLOR },
  { name: OPTION.GRADIENT, value: OPTION.GRADIENT },
]

export const selectionIcon: IF.COMM.ValueNameIF[] = [
  { name: OPTION.IMAGE, value: OPTION.IMAGE },
  { name: OPTION.ICON, value: OPTION.ICON },
  { name: OPTION.URL, value: OPTION.URL },
  { name: OPTION.UPLOAD, value: OPTION.UPLOAD },
]

export const selectionNoGrad: IF.COMM.ValueNameIF[] = [
  { name: OPTION.IMAGE, value: OPTION.IMAGE },
  { name: OPTION.URL, value: OPTION.URL },
  { name: OPTION.UPLOAD, value: OPTION.UPLOAD },
  { name: OPTION.COLOR, value: OPTION.COLOR },
]

export const palette: IF.COMM.SVGIF[] = [{ id: 1, offset: '0.1', color: 'rgb(238, 241, 11)', active: true }]

export const size2MB = 2097152

export const size3MB = 3145728

export const size5MB = 5242880

export const max30 = 30

export const max60 = 60

export const initialUploadPercent: IF.COMM.UploadStatusIF = { percentage: 0 }
