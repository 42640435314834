import {
  FacebookFilled,
  GithubFilled,
  LinkedinFilled,
  TwitterSquareFilled,
  InstagramFilled,
  YoutubeFilled,
} from '@ant-design/icons'

const IntroIcons = (props: { color: string }) => {
  const { color } = props
  const iconsStyle: React.CSSProperties = {
    padding: '6px',
    fontSize: '1.2rem',
    display: 'flex',
    justifyContent: 'space-evenly',
  }
  return (
    <div style={iconsStyle}>
      <FacebookFilled style={{ color: color }} />
      <TwitterSquareFilled style={{ color: color }} />
      <LinkedinFilled style={{ color: color }} />
      <InstagramFilled style={{ color: color }} />
      <YoutubeFilled style={{ color: color }} />
      <GithubFilled style={{ color: color }} />
    </div>
  )
}

export default IntroIcons
