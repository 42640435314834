import * as AUTH from './auth'
import * as BKGD from './background'
import * as CTRL from './control'
import * as FRME from './frame'
import * as USER from './user'
import * as INTR from './introduction'
import * as NAVG from './navigation'
import * as EM from '../enums'
import { IF } from '../components/imports'

export const modules: IF.STAT.ModuleIF[] = []

export const module1: IF.STAT.ModuleIF = {
  id: 1,
  type: EM.MODL.TYPE.INTRODUCTION,
  order: 1,
  title: 'intro 1',
  hover: false,
  show: true,
  status: 'active',
  frame: FRME.frame1,
  deletedTime: null,
  value: { introduction: INTR.introduction1 },
}

export const module2: IF.STAT.ModuleIF = {
  id: 2,
  type: EM.MODL.TYPE.INTRODUCTION,
  order: 2,
  title: 'intro 2',
  hover: false,
  show: true,
  status: 'active',
  frame: FRME.frame2,
  deletedTime: null,
  value: { introduction: INTR.introduction2 },
}

export const status: IF.STAT.StatusIF = {
  loading: false,
  complete: false,
}

export const state: IF.STAT.StateIF = {
  auth: AUTH.auth,
  status: status,
  profile: USER.profile,
  MODLSnapshot: modules,
  BKGDSnapshot: BKGD.backgrounds,
  CTRLSnapshot: CTRL.control,
  NAVGSnapshot: NAVG.navibar,
  MENUSnapshot: NAVG.menu,
}
