export enum BUTTON {
    DRAG = 'Left-click to move around',
    RIGHT = 'Right-click to display Edit',
    EDIT = 'Edit Module',
}

export enum EDITOR {
    DIMENSION = 'Dimension',
    POSITION = 'Postion',
    EDGE = 'Edge Spacing',
    JUSTIFY = 'Arrangement',
    RADIUS = 'Border Radius',
    STYLE = 'Border Style',
    LINE = 'Line Style',
    WIDTH = 'Line Size',
    COLOR = 'Line Color',
    IMAGEOPTION = 'Image Option',
    IMAGESTYLE = 'Image Style',
    IMAGEWIDTH = 'Image Size',
    IMAGESLICE = 'Image Slice',
    IMAGESPACE = 'Image Space',
}

export enum FORM {
    WIDTH = 'width',
    HEIGHT = 'height',
    TOP = 'top',
    LEFT = 'left',
    JUSTIFY = 'justify',
    PADDING = 'padding',
    RADIUS = 'radius',
    ROUND = 'round',
    REPEAT = 'repeat',
    STRETCH = 'stretch',
    SPACE = 'space',
    LINE = 'Line Style',
    IMAGE = 'Image Style',
    LINESTYLE = 'line style',
    LINEWIDTH = 'line width',
    LINECOLOR = 'line color',
    IMAGESTYLE = 'image style',
    IMAGEWIDTH = 'image width',
    IMAGESLICE = 'image slice',
    IMAGESPACE = 'image space',
    IMAGESOURCE = 'image source',
}

export enum STYLE {
    ROUND = 'Round',
    REPEAT = 'Repeat',
    STRETCH = 'Stretch',
    SPACE = 'Space'
}