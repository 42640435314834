import React from 'react'
import base from '../../assets/base.module.scss'
import style from '../../assets/register.module.scss'
import { useHistory } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from '../../../hooks'
import { Signin, Forget, Signup, Signout, Signok } from '../index'
import { isEmpty } from 'lodash'
import { EM, RX } from '../imports'


const Frame = () => {
    /** Redux Properties */
    const history = useHistory()
    const auth = useAppSelector(state => state.talentsReducer.auth)
    const dispatch = useAppDispatch()

    /** React Methods */
    React.useEffect(() => {
        RX.AUTH.authAutoSignin(auth, dispatch)
    }, [])

    const registerComponents = () => {
        switch (window.location.pathname) {
            case EM.RSTR.URL.SIGNIN:
                return <Signin auth={auth} dispatch={dispatch} />
            case EM.RSTR.URL.SIGNUP:
                if (auth.message) return <Signok auth={auth} dispatch={dispatch} />
                return <Signup auth={auth} dispatch={dispatch} />
            case EM.RSTR.URL.FORGET:
                if (auth.message) return <Forget auth={auth} dispatch={dispatch} />
                return <Forget auth={auth} dispatch={dispatch} />
            default:
                setTimeout(() => {
                    history.push(EM.RSTR.URL.SIGNIN)
                }, 1000)
                return registerLoading()
        }
    }

    const registerSignout = () => {
        return <Signout auth={auth} dispatch={dispatch} />
    }

    const registerLoading = () => {
        return <p>{EM.RSTR.TEXT.LOADING} </p>
    }

    return (
        <div className={base.baseColContainer}>
            <div className={style.registerFrame}>
                {!isEmpty(auth.token) && registerSignout()}
                {isEmpty(auth.token) && auth.loading && registerLoading()}
                {isEmpty(auth.token) && !auth.loading && registerComponents()}
            </div>
        </div>
    )
}

export default Frame
