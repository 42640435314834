import style from '../../assets/register.module.scss'
import { Link, useHistory } from 'react-router-dom'
import { Form, Input, Button } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { isEmpty } from 'lodash'
import { EM, RX } from '../imports'
import * as m from './methods'

const Forget = (props: any) => {
    /** Properties */
    const { auth, dispatch } = props
    const history = useHistory()

    // eslint-disable-next-line
    const validEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

    /** Local Methods */
    const onFinish = async (values: any) => {
        await RX.AUTH.authForget(auth, dispatch, values.email)
    }

    const goToSignIn = () => {
        RX.AUTH.authSetMessage(auth, dispatch, null)
        history.push(EM.RSTR.URL.SIGNIN)
    }

    return (
        <div className={style.registerDiv}>
            <div className={style.registerTitle}>
                {EM.RSTR.TITLE.FORGET}
            </div>
            {isEmpty(auth.message) &&
                <Form
                    name={EM.RSTR.FORMNAME.FORGETFORM}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name={EM.RSTR.TYPE.EMAIL}
                        rules={[
                            {
                                type: EM.RSTR.TYPE.EMAIL,
                                message: EM.RSTR.FORM.EMAILINVALID,
                            },
                            {
                                required: true,
                                message: EM.RSTR.FORM.EMAILMESSAGE,
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value) {
                                        return Promise.resolve()
                                    } else if (!auth.users.includes(getFieldValue(EM.RSTR.TYPE.EMAIL))
                                        && validEmail.test(value)) {
                                        return Promise.reject(new Error(EM.RSTR.FORM.EMAILISNULL))
                                    } else if (getFieldValue(EM.RSTR.TYPE.EMAIL).length >= 150) {
                                        return Promise.reject(new Error(EM.RSTR.FORM.EMAILMAXERR))
                                    } else {
                                        return Promise.resolve()
                                    }
                                },
                            }),
                        ]}
                    >
                        <Input
                            size="large"
                            type={EM.RSTR.TYPE.EMAIL}
                            prefix={<UserOutlined />}
                            placeholder={EM.RSTR.PLACEHOLDER.EMAIL}
                            disabled={auth.loading}
                        />
                    </Form.Item>
                    {!isEmpty(auth.error) && <p className={style.signupError}>{auth.error}</p>}
                    <Form.Item className={style.registerCenter}>
                        <Button size="large" type="primary" htmlType="submit"
                            disabled={auth.loading} className={style.registerButton}>
                            {EM.RSTR.TEXT.REQUEST}
                        </Button>
                    </Form.Item>
                    <div className={style.registerCenter}>
                        {EM.RSTR.TEXT.ACCOUNTEXITS}&nbsp;
                        <Link className={style.signinCreate} to={EM.RSTR.URL.SIGNIN}>
                            {EM.RSTR.TEXT.SIGNIN}
                        </Link>
                    </div>
                </Form>
            }
            {auth.message === EM.RSTR.AUTH.DONE &&
                <>
                    <div>{m.emailSent}</div>
                    <div className={style.registerMessage} onClick={goToSignIn}>
                        <Button size="large" type="primary" htmlType="submit"
                            disabled={auth.loading} className={style.registerButton}>
                            {EM.RSTR.TEXT.SIGNIN}
                        </Button>
                    </div>
                </>
            }
        </div>
    )
}

export default Forget
